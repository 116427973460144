import { observable } from "mobx";
import Tracking from "../Tracking";

class TermsOfUse {
  @observable open: boolean = false

  onClose: any

  show = (onClose?: any) => {
    this.open = true
    this.onClose = onClose
    Tracking.modalView("TermsOfUseDialog")
  }

  close = () => {
    this.open = false
    if (this.onClose) {
      this.onClose()
    }
  }

}

export default TermsOfUse