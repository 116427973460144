import * as React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  IconButton, Menu, MenuItem,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Registration from "../../model/Registration";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {ClassStatus} from "../../API";
import LinearProgress from "@material-ui/core/LinearProgress";
import {isoToLocalDateTime} from "../../stores/StoreUtilities";
import {format} from 'date-fns';
import {observable} from "mobx";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import UserStore from "../../stores/UserStore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    height: 78,
    padding: 0
  },
  content: {
    display: "flex",
    padding: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  left: {
    flexShrink: 0,
    padding: 0,
    alignSelf: "center",
  },
  middle: {
    flexGrow: 1,
    padding: "5px 10px",
  },
  right: {
    flexShrink: 0,
    paddingLeft: 10,
    alignSelf: "right",
  },
  media: {
    width: 78,
    height: 78
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
  },
  // Avatar Styles
  unRegisteredAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: 35,
    height: 35,
  },
  unregisteredIcon: {
    color: theme.palette.text.secondary,
    width: 25,
    height: 25,
  },
  notStartedAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: 35,
    height: 35,
  },
  notStartedIcon: {
    color: theme.palette.text.secondary,
    width: 25,
    height: 25,
  },
  inProgressAvatar: {
    width: 35,
    height: 35,
  },
  inProgressIcon: {
    color: theme.palette.text.primary,
    width: 25,
    height: 25,
  },
  failedAvatar: {
    backgroundColor: theme.palette.error.dark,
    width: 35,
    height: 35,
  },
  failedIcon: {
    color: theme.palette.error.contrastText,
    width: 25,
    height: 25,
  },
  passedAvatar: {
    backgroundColor: theme.palette.success.main,
    width: 35,
    height: 35,
  },
  passedIcon: {
    color: theme.palette.success.contrastText,
    width: 25,
    height: 25,
  },
  // Progress styles
  progressBar: {
    marginTop: 5
  },
  linearProgress: {
    position: "relative",
    bottom: 16,
    left: 2,
    width: "calc(100% - 4px)",
    height: 3,
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    zIndex: 10
  },
  linearProgressDeterminateColor: {
    backgroundColor: theme.palette.primary.light
  },
  classProgressNotStarted: {
    backgroundColor: theme.palette.primary.light
  },
  classProgressPassing: {
    backgroundColor: theme.palette.success.main
  },
  classProgressFailing: {
    backgroundColor: theme.palette.warning.dark,
  },
  classProgressFailed: {
    backgroundColor: theme.palette.error.dark,
  },
  addButton: {
    backgroundColor: theme.palette.background.paper,
    width: 20,
    height: 20,
  },
  addIcon: {
    color: theme.palette.secondary.main,
    // color: theme.palette.success.main,
    width: 20,
    height: 20,
  },
  removeButton: {
    backgroundColor: theme.palette.background.paper,
    width: 20,
    height: 20,
  },
  removeIcon: {
    color: theme.palette.secondary.main,
    // color: theme.palette.error.main,
    width: 20,
    height: 20,
  },
  iconButton: {
    padding: 4
  },
  menuIcon: {
    color: theme.palette.text.secondary,
    width: 20,
    height: 20,
    zOrder: 100
  },

})

interface IClassHistoryCardProps {
  s3UrlCacheStore?: S3UrlCacheStore
  registration: Registration
  onRegister?: any
  onUnregister?: any
  onReset?: any
  userStore?: UserStore
}

@inject("s3UrlCacheStore", "userStore")
@observer
class ClassHistoryCard extends React.Component<WithStyles<typeof styles> & IClassHistoryCardProps> {

  @observable coverImageUrl?: string
  @observable resetEnabled = false
  @observable menuAnchor = null

  componentDidMount() {
    const { s3UrlCacheStore, registration, userStore } = this.props

    this.resetEnabled = userStore!.isAdminOrAgent ||
      (userStore!.isEmployer && registration.class !== undefined && registration.class.course !== undefined && registration.class.course.isFree)

    if (registration.class && registration.class.course && registration.class.course.coverUrl) {
      const coverUrl = registration.class.course.coverUrl
      s3UrlCacheStore!.get(coverUrl)
        .then((url: string | undefined) => {
          this.coverImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading cover image")
        })
    }
  }

  render() {
    const { classes, registration } = this.props

    const user = registration.user
    if (!user) {
      return null
    }

    let course = null
    let title = ""
    let started = ""
    let status = ""
    if (registration.class && registration.class.course) {
      course = registration.class.course
      title = course.title

      if (registration.classStatus === ClassStatus.NotStarted) {
        started = "Not Started"
      } else {
        if (registration.startedAt) {
          const startedAt = isoToLocalDateTime(registration.startedAt)
          started = `Started on ${format(startedAt, 'M/d/yyyy')}`
        } else {
          started = `Started`
        }
      }

      if (registration.classStatus === ClassStatus.InProgress) {
        status = `${Math.round(registration.classProgress)}% Completed`
      } else if (registration.classCompleted) {
        if (registration.completedAt) {
          const completedAt = isoToLocalDateTime(registration.completedAt)
          status = `${registration.classStatus} on ${format(completedAt, 'M/d/yyyy')}`
        } else {
          status = 'Completed'
        }
      } else if (registration.classStatus === ClassStatus.NotStarted && registration.createdAt) {
        const createdAt = isoToLocalDateTime(registration.createdAt)
        status = `Added on ${format(createdAt, 'M/d/yyyy')}`
      }

    }

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.left}>
            <CardMedia className={classes.media}
                       image={this.coverImageUrl}
                       title={title}/>
            { this.renderProgress() }
          </div>
          <div className={classes.middle}>
            <Typography className={classes.title}>
              {title}
            </Typography>
            <Typography className={classes.details}>
              {started}
            </Typography>
            <Typography className={classes.details}>
              {status}&nbsp;
            </Typography>
          </div>
          <div className={classes.right}>
            { this.renderButton() }
          </div>
        </CardContent>
      </Card>
    )
  }

  renderAvatar = () => {
    const { classes, registration } = this.props

    let avatar = null

    if (!registration.id) {
      avatar = <Avatar className={classes.unRegisteredAvatar} >
        <AssignmentIndIcon className={classes.unregisteredIcon}/>
      </Avatar>
    } else {
      if (registration.classStatus === ClassStatus.NotStarted) {
        avatar = <Avatar className={classes.notStartedAvatar}>
          <AssignmentIcon className={classes.notStartedIcon}/>
        </Avatar>
      } else if (registration.classStatus === ClassStatus.InProgress) {
        avatar = <Avatar className={classes.inProgressAvatar}>
          <AssignmentIcon className={classes.inProgressIcon} />
        </Avatar>
      } else if (registration.classStatus === ClassStatus.Failed) {
        avatar = <Avatar className={classes.failedAvatar}>
          <AssignmentTurnedInIcon className={classes.failedIcon} />
        </Avatar>
      } else if (registration.classStatus === ClassStatus.Passed) {
        avatar = <Avatar className={classes.passedAvatar}>
          <AssignmentTurnedInIcon className={classes.passedIcon} />
        </Avatar>
      }
    }

    return avatar
  }

  renderProgress = () => {
    const { classes, registration } = this.props

    let result = null

    if (registration.id) {

      let progressClassName = classes.classProgressNotStarted
      if (registration.classStatus === ClassStatus.InProgress) {
        progressClassName = registration.passing ? classes.classProgressPassing : classes.classProgressFailing
      } else if (registration.classStatus === ClassStatus.Failed) {
        progressClassName = classes.classProgressFailed
      } else if (registration.classStatus === ClassStatus.Passed) {
        progressClassName = classes.classProgressPassing
      }
      result =
        <div className={classes.progressBar}>
          <LinearProgress className={classes.linearProgress}
                          classes={{barColorPrimary: progressClassName, determinate: classes.linearProgressDeterminateColor}}
                          variant="determinate" value={registration.classProgress}/>
        </div>
    }

    return result
  }

  private renderButton() {
    const { classes, registration } = this.props

    let button = null

    if (!registration.id) {
      button =
        <IconButton className={classes.addButton} onClick={this.onRegister} aria-label="assign">
          <AddCircleIcon className={classes.addIcon}/>
        </IconButton>
    } else if (registration.classStatus === ClassStatus.NotStarted) {
      button =
        <IconButton className={classes.removeButton} onClick={this.onUnregister} aria-label="unassign">
          <RemoveCircleIcon className={classes.removeIcon}/>
        </IconButton>
    } else if (registration.classCompleted) {
      button = this.renderMenu()
    }

    return button
  }

  renderMenu = () => {
    const { classes, registration } = this.props

    if (!this.resetEnabled && !registration.classCompleted) {
      return null
    }

    const menuId = `class-menu`

    return (
      <div>
        <IconButton
          color="inherit"
          className={classes.iconButton}
          aria-label="Menu"
          aria-owns={this.menuAnchor ? menuId : undefined}
          aria-haspopup="true"
          onClick={this.onOpenMenu}
        >
          <MoreVertIcon className={classes.menuIcon}/>
        </IconButton>
        <Menu id={menuId}
              anchorEl={this.menuAnchor}
              keepMounted
              open={Boolean(this.menuAnchor)}
              onClose={this.onCloseMenu}
        >
          {this.resetEnabled &&
            <MenuItem onClick={this.onResetClass}>Reset Class</MenuItem>
          }
        </Menu>
      </div>
    )
  }

  onRegister = () => {
    const { registration, onRegister} = this.props

    if (onRegister) {
      onRegister(registration)
    }
  }

  onUnregister = () => {
    const { registration, onUnregister} = this.props

    if (onUnregister) {
      onUnregister(registration)
    }
  }

  onOpenMenu = (event: any) => {
    console.log("onOpenMenu")
    this.menuAnchor = event.currentTarget
  }

  onCloseMenu = () => {
    this.menuAnchor = null
  }

  onResetClass = () => {
    const { onReset, registration } = this.props
    if (onReset) {
      onReset(registration)
    }
    this.onCloseMenu()
  }



}

export default withStyles(styles)(ClassHistoryCard)
