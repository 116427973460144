import {observable} from "mobx";
import LearningAPI from "../apis/LearningAPI";
import * as APITypes from "../API"
import {Account} from "../model/Account";
import Class from "../model/Class";
import CourseStore from "./CourseStore";
import Course from "../model/Course";
import {
  AccountType,
  CreateClassInput,
  CreateRegistrationInput,
  UpdateAccountInput,
  UpdateClassInput
} from "../API";
import {addYears, format, subDays} from "date-fns";
import {createUUID, getISODateFromDate, isoToLocalDate, phoneToE164Format} from "./StoreUtilities";
import User from "../model/User";
import Logger from "../components/Logger";
import Registration from "../model/Registration";
import Tracking from "../components/Tracking";
import ControlTower, {Routes} from "../components/ControlTower";
import Invoice from "../model/Invoice";
import BillingAPI from "../apis/BillingAPI";
import {Activity} from "../model/Activity";
import Charge from "../model/Charge";
import CertificateAPI from "../apis/CertificateAPI";

export const AccountStoreConstants = {
  PRIMARY_ACCOUNT_ID: "primary"
}

class AccountStore {
  learningAPI: LearningAPI
  billingAPI: BillingAPI
  certificateAPI: CertificateAPI
  courseStore: CourseStore

  @observable account?: Account
  @observable isLoading = true

  accounts: Account[] = []
  classes: Class[] = []
  users: User[] = []

  constructor(options: any) {
    this.learningAPI = (options && options.learningAPI) ? options.learningAPI : null
    this.billingAPI = (options && options.billingAPI) ? options.billingAPI : null
    this.certificateAPI = (options && options.certificateAPI) ? options.certificateAPI : null
    this.courseStore = (options && options.courseStore) ? options.courseStore : null
  }

  async init(account: Account) {
    this.account = account
    await this.joinCourses()
    this.isLoading = false
  }

  async loadAccount(accountId: string) {
    if (!this.account || this.account.id !== accountId) {
      console.log(`loadingAccount(${accountId})`)
      this.isLoading = true
      const accountData = await this.learningAPI.getAccount(accountId)
        .catch((err: Error) => {
          console.log(`getAccount error: ${err.message}`)
          ControlTower.route(Routes.notFound)
        })
      if (accountData) {
        const account = new Account(accountData)
        await this.init(account)
        return account
      }
    } else {
      return this.account
    }
  }

  get isAgency() {
    return this.account ? this.account.accountType === AccountType.Agency : false
  }

  get isIndividual() {
    return this.account ? this.account.id === "Primary" : false
  }

  listAccounts = async (): Promise<Account[]> => {
    if (this.accounts.length === 0) {
      const data = await this.learningAPI.listAccounts()
      if (data && data.items) {
        this.accounts = data.items.map((item: any) => {
          return new Account(item)
        })
      }
    }

    return [...this.accounts]
  }

  getAccount = async (accountId: string): Promise<Account | undefined> => {
    let account: Account | undefined

    if (accountId === this.account!.id) {
      account = this.account
    } else {
      account = this.accounts.find((a: Account) => a.id === accountId)
    }

    if (!account) {
      const data = await this.learningAPI.getAccount(accountId)
      if (data) {
        account = new Account(data)
      }
    }

    return account
  }

  async createAccount(input:  APITypes.CreateAccountInput) {
    const account = await this.learningAPI!.createAccount(input)
    if (account) {
      Tracking.event({action: "Create Account"})
      this.account = new Account(account)
      this.isLoading = false
      this.accounts.push(this.account)
      return this.account
    } else {
      return null
    }
  }

  async updateAccount(input:  APITypes.UpdateAccountInput) {
    const result = await this.learningAPI!.updateAccount(input)
    if (result) {
      // Tracking.event({action: "Create Account"})
      const account = new Account(result)
      if (!account.classes || account.classes.length === 0) {
        account.classes = this.account!.classes
      }
      if (!account.users || account.users.length === 0) {
        account.users = this.account!.users
      }
      this.account = account

      // Update cache
      const index = this.accounts.findIndex((a: Account) => a.id === account.id)
      if (index >= 0) {
        this.accounts[index] = account
      } else {
        this.accounts.push(account)
      }
      return this.account
    } else {
      return null
    }
  }

  listClasses = async (): Promise<Class[]> => {
    if (this.account && this.account.classes) {
      await this.joinCourses()
      return [...this.account.classes]
    } else {
      return []
    }
  }

  getClass = (classId: string): Class | undefined => {
    const classObj = this.account!.classes.find((c: Class) => { return c.id === classId})
    return classObj
  }

  getClassAndRegistrations = async (classId: string): Promise<Class | undefined> => {
    const data = await this.learningAPI.getClass(classId)
    if (data) {
      const registrationCount = data.registrations && data.registrations.items ? data.registrations.items.length : 0
      if (data.seatsFilled === null || data.seatsFilled !== registrationCount) {
        // Update seatsFilled
        const input: UpdateClassInput = {
          id: data.id,
          accountId: data.accountId,
          seatsFilled: registrationCount
        }
        const result = await this.updateClass(input)
        if (result) {
          data.seatsFilled = result.seatsFilled
        }
      }
      return new Class(data)
    } else {
      return undefined
    }
  }

  async addClass(course: Course) {
    const termBegin = new Date()
    let termEnd = addYears(termBegin, 1)
    termEnd = subDays(termEnd, 1)
    const classInput: CreateClassInput = {
      courseId: course.id,
      accountId: this.account!.id,
      termBegin: getISODateFromDate(termBegin),
      termEnd: getISODateFromDate(termEnd)
    }
    const classObj = await this.learningAPI.createClass(classInput)
    if (classObj) {
      const newClass = new Class(classObj)
      newClass.course = course
      this.account!.classes.push(newClass)
      return newClass
    } else {
      return null
    }
  }

  async addPaidClass(classInput: CreateClassInput, course: Course) {
    const classObj = await this.learningAPI.createClass(classInput)
    if (classObj) {
      const newClass = new Class(classObj)
      newClass.course = course
      this.account!.classes.push(newClass)
      return newClass
    } else {
      return null
    }
  }

  async addFreeClass() {
    const courses = await this.courseStore!.listCourses()
    if (courses && courses.length > 0) {
      const course = courses.find((c: Course) => {
        return c.isFree
      })
      if (course) {
        return await this.addClass(course)
      }
    }
    return null
  }

  async updateClass(input:  APITypes.UpdateClassInput) {
    const result = await this.learningAPI!.updateClass(input)
    if (result) {
      // Tracking.event({action: "Create Account"})
      let found = this.account!.classes.find((c: Class) => c.id === result.id)
      if (found) {
        found.update(result)
      } else {
        found = new Class(result)
      }
      return found
    } else {
      return null
    }
  }

  async addClassRegistration(input: CreateRegistrationInput) {

    // Verify seat availability in class
    const classObj = this.getClass(input.classId)
    if (!classObj) {
      throw new Error("Unable to find class")
    }

    if (!classObj.course!.isFree) {
      if (!classObj.seatsPurchased || (classObj.seatsFilled && classObj.seatsFilled >= classObj.seatsPurchased)) {
        throw new Error(`${classObj.course!.title} is full. Please add more seats.`)
      }
    }

    const result = await this.learningAPI!.createRegistration(input)
      .catch(err => {
        Logger.error("Unable to create class registration")
      })

    if (result) {
      const registration = new Registration(result)
      this.joinClassToRegistration(registration)
      return registration
    } else {
      return null
    }
  }

  async deleteClassRegistration(id: string) {

    const result = await this.learningAPI!.deleteRegistration(id)
      .catch(err => {
        Logger.error("Unable to delete class registration")
      })

    if (result) {
      const registration = new Registration(result)
      return registration
    } else {
      return null
    }
  }

  async createCertificate(registration: Registration): Promise<Blob> {

    const completedDate = isoToLocalDate(registration.completedAt)
    const result = await this.certificateAPI.create("StandardCertificateForm.pdf",
      {
        student: `${registration.user?.firstName} ${registration.user?.lastName}`,
        course: registration.class?.course?.title,
        day: format(completedDate, "do"),
        monthYear: format(completedDate, "MMMM, yyyy")
      })

    return result
  }

  async joinCourses() {
    if (this.account && this.account.classes) {
      const courses = await this.courseStore!.listCourses()

      this.account.classes.forEach((c: Class) => {
        const courseFound = courses.find((course: Course) => course.id === c.courseId)
        if (courseFound) {
          c.course = courseFound
        }
      })
      // Sort classes by course title
      this.account.classes.sort((a: Class, b: Class) => {
        if (a.course && b.course) {
          return a.course.title.localeCompare(b.course.title)
        } else {
          return a.id.localeCompare(b.id)
        }
      })

    }
  }

  listUsers = async (accountId: string): Promise<User[]> => {
    let users: User[] = []

    if (accountId) {
      let account = await this.getAccount(accountId)

      if (account) {
        if (account.users.length === 0) {
          const data = await this.learningAPI.getAccountUsers(accountId)
          if (data) {
            await account.loadUsers(data.users!.items!)
          }
        }

        users = [...account.users]
      }
    }

    return users
  }

  getUser = async (userId: string, accountId?: string): Promise<User | undefined> => {
    let user : User | undefined

    if (accountId) {
      let account = await this.getAccount(accountId)
      if (account) {
        user = account.users.find((u: User) => u.id === userId)
      }
    }

    if (!user) {
      const data = await this.learningAPI.getUser(userId)
      if (data) {
        user = new User(data)
        this.joinClassesToUser(user)
      }
    }

    return user
  }

  joinClassesToUser = (user: User) => {
    if (this.account && this.account.classes && user.registrations.length > 0) {
      user.registrations.forEach((r: Registration) => {
        r.class = this.findClass(r.classId)
      })
    }
  }

  joinClassToRegistration = (registration: Registration) => {
    if (!registration.class) {
      registration.class = this.findClass(registration.classId)
    }
  }

  findUser = async (email: string): Promise<User | undefined> => {
    let user
    const users = await this.listUsers(this.account!.id)
    if (users && users.length > 0) {
      const search = email.toLowerCase()
      user = users.find((u: User) => {
        return u.email === search
      })
    }
    return user
  }

  findClass = (id: string) => {
    return this.account!.classes.find((c: Class) => c.id === id)
  }

  async updateUser(input: APITypes.UpdateUserInput) {
    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const update = await this.learningAPI!.updateUser(input)
    if (update) {
       const user = new User(update)
       // Update list
       const index = this.account!.users.findIndex((u: User) => { return u.id === input.id})
       if (index >= 0) {
          this.account!.users[index] = user
       }
       return user
    } else {
      return null
    }
  }

  async createUser(input: APITypes.CreateUserInput) {
    // Verify email is not in use
    const existingUser = await this.findUser(input.email)
    if (existingUser) {
      throw Error("This email is already in use")
    }

    input.id = createUUID()

    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }

    const data = await this.learningAPI!.createUser(input)
    if (data) {
      Tracking.event({action: "Create User"})
      const user = new User(data)
      this.account!.users.push(user)
      return user
    } else {
      return null
    }
  }

  async deleteUser(userId: string) {
    const result = await this.learningAPI!.deleteUser(userId)
    if (result) {
      const user = new User(result)
      // Remove from list
      const index = this.account!.users.findIndex((u: User) => u.id === userId)
      if (index >= 0) {
        this.account!.users.splice(index, 1)
      }
      return user
    } else {
      return null
    }
  }

  // Billing Related Methods

  createInvoice = async (user: User, course: Course, couponId: string | undefined, quantity: number, amount: number, tokenId: string | undefined) => {
    const invoice = new Invoice({
      customer: this.account!.customerId,
      collection_method: "charge_automatically",
      auto_advance: true,
      items: [
        {
          customer: this.account!.customerId,
          unit_amount: Math.round(amount * 100),
          quantity: quantity,
          currency: "usd",
          description: course.title,
        }
      ]
    })

    if (couponId) {
      invoice.items[0].discounts = [
        {coupon: couponId}
      ]
    }

    const result = await this.billingAPI.createInvoice(user, this.account!, invoice, tokenId)

    if (!this.account!.customerId && result && result.customer) {
      const input: UpdateAccountInput = {
        id: this.account!.id,
        customerId: result.customer
      }
      const account = await this.updateAccount(input)
      if (account) {
        this.account!.customerId = account.customerId
      }
    }
    // console.log(`createInvoice = ${JSON.stringify(result)}`)

    return (result)
  }

  getCustomer = async (id: string) => {
    const result = await this.billingAPI.getCustomer(id)
    return result
  }

  deleteSource = async (sourceId: string) => {
    if (this.account!.customerId) {
      return await this.billingAPI.deleteSource(this.account!.customerId, sourceId)
    } else {
      return null
    }
  }

  // Charges methods

  listCharges = async (accountId: string) => {
    let charges: Charge[] = []

    const account = await this.getAccount(accountId)
    if (account && account.customerId) {
      const result = await this.billingAPI.getCharges(account.customerId)
      if (result && result.data) {
        charges = result.data.map((item: any) => {
          return new Charge(item)
        })
      }
    }

    return charges
  }

  // Activity related methods

  listAccountActivity = async (accountId: string, filter: APITypes.ModelActivityFilterInput): Promise<Activity[]> => {
    const data = await this.learningAPI.listAccountActivity(accountId, filter)
    if (data && data.items) {
      return data.items.map((item: any) => {
        return new Activity(item)
      })
    }

    return []
  }

  listActivityByDate = async (date: string, filter?: APITypes.ModelActivityFilterInput): Promise<Activity[]> => {
    let activities: Activity[] = []

    const data = await this.learningAPI.listActivityByDate(date, filter)
    if (data && data.items) {
      activities = data.items.map((item: any) => {
        return new Activity(item)
      })
      await this.joinActivities(activities)
    }

    return activities
  }

  joinActivities = async (activities: Activity[]) => {
    if (this.accounts.length === 0) {
      await this.listAccounts()
    }

    for (const a of activities) {
      a.account = await this.getAccount(a.accountId)
      if (a.account && a.account.users.length === 0) {
        await this.listUsers(a.accountId)
      }
      a.user = await this.getUser(a.userId, a.accountId)
    }
  }

}

export default AccountStore