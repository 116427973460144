import * as React from "react";
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {FormControlProps} from "@material-ui/core/FormControl";
import {inject} from "mobx-react";
// import {isEmail, isMobilePhone, isPostalCode, isURL} from "validator"
import {IFieldValidator} from "./FormValidator"
// import {isEmail, isMobilePhone, isPostalCode, isURL, MobilePhoneLocale} from "validator";
import isPostalCode, {PostalCodeLocale} from "validator/lib/isPostalCode";
import isEmail from "validator/lib/isEmail";
import isMobilePhone, {MobilePhoneLocale} from "validator/lib/isMobilePhone";
import isURL from "validator/lib/isURL";
import isFloat from 'validator/lib/isFloat'
import {parse} from "date-fns";
import {Autocomplete} from "@material-ui/lab";


// type MobilePhoneLocale = ValidatorJS.MobilePhoneLocale;
// type PostalCodeLocale = ValidatorJS.PostalCodeLocale;

const styles = (theme: Theme) => createStyles({
  root: {
  }
})

interface ITextFieldValidations {
  required?: boolean
  minLength?: number
  maxLength?: number
  isEmail?: boolean | null
  isMobilePhone?: boolean | null
  isPostalCode?: string | null
  isStrongPassword?: number
  matches?: string
  isURL?: boolean
  isURLMatch?: string
  isUsername?: string
  minDate?: string
  maxDate?: string
  minValue?: number
  maxValue?: number
  isFloat?: number | null
}

interface ITextFieldValdatorProps {
  error?: boolean
  helperText?: React.ReactNode
  label?: React.ReactNode
  inputRef?: any
  required?: boolean
  validators?: ITextFieldValidations
  errorMessages?: any
  onBlur?: any // React.EventHandler<any>
  onChange?: any // React.EventHandler<any>
  formvalidator?: any
  validate?: any
  className?: string
  autocompleteOptions?: any
}

export type TextFieldValidatorProps = TextFieldProps & ITextFieldValdatorProps & IFieldValidator

@inject("formvalidator")
class TextFieldValidator extends React.Component<WithStyles<typeof styles> & TextFieldValidatorProps > {

  state = {
    isValid: true,
    errorMessage: "",
    value: "",
  }

  componentDidMount() {
    const {formvalidator} = this.props as ITextFieldValdatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
  }

  /* eslint-disable no-template-curly-in-string */

  static errorMessages = {
    required: "${label} is required",
    minLength: "${label} must be at least ${param} characters",
    maxLength: "${label} must be no more than ${param} characters",
    isMobilePhone: "Invalid phone number",
    isEmail: "Invalid email",
    isPostalCode: "Invalid postal code",
    isStrongPassword: "Password must be at least 8 characters with at least one upper-case, digit or symbol character.",
    matches: "Invalid ${label}",
    isURL: "Invalid URL",
    isURLMatch: "Invalid URL",
    isUsername: "Username must be at least 8 characters of (a-z, 0-9, -._)",
    minDate: "${label} must be >= ${param}",
    maxDate: "${label} must be <= ${param}",
    minValue: "${label} must be >= ${param}",
    maxValue: "${label} must be <= ${param}",
    isFloat: "Must be a number"
  }

  static defaultLocale = "en-US"
  static defaultCountry = "US"
  static minPasswordLength = 8

  render() {
    const {
      inputRef,
      error,
      onBlur,
      onChange,
      autocompleteOptions,
      ...rest
    } = this.props as ITextFieldValdatorProps;

    if (autocompleteOptions) {
      return (
        <Autocomplete
          autoSelect
          includeInputInList
          {...autocompleteOptions}
          onChange={this.onAutocompleteChange}
          renderInput={(params) =>
            <div ref={params.InputProps.ref}>
              <TextField
                {...params}
                {...rest}
                onBlur={(event: any) => this.onBlur(event, params.inputProps)}
                className={this.props.className ?? this.props.classes.root}
                error={!this.state.isValid || this.props.error}
                helperText={((!this.state.isValid || this.props.error) && this.state.errorMessage) || this.props.helperText}
              /></div>}
        />
      )
    } else {
      return (
        <TextField
          {...rest}
          className={this.props.className ?? this.props.classes.root}
          onBlur={this.onBlur}
          onChange={this.onChange}
          error={!this.state.isValid || this.props.error}
          helperText={((!this.state.isValid || this.props.error) && this.state.errorMessage) || this.props.helperText}
        />
      )
    }
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>, inputParams?: any): void => {
    const { value } = event.currentTarget;
    const { props } = this
    const { onBlur } = props as FormControlProps

    this.validate(value)

    if (onBlur) {
      onBlur(event);
    }

    if (inputParams && inputParams.onBlur) {
      inputParams.onBlur(event)
    }
  }

  private onChange = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const { props } = this
    const { onChange } = props as FormControlProps

    // console.log(`onChange(value='${value}')`)

    if (!this.state.isValid) {
      this.validate(value)
    }

    if (onChange) {
      onChange(event);
    }
  }

  private onAutocompleteChange = (event: any, value: any, reason: string): void => {
    const { props } = this
    const { onChange, autocompleteOptions } = props // as FormControlProps

    // console.log(`onAutocompleteChange(value='${value}', reason='${reason}')`)

    if (!this.state.isValid) {
      this.validate(value)
    }

    if (autocompleteOptions.onChange) {
      autocompleteOptions.onChange(event, value, reason)
    } else if (onChange) {
      if (typeof value !== 'string' && autocompleteOptions.getOptionLabel) {
        onChange({target: {name: props.name, value: autocompleteOptions.getOptionLabel(value)}});
      } else {
        onChange({target: {name: props.name, value: value}});
      }
    }
  }

  validate = (value: any): boolean => {
    const { validators, errorMessages, autocompleteOptions } = this.props as ITextFieldValdatorProps
    let isValid = true

    if (autocompleteOptions && autocompleteOptions.getOptionLabel && typeof value !== 'string') {
      value = autocompleteOptions.getOptionLabel(value)
    }

    if (validators) {
      const errorName = Object.keys(validators).find((validatorName: string) => {
        if (TextFieldValidator.validators[validatorName]) {
          const validator = TextFieldValidator.validators[validatorName]
          return !validator(value, validators[validatorName])
        } else {
          return false
        }
      })

      if (errorName) {
        let errorMessage = "Error"
        if (errorMessages && errorMessages[errorName]) {
          // Use custom error message
          errorMessage = errorMessages[errorName]
        } else if (TextFieldValidator.errorMessages[errorName]) {
          errorMessage = TextFieldValidator.errorMessages[errorName]
        }
        if (errorMessage.indexOf("${label}") >= 0) {
          // Replace ${label} with label property
          const label = this.props.label || "This"
          errorMessage = errorMessage.replace("${label}", label.toString())
        }
        if (errorMessage.indexOf("${param}") >= 0) {
          // Replace ${param} with validator param
          const param = validators[errorName]
          errorMessage = errorMessage.replace("${param}", param)
        }

        isValid = false
        this.setState({isValid, errorMessage })
      } else {
        isValid = true
        this.setState({isValid, errorMessage: ""})
      }
    }

    return isValid
  }

  static validators = {
    required: (value: string, param: boolean) => {
      return (!param || (value && value.length > 0))
    },
    minLength: (value: string, param: number) => {
      return (value && value.length >= param )
    },
    maxLength: (value: string, param: number) => {
      return (!value || value.length <= param )
    },
    isEmail: (value: string) => {
      return (!value || isEmail(value))
    },
    isMobilePhone: (value:string, param: any) => {
      const locale = (param!==null && param!==true) ? param : TextFieldValidator.defaultLocale
      return (!value || isMobilePhone(value, locale as MobilePhoneLocale))
    },
    isPostalCode: (value: string, param: any) => {
      const locale = (param!==null && param!==true) ? param : TextFieldValidator.defaultCountry
      return (!value || isPostalCode(value, locale as PostalCodeLocale))
    },
    isStrongPassword: (value: string, param: number = 5) => {
      return (TextFieldValidator.passwordStrength(value) >= param)
    },
    matches: (value: string, param: string) => {
      return (TextFieldValidator.matches(value, param))
    },
    isURL: (value: string, param: any) => {
      return (!value || isURL(value, param ? param : {}))
    },
    isURLMatch: (value: string, param: string) => {
      return (TextFieldValidator.matches(value, param))
    },
    isUsername: (value: string, param: string) => {
      return (TextFieldValidator.matches(value, param))
    },
    minDate: (value: string, param: string) => {
      return (TextFieldValidator.minDate(value, param))
    },
    maxDate: (value: string, param: string) => {
      return (TextFieldValidator.maxDate(value, param))
    },
    minValue: (value: string, param: number) => {
      return (value !== "" && parseFloat(value) >= param)
    },
    maxValue: (value: string, param: number) => {
      return (value !== "" && parseFloat(value) <= param)
    },
    isFloat: (value: string) => {
      return (!value || isFloat(value))
    }
  }

  static passwordStrength = (password: string) => {

    let strength = 0

    if (password && password.length > 0) {
      ++strength

      if (TextFieldValidator.hasLength(password, TextFieldValidator.minPasswordLength)) {
        ++strength

        if (TextFieldValidator.hasNumber(password)) {
          ++strength
        }

        if (TextFieldValidator.hasMixed(password)) {
          ++strength
        }

        if (TextFieldValidator.hasSpecial(password)) {
          ++strength
        }
      }
    }

    return strength
  }

  static hasLength = (value: string, minLength: number) => {
    return (value && value.length >= minLength)
  }

  static hasNumber = (value: string) => {
    return new RegExp(/[0-9]/).test(value);
  }

  static hasMixed = (value: string) => {
    return new RegExp(/[a-z]/).test(value) &&
      new RegExp(/[A-Z]/).test(value);
  }

  static hasSpecial = (value: string) => {
    return new RegExp(/[!#@$%^&*:;,)(+=._-]/).test(value);
  }

  static matches = (value: string, param: string) => {
    return new RegExp(param).test(value);
  }

  static minDate = (value: string, param: string) => {
    const date = parse(value, "M/d/yyyy", new Date())
    const minDate = parse(param, "M/d/yyyy", new Date())
    return (!isNaN(date.getTime()) && !isNaN(minDate.getTime()) && date.getTime() >= minDate.getTime())
  }

  static maxDate = (value: string, param: string) => {
    const date = parse(value, "M/d/yyyy", new Date())
    const maxDate = parse(param, "M/d/yyyy", new Date())
    return (!isNaN(date.getTime()) && !isNaN(maxDate.getTime()) && date.getTime() <= maxDate.getTime())
  }
}

export default withStyles(styles)(TextFieldValidator)