import * as React from 'react'
import { Grid } from '@material-ui/core';
import TermsOfUse from "./TermsOfUse"
import {inject, observer} from "mobx-react";
import PrivacyPolicy from "./PrivacyPolicy";
import MarginRow from "./MarginRow";
import ModalDialog from "../ModalDialog";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {Routes} from "../ControlTower";

const styles = (theme: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active
  },
  content: {
    height: "calc(75vh)",
    width: "100%",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll"
  }
})

interface ITermsOfUseDialogProps {
  termsOfUse?: TermsOfUse
  privacyPolicy?: PrivacyPolicy
}

@inject("termsOfUse", "privacyPolicy")
@observer
class TermsOfUseDialog extends React.Component<WithStyles<typeof styles> & ITermsOfUseDialogProps> {

  privacyPolicy = (ev: any) => {
    ev.preventDefault()
    this.props.privacyPolicy!.show()
  }

  render() {
    const { classes, termsOfUse } = this.props

    return (
      <ModalDialog
        maxWidth="md"
        open={termsOfUse!.open}
        onClose={termsOfUse!.close}>
        <MarginRow>
          <Grid container>
            <Grid item lg={12} className={classes.content}>
              <iframe src={Routes.terms} title="Terms Of Service" className={classes.iframe}>
              </iframe>
            </Grid>
          </Grid>
        </MarginRow>
      </ModalDialog>
    )
  }
}

export default withStyles(styles)(TermsOfUseDialog)