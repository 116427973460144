import * as React from "react";
import {
  Card, CardActionArea,
  CardContent,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Lesson from "../../model/Lesson";
import Course from "../../model/Course";
import ReactPlayer from 'react-player'
import Registration from "../../model/Registration";
import {LessonStatus} from "../../API";
import UserStore from "../../stores/UserStore";
import config from "react-global-configuration";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    maxHeight: 'calc(100vw * (9/16) + 50px)',
    padding: 0
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 5px 5px 10px",
    display: "flex"
  },
  left: {
    flexShrink: 0,
    paddingRight: 5,
    alignSelf: "center",
  },
  right: {
    flexGrow: 1
  },
  title: {
    fontSize: 16,
    fontWeight: 800,
    paddingBottom: 4
  },
  titleButtons: {
    textAlign: "right"
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3
  },
  media: {
    width: "100%",
    height: 'calc(100vw * (9/16))',
    maxHeight: 540,
    padding: 0
  }

})

interface ILessonPlayerProps {
  course: Course
  lesson: Lesson
  registration: Registration
  disabled?: boolean
  hideContent?: boolean
  playing?: boolean
  onPlay?: any
  onPause?: any
  onProgress?: any
  onEnded?: any
  userStore?: UserStore
}

@observer
@inject("userStore")
class LessonPlayer extends React.Component<WithStyles<typeof styles> & ILessonPlayerProps> {

  @observable coverImageUrl?: string
  @observable player?: ReactPlayer
  @observable ready = false

  private firstPlay = true
  private duration = 0
  private playedSeconds = 0

  render() {
    const { classes, course, lesson, disabled, hideContent } = this.props
    let title = ""
    let description = ""
    if (course) {
      title = course.title
      description = course.description
    }

    console.log(`LessonPlayer.disabled = ${disabled}`)

    return (
      <Card className={classes.card}>
        <CardActionArea>
          <CardContent className={classes.content}>
            <div className={classes.right}>
              <Typography variant="h4" className={classes.title}>
                {title}
              </Typography>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </div>
          </CardContent>
          {!hideContent &&
          <CardContent className={classes.media}>
            {!disabled &&
              <ReactPlayer
                url={lesson.videoUrl}
                ref={this.ref}
                controls={true}
                playsinline={true}
                width="100%"
                height="100%"
                playing={this.props.playing}
                onPlay={this.onPlay}
                onPause={this.onPause}
                onSeek={this.onSeek}
                onProgress={this.onProgress}
                onEnded={this.onEnded}
                onReady={this.onReady}
              />
            }
            {disabled &&
              <ReactPlayer
                url={lesson.videoUrl}
                ref={this.ref}
                controls={false}
                playsinline={true}
                width="100%"
                height="100%"
              />
            }
          </CardContent>
          }
        </CardActionArea>
      </Card>
    )
  }

  ref = (player: ReactPlayer) => {
    this.player = player
  }

  onReady = () => {
    const { registration, lesson } = this.props
    this.ready = true
    // Seek to last watched position for current lesson
    if (this.player && registration && registration.videoProgress > 0 &&
        lesson && lesson.number === registration.lessonNumber &&
        registration.lessonStatus === LessonStatus.InProgress)
    {
      const seconds = registration.videoProgress
      console.log(`Seeking to: ${seconds}`)
      this.player.seekTo(seconds, "seconds")
    }
  }

  onPlay = () => {
    const { registration, disabled } = this.props
    if (this.player && this.ready) {
      if (this.firstPlay && registration) {
        this.firstPlay = false
        this.duration = this.player!.getDuration()
      }
      if (disabled) {
        if (this.props.onPause) {
          console.log("onPlay disabled onPause")
          this.props.onPause()
        }
      } else {
        if (this.props.onPlay) {
          this.props.onPlay()
        }
      }
    }
  }

  onPause = () => {
    console.log("LessonPlayer onPause")
    if (this.props.onPause && this.ready) {
      // Ignore pause within the last 3 seconds to avoid a race condition
      if ((this.duration - this.playedSeconds) < 3) {
        console.log("Not calling onPause within 3 seconds of end")
      } else {
        this.props.onPause()
      }
    }
  }

  onSeek = (seconds: number) => {
    const { registration  } = this.props
    const seekDisabled = config.get("branch") === "main"
    if (seekDisabled && seconds > registration.videoProgress) {
      // Prevent seeking ahead
      this.player?.seekTo(registration.videoProgress, "seconds")
    }
  }

  onProgress = (progress: any) => {
    this.playedSeconds = Math.floor(progress.playedSeconds)
    if (this.props.onProgress) {
      this.props.onProgress(this.playedSeconds)
    }
  }

  onEnded = () => {
    if (this.props.onEnded) {
      const seconds = this.player!.getDuration()
      this.props.onEnded(Math.floor(seconds))
    }
  }
}

export default withStyles(styles)(LessonPlayer)
