import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import {Grid, Typography} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import ControlTower, {Routes} from "../../components/ControlTower";
import Progress from "../../components/Progress";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import MarginRow from "../../components/page/MarginRow";
import Page from "../../components/page/Page";
import CourseStore from "../../stores/CourseStore";
import Confirm from "../../components/confirm/Confirm";
import Notify from "../../components/notify/Notify";
import Registration from "../../model/Registration";
import TitleBar from "../../components/TitleBar";
import TitleButton from "../../components/TitleButton";
import RegistrationCard from "../../components/RegistrationCard";
import Class from "../../model/Class";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    width: "100%",
    margin: 0
  },
  titleBar: {
    marginBottom: theme.spacing(1)
  },
  group: {
    // marginTop: theme.spacing(0.5)
    margin: 0
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  subtitle: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
})

interface IHomePageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  confirm?: Confirm
  notify?: Notify
}

@inject("userStore", "accountStore", "courseStore", "progress", "confirm", "notify")
@observer
class MyClassesPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IHomePageProps & WithTheme> {

  @observable isLoading = true
  @observable classes: Class[] = []
  @observable registrations: Registration[] = []
  @observable incomplete: Registration[] = []
  @observable completed: Registration[] = []

  componentDidMount() {
    const {userStore, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("MyClasses")
    when(
      () => !userStore!.isLoading && !accountStore?.isLoading,
      async () => {
        if (userStore!.isAdminOrAgent) {
          ControlTower.route(Routes.accounts)
          return
        }
        await this.loadRegistrations()
        this.isLoading = false
        progress!.hide("MyClasses")
      }
    )
  }

  render() {
    const { classes, userStore } = this.props

    const isAuthenticated = userStore!.isAuthenticated
    const title = "My Classes"

    return (
      <Page title={title}>
        <MarginRow>
          {!this.isLoading && isAuthenticated &&
            <Grid container className={classes.content} direction="column">
                <Grid item className={classes.titleBar}>
                  <TitleBar title={title}>
                    <TitleButton title="+ Add" onClick={this.onAddClass}/>
                  </TitleBar>
                </Grid>

                <Grid container className={classes.group} direction="row">
                  {this.incomplete.map((r: Registration) => {
                    return (
                      <Grid item sm={6} xs={12} className={classes.card} key={r.id}>
                        <RegistrationCard registration={r} onCardAction={this.onCardAction}/>
                      </Grid>
                    )
                  })
                  }
                </Grid>

                <Grid container className={classes.group} direction="row">
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.subtitle}>Completed</Typography>
                  </Grid>
                  {this.completed.map((r: Registration) => {
                    return (
                      <Grid item sm={6} xs={12} className={classes.card} key={r.id}>
                        <RegistrationCard registration={r} onCardAction={this.onCardAction}/>
                      </Grid>
                    )
                  })
                  }
                </Grid>
            </Grid>
          }
        </MarginRow>
      </Page>
    )
  }

  onAddClass = async () => {
    const { userStore } = this.props
    ControlTower.route(`${Routes.account}/${userStore!.user!.accountId}/classes`)
  }

  onCardAction = (action: string, registration: Registration) => {
    if (action === "open") {
      ControlTower.route(`${Routes.class}/${registration.classId}`)
    } else if (action === "reset") {
      const { userStore, confirm } = this.props

      confirm!.show("Confirm Reset Class",
        `Please confirm you want to reset ${registration!.class!.course!.title}`,
        ["Confirm", "Cancel"],
        async () => {
          const updatedRegistration = await userStore!.resetRegistration(registration!.id)
          if (updatedRegistration) {
            await this.loadRegistrations()
          }
          return true
        },
        () => {
          // Do nothing
        })
    }
  }

  loadRegistrations = async () => {
    const {userStore, accountStore} = this.props

    let registrations = [...userStore!.user!.registrations]

    // Associate Registrations with Classes
    this.classes = await accountStore!.listClasses()

    registrations.forEach((r: Registration) => {
      const classFound = this.classes.find((c: Class) => c.id === r.classId)
      if (classFound) {
        r.class = classFound
      }
    })

    const incomplete: Registration[] = []
    const completed: Registration[] = []

    registrations.forEach((r: Registration) => {
      if (r.classCompleted) {
        completed.push(r)
      } else {
        incomplete.push(r)
      }
    })

    // Sort incomplete by most recently updated
    incomplete.sort((a: Registration, b: Registration) => {
      return b.updatedAt.localeCompare(a.updatedAt)
    })
    this.incomplete = incomplete

    // Sort completed by completedAt ascending
    completed.sort((a: Registration, b: Registration) => {
      return a.completedAt.localeCompare(b.completedAt)
    })
    this.completed = completed
  }


}

export default withTheme((withStyles(styles)(MyClassesPage)))