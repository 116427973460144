import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import ControlTower, {Routes} from "../../components/ControlTower";
import {Link} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active,
    cursor: "pointer"
  },
  table: {
    borderSpacing: 0,
    borderCollapse: "collapse"
  },
  td: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    padding: 4,
    "&:first-child": {
      fontWeight: 600,
      whiteSpace: "nowrap"
    }
  }
})

class PrivacyPolicyUS extends React.Component<WithStyles<typeof styles>> {

  render() {
    const {classes} = this.props

    const backLink = (document.referrer.endsWith(Routes.terms)) ?
      <p><Link onClick={ControlTower.back} className={classes.link}>Back</Link></p> : null

    return (
      <div>
        {backLink}
        <h1>GovGig.us Privacy Policy</h1>
        <p>Last Updated: March 18, 2022</p>

        <p>This privacy policy is part of the Terms of Use agreement between you and Watermark Solutions LLC, DBA GovGig (“Company”). It is Company's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to https://www.GovGig.us (hereinafter, "us", "we", or "Https://www.GovGig.us"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
        <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the terms, conditions and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>

        <h3>Definitions</h3>
        <table className={classes.table}>
          <tr>
              <td className={classes.td}>Company</td>
              <td className={classes.td}>Watermark Solutions LLC, DBA GovGig and its wholly owned subsidiaries</td>
          </tr>
          <tr>
            <td className={classes.td}>Non-Personal Data</td>
            <td className={classes.td}>Data that is not considered personal data under applicable law. Company reserves the right to treat data as Personal Data or Non-Personal Data based on applicable law, the data and the data subject.</td>
          </tr>
          <tr>
            <td className={classes.td}>Personal Data</td>
            <td className={classes.td}>Any information or data that identifies you or could reasonably be used to identify you or your computing device(s) either directly or indirectly.</td>
          </tr>
          <tr>
            <td className={classes.td}>Sensitive Personal Data</td>
            <td className={classes.td}>Data about you that discloses race or ethnic origin, religion, beliefs, genetic or biometric data, union membership, health data, sexual identity, sexual orientation</td>
          </tr>
          <tr>
            <td className={classes.td}>Site</td>
            <td className={classes.td}>The GovGig.us website</td>
          </tr>
        </table>

        <h3>Data We Collect</h3>
        <p>As permitted by law, Company may collect, use, store and transfer personal data about you:</p>

        <table className={classes.table}>
          <tr>
            <td className={classes.td}>Identifying Data</td>
            <td className={classes.td}>name, resume, user name or other identifier</td>
          </tr>
          <tr>
            <td className={classes.td}>Contact Data</td>
            <td className={classes.td}>address, email address, phone number or other contact information</td>
          </tr>
          <tr>
            <td className={classes.td}>Financial Data</td>
            <td className={classes.td}>credit, debit or payment service data, which is collected by Company’s payment service providers. Company does not directly collect Financial Data</td>
          </tr>
          <tr>
            <td className={classes.td}>Transaction Data</td>
            <td className={classes.td}>information about payments made, goods and services subscriptions.</td>
          </tr>
          <tr>
            <td className={classes.td}>Access Data</td>
            <td className={classes.td}>device identifiers, login data, dates and times of Site access, browser type and version, operating system type and version, cookie data, time zone and location, other technology on the device(s) you use to access the Site, and how you use the Site.</td>
          </tr>
          <tr>
            <td className={classes.td}>Account Data</td>
            <td className={classes.td}>your username and password, orders placed, preferences, searches, feedback, survey responses, job applications.</td>
          </tr>
        </table>

        <h3>Non-Personal Data</h3>
        <p>Company collects, stores and uses aggregated data that may be derived from your Personal Data but is not considered Personal Data because it does not directly or indirectly disclose your identity.</p>

        <h3>Required Personal Data</h3>
        <p>Where required by law or under the terms of an agreement between you and Company, you may be asked to provide Personal Data. You agree that where such Personal Data is reasonably required by your agreement with Company for the fulfilment of the services provided by Company, or required by law, Company may limit or terminate services on Site if you do not provide such required Personal Data.</p>

        <h3>Sensitive Personal Data</h3>
        <p>Company does not require or ask you to provide Sensitive Personal Data. You are responsible for any Sensitive Personal Data you include in resumes or other information you post on the Site, and you agree that Company shall have no liability for any Sensitive Personal Data you provide.</p>

        <h3>Sources of Personal Data</h3>
        <p>Personal Data includes data that you provide directly to Company when you create or update an account or use the services of the Site. It also includes data that you provide indirectly, such as your activity on the Site. We also receive data from third parties such as analytics or advertising providers that can be Personal Data.</p>

        <h3>How Your Data is Used</h3>
        <p><u>Non-Personal Data</u></p>
        <p>We may use your non-Personal data for any purpose not prohibited by law or where inconsistent with the Terms of Use, Privacy Policy, and any jurisdictionally specific privacy policies of Company.</p>

        <p><u>Personal Data</u></p>
        <p>We will only use your Personal Data to provide services to you by Company or its affiliates, except as otherwise described herein, or as permitted by your consent.</p>

        <h3>Who We Share Your Data With</h3>
        <p>Company shares Personal Data only to those that need to know that information in order to process it on Company’s behalf or to provide services available from the Site. Third parties receiving your Personal Data are authorized to only use such data for the designated purpose and are not authorized to share your data with other third parties. Company may share Personal Data with third parties including:</p>
        <ul>
          <li>External third parties in order to provide services to you, such as identify verification, IT services, payment platforms, communication services, and security.</li>
          <li>Professional advisors such as lawyers, bankers, insurers and financial services in the operation of its business.</li>
          <li>Taxing authorities, where reporting of processing activities is required.</li>
          <li>Fraud protection services where disclosure is reasonably necessary to protect the interests or safety of Company, its customers or others.</li>
          <li>In connection with proposed or actual financing, merger, sale, purchase or transfer of the Company. In the event of a merger, sale or reorganization, the users of the Site will be one of the assets transferred. The terms of this Privacy Policy shall be binding on successors to Company.</li>
          <li>Third parties, where you have consented to disclosure of your Personal Data, including when you post your resume or apply for a posted job.</li>
          <li>Law enforcement agencies, courts, and other third parties to the extent required by law or required to comply with the legal obligations of Company.</li>
          <li>Where required to enforce the Terms of Use and other agreements, to protect the rights of Company, customers and other third parties, or where required to investigate or act on suspected or actual unlawful acts.</li>
          <li>When you apply for a job, Company will share your Personal Data with the prospective employer or its agent.</li>
          <li>When you post your resume, Company will share your Personal Data with prospective employers and/or their agents. When you remove your resume, Company will stop sharing your resume, however employers or their agents who were provided access to your Personal Data prior to you removing your resume will continue to have your Personal Data.</li>
        </ul>

        <p>Company does not sell your information to third parties.</p>

        <h3>Where your Data is Stored</h3>
        <p>Retention of your Personal Data depends on the purpose of the data, legal or accounting requirements associated with the data, and any regulatory requirements such as financial or tax reporting, any requests made by you concerning the retention of your Personal Data, and where retaining the data is necessary to the rights of Company (such as defense of claims). Company may anonymize your Personal Data so it can no longer be associated to you and may use that anonymized data without further notice.</p>

        <h3>Data Subject Rights</h3>
        <p>Depending on applicable law, you may have rights concerning your Personal Data</p>

        <table className={classes.table}>
          <tr>
            <td className={classes.td}>Information</td>
            <td className={classes.td}>the right to know what personal data Company has about you and how it is used</td>
          </tr>
          <tr>
            <td className={classes.td}>Access</td>
            <td className={classes.td}>the right to a copy of your Personal Data, subject to restrictions</td>
          </tr>
          <tr>
            <td className={classes.td}>Rectification</td>
            <td className={classes.td}>the right to require Company to correct incomplete or inaccurate Personal Data</td>
          </tr>
          <tr>
            <td className={classes.td}>Withdrawal</td>
            <td className={classes.td}>the right to withdraw your consent for Company to process to your Personal Data. Withdrawal of consent will not affect the lawfulness of any processing performed prior to your withdrawal of consent. Withdrawal of your consent may result in termination of services on the Site.</td>
          </tr>
        </table>

        <h3>Exercising Data Subject Rights</h3>
        <p>Requests to exercise your data subject rights can be made by email to <a href="mailto:dsar@govgig.us">dsar@govgig.us</a>. You must provide your account identifier. You will be asked to provide additional information from which Company can verify your request. No fees for exercising data subject rights are ordinarily required, however Company may charge a reasonable fee if your request is excessive, repetitive or unfounded.</p>

        <h3>Time to Respond to Data Subject Requests</h3>
        <p>Company will endeavor to respond to all legitimate requests within the time limits required by applicable law. In the event of a delay, you will be notified with the reason of such delay.</p>

        <h3>Marketing</h3>
        <p>Company may use your Personal Data to send you marketing emails with information about the services provided by Company and will obtain consent to do so where required by law. You may unsubscribe to such marketing by choosing the option to unsubscribe included in such marketing emails.</p>

        <h3>Cookies</h3>
        <p>Company and third parties may use cookies or similar tracking technologies to analyze trends, operate the Site, track user activities on the Site and to gather demographic data. You may elect to disable cookies using your browser settings; however, some features of the Site may not be available when cookies are disabled. Your use of the Site without changing your browser settings indicates your acceptance of Company’s use of cookies and similar tracking technologies.</p>

        <h3>Data Security</h3>
        <p>Company uses appropriate organizational and technical measures to protect Personal Data. No transmission or storage of data can be guaranteed to be 100% secure. You acknowledge that there are security and privacy limitations of the Internet that are beyond Company’s control. The security, integrity and privacy of data exchanged between you and Company cannot be guaranteed.</p>

        <h3>Links to Third Party Sites</h3>
        <p>The Site may contain links to external sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. Company strongly advises you to review the Privacy Policy and terms and conditions of every site you visit.</p>
        <p>Company has no control over, and assume no responsibility for, the content, privacy policies or practices of any third-party sites, products or services.</p>

        <h3>Minors and Children</h3>
        <p>The services of Company and the Site are not intended for use by persons under the age of eighteen years. Company does not knowingly collect or solicit for Personal Data from anyone under the age of 13 or knowingly allow such persons to register to use the Site. In the event that you believe Company might have Personal Data from or about a child under 13, contact <a href="mailto:dsar@govgig.us">dsar@govgig.us</a>. We will delete that data to the extent required by law as quickly as possible</p>

        <h3>Changes to Privacy Policy</h3>
        <p>Company reserves the right to change this Privacy Policy at any time. You will be notified of material changes by Company posting notice on the Site or issuing email to registered users of the Site prior to such changes. Company will update the effective date of such changes on the Site. You acknowledge that you are responsible for maintaining a current email with your account on Site and to review this Privacy Policy on the site periodically. If you object to any changes to the Privacy Policy or any terms of service, you can stop using the Site and delete your account. Your continued use of the site indicates your acceptance of any changes to this Privacy Policy.</p>

        <h3>Residents of California</h3>
        <p>Residents of California have specific rights under the California Consumer Privacy Act. Company’s Privacy Notice for residents of California is at <a href={Routes.privacyCCPA}>{Routes.privacyCCPA}</a> and is hereby incorporated into this Privacy Policy. </p>

      </div>
    )
  }
}

export default withStyles(styles)(PrivacyPolicyUS)