import * as React from "react";
import {
  Card, 
  CardActionArea,
  CardContent, 
  CardMedia,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {observable} from "mobx";
import TitleButton from "../../components/TitleButton";
import {format} from "date-fns";
import {isoToLocalDateTime} from "../../stores/StoreUtilities";
import Course from "../../model/Course";

const styles = (theme: Theme) => createStyles({
  card: {
    minHeight: 125,
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2,
    paddingTop: theme.spacing(0)
  },
  titleButtons: {
    textAlign: "right"
  },
  actionArea: {
    color: theme.palette.secondary.main
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    overflow: "hidden",
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    overflow: "hidden",
  },
  cover: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  media: {
    width: 78,
    height: 78
  },
  image: {
    width: "100%",
    height: "auto"
  }
})

interface ICourseCardProps {
  course: Course
  s3UrlCacheStore?: S3UrlCacheStore
  onCardAction?: any
  onEdit?: any
}

@inject("s3UrlCacheStore")
@observer
class CourseCard extends React.Component<WithStyles<typeof styles> & ICourseCardProps> {

  @observable coverImageUrl?: string

  componentDidMount() {
    const { s3UrlCacheStore, course } = this.props

    if (course && course.coverUrl) {
      const coverUrl = course.coverUrl
      s3UrlCacheStore!.get(coverUrl)
        .then((url: string | undefined) => {
          this.coverImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading cover image")
        })
    }
  }

  render() {
    const { classes, course, onEdit } = this.props

    let title = ""
    let description = ""
    let status = ""

    if (course) {
      title = course.title
      description = course.description
      if (course.updatedAt) {
        const updatedAt = isoToLocalDateTime(course.updatedAt)
        status = `Updated at ${format(updatedAt, 'M/d/yyyy h:mm aa')}`
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container direction="row" justifyContent="space-between" wrap="nowrap" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" wrap="nowrap" spacing={2}>
                <Grid item>
                  <CardActionArea onClick={this.onCardAction}>
                    <CardMedia className={classes.media}
                              image={this.coverImageUrl}
                              title={title}/>
                  </CardActionArea>
                </Grid>
                <Grid item>
                  <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
                    <Typography variant="h6" className={classes.title}>
                      {title}
                    </Typography>
                  </CardActionArea>
                  <Typography className={classes.description}>
                    {description}
                  </Typography>
                  <Typography className={classes.description}>
                    {status}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {onEdit &&
                <Typography className={classes.titleButtons}>
                  <TitleButton title="Edit" variant="secondary" onClick={this.onEdit}/>
                </Typography>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  onCardAction = () => {
    const { onCardAction, course } = this.props

    if (onCardAction) {
      onCardAction(course)
    }
  }

  onEdit = () => {
    const { onEdit, course } = this.props

    if (onEdit) {
      onEdit(course)
    }
  }

}

export default withStyles(styles)(CourseCard)
