import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import FormValidator from "../form/FormValidator";
import {Paper} from "@material-ui/core";
import DialogButton from "../form/DialogButton";
import UserStore, {UserStoreConstants} from "../../stores/UserStore";
import TextFieldValidator from "../form/TextFieldValidator";
import Visible from "../Visible";
import ProgressButton from "../form/ProgressButton";
import Notify from "../notify/Notify";

const styles = (theme: Theme) => createStyles({
  message: {
    color: 'red',
    marginTop: 10
  },
  dialogPaper: {
    minWidth: 360,
    maxWidth: 480,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(50vh - 325px)',
    },
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10 
  },
  dialogContent: {
    padding: "0 20px 10px 20px",
  },
  dialogActions: {
    justifyContent: "flex-start",
    paddingBottom: 20,
    paddingLeft: 20,
    borderRadius: "0px 0px 10px 10px"
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: "10px 10px 0px 0px"
  },
  submitButton: {
    color: "#fff",
  },
  instructions: {
    marginBottom: 0
  }
})

interface IPasswordAssistDialogProps {
  onSignIn: any,
  onClose: any,
  userStore?: UserStore
  notify?: Notify
}

@inject("userStore", "notify")
@observer
class PasswordAssistDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IPasswordAssistDialogProps> {

  @observable values = {
    email: "",
    code: "",
    newPassword: "",
  }
  @observable message = ""
  @observable confirming: boolean = false
  @observable newPasswordRequired: boolean = false
  @observable isProcessing: boolean = false

  private cognitoUser: any

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.onClose()
    }
  };

  onSignIn = () => {
    this.props.onSignIn()
  }

  onSubmit = async () => {
    const { userStore, notify } = this.props
    this.message = ""

    if (this.confirming && !this.newPasswordRequired) {
      // Get new password
      this.newPasswordRequired = true
    } else if (this.newPasswordRequired) {
      // Verify confirmation code and reset password
      this.isProcessing = true
      const result = await userStore!.forgotPasswordSubmit(this.values.email.toLowerCase(), this.values.code, this.values.newPassword)
        .catch((err: Error) => {
          this.isProcessing = false
          this.message = err.message
        })

      this.isProcessing = false
      if (result) {
        // Sign-in
        this.isProcessing = true
        const user = await userStore!.signIn(this.values.email.toLowerCase(), this.values.newPassword)
          .catch((err: Error) => {
            this.isProcessing = false
            this.message = err.message
          })

        this.isProcessing = false
        if (user && this.props.onSignIn) {
          notify!.show("success", "Your password has been reset.")
          this.props.onSignIn()
        }
      }
    } else {
      // Send forgot password confirmation code to email
      this.isProcessing = true
      const result = await userStore!.forgotPassword(this.values.email.toLowerCase())
        .catch((err: Error) => {
          this.isProcessing = false
          if (err.name === UserStoreConstants.USER_NOT_FOUND_EXCEPTION) {
            this.message = "User not found."
          } else {
            this.message = err.message
          }
        })

      this.isProcessing = false
      if (result) {
        this.confirming = true
      }
    }
  }

  onResendCode = () => {
    const { userStore } = this.props
    this.message = ""

    userStore!.forgotPassword(this.values.email.toLowerCase())
      .then(() => {
        this.message = "Confirmation code email has been resent."
      })
      .catch((err: Error) => {
        this.message = err.message
      })
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  render() {
    const { classes } = this.props

    return (
      <Paper className={classes.dialogPaper}>
        <FormValidator 
          onSubmit={this.onSubmit} 
          autoComplete="off" 
          name="passwordForm" 
          id="passwordForm"
        >
          <DialogTitle
            id="password-dialog-title" className={ classes.dialogTitle}>
            Password Assistance
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              Please submit your sign-in email address to reset your password.
            </DialogContentText>
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="email"
              label="Email"
              type="text"
              validators={{required:true}}
              value={this.values.email}
              onChange={this.onChange}
              fullWidth
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="on"
              disabled={this.confirming}
            />
            <Visible if={this.confirming}>
              <DialogContentText className={classes.instructions}>
                Please check your email for a confirmation code.
              </DialogContentText>
              <TextFieldValidator
                margin="dense"
                name="code"
                label="Confirmation Code"
                type="text"
                value={this.values.code}
                validators={{required:true, matches:"^\\d{6}$"}}
                onChange={this.onChange}
                fullWidth
              />
              <DialogButton variant="tertiary" onClick={this.onResendCode}>
                Resend confirmation code
              </DialogButton>
            </Visible>
            <Visible if={this.newPasswordRequired}>
              <DialogContentText className={classes.instructions}>
                Please enter a new password.
              </DialogContentText>
              <TextFieldValidator
                margin="dense"
                name="newPassword"
                label="New Password"
                type="password"
                validators={{required:true, isStrongPassword:3}}
                value={this.values.newPassword}
                onChange={this.onChange}
                fullWidth
                // helperText="Please enter a new password of at least 8 characters with at least one digit or symbol."
              />
            </Visible>
            <DialogContentText className={classes.message}>
              {this.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <ProgressButton variant="contained" color="secondary"
                            type="submit" processing={this.isProcessing}
                            onClick={this.onSubmit}>
              Submit
            </ProgressButton>
            <DialogButton variant="secondary" onClick={this.onClose}>
              Cancel
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Paper>
    );
  }
}

export default withStyles(styles)(PasswordAssistDialog)
