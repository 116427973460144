import * as React from 'react'
import Page from '../../components/page/Page'
import {
  Box,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import DialogButton from "../../components/form/DialogButton";
import {computed, observable} from "mobx";
import {inject, observer} from "mobx-react";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import UserStore, {UserStoreConstants} from "../../stores/UserStore";
import User from "../../model/User";
import {ActivityType, UpdateUserInput, UserRole, UserStatus} from "../../API";
import Visible from "../../components/Visible";
import AccountStore from "../../stores/AccountStore";
import ProgressButton from "../../components/form/ProgressButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tracking from "../../components/Tracking";

const styles = (theme: Theme) => createStyles({
  logo: {
    height: '150px',
    width: 'auto'
  },
  message: {
    color: theme.palette.error.main,
    fontSize: 18,
    marginTop: 10
  },
  instructions: {
    color: theme.palette.text.primary,
    fontSize: 18,
    marginTop: 10
  },
  dialogContent: {
    padding: theme.spacing(3)
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: "0px 0px 10px 10px"
  },
  dialogTitle: {
    padding: theme.spacing(2), 
    borderRadius: "10px 10px 0px 0px"
  },
  label: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  msgLink: {
    color: theme.palette.secondary.main
  },
  errorLink: {
    color: "white"
  }
})

interface IRegisterPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  notify?: Notify
  accountId?: string
  userId?: string
  role?: UserRole 
  email?: string
}

@inject("userStore", "accountStore", "notify" /*, "privacyPolicy", "termsOfService" */)
@observer
class RegisterPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IRegisterPageProps & WithTheme> {

  @observable values = {
    password: "",
    phone: "",
    userId: "",
    accountId: "",
    verificationCode: "",
  }
  @observable role?: UserRole 
  @observable email?: string 
  @observable user?: User
  @observable isVerifying: boolean = false
  @observable autoVerify: boolean = false
  @observable isProcessing: boolean = false
  @observable message = ""

  @computed 
  get validInfo(): boolean {
    return this.values.accountId !== ""
      && this.values.userId !== ""
      && this.email !== undefined
      && this.role !== undefined
  }

  componentDidMount () {
    const { accountId, userId, role, email } = this.props

    if (accountId) {
      this.values.accountId = accountId
    }

    if (userId) {
      this.values.userId = userId
    }

    this.email = email 
    this.role = role 
  }

  render() {
    const title = "Register"

    return (
      <Page title={title}>
        <MarginRow>
          <Box 
            py={3}
          >
            <Grid container justifyContent='center'>
              <Grid item xs={12} sm={6}>
                { this.validInfo ?
                  this.renderForm() : 
                  this.renderWarning()
                }
              </Grid>
            </Grid>
          </Box>
        </MarginRow>
      </Page>
    )
  }

  renderForm() {
    const { classes } = this.props

    return (
      <Paper>
        <FormValidator 
          onSubmit={this.onSubmit} 
          autoComplete="off" 
          name="academyRegisterForm" 
          id="academyRegisterForm"
        >
          <DialogTitle
            id="login-dialog-title"
            className={ classes.dialogTitle }
          >
            Complete GovGig Registration
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Visible if={!this.isVerifying}>
              <DialogContentText className={classes.instructions}>
                Please create a password to complete your registration.
              </DialogContentText>
              <DialogContentText className={classes.instructions}>
                <span>If you have already registered, <a href="/" className={classes.msgLink}>click to Sign In.</a></span>
              </DialogContentText>
              <TextFieldValidator
                margin="dense"
                name="email"
                id="EmailInput"
                label="Email"
                variant="standard"
                placeholder=""
                type="text"
                validators={{required:true}}
                value={this.email}
                onChange={this.onChange}
                fullWidth
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="on"
              />
              <TextFieldValidator
                margin="dense"
                name="password"
                label="Create Password"
                type="password"
                variant="standard"
                validators={{required:true, isStrongPassword:3}}
                value={this.values.password}
                onChange={this.onChange}
                fullWidth
              />
            </Visible>

            <DialogContentText className={classes.message}>
              {this.message}
            </DialogContentText>

            <Visible if={this.isVerifying}>
              <Grid item className={classes.message}>
                <Typography variant="body2" className={classes.instructions}>
                  Please check your email for a verification code.
                </Typography>
              </Grid>
              <TextFieldValidator
                margin="dense"
                name="verificationCode"
                label="Verification Code"
                type="text"
                variant="standard"
                value={this.values.verificationCode}
                validators={{required:true, matches:"^\\d{6}$"}}
                onChange={this.onChange}
                fullWidth
              />
              <DialogButton variant="tertiary" onClick={this.onResendCode}>
                Resend verification code
              </DialogButton>
            </Visible>

          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <DialogButton variant="secondary" onClick={this.onCancel}>
              Cancel
            </DialogButton>
            <ProgressButton variant="contained" color="secondary" fullWidth={false}
                            type="submit" processing={this.isProcessing}>
              { this.isVerifying ? "Verify" : "Register" }
            </ProgressButton>
          </DialogActions>
        </FormValidator>
      </Paper>
    )
  }

  renderWarning() {
    const { classes } = this.props

    return (
      <Paper>
        <DialogTitle
          id="login-dialog-title"
          className={ classes.dialogTitle }
        >
          Complete GovGig Registration
        </DialogTitle>
        <DialogContent>
          <Box pb={1}>
            <DialogContentText className={classes.message}>
              {"Invalid registration link"}
            </DialogContentText>
            <Typography gutterBottom>
              If you were invited to join GovGig, please click on the invitation link you should have received via email.
            </Typography>
            <Typography gutterBottom>
              If you did not receive an invite, or have another issue, please contact GovGig.
            </Typography>
          </Box>
        </DialogContent>
      </Paper>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onCancel = () => {
    ControlTower.route(Routes.home)
  }

  onSubmit = async () => {
    const {accountId, userStore, accountStore, notify, classes} = this.props
    const values = this.values
    let verification = ""

    if (!this.isVerifying) {
      this.isProcessing = true

      // Sign-up user
      const signupResult = await userStore!.signUp(values.userId, values.password, this.email!, values.phone,
                                                   values.accountId, this.role!)
        .catch(async (err: Error) => {
          let message
          if (err.name === UserStoreConstants.USERNAME_EXISTS_EXCEPTION) {
            // Try resending confirmation code
            await userStore!.resendSignUp(this.values.userId)
              .then((result: any) => {
                this.isVerifying = true
              })
              .catch((err: Error) => {
                if (err.message === UserStoreConstants.USER_ALREADY_CONFIRMED_MESSAGE) {
                  message = <span>{err.message} <a href="/" className={classes.errorLink}>Click here to Sign In.</a></span>
                } else {
                  message = err.message
                }
              })
          } else {
            message = `Unable to create user. ${err.message}`
          }
          if (message) {
            notify!.show("error", message)
            Tracking.event({action: 'RegisterError', label: message})
          }
          this.isProcessing = false
        })
      if (signupResult) {
        this.isVerifying = true
      }
      this.isProcessing = false
    } else if (this.isVerifying) {
      this.isProcessing = true

      verification = await userStore!.confirmSignUp(values.userId, values.verificationCode)
        .catch((err: Error) => {
          notify!.show("error", "Unable to verify account")
          Tracking.event({action: 'RegisterVerifyError', label: err.message})
          this.isProcessing = false
        })

      if (verification === "SUCCESS") {
        // Sign in
        let user = await userStore!.signIn(values.userId, values.password)
          .catch((err: Error) => {
            if (err.message !== UserStoreConstants.USER_NOT_FOUND) {
              notify!.show("error", "Unable to signin")
              this.isProcessing = false
            }
          })

        if (!user && accountId) {
          notify!.show("error", "A user with this email was not found for this account.  Please contact the account user or administrator.")
          Tracking.event({action: 'RegisterError', label: 'User not found'})
          this.isProcessing = false
          return
        }

        if (user && accountId && user.accountId !== accountId) {
          notify!.show("error", "A user with this email is already registered for another account.  Please contact the account user or administrator.")
          Tracking.event({action: 'RegisterError', label: 'Email already registered'})
          this.isProcessing = false
          return
        }

        if (user && user.email !== this.email!.toLowerCase()) {
          notify!.show("error", "The email address does not match the invitation.  Please contact the account user or administrator.")
          Tracking.event({action: 'RegisterError', label: "Email doesn't match invitation"})
          this.isProcessing = false
          return
        }

        if (user) {
          // Update User to registered
          const updateUserInput: UpdateUserInput = {
            id: user.id,
            userStatus: UserStatus.Registered
          }

          user = await userStore!.updateUser(updateUserInput)
            .catch((err: Error) => {
              notify!.show("error", "Unable to update user")
              Tracking.event({action: 'RegisterError', label: `Unable to update user: ${err.message}`})
              this.isProcessing = false
            })

          if (user.isAdminOrAgent) {
            // Sign-in again to authenticate with new role
            user = await userStore!.signIn(values.userId, values.password)
          }

          // Initialize accountStore for new user
          if (user.account) {
            await accountStore!.init(user.account)
          } else {
            console.log("user.account is undefined!")
          }

          this.isProcessing = false
          notify!.show("success", "Welcome to GovGig Academy!")
          Tracking.event({action: 'RegisterSuccess'})
          userStore!.createActivity(ActivityType.UserRegister, user.id)
          if (user.role === UserRole.Employer) {
            ControlTower.route(`${Routes.account}/${user.accountId}/classes`)
          } else if (user.role === UserRole.Admin) {
            ControlTower.route(Routes.accounts)
          } else {
            ControlTower.route(Routes.myClasses)
          }
        }
      }
    }
  }

  onResendCode = () => {
    const { userStore, notify} = this.props
    Tracking.event({action: 'RegisterResendCode'})
    userStore!.resendSignUp(this.values.userId)
      .then((result: any) => {
        notify!.show("success", "Verification code resent")
      })
      .catch((err: Error) => {
        notify!.show("error", "Unable to resend verification code")
      })
  }

}

export default withTheme((withStyles(styles)(RegisterPage)))