import * as React from 'react'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import AccountCard from "./AccountCard";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import {AccountType, UserRole} from "../../API";
import UserCard from "./UserCard";
import ControlTower, {Routes} from "../../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    justifyContent: 'top',
    alignItems: 'center',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
})

interface IAccountInfoProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountInfo extends React.Component<WithStyles<typeof styles> & IAccountInfoProps & WithTheme> {

  @observable account?: Account
  @observable user?: User
  @observable isLoading = true

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountInfo")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      () => {
        this.account = accountStore!.account
        this.user = userStore!.user
        this.isLoading = false
        progress!.hide("AccountInfo")
      }
    )
  }

  render() {
    const { classes } = this.props

    if (this.isLoading) {
      return null
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.content} direction="row">
        {((this.account!.accountType === AccountType.Agency && this.user!.role === UserRole.Employer) ||
          (this.user!.role === UserRole.Admin)) &&
          <Grid item sm={6} xs={12} className={classes.card}>
              <AccountCard account={this.account!} onEdit={this.onEdit}/>
          </Grid>
        }
        {(this.account!.accountType === AccountType.Individual || this.user!.role === UserRole.Student) &&
          (this.user!.role !== UserRole.Admin) &&
          <Grid item sm={6} xs={12} className={classes.card}>
            <UserCard user={this.user!}/>
          </Grid>
        }
        </Grid>
      </div>
    )
  }

  onEdit = async (account: Account) => {
    ControlTower.route(`${Routes.accountEdit}/${account.id}`)
  }

}

export default withTheme((withStyles(styles)(AccountInfo)))