import * as React from 'react'
import {
  createStyles,
  Icon,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  Typography,
  Button
} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  primaryButton: {
    minHeight: 24,
    marginLeft: 5,
    minWidth: 64,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    "&:active": {
      background: theme.palette.primary.light,
    },
  },
  secondaryButton: {
    minHeight: 24,
    marginLeft: 5,
    minWidth: 64,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
    "&:hover": {
      background: theme.palette.secondary.light,
    },
    "&:active": {
      background: theme.palette.secondary.dark,
    },
  },
  tertieryButton: {
    minHeight: 24,
    marginLeft: 5,
    minWidth: 64,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.light,
    },
    "&:active": {
      background: theme.palette.primary.dark,
    },
  },
  icon: {
    color: theme.palette.secondary.contrastText
  },
  label: {
    marginTop: 2 // Text was not appearing vertically centered, this adjustment fixes the issue.
  }
})

interface ITitleButtonProps {
  title?: string
  icon?: string
  onClick?: any
  variant?: 'primary' | 'secondary' | 'tertiary'
  className?: string
}

class TitleButton extends React.Component<WithStyles<typeof styles> & ITitleButtonProps & WithTheme> {

  onClick = (event: any) => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const { classes, icon, title, onClick, variant } = this.props

    let vari: "contained" | "outlined" | "text" = "contained"
    let className = classes.primaryButton
    if (variant === "secondary") {
      vari = "contained"
      className = classes.secondaryButton
    } else if (variant === "tertiary") {
      vari = "text"
      className = classes.tertieryButton
    }

    if (this.props.className) {
      className = this.props.className
    }

    return (
      <Button size="small"
              variant={vari}
              onClick={onClick}
              className={className}>
        { icon && <Icon className={classes.icon} title={title}>{icon}</Icon> }
        <Typography noWrap className={classes.label}>{title}</Typography>
      </Button>
    )
  }
}

export default withTheme((withStyles(styles)(TitleButton)))