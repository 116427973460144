import * as React from 'react'
import { Grid } from '@material-ui/core';
import PrivacyPolicy from "./PrivacyPolicy";
import {inject, observer} from "mobx-react";
import MarginRow from "./MarginRow";
import ModalDialog from "../ModalDialog";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {Routes} from "../ControlTower";

const styles = (theme: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active
  },
  content: {
    height: "calc(75vh)",
    width: "100%",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll"
  }
})

interface IPrivacyPolicyDialogProps {
  privacyPolicy?: PrivacyPolicy
}

@inject("privacyPolicy")
@observer
class PrivacyPolicyDialog extends React.Component<WithStyles<typeof styles> & IPrivacyPolicyDialogProps> {

  render() {
    const { classes, privacyPolicy } = this.props

    return (
      <ModalDialog
        maxWidth="md"
        open={privacyPolicy!.open}
        onClose={privacyPolicy!.close}>
        <MarginRow>
          <Grid container>
            <Grid item lg={12} className={classes.content}>
              <iframe src={Routes.privacy} title="Terms Of Service" className={classes.iframe}>
              </iframe>
            </Grid>
          </Grid>
        </MarginRow>
      </ModalDialog>
    )
  }
}

export default withStyles(styles)(PrivacyPolicyDialog)
