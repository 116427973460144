import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import AccountStore from "../../stores/AccountStore";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import {observable, when} from "mobx";
import Class from "../../model/Class";
import UserStore from "../../stores/UserStore";
import Lesson from "../../model/Lesson";
import Course from "../../model/Course";
import CourseStore from "../../stores/CourseStore";
import LessonPlayer from "./LessonPlayer";
import LessonCard from "../class/LessonCard";
import NavigationBar from "../../components/NavigationBar";
import ControlTower, {Routes} from "../../components/ControlTower";
import Registration, {PASSING_SCORE} from "../../model/Registration";
import {ActivityType, ClassStatus, LessonStatus, UpdateRegistrationInput} from "../../API";
import DialogButton from "../../components/form/DialogButton";
import QuizCard from "../class/QuizCard";
import Notify from "../../components/notify/Notify";
import Tracking from "../../components/Tracking";
import {getISODateFromDate, getISODateToday, isoToLocalDate} from "../../stores/StoreUtilities";
import ClassCompletionDialog from "./ClassCompletionDialog";
import {addYears, subDays} from "date-fns";
import {Question} from "../../model/Question";
import ProfileActionDialog from "./ProfileActionDialog";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  quizContainer: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  quizButton: {
  }
})

// const PASSING_SCORE = 80.0

interface ILessonPageProps {
  classId?: string
  lessonId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "courseStore", "progress", "notify")
@observer
class LessonPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ILessonPageProps & WithTheme> {

  @observable classObj?: Class
  @observable course?: Course
  @observable lesson?: Lesson
  @observable questionIndex: number = 0
  @observable registration?: Registration
  @observable isLoading = true
  @observable playing = true
  @observable disabled = false
  @observable showCompletionDialog = false
  @observable showProfileActionDialog = false
  private lastProgressSaved: number = 0.0
  private saveInterval = 30 // seconds
  update: UpdateRegistrationInput = {
    id: ""
  }

  async componentDidMount () {
    const { userStore, accountStore, courseStore, classId, lessonId, progress } = this.props
    this.isLoading = true
    progress!.show("LessonPage")

    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        console.log("async componentDidMount")
        console.log("getClass")
        this.classObj = accountStore!.getClass(classId!)
        if (this.classObj) {
          console.log("getClass succeeded")
          const course = await courseStore!.getCourse(this.classObj.courseId)
          if (course)
            console.log("getCourse succeeded")
            this.course = course
            this.lesson = course?.getLesson(lessonId!)
            const registration = userStore!.getClassRegistration(classId!)
            if (registration) {
              console.log("getClassRegistration succeeded")
              accountStore!.joinClassToRegistration(registration)
              this.disabled = registration!.lessonStatus === LessonStatus.Quiz
              this.lastProgressSaved = registration!.videoProgress
              this.registration = registration
            }
            this.isLoading = false
            this.questionIndex = 0
            this.lastProgressSaved = 0
            console.log("LessonPage loading completed")
            progress!.hide("LessonPage")
          }
    })
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.lessonId !== this.props.lessonId) {
      this.componentDidMount()
    }
  }

  render() {
    const { classes } = this.props

    if (!this.registration) {
      return null
    }

    const title = (this.classObj) ? this.classObj.course!.title : ""

    let showTakeQuiz = false
    let showQuiz = false
    let showNext = false
    let hideContent = false
    if (this.registration && this.lesson &&
        !this.registration.classCompleted &&
        this.registration.lessonNumber === this.lesson.number)
    {
      const lessonStatus = this.registration!.lessonStatus
      if (lessonStatus === LessonStatus.Ended) {
        if (this.lesson.questions && this.lesson.questions && this.lesson.questions.length > 0) {
          showTakeQuiz = true
        } else {
          showNext = true
        }
      } else if (lessonStatus === LessonStatus.Quiz) {
        showQuiz = true
        hideContent = this.lesson.videoDuration > 10  // Don't hide for short videos
        if (this.lesson.questions.length > this.questionIndex) {
          const questionNumber = this.lesson.questions[this.questionIndex].number
          if (questionNumber === this.registration.answers.length) {
            // Pause to show the answer
            showNext = true
          }
        }
      } else if (this.registration!.lessonCompleted) {
        showQuiz = true
        hideContent = true
        showNext = true
      }
    }

    return (
      <Page title={title}>
        <MarginRow>
          {!this.isLoading && this.course && this.registration && !this.showCompletionDialog && !this.showProfileActionDialog &&
          <Grid container className={classes.root} direction="column">
            <NavigationBar title={title} onBack={this.onBack}/>
            <Grid item xs={12} className={classes.card}>
              {!this.isLoading &&
                <LessonPlayer course={this.course} lesson={this.lesson!}
                              registration={this.registration!}
                              playing={this.playing}
                              onPlay={this.onPlay}
                              onPause={this.onPause}
                              onProgress={this.onProgress}
                              onEnded={this.onEnded}
                              disabled={this.disabled}
                              hideContent={hideContent}
                />
              }
            </Grid>
            <Grid item xs={12} className={classes.card}>
              {!this.isLoading &&
                <LessonCard lesson={this.lesson!} onPlay={this.onTogglePlay} playing={this.playing} disabled={this.disabled}/>
              }
            </Grid>
            {showTakeQuiz &&
              <Grid item xs={12} className={classes.card}>
                <DialogButton variant="primary" onClick={this.onQuiz} fullWidth={true}>
                  Take Quiz
                </DialogButton>
              </Grid>
            }
            {showQuiz &&
            <Grid item xs={12} className={classes.card}>
              <QuizCard lesson={this.lesson!} questionIndex={this.questionIndex} registration={this.registration!} onAnswer={this.onAnswer}/>
            </Grid>
            }
            {showNext &&
              <Grid item xs={12} className={classes.card} >
                <DialogButton variant="primary" onClick={this.onNext} fullWidth={true}>
                  NEXT
                </DialogButton>
              </Grid>
            }
          </Grid>
          }
          { this.showCompletionDialog &&
            <div className={classes.root}>
              <ClassCompletionDialog registration={this.registration!} onClose={this.onCloseCompletion}/>
            </div>
          }
          { this.showProfileActionDialog &&
          <div className={classes.root}>
            <ProfileActionDialog registration={this.registration!} onClose={this.onCloseProfileAction}/>
          </div>
          }
        </MarginRow>
      </Page>
    )
  }

  onBack = () => {
    ControlTower.route(`${Routes.class}/${this.props.classId}`)
  }

  onTogglePlay = () => {
    console.log("onPlay")
    this.playing = !this.playing
  }

  onPlay = () => {
    Tracking.event({action: 'PlayLesson'})
    this.playing = true

    if (!this.registration) {
      return
    }
    const registration = this.registration

    // Only track progress of the current class && uncompleted lesson
    if (!registration.classCompleted &&
        registration.lessonNumber === this.lesson!.number &&
        (registration.lessonStatus === null ||
         registration.lessonStatus === LessonStatus.NotStarted))
    {
      this.update.classStatus = ClassStatus.InProgress
      this.update.lessonStatus = LessonStatus.InProgress
      this.updateRegistration("onPlay")
    }
  }

  onPause = () => {
    this.playing = false

    if (!this.registration) {
      return
    }

    const registration = this.registration

    // Only track progress of the current class && uncompleted lesson
    if (registration.classCompleted ||
        registration.lessonNumber !== this.lesson!.number ||
        (registration.lessonStatus !== null &&
         registration.lessonStatus !== LessonStatus.NotStarted &&
         registration.lessonStatus !== LessonStatus.InProgress)) {
      return
    }
    this.updateRegistration("onPause")
  }

  onEnded = (seconds: number) => {
    Tracking.event({action: 'EndLesson'})
    this.playing = false

    if (!this.registration) {
      return
    }

    const registration = this.registration

    // Only track progress of the current class && uncompleted lesson
    if (registration.classCompleted ||
        registration.lessonNumber !== this.lesson!.number ||
        (registration.lessonStatus !== LessonStatus.NotStarted &&
         registration.lessonStatus !== LessonStatus.InProgress)) {
      return
    }

    this.update.lessonStatus = LessonStatus.Ended
    this.update.videoProgress = seconds
    this.updateRegistration("onEnded")
  }

  onProgress = (seconds: number) => {
    if (!this.registration) {
      return
    }
    const registration = this.registration

    // Only track progress of the current class && uncompleted lesson
    if (registration.classCompleted ||
      registration.lessonNumber !== this.lesson!.number ||
      (registration.lessonStatus !== null &&
        registration.lessonStatus !== LessonStatus.NotStarted &&
        registration.lessonStatus !== LessonStatus.InProgress)) {
      return
    }

    // console.log(`onProgress: ${seconds}`)
    if (seconds > registration.videoProgress) {
      this.update.videoProgress = seconds
      if (!registration.lessonStatus || registration.lessonStatus === LessonStatus.NotStarted) {
        this.update.lessonStatus = LessonStatus.InProgress
        this.update.classStatus = ClassStatus.InProgress
      }

      // Periodically save progress
      if (seconds >= this.lastProgressSaved + this.saveInterval) {
        this.updateRegistration("onProgress")
      }
    }
  }

  onQuiz = () => {
    if (this.registration!.lessonStatus !== LessonStatus.Ended) {
      return
    }
    Tracking.event({action: 'LessonQuiz'})
    this.disabled = true
    this.update.lessonStatus = LessonStatus.Quiz
    this.updateRegistration("onQuiz")
  }

  onAnswer = (answer: number) => {
    const { userStore } = this.props
    if (!this.registration || this.registration.lessonStatus !== LessonStatus.Quiz) {
      // Don't allow changing answer
      return
    }

    if (answer <= 0) {
      // Cancelled
      Tracking.event({action: 'LessonQuizCancel'})
      this.disabled = false
      this.update.lessonStatus = LessonStatus.Ended
    } else {
      const correctAnswer = this.lesson!.questions[this.questionIndex].correctAnswer
      if (answer !== correctAnswer) {
        // Indicate wrong answer with negative number
        answer = -answer
        Tracking.event({action: 'LessonFailed'})
      } else {
        Tracking.event({action: 'LessonPassed'})
      }
      this.update.answers = this.registration.answers ? this.registration.answers : []
      this.update.answers[this.lesson!.questions[this.questionIndex].number-1] = answer
      if (this.isQuizCompleted()) {
        // Lessons are considered passed when the final question has been answered
        this.update!.lessonStatus = LessonStatus.Passed
      }

      userStore!.createActivity(ActivityType.LessonComplete, this.lesson!.id)
    }

    this.updateRegistration("onAnswer")
  }

  onNext = async () => {
    const { userStore } = this.props
    if (!this.registration!.lessonCompleted) {
      if (this.registration!.lessonStatus === LessonStatus.Quiz && this.lesson!.questions.length-1 > this.questionIndex) {
        // Advance to the next question
        this.questionIndex += 1
        return
      }
    }


    const nextLesson = this.course?.getNextLesson(this.registration!.lessonNumber)

    if (nextLesson) {
      this.update.lessonNumber = nextLesson.number
      this.update.lessonId = nextLesson.id
      this.update.lessonStatus = LessonStatus.NotStarted
      this.update.videoProgress = 0
      this.update.score = this.computeScore()
      this.update.classProgress = this.computeClassProgress()
      const registration = await this.updateRegistration("onNext")
      if (registration) {
        // ControlTower.route(`${Routes.class}/${this.classObj!.id}`)
        // Route to next lesson
        ControlTower.route(`${Routes.class}/${this.classObj!.id}/lesson/${nextLesson.id}`)
      }
    } else {
      this.update.lessonNumber = 1
      this.update.videoProgress = 0.0
      this.update.score = this.computeScore()
      this.update.classProgress = 100.0
      if (this.update.score >= PASSING_SCORE) {
        this.update.classStatus = ClassStatus.Passed
        Tracking.event({action: 'ClassPassed'})
      } else {
        this.update.classStatus = ClassStatus.Failed
        Tracking.event({action: 'ClassFailed'})
      }
      const registration = await this.updateRegistration("onNext")
      if (registration) {
        userStore!.createActivity(ActivityType.ClassComplete, registration.classId)
        this.showCompletionDialog = true
      }
    }
  }

  onCloseCompletion = () => {
    const { userStore } = this.props
    this.showCompletionDialog = false

    if (userStore!.isStudent && this.registration!.classStatus === ClassStatus.Passed) {
      this.showProfileActionDialog = true
    } else {
      ControlTower.route(Routes.myClasses)
    }
  }

  onCloseProfileAction = () => {
    this.showProfileActionDialog = false
    ControlTower.route(Routes.myClasses)
  }

  isQuizCompleted = () => {
    return this.lesson!.questions.length-1 === this.questionIndex
  }

  getLessonResultStatus = () => {
    // This is not used presently
    if (this.lesson!.questions.length === 0) {
      return LessonStatus.Passed
    }

    let lessonStatus = LessonStatus.Passed
    this.lesson!.questions.forEach((q: Question) => {
      const answer = this.registration?.getAnswer(q.number)
      if (answer && answer < 0) {
        lessonStatus = LessonStatus.Failed
      }
    })

    return lessonStatus
  }


  updateRegistration = async (source: string): Promise<Registration | null> => {
    const { userStore, notify } = this.props
    console.log(`updateRegistration: ${source}`)
    this.update.id = this.registration!.id
    // console.log(`updateRegistration: ${JSON.stringify(this.update)}`)

    // Update started and competed dates
    if (this.registration!.classStatus === ClassStatus.NotStarted && this.update.classStatus === ClassStatus.InProgress) {
      this.update.startedAt = getISODateToday()
      const nextAssignmentAt = this.classObj!.getRegistrationNextAssignmentAt()
      if (nextAssignmentAt) {
        this.update.nextAssignmentAt = nextAssignmentAt
      }
      const startedAt = isoToLocalDate(this.update.startedAt)
      const endsAt = subDays(addYears(startedAt, 1), 1)
      this.update.endsAt = getISODateFromDate(endsAt)
    } else if (this.registration!.classStatus === ClassStatus.InProgress &&
               (this.update.classStatus === ClassStatus.Passed || this.update.classStatus === ClassStatus.Failed)) {
      this.update.completedAt = getISODateToday()
    }

    return userStore!.updateRegistration(this.update)
      .then( (registration: Registration | undefined) => {
        console.log(`saved progress: ${source}`)
        if (registration) {
          // console.log(`Updated Registration: ${JSON.stringify(registration)}`)
          this.registration = registration
          this.lastProgressSaved = registration.videoProgress
          this.update = {
            id: registration.id
          }
          return this.registration
        } else {
          return null
        }
      })
      .catch((error) => {
        notify!.show("error", "Error saving status")
        console.log(`saveProgress error: ${error}`)
        return null
      })
  }

  computeScore() {
    let answers = this.update.answers ? this.update.answers : this.registration!.answers
    let score = 0

    if (answers && answers.length > 0) {
      let passed = 0
      let count = 0
      for (let answer of answers) {
        if (answer && answer > 0) {
          ++passed
        }
        if (answer) {
          ++count
        }
      }
      score = passed / count * 100.0
    }
    return score
  }

  computeClassProgress() {
    let progress = 0
    if (this.course && this.registration) {
      const index = this.course.lessons.findIndex((l: Lesson) => { return l.number === this.registration!.lessonNumber })
      if (index >= 0) {
        progress = (index+1) / this.course.lessons.length * 100.0
      }
    }
    return progress
  }

}

export default withTheme((withStyles(styles)(LessonPage)))