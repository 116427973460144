import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Tab, Tabs, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import ControlTower, {Routes} from "../../components/ControlTower";
import TitleBar from "../../components/TitleBar";
import ActivityReport from "./ActivityReport";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    justifyContent: 'top',
    alignItems: 'center',
    overflow: "hidden",
  },
  navigation: {
  },
  content: {
  },
  navigationBar: {
    display: "flex",
    flex: "none",
    flexDirection: "row",
    height: 30,
    width: "100%",
    maxWidth: 960,
    paddingRight: theme.spacing(1)
  },
  tabs: {

  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    fontWeight: 800
  },
  hiddenTab: {
    display: "none"
  }
})

export enum ReportsTabNames {
  info = "activity",
}

interface IReportsPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  accountId?: string
  tab?: string
}

@inject("userStore", "accountStore", "progress")
@observer
class ReportsPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IReportsPageProps & WithTheme> {

  tabNames: string[] = ["activity"]

  @observable account?: Account
  @observable user?: User
  @observable isLoading = true

  componentDidMount () {
    const { progress, accountId } = this.props

    this.isLoading = true
    progress!.show("ReportsPage")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading,
      async () => {
        this.user = userStore!.user
        if (accountId) {
          this.account = await accountStore!.loadAccount(accountId!)
        }
        this.isLoading = false
        progress!.hide("ReportsPage")
      }
    )
  }

  handleChange = (event: any, value: any) => {
    // Navigate to tab to allow a direct url to each tab
    const tabName = this.tabNames[value]
     ControlTower.route(`${Routes.account}/${this.props.accountId}/${tabName}`)
  }

  render() {
    const { classes } = this.props

    // if (!this.account) {
    //   return null
    // }

    const title = "Reports"

    const tabName = (this.props.tab !== undefined) ? this.props.tab : this.tabNames[0]
    let tabValue = this.tabNames.findIndex(name => tabName === name)
    if (tabValue < 0) {
      tabValue = 0
    }

    // const isAdmin = userStore!.isAdmin
    // const isEmployer = isAdmin || userStore!.isEmployer

    return (
      <Page title={title}>
        <MarginRow>
          <div className={ classes.root}>
            <Grid container className={classes.navigation}>
              <TitleBar title={title}>
              </TitleBar>
              <Tabs
                value={tabValue}
                onChange={this.handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                className={ classes.tabs }
              >
                <Tab className={classes.tab} label="Activity"/>
              </Tabs>
            </Grid>
            <Grid container className={classes.content}>
              {tabValue === 0 && <ActivityReport />}
            </Grid>
          </div>
        </MarginRow>
      </Page>
    )
  }

  onBack = () => {
    ControlTower.route(Routes.accounts)
  }

}

export default withTheme((withStyles(styles)(ReportsPage)))