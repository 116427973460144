import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogContent,
  Grid,
  Paper,
  Select,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import Class from "../../model/Class";
import ControlTower, {Routes} from "../../components/ControlTower";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import CourseStore from "../../stores/CourseStore";
import Account from "../../model/Account";
import Course from "../../model/Course";
import {format, isBefore, parse, startOfToday} from "date-fns";
import PaymentForm from "./PaymentForm";
import {Elements, StripeProvider} from "react-stripe-elements";
import config from 'react-global-configuration';
import {
  ActivityType,
  AssignmentSchedule, ClassStatus,
  CreateClassInput,
  CreateRegistrationInput, LessonStatus,
  UpdateClassInput,
} from "../../API";
import {getErrorMessage, getISODateFromDate, isoToLocalDate} from "../../stores/StoreUtilities";
import TitleButton from "../../components/TitleButton";
import User from "../../model/User";
import Visible from "../../components/Visible";
import DialogActions from "@material-ui/core/DialogActions";
import DialogButton from "../../components/form/DialogButton";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
  },
  navigationBar: {
    display: "flex",
    flex: "none",
    flexDirection: "row",
    width: "100%",
    maxWidth: 960,
    paddingRight: theme.spacing(1)
  },
  classCardContainer : {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.error.main,
    marginTop: 10
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    // maxWidth: 480,
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: "0px 0px 10px 10px"
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  content: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'top',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(0),
    // maxHeight: 'calc(100vh - 236px)',
    // overflowY: "scroll"
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    paddingBottom: 4
  },
  paymentForm: {
    // paddingTop: theme.spacing(1)
  },
  currencyField: {
    color: theme.palette.secondary.main
  },
  fieldGroup: {
    marginTop: theme.spacing(1)
  },
  fieldSet: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  selectContainer: {

  },
  selectField: {
    width: "100%",
  },
  applyButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: "10px !important"
  }
})

interface IAccountClassEditPageProps {
  accountId?: string
  classId?: string
  courseId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  notify?: Notify
  location?: any
  stripe?: any
}

// const AssignmentScheduleHelperText = {
//   "All": "All lessons will be assigned at the beginning of class",
//   "Quarterly": "Lessons will be delivered in 4 quarterly installments",
//   "Monthly": "Lessons will be delivered in 12 monthly installments"
// }

@inject("accountStore", "userStore", "courseStore", "progress", "notify")
@observer
class AccountClassEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountClassEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable account?: Account
  @observable classObj?: Class
  @observable customer: any
  @observable source: any
  @observable coupon: any
  @observable course?: Course
  @observable owners: User[] = []
  @observable isEnded: boolean = false
  @observable values = {
    title: "",
    description: "",
    seatsPurchased: 0,
    quantity: 1,
    termBegin: "",
    termEnd: "",
    couponCode: "",
    seatPrice: "$0.00",
    amountDue: "$0.00",
    ownerId: "",
    scheduleId: "All"
  }

  componentDidMount () {
    const { accountId, classId, accountStore, userStore, courseStore, progress} = this.props
    this.isLoading = true
    progress!.show("AccountClassEditPage")
    when(
      () => !accountStore!.isLoading,
      async () => {
        if (accountId) {
          this.account = await accountStore!.loadAccount(accountId!)
        } else {
          this.account = accountStore!.account
        }
        if (classId) {
          this.classObj = await accountStore!.getClass(classId!)
          if (this.classObj) {
            this.values.termBegin = format(isoToLocalDate(this.classObj.termBegin), 'M/d/yyyy')
            const termEnd = isoToLocalDate(this.classObj.termEnd)
            this.values.termEnd = format(termEnd, 'M/d/yyyy')
            this.isEnded = isBefore(termEnd, startOfToday())
            this.values.seatsPurchased = this.classObj.seatsPurchased ? this.classObj.seatsPurchased : 0
            this.values.couponCode = this.classObj.couponCode
            this.values.scheduleId = this.classObj.assignmentSchedule ? this.classObj.assignmentSchedule.toString() : "All"
            this.course = await courseStore!.getCourse(this.classObj.courseId)

            if (this.course) {
              this.values.title = this.course.title
              this.values.description = this.course.description
            }
          }
        } else {
          // Get courseId query param
          const courseId = this.props.courseId ?? new URLSearchParams(this.props.location.search).get("courseId")

          if (courseId) {
            this.course = await courseStore!.getCourse(courseId)
            if (this.course) {
              this.values.title = this.course.title
              this.values.description = this.course.description
            }
            const termBegin = new Date()
            this.values.termBegin = format(termBegin, 'M/d/yyyy')
            let termEnd = new Date(2099, 11, 31) // addYears(termBegin, 1)
            // termEnd = subDays(termEnd, 1)
            this.values.termEnd = format(termEnd, 'M/d/yyyy')
          }
        }
        if (this.course) {
          const price = await courseStore!.getCoursePrice(this.course, this.account)
          if (price) {
            this.values.seatPrice = this.formatCurrency(price.unit_amount / 100.0, true)
          }
        }
        if (this.values.couponCode) {
          await this.applyCoupon(true)
        }
        this.updateAmountDue()

        if (userStore!.isAdminOrAgent) {
          await this.getOwners()
        }

        // Get Stripe Customer record and source
        if (userStore!.isStudent) {
          const user = userStore!.user
          if (user && user.customerId) {
            this.customer = await userStore!.getCustomer(user.customerId)
            if (this.customer) {
              // console.log(`customer = ${JSON.stringify(this.customer)}`)
              if (this.customer.default_source && this.customer.sources && this.customer.sources.data && this.customer.sources.data.length > 0) {
                if (this.customer.sources.data[0].id === this.customer.default_source) {
                  this.source = this.customer.sources.data[0]
                  // console.log(`User source = ${JSON.stringify(this.source)}`)
                }
              }
            }
          }
        } else {
          if (this.account && this.account!.customerId) {
            this.customer = await accountStore!.getCustomer(this.account!.customerId)
            if (this.customer) {
              // console.log(`customer = ${JSON.stringify(this.customer)}`)
              if (this.customer.default_source && this.customer.sources && this.customer.sources.data && this.customer.sources.data.length > 0) {
                if (this.customer.sources.data[0].id === this.customer.default_source) {
                  this.source = this.customer.sources.data[0]
                  // console.log(`source = ${JSON.stringify(this.source)}`)
                }
              }
            }
          }
        }
        progress!.hide("AccountClassEditPage")
        this.isLoading = false
      }
    )
  }

  render() {
    const { classes, classId, userStore } = this.props

    if (!this.course) {
      return null
    }

    const title = classId ? "Edit Class" : "Purchase Class"
    const minDate = format(new Date(), 'M/d/yyyy')
    const isAdminOrAgent = userStore!.isAdminOrAgent
    const isFree = this.course.isFree
    const isStudent = userStore!.isStudent

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6}>
              <TitleBar title={title} className={classes.titleBar}>
              </TitleBar>
              <Paper className={classes.dialogPaper}>
                <FormValidator autoComplete="off"
                               name="classEditForm" id="classEditForm" className={classes.form}>
                  <DialogContent className={classes.dialogContent}>
                    <TextFieldValidator
                      autoFocus
                      margin="dense"
                      name="title"
                      label="Title"
                      type="text"
                      variant="standard"
                      validators={{required:true}}
                      value={this.values.title}
                      fullWidth
                      disabled={true}
                    />
                    <Visible if={!isStudent}>
                      <Grid container className={classes.fieldGroup} spacing={1}>
                        <Grid item xs={6}>
                          <label className={classes.fieldLabel}>
                            Lesson Release Schedule
                            {this.renderScheduleSelect()}
                          </label>
                          <Visible if={false}>
                            <TextFieldValidator
                              margin="dense"
                              name="termBegin"
                              label="Term Begin Date"
                              type="text"
                              variant="standard"
                              validators={{required:true, minDate:minDate}}
                              onChange={this.onChange}
                              value={this.values.termBegin}
                              disabled
                              fullWidth
                              placeholder="m/d/yyyy"
                            />
                          </Visible>
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldValidator
                            margin="dense"
                            name="termEnd"
                            label="Term End Date"
                            type="text"
                            variant="standard"
                            validators={{required:true, minDate:minDate}}
                            onChange={this.onChange}
                            value={this.values.termEnd}
                            fullWidth
                            placeholder="m/d/yyyy"
                          />
                        </Grid>
                      </Grid>
                    </Visible>
                    <Visible if={!isFree && !isStudent}>
                      <Grid container className={classes.fieldGroup} spacing={1}>
                        <Grid item xs={6}>
                          <TextFieldValidator
                            autoFocus
                            margin="dense"
                            name="quantity"
                            label="New Seats"
                            type="text"
                            variant="standard"
                            validators={{required:true, minValue:0}}
                            onChange={this.onChange}
                            value={this.values.quantity}
                            fullWidth
                            placeholder=""
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldValidator
                            autoFocus
                            margin="dense"
                            name="quantity"
                            label="Purchased Seats"
                            type="text"
                            variant="standard"
                            validators={{required:true, minValue:1}}
                            onChange={this.onChange}
                            value={this.values.seatsPurchased}
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Visible>
                    <Visible if={!isFree}>
                      <Grid container className={classes.fieldGroup} spacing={1}>
                        <Grid item xs={6}>
                          <TextFieldValidator
                            margin="dense"
                            name="couponCode"
                            label="Coupon Code"
                            type="text"
                            variant="standard"
                            validators={{required:false}}
                            onChange={this.onChange}
                            value={this.values.couponCode}
                            fullWidth
                            placeholder=""
                            InputProps={{endAdornment: <TitleButton title="Apply" variant="secondary" onClick={(e: any) => this.applyCoupon(false)}/>}}
                          />
                        </Grid>
                        {/*<Grid item xs={6} className={classes.applyButtonContainer}>*/}
                        {/*</Grid>*/}
                        {isAdminOrAgent &&
                          <Grid item xs={6} className={classes.fieldSet}>
                            <label className={classes.fieldLabel}>
                              Owner
                              {this.renderOwnerSelect()}
                            </label>
                          </Grid>
                        }
                      </Grid>
                      <Grid container className={classes.fieldGroup} spacing={1}>
                        <Grid item xs={6}>
                          <TextFieldValidator
                            autoFocus
                            margin="dense"
                            name="seatPrice"
                            label="Price per seat"
                            type="text"
                            variant="outlined"
                            validators={{required:false}}
                            value={this.values.seatPrice}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldValidator
                            autoFocus
                            margin="dense"
                            name="amountDue"
                            label="Amount Due"
                            type="text"
                            variant="outlined"
                            InputProps={{
                              classes: {
                                disabled: classes.currencyField,
                              }
                            }}
                            validators={{required:false}}
                            value={this.values.amountDue}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Visible>
                  </DialogContent>
                  <Visible if={isFree}>
                    <DialogActions className={classes.dialogActions}>
                      <DialogButton variant="secondary" onClick={this.onCancel}>
                        Cancel
                      </DialogButton>
                      <DialogButton variant="primary" onClick={this.onPayment}>
                        Submit
                      </DialogButton>
                    </DialogActions>
                  </Visible>
                </FormValidator>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!this.isLoading && !isFree &&
                <React.Fragment>
                  <TitleBar title="Payment Info" className={classes.titleBar}>
                  </TitleBar>
                  <Paper className={classes.dialogPaper}>
                    <DialogContent className={classes.dialogContent}>
                      <Grid item xs className={classes.paymentForm}>
                        <StripeProvider apiKey={config.get("stripe.apiKey")}>
                          <Elements>
                            <PaymentForm source={this.source}
                                         amountDue={this.getCurrency(this.values.amountDue)}
                                         confirmSubmit={this.confirmSubmit}
                                         onPayment={this.onPayment}
                                         onCancel={this.onCancel}/>
                          </Elements>
                        </StripeProvider>
                      </Grid>
                    </DialogContent>
                  </Paper>
                </React.Fragment>
              }
            </Grid>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  onChange = (event: any) => {
    const name = event.target.name
    if (name === "quantity") {
      const value = parseInt(event.target.value)
      this.values[name] = isNaN(value) ? 0 : value
      this.updateAmountDue()
    } else if (name === "couponCode") {
      this.values[name] = event.target.value.toUpperCase()
    } else {
      this.values[name] = event.target.value
    }
  }

  applyCoupon = async (silent: boolean = false) => {
    const { courseStore, notify } = this.props
    const code = this.values.couponCode
    let coupon
    if (code) {
      coupon = await courseStore!.getCoupon(code)
        .catch((err: any) => {
          notify!.show("error", "Error getting coupon")
        })

      if (coupon) {
        if (coupon.max_redemptions && coupon.times_redeemed >= coupon.max_redemptions) {
          if (!silent) {
            notify!.show("error", "This coupon is used up and cannot be applied")
          }
          coupon = undefined
        } else if (coupon.redeem_by) {
          const now = new Date()
          if (now.getTime() / 1000 > coupon.redeem_by) {
            if (!silent) {
              notify!.show("error", "This coupon has expired and cannot be applied")
            }
            coupon = undefined
          }
        }
        if (coupon) {
          if (!silent) {
            notify!.show("success", `${coupon.name} coupon applied!`)
          }
        }
      } else {
        if (!silent) {
          notify!.show("warning", "This coupon code is not available")
        }
      }

    }

    this.coupon = coupon

    this.updateAmountDue()
  }

  updateAmountDue = () => {
    const seatPrice = this.getCurrency(this.values.seatPrice)
    let amountDue = this.values.quantity * seatPrice
    // Apply coupon
    if (this.coupon) {
      if (this.coupon.amount_off) {
        const amountOff = this.coupon.amount_off / 100.0
        if (amountOff >= amountDue) {
          amountDue = 0
        } else {
          amountDue -= amountOff
        }
      } else if (this.coupon.percent_off) {
        amountDue -= amountDue * (this.coupon.percent_off / 100.0)
      }
    }
    this.values.amountDue = this.formatCurrency(amountDue, true)
  }

  getCurrency = (value: string) => {
    if (value) {
      const amount = value.replace(/[$,]/g, '');
      return parseFloat(amount)
    } else {
      return 0.00;
    }
  }

  formatCurrency = (num: number, addDollarSign: boolean = true) => {
    let s = num.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    if (addDollarSign) {
      s = '$ ' + s;
    }
    return s;
  }

  confirmSubmit = () => {
    const { notify } = this.props

    if (!this.values.quantity && !this.classObj && !this.course!.isFree) {
      notify!.show("error", "Quantity must be > 0")
      return false
    } else if (this.values.quantity && this.values.seatPrice === "$0.00") {
      notify!.show("error", "Course price not found")
      return false
    }
    return true
  }

  onPayment = async (token: any, state: any): Promise<boolean> => {
    const { userStore, accountStore, notify } = this.props

    const quantity = this.values.quantity
    const seatPrice = this.getCurrency(this.values.seatPrice)
    const amountDue = this.getCurrency(this.values.amountDue)
    const couponId = this.values.couponCode
    const tokenId = token ? token.id : null
    let owner: User | undefined
    const isStudent = userStore!.isStudent
    const isEmployer = userStore!.isEmployer

    if (userStore!.isAdminOrAgent) {
      // Must specify a user
      if (!this.values.ownerId && amountDue > 0) {
        if (this.owners.length > 0) {
          notify!.show("error", "Please select an owner")
        } else {
          notify!.show("error", "You must first add an owner")
        }
        return false
      }
      owner = this.owners.find((u: User) => u.id === this.values.ownerId)
    } else {
      owner = userStore!.user
    }

    if (amountDue > 0) {
      let invoiceResult
      if (isStudent) {
        invoiceResult = await userStore!.createInvoice(this.course!, couponId, quantity, seatPrice, tokenId)
          .catch((err: Error) => {
            notify!.show("error", err.message)
          })
      } else {
        invoiceResult = await accountStore!.createInvoice(owner!, this.course!, couponId, quantity, seatPrice, tokenId)
          .catch((err: Error) => {
            notify!.show("error", err.message)
          })
      }

      if (invoiceResult) {
        if (!this.classObj) {
          // Add new class
          const termBegin = parse(this.values.termBegin, 'M/d/yyyy', new Date())
          const termEnd = parse(this.values.termEnd, 'M/d/yyyy', new Date())
          const classInput: CreateClassInput = {
            courseId: this.course!.id,
            accountId: accountStore!.account!.id,
            ownerId: userStore!.user!.id,
            userId: isStudent ? userStore!.user!.id : undefined,
            termBegin: getISODateFromDate(termBegin),
            termEnd: getISODateFromDate(termEnd),
            seatsPurchased: this.values.quantity,
            seatsFilled: 0,
            assignmentSchedule: AssignmentSchedule[this.values.scheduleId]
          }
          // Don't store ADMIN coupons
          if (this.values.couponCode.indexOf("ADMIN") < 0) {
            classInput.couponCode = this.values.couponCode ? this.values.couponCode : null
          }

          const classObj = await accountStore!.addPaidClass(classInput, this.course!)
            .catch((err: Error) => {
              notify!.show("error", "Unable to add class.")
            })

          if (classObj) {
            if (isStudent || isEmployer) {
              // Add the individual to the class
              const input: CreateRegistrationInput = {
                accountId: classObj.accountId,
                classId: classObj.id,
                userId: userStore!.user!.id,
                classStatus: ClassStatus.NotStarted,
                classProgress: 0,
                lessonNumber: 1,
                lessonStatus: LessonStatus.NotStarted,
                videoProgress: 0,
                score: 0,
                lessonsAssigned: classObj.getRegistrationLessonsAssigned()
              }

              const registration = await accountStore!.addClassRegistration(input)
                .catch(err => {
                  notify!.show("error", getErrorMessage(err))
                })

              if (!registration) {
                return false
              }

              // Reload registrations
              await userStore!.loadUser(userStore!.user!.id)
            }

            notify!.show("success", `${this.course!.title} class added!`)
            this.classObj = classObj
            userStore!.createActivity(ActivityType.ClassCreate, classObj.id)
            this.onCancel()
          }
        } else {
          // Update existing class
          const termBegin = parse(this.values.termBegin, 'M/d/yyyy', new Date())
          const termEnd = parse(this.values.termEnd, 'M/d/yyyy', new Date())
          const update: UpdateClassInput = {
            id: this.classObj.id,
            seatsPurchased: this.classObj.seatsPurchased + quantity,
            termBegin: getISODateFromDate(termBegin),
            termEnd: getISODateFromDate(termEnd),
            assignmentSchedule: AssignmentSchedule[this.values.scheduleId]
          }
          // Don't store ADMIN coupons
          if (this.values.couponCode && this.values.couponCode.indexOf("ADMIN") < 0) {
            update.couponCode = this.values.couponCode ? this.values.couponCode : null
          }

          const classObj = await accountStore!.updateClass(update)
            .catch((err: Error) => {
              notify!.show("error", "Unable to update class.")
            })

          if (classObj) {
            userStore!.createActivity(ActivityType.ClassEdit, classObj.id)
            notify!.show("success", `${this.course!.title} updated!`)
            this.onCancel()
          }
        }
      }
    } else {
      // No payment due

      if (!this.classObj) {
        // Add new class
        const termBegin = parse(this.values.termBegin, 'M/d/yyyy', new Date())
        const termEnd = parse(this.values.termEnd, 'M/d/yyyy', new Date()) // TODO: Increase this
        const classInput: CreateClassInput = {
          courseId: this.course!.id,
          accountId: accountStore!.account!.id,
          ownerId: userStore!.user!.id,
          userId: isStudent ? userStore!.user!.id : undefined,
          termBegin: getISODateFromDate(termBegin),
          termEnd: getISODateFromDate(termEnd),
          seatsPurchased: quantity,
          seatsFilled: 0,
          assignmentSchedule: AssignmentSchedule[this.values.scheduleId]
        }

        const classObj = await accountStore!.addPaidClass(classInput, this.course!)
          .catch((err: Error) => {
            notify!.show("error", "Unable to add class.")
          })

        if (classObj && isStudent) {
          // Add the individual to the class
          const input: CreateRegistrationInput = {
            accountId: classObj.accountId,
            classId: classObj.id,
            userId: userStore!.user!.id,
            classStatus: ClassStatus.NotStarted,
            classProgress: 0,
            lessonNumber: 1,
            lessonStatus: LessonStatus.NotStarted,
            videoProgress: 0,
            score: 0,
            lessonsAssigned: classObj.getRegistrationLessonsAssigned()
          }

          await accountStore!.addClassRegistration(input)
            .catch(err => {
              notify!.show("error", "Unable to register user")
            })

          // Reload registrations
          await userStore!.loadUser(userStore!.user!.id)
        }

        if (classObj) {
          notify!.show("success", `${this.course!.title} added!`)
          this.classObj = classObj
          this.onCancel()
        }
      } else {
        // Update existing class
        const termBegin = parse(this.values.termBegin, 'M/d/yyyy', new Date())
        const termEnd = parse(this.values.termEnd, 'M/d/yyyy', new Date())
        const update: UpdateClassInput = {
          id: this.classObj.id,
          termBegin: getISODateFromDate(termBegin),
          termEnd: getISODateFromDate(termEnd),
          seatsPurchased: this.classObj.seatsPurchased + quantity,
          assignmentSchedule: AssignmentSchedule[this.values.scheduleId]
        }

        const classObj = await accountStore!.updateClass(update)
          .catch((err: Error) => {
            notify!.show("error", "Unable to update class.")
          })

        if (classObj && isStudent) {
          // Add the individual to the class
          const input: CreateRegistrationInput = {
            accountId: classObj.accountId,
            classId: classObj.id,
            userId: userStore!.user!.id,
            classStatus: ClassStatus.NotStarted,
            classProgress: 0,
            lessonNumber: 1,
            lessonStatus: LessonStatus.NotStarted,
            videoProgress: 0,
            score: 0,
            lessonsAssigned: classObj.getRegistrationLessonsAssigned()
          }

          await accountStore!.addClassRegistration(input)
            .catch(err => {
              notify!.show("error", "Unable to register user")
            })

          // Reload registrations
          await userStore!.loadUser(userStore!.user!.id)
        }

        if (classObj) {
          notify!.show("success", `${this.course!.title} updated`)
          this.onCancel()
        }
      }
    }

    return true
  }

  onCancel = () => {
    const { accountId, userStore } = this.props
    if (this.classObj && !userStore!.isStudent) {
      ControlTower.route(`${Routes.account}/${accountId}/class/${this.classObj.id}`)
    } else {
      ControlTower.route(`${Routes.myClasses}`)
    }
  }

  getOwners = async () => {
    const { accountStore } = this.props
    if (!this.owners || this.owners.length === 0) {
      const users = await accountStore!.listUsers(accountStore!.account!.id)
      if (users) {
        const owners = users.filter((u: User) => u.isEmployer || u.isAdminOrAgent )
        // Sort by createdAt so defaults to first owner
        owners.sort((a: User, b: User) => a.createdAt.localeCompare(b.createdAt))
        this.owners = owners
        if (owners.length > 0) {
          this.values.ownerId = this.owners[0].id
        }
      } else {
        this.owners = []
      }
    }

    return this.owners
  }

  renderScheduleSelect() {
    const { classes } = this.props

    return (
      <div className={classes.selectContainer}>
        <Select
          native
          name="scheduleId"
          value={this.values.scheduleId}
          onChange={this.onChange}
          inputProps={{
            name: 'scheduleId',
            id: 'scheduleId',
          }}
          className={classes.selectField}
        >
          <option value="All">All At Once</option>
          <option value="Monthly">Monthly</option>
          <option value="Quarterly">Quarterly</option>
        </Select>
        {/*<FormHelperText>{AssignmentScheduleHelperText[this.values.scheduleId]}</FormHelperText>*/}
      </div>
    )
  }

  renderOwnerSelect() {
    const { classes } = this.props

    return (
      <div className={classes.selectContainer}>
        <Select
          native
          name="ownerId"
          value={this.values.ownerId}
          onChange={this.onChange}
          inputProps={{
            name: 'ownerId',
            id: 'ownerId',
          }}
          className={classes.selectField}
          // disableUnderline
          // classes={{root: classes.selectRoot}}
        >
          { this.owners.map((u: User) => {
            return <option value={u.id} key={u.id}>{u.fullName}</option>
          })
          }
        </Select>
      </div>
    )
  }

}

export default withTheme((withStyles(styles)(AccountClassEditPage)))