import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import PrivacyPolicyUS from "./PrivacyPolicyUS";
import PrivacyPolicyCCPA from "./PrivacyPolicyCCPA";

const styles = (theme: Theme) => createStyles({

})

interface IPrivacyPolicyPageProps {
  option?: string
}

class PrivacyPolicyPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & WithTheme & IPrivacyPolicyPageProps> {

  render() {
    const { option } = this.props

    return (
      <Page title="Privacy Policy" hideHeader={true} hideFooter={true}>
        <MarginRow>
          {option === undefined &&
            <PrivacyPolicyUS/>
          }
          {option === "ccpa" &&
            <PrivacyPolicyCCPA/>
          }
        </MarginRow>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(PrivacyPolicyPage)))

