import Course from "./Course";
import Registration from "./Registration";
import {AssignmentSchedule} from "../API";
import {getISODateFromDate, isoToLocalDate} from "../stores/StoreUtilities";
import {addMonths, addYears, format, startOfToday, subDays} from "date-fns";


export class Class {
  id: string
  createdAt: string
  updatedAt: string
  courseId: string
  course?: Course
  accountId: string
  ownerId: string
  userId: string
  termBegin: string
  termEnd: string
  seatsPurchased: number
  seatsFilled: number
  couponCode: string
  assignmentSchedule: AssignmentSchedule
  registrations: Registration[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.courseId = data.courseId
    this.course = data.course ? new Course(data.course) : undefined
    this.accountId = data.accountId
    this.ownerId = data.ownerId
    this.userId = data.userId
    this.termBegin = data.termBegin
    this.termEnd = data.termEnd
    this.seatsPurchased = data.seatsPurchased
    this.seatsFilled = data.seatsFilled
    this.couponCode = data.couponCode
    this.assignmentSchedule = data.assignmentSchedule ? data.assignmentSchedule : AssignmentSchedule.All
    this.registrations = []
    if (data.registrations && data.registrations.items) {
      this.loadRegistrations(data.registrations.items)
    }

    // Fix termEnd if same as termStart
    if (this.termBegin && this.termBegin === this.termEnd) {
      const begin = isoToLocalDate(this.termBegin)
      let end = addYears(begin, 1)
      end = subDays(end, 1)
      this.termBegin = getISODateFromDate(begin)
      this.termEnd = getISODateFromDate(end)
    }
  }

  loadRegistrations(items: any[]) {
    this.registrations = items.map((item: any) => new Registration(item))
  }

  update(data: any) {
    // this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    // this.courseId = data.courseId
    if (data.course) {
      this.course = data.course
    }
    // this.accountId = data.accountId
    this.termBegin = data.termBegin
    this.termEnd = data.termEnd
    this.seatsPurchased = data.seatsPurchased
    this.seatsFilled = data.seatsFilled
    this.couponCode = data.couponCode
    this.assignmentSchedule = data.assignmentSchedule
    // this.registrations = []
    if (data.registrations && data.registrations.items) {
      this.loadRegistrations(data.registrations.items)
    }
  }

  // Compute the default lessonsAssigned
  getRegistrationLessonsAssigned() {
    let lessonsAssigned = 0
    let schedule = this.assignmentSchedule

    if (schedule === AssignmentSchedule.All) {
      lessonsAssigned = this.course!.lessonCount
    } else {

      if (schedule === AssignmentSchedule.Monthly) {
        lessonsAssigned = Math.round(this.course!.lessonCount * (1 / 12))
      } else if (schedule === AssignmentSchedule.Quarterly) {
        lessonsAssigned = Math.round(this.course!.lessonCount * (1 / 4))
      }
    }

    return lessonsAssigned
  }

  // Compute the default nextAssignmentAt date
  getRegistrationNextAssignmentAt() {
    let nextAssignmentAt = ""
    let schedule = this.assignmentSchedule

    if (schedule === AssignmentSchedule.All) {
      nextAssignmentAt = ""
    } else {
      const startDate = startOfToday()
      if (schedule === AssignmentSchedule.Monthly) {
        nextAssignmentAt = format(addMonths(startDate, 1), "yyyy-MM-dd")
      } else if (schedule === AssignmentSchedule.Quarterly) {
        nextAssignmentAt = format(addMonths(startDate, 3), "yyyy-MM-dd")
      }
    }

    return nextAssignmentAt
  }

}

export default Class