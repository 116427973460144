import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel, FormGroup,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DialogButton from "../../components/form/DialogButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import {AccountSize, AccountStatus, AccountType, ActivityType, CreateAccountInput, UpdateAccountInput} from "../../API";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import Tracking from "../../components/Tracking";
import Visible from "../../components/Visible";
import config from 'react-global-configuration';
import TitleButton from "../../components/TitleButton";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.error.main,
    marginTop: 10
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    // maxWidth: 480,
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: "0px 0px 10px 10px"
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 0,
  },
  radioGroup: {
    marginTop: 0
  }
})

interface IAccountEditPageProps {
  accountId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  notify?: Notify
}

@inject("accountStore", "userStore", "progress", "notify")
@observer
class AccountEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable account?: Account
  @observable values = {
    accountType: "Agency",
    accountStatus: "Active",
    accountSize: "Small",
    accountName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    customerId: ""
  }
  @observable message = ""

  componentDidMount () {
    const { accountId, userStore, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("AccountEditPage")
    when(
      () => !userStore!.isLoading,
      async () => {
        if (accountId) {
          this.account = await accountStore!.getAccount(this.props.accountId!)
          if (this.account) {
            this.values = {
              accountName: this.account.name,
              accountType: this.account.accountType,
              accountStatus: this.account.accountStatus.toString(),
              accountSize: this.account.accountSize.toString(),
              address: this.account.address,
              city: this.account.city,
              state: this.account.state,
              postalCode: this.account.postalCode,
              customerId: this.account.customerId
            }
          }
        }
        this.isLoading = false
        progress!.hide("AccountEditPage")
      }
    )
  }


  render() {
    const { classes, userStore } = this.props

    const title = this.account ? "Edit Account" : "Add Account"
    const isAdminOrAgent = userStore!.isAdminOrAgent

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                             name="accountEditForm" id="accountEditForm" className={classes.form}>
                <DialogContent className={classes.dialogContent}>
                  <TextFieldValidator
                    autoFocus
                    margin="dense"
                    name="accountName"
                    label="Agency Name"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.accountName}
                    fullWidth
                    placeholder="Name of your agency"
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="address"
                    label="Address"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    value={this.values.address}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="city"
                    label="City"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    value={this.values.city}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="state"
                    label="State"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    value={this.values.state}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="postalCode"
                    label="ZIP Code"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    value={this.values.postalCode}
                    fullWidth
                  />
                  <Visible if={false}>
                    <div className={classes.label}>Type</div>
                    <RadioGroup aria-label="accountType" name="accountType" className={classes.radioGroup}
                                value={this.values.accountType} onChange={this.onChange} row>
                      <FormControlLabel
                        value="Agency"
                        control={<Radio color="secondary" />}
                        label="Agency"
                        labelPlacement="end"
                        disabled={this.account && this.account.accountType === AccountType.Agency}
                      />
                      <FormControlLabel
                        value="Individual"
                        control={<Radio color="secondary" />}
                        label="Individual"
                        labelPlacement="end"
                        disabled={this.account && this.account.accountType === AccountType.Agency}
                      />
                    </RadioGroup>
                  </Visible>
                  <Visible if={false /*this.values.accountType === "Agency"*/ }>
                    <FormGroup>
                      <div className={classes.label}>Agency Size (number of responders)</div>
                      <RadioGroup aria-label="accountSize" name="accountSize" value={this.values.accountSize} onChange={this.onChange} row>
                        <FormControlLabel
                          value="Small"
                          control={<Radio color="secondary" />}
                          label="Small (1-49)"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="Medium"
                          control={<Radio color="secondary" />}
                          label="Medium (50-199)"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="Large"
                          control={<Radio color="secondary" />}
                          label="Large (200+)"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormGroup>
                  </Visible>
                  <Visible if={isAdminOrAgent && this.account !== undefined}>
                    <div className={classes.label}>Status</div>
                    <RadioGroup aria-label="accountStatus" name="accountStatus" className={classes.radioGroup}
                                value={this.values.accountStatus} onChange={this.onChange} row>
                      <FormControlLabel
                        value="Active"
                        control={<Radio color="secondary"/>}
                        label="Active"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Suspended"
                        control={<Radio color="secondary"/>}
                        label="Suspended"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    {this.values.customerId &&
                      <TextFieldValidator
                        margin="dense"
                        name="customerId"
                        label="Stripe Customer ID"
                        type="text"
                        variant="standard"
                        validators={{required:false}}
                        value={this.values.customerId}
                        fullWidth
                        disabled
                        InputProps={{endAdornment: <TitleButton title="Open" variant="secondary" onClick={this.onStripe}/>}}
                      />
                    }
                  </Visible>
                <DialogContentText className={classes.message}>
                  {this.message}
                </DialogContentText>

                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="secondary" onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                  <DialogButton variant="primary">
                    Save
                  </DialogButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onCancel = () => {
    const { accountId } = this.props

    if (ControlTower.currentRoute.startsWith(Routes.accountsEdit)) {
      ControlTower.route(`${Routes.accounts}`)
    } else {
      ControlTower.route(`${Routes.account}/${accountId}/info`)
    }
  }

  onSubmit = async () => {
    const { accountId, accountStore, userStore, notify } = this.props

    const values = this.values

    if (accountId) {
      Tracking.event({action: 'UpdateAccount'})
      const input: UpdateAccountInput = {
        id: accountId!,
        accountType: values.accountType === "Agency" ? AccountType.Agency : AccountType.Individual,
        accountStatus: (AccountStatus as any)[values.accountStatus],
        accountSize: AccountSize[values.accountSize],
        name: values.accountName,
        address: values.address,
        city: values.city,
        state: values.state,
        postalCode: values.postalCode
      }
      const update = await accountStore!.updateAccount(input)
        .catch((err: Error) => {
          notify!.show("error", "Unable to update account")
        })

      if (update) {
        notify!.show("success", "Account updated!")
        this.onCancel()
      }
    } else {
      Tracking.event({action: 'CreateAccount'})
      const input: CreateAccountInput = {
        id: accountId!,
        accountType: values.accountType === "Agency" ? AccountType.Agency : AccountType.Individual,
        accountStatus: (AccountStatus as any)[values.accountStatus],
        name: values.accountName,
        address: values.address,
        city: values.city,
        state: values.state,
        postalCode: values.postalCode
      }
      const account = await accountStore!.createAccount(input)
        .catch((err: Error) => {
          notify!.show("error", "Unable to create account")
        })

      if (account) {
        notify!.show("success", "Account created!")
        if (userStore!.isAdminOrAgent) {
          await accountStore!.init(account)
        }
        userStore!.createActivity(ActivityType.AccountCreate, account.id)
        ControlTower.route(`${Routes.account}/${account.id}`)
      }
    }

  }

  onStripe = async() => {
    if (this.account!.customerId) {
      const env = config.get("branch") === "main" ? "live" : "test"
      ControlTower.open(`https://dashboard.stripe.com/${env}/customers/${this.account!.customerId}`, '_blank')
    }
  }

}

export default withTheme((withStyles(styles)(AccountEditPage)))