import {observable} from "mobx";

type ConfirmCallback = () => boolean | Promise<boolean>
type CancelCallback = () => void

class Confirm {

  @observable open: boolean = false
  @observable title: string = ''
  @observable content: any
  @observable buttons?: string[]

  onConfirm?: ConfirmCallback
  onCancel?: CancelCallback

  show = (title: string, content: any, buttons?: string[], onConfirm?: ConfirmCallback, onCancel?: CancelCallback) => {
    this.open = true
    this.title = title
    this.content = content
    this.onCancel = onCancel
    this.onConfirm = onConfirm
    this.buttons = buttons
  }

  confirm = () => {
    if (this.onConfirm) {
      const result = this.onConfirm()
      if (result instanceof Promise && result as Promise<boolean>) {
        (result as Promise<boolean>).then((confirmed:boolean) => {
          this.open = !confirmed
        })
      } else {
        this.open = !result
      }
    }
  }

  cancel = () => {
    this.open = false
    if (this.onCancel) {
      this.onCancel()
    }
  }
}

export default Confirm