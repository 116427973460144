import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import ControlTower, {Routes} from "../../components/ControlTower";
import TitleBar from "../../components/TitleBar";
import TitleButton from "../../components/TitleButton";
import Course from "../../model/Course";
import {observable, when} from "mobx";
import CourseStore from "../../stores/CourseStore";
import Progress from "../../components/Progress";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import CourseCard from "./CourseCard";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    // flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
    width: "100%",
    margin: 0
  },
  titleBar: {
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  content: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },

})

interface ICoursesPageProps {
  userStore?: UserStore
  courseStore?: CourseStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "courseStore", "progress", "notify")
@observer
class CoursesPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICoursesPageProps & WithTheme> {

  @observable isLoading = true
  @observable courses: Course[] = []

  async componentDidMount () {
    const { userStore, progress } = this.props
    this.isLoading = true
    progress!.show("CoursesPage")
    when (
      () => !userStore!.isLoading,
      async () => {
        await this.loadCourses()
        this.isLoading = false
        progress!.hide("CoursesPage")
      }
    )
  }

  render() {
    const { classes } = this.props

    const title = "Courses"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <Grid item xs={12} className={classes.titleBar}>
              <TitleBar title={title}>
                <TitleButton title="+ Add" variant="secondary" onClick={this.onAddCourse}/>
              </TitleBar>
            </Grid>

            {this.courses &&
            <Grid container className={classes.content} direction="row" spacing={1}>
              {this.courses.map((c: Course) => {
                return (
                  <Grid item sm={6} xs={12} className={classes.card} key={c.id}>
                    <CourseCard course={c} onCardAction={this.onCardAction} onEdit={this.onEdit}/>
                  </Grid>
                )
              })}
            </Grid>
            }

          </Grid>
        </MarginRow>
      </Page>
    )
  }

  onAddCourse = () => {
    ControlTower.route(`${Routes.courseEdit}`)
  }

  onCardAction = (course: Course) => {
    ControlTower.route(`${Routes.courses}/${course.id}`)
  }

  onEdit = (course: Course) => {
    ControlTower.route(`${Routes.courseEdit}/${course.id}`)
  }

  loadCourses = async () => {
    const { courseStore } = this.props
    const courses = await courseStore!.listCourses()
    if (courses) {
      courses.sort((a: Course, b: Course) => a.title.localeCompare(b.title))
      this.courses = courses
    }
  }
}

export default withTheme((withStyles(styles)(CoursesPage)))