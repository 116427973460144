const AppConfig = {
  env: process.env.REACT_APP_ENV,
  nodeEnv: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  commitId: process.env.REACT_APP_COMMIT_ID,
  build: process.env.REACT_APP_JOB_ID,
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_KEY
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
  },
  google: {
    trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
    options: {
    }
  },
  checkBuildInterval: 900000, // 15 minutes
  domain: process.env.REACT_APP_DOMAIN ?? "govgig.us",
  homeUrl: process.env.REACT_APP_HOME_URL ? process.env.REACT_APP_HOME_URL : "/",
  jobsUrl: process.env.REACT_APP_JOBS_URL,
  newsUrl: process.env.REACT_APP_NEWS_URL,
  tinymce: {
    apiKey: process.env.REACT_APP_TINYMCE_API_KEY
  },
  branch: process.env.REACT_APP_BRANCH
};

export default AppConfig;