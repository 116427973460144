import * as React from 'react'
import {Box, createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import UserCard from "./UserCard";
import TitleButton from "../../components/TitleButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import SearchBar from 'material-ui-search-bar'
import {UserStatus} from "../../API";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center'
  },
  card: {
    paddingTop: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  },
  searchBar: {
    height: 40,
  },
  subtitle: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  quantity: {
    margin: theme.spacing(0),
    color: theme.palette.text.secondary,
    fontWeight: 600,
    textAlign: "right",
    paddingRight: theme.spacing(1)
  },

})

interface IAccountUsersProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountUsers extends React.Component<WithStyles<typeof styles> & IAccountUsersProps & WithTheme> {

  @observable account?: Account
  @observable allUsers: User[] = []
  @observable inactive: User[] = []
  @observable invited: User[] = []
  @observable registered: User[] = []
  @observable suspended: User[] = []
  @observable isLoading = true
  @observable search?: string

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountUsers")
    const { userStore, accountStore} = this.props
    when (
      () => !userStore!.isLoading,
      async () => {
        this.account = accountStore!.account
        let users = await accountStore!.listUsers(this.account!.id)
        this.allUsers = users.sort((a: User, b: User) => { return a.fullName.localeCompare(b.fullName)})
        this.filterUsers()
        this.isLoading = false
        progress!.hide("AccountUsers")
      }
    )
  }

  render() {
    const { classes, userStore } = this.props

    if (this.isLoading) {
      return null
    }

    const isAgent = userStore!.isAdminOrAgent
    const isEmployer = userStore!.isEmployer

    return (
      <div className={classes.root}>
        <Box mt={2} borderRadius={10} bgcolor="grey.500" p={1}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <SearchBar 
                value={this.search} 
                className={classes.searchBar}
                onChange={this.onSearchChange} 
                onCancelSearch={this.onCancelSearch} 
              />
            </Grid>
            <Grid item>
              { (isAgent || isEmployer) &&
                <TitleButton title="+ Add" variant="secondary" onClick={this.onAddStudent}/>
              }
            </Grid>
          </Grid>
        </Box>
        {this.allUsers &&
          <React.Fragment>
            <Grid container className={classes.content} direction="row" spacing={1}>
              <Grid item xs={11}>
                <Typography variant="body1" className={classes.subtitle}>Registered</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" className={classes.quantity}>{this.registered.length}</Typography>
              </Grid>
              {this.registered.map((u: User) => {
                return (
                  <Grid item sm={6} xs={12} className={classes.card} key={u.id}>
                    <UserCard user={u} onCardAction={this.onCardAction}/>
                  </Grid>
                )
              })}
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              <Grid item xs={11}>
                <Typography variant="body1" className={classes.subtitle}>Inactive</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" className={classes.quantity}>{this.inactive.length}</Typography>
              </Grid>
              {this.inactive.map((u: User) => {
                return (
                  <Grid item sm={6} xs={12} className={classes.card} key={u.id}>
                    <UserCard user={u} onCardAction={this.onCardAction}/>
                  </Grid>
                )
              })}
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              <Grid item xs={11}>
                <Typography variant="body1" className={classes.subtitle}>Invited</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" className={classes.quantity}>{this.invited.length}</Typography>
              </Grid>
              {this.invited.map((u: User) => {
                return (
                  <Grid item sm={6} xs={12} className={classes.card} key={u.id}>
                    <UserCard user={u} onCardAction={this.onCardAction}/>
                  </Grid>
                )
              })}
            </Grid>


            <Grid container className={classes.content} direction="row" spacing={1}>
              <Grid item xs={11}>
                <Typography variant="body1" className={classes.subtitle}>Suspended</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" className={classes.quantity}>{this.suspended.length}</Typography>
              </Grid>
              {this.suspended.map((u: User) => {
                return (
                  <Grid item sm={6} xs={12} className={classes.card} key={u.id}>
                    <UserCard user={u} onCardAction={this.onCardAction}/>
                  </Grid>
                )
              })}
            </Grid>
          </React.Fragment>
        }
      </div>
    )
  }

  onSearchChange = (value: string) => {
    this.search = value
    // localStorage.setItem(ClientsPageConstants.CLIENTS_SEARCH_NAME, this.search)
    this.filterUsers()
  }

  onCancelSearch = () => {
    this.search = undefined
    // localStorage.removeItem(ClientsPageConstants.CLIENTS_SEARCH_NAME)
    this.filterUsers()
  }

  onAddStudent = () => {
    const { accountStore } = this.props
    ControlTower.route(`${Routes.account}/${accountStore!.account!.id}/userEdit`)
  }

  onCardAction = (user: User) => {
    ControlTower.route(`${Routes.account}/${user.accountId}/person/${user.id}`)
  }

  filterUsers = () => {
    let users: User[] = []

    if (this.search) {
      const search = this.search.toLowerCase()
      this.allUsers.forEach((u: User) => {
        if (u.fullName.toLowerCase().indexOf(search!) >= 0 ||
           (u.jobTitle && u.jobTitle.toLowerCase().indexOf(search!) >= 0) ||
            u.email.toLowerCase().indexOf(search!) >= 0 ||
            u.role.toLowerCase().indexOf(search!) >= 0 ||
            u.userStatus.toLowerCase().indexOf(search) >= 0) {
          users.push(u)
        }
      })
    } else {
      users = this.allUsers
    }

    let inactive: User[] = []
    let invited: User[] = []
    let registered: User[] = []
    let suspended: User[] = []

    users.forEach((u: User) => {
      switch (u.userStatus) {
        case UserStatus.Inactive:
          inactive.push(u)
          break
        case UserStatus.Invited:
          invited.push(u)
          break
        case UserStatus.Registered:
          registered.push(u)
          break
        case UserStatus.Suspended:
          suspended.push(u)
          break
      }
    })

    this.inactive = inactive
    this.invited = invited
    this.registered = registered
    this.suspended = suspended
  }
}

export default withTheme((withStyles(styles)(AccountUsers)))