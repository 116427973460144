import * as React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import UserStore from "../../stores/UserStore";
import ControlTower, {Routes} from "../ControlTower";
import Tracking from "../Tracking";
import AccountStore from "../../stores/AccountStore";
import config from 'react-global-configuration';

const styles = (theme: Theme) => createStyles({
  menuIcon: {
    color: theme.palette.primary.main
  },
  menuItem: { // TODO: Fix pseudo selector styles
    color: theme.palette.primary.main,
    // '&:hover': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.hover
    // },
    // '&:focus': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.selected
    // },
    // '&:active': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.active
    // }
  },
  activeMenuItem: {
    fontWeight: 900,
    color: theme.palette.text.primary,
    // '&:hover': {
    //   color: theme.palette.secondary.main,
    //   backgroundColor: theme.palette.action.hover
    // },
    // '&:focus': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.selected
    // },
    // '&:active': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.active
    // }
  }
})

interface ISimpleMenuProps {
  userStore?: UserStore
  accountStore?: AccountStore
}

@inject("userStore", "accountStore")
@observer
class SimpleMenu extends React.Component<WithStyles<typeof styles> & ISimpleMenuProps> {

  @observable anchorEl = null
  @observable showAuth = false
  @observable isAuthenticated = false

  handleClick = (event: any) => {
    this.anchorEl = event.currentTarget
  };

  handleClose = () => {
    this.anchorEl = null
  };

  handleHome = () => {
    ControlTower.route(Routes.home)
  }

  handleFindJobs = () => {
    const jobsUrl = config.get("jobsUrl")
    if (jobsUrl) {
      ControlTower.open(`${jobsUrl}/find-jobs`, "_self")
    }
  }

  handleClasses = () => {
    ControlTower.route(`${Routes.myClasses}`)
  }

  handleAccount = () => {
    const { accountStore } = this.props
    ControlTower.route(`${Routes.account}/${accountStore!.account!.id}`)
  }

  handleAccounts = () => {
    ControlTower.route(`${Routes.accounts}`)
  }

  handleReports = () => {
    ControlTower.route(`${Routes.reports}`)
  }

  handleCourses = () => {
    ControlTower.route(`${Routes.courses}`)
  }

  handleSignOut = async () => {
    Tracking.event({action: "SignOut"})
    await this.props.userStore!.signOut()
    ControlTower.route(Routes.home)
  }

  render() {
    const { classes } = this.props

    const currentPath = ControlTower.currentRoute

    // const homeButton =
    //   <MenuItem key="0"
    //             className= {classes.menuItem}
    //             onClick={this.handleHome}>
    //     Home
    //   </MenuItem>

    const classesButton =
      <MenuItem key="1"
                className={currentPath === Routes.myClasses ? classes.activeMenuItem : classes.menuItem}
                onClick={this.handleClasses}>
        My Classes
      </MenuItem>

    const findJobsButton =
      <MenuItem key="2"
                className={classes.menuItem}
                onClick={this.handleFindJobs}>
        Find Jobs
      </MenuItem>

    const postJobsButton = 
      <MenuItem 
        key="3"
        className={classes.menuItem}
        onClick={() => {
          const jobsUrl = config.get("jobsUrl")
          if (jobsUrl) {
            ControlTower.open(`${jobsUrl}/create-job-posts`, "_self")
          }
        }}
      >
        Post Jobs
      </MenuItem>

    const signOutButton =
      <MenuItem key="3"
                className={classes.menuItem}
                onClick={this.handleSignOut}>
        Log Out
      </MenuItem>

    const menuItems = []

    if (this.props.userStore!.isAdminOrAgent) {
      const accountsButton =
        <MenuItem key="4"
                  className={currentPath === Routes.accounts ? classes.activeMenuItem : classes.menuItem}
                  onClick={this.handleAccounts}>
          Accounts
        </MenuItem>

      const reportsButton =
        <MenuItem key="5"
                  className={currentPath === Routes.reports ? classes.activeMenuItem : classes.menuItem}
                  onClick={this.handleReports}>
          Reports
        </MenuItem>
      const coursesButton =
        <MenuItem key="6"
                  className={currentPath.startsWith(Routes.courses) ? classes.activeMenuItem : classes.menuItem}
                  onClick={this.handleCourses}>
          Courses
        </MenuItem>

      menuItems.push(
        // homeButton,
        accountsButton,
        reportsButton,
        coursesButton,
        findJobsButton,
        postJobsButton,
        signOutButton
      )
    } else {
      const accountButton =
        <MenuItem key="4"
                  className={currentPath.startsWith(Routes.account) ? classes.activeMenuItem : classes.menuItem}
                  onClick={this.handleAccount}>
          Account
        </MenuItem>
      menuItems.push(
        // homeButton,
        classesButton,
        findJobsButton,
        postJobsButton,
        accountButton,
        signOutButton
      )
    }

    return (
      <div>
        <div>
          <IconButton
            color="inherit"
            aria-label="Menu"
            aria-owns={this.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={this.anchorEl}
            open={Boolean(this.anchorEl)}
            onClose={this.handleClose}
          >
            { menuItems }
          </Menu>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleMenu)
