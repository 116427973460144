import * as React from "react";
import {
  Card,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { observer} from "mobx-react";
import TitleButton from "../../components/TitleButton";
import Charge from "../../model/Charge";
import {isoToLocalDate, numberToMoneyFormat} from "../../stores/StoreUtilities";
import {format} from "date-fns";
import ControlTower from "../../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    height: 60,
    padding: 0
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "0px 10px",
    display: "flex",
    flexGrow: 1
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 0,
  },
  value: {
    fontSize: 16,
    fontWeight: 800,
    maxHeight: 26,
    overflow: "hidden",
  },
  titleButtons: {
    marginTop: 18,
    textAlign: "right"
  },

})

interface IAccountChargeCardProps {
  charge: Charge
}

@observer
class AccountChargeCard extends React.Component<WithStyles<typeof styles> & IAccountChargeCardProps> {

  render() {
    const { classes, charge } = this.props

    const date = format(isoToLocalDate(charge.created), "M/d/yyyy")
    const amount = numberToMoneyFormat(charge.amount)

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
            <Grid container justifyContent={'space-between'} direction="row">
              <Grid item xs={5}>
                <Typography className={classes.label}>
                  Date
                </Typography>
                <Typography className={classes.value}>
                  {date}
                </Typography>
              </Grid>

              <Grid item xs={5} style={{textAlign: "right", paddingRight: 4}}>
                <Typography className={classes.label}>
                  Amount
                </Typography>
                <Typography className={classes.value}>
                  {amount}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography className={classes.titleButtons}>
                  <TitleButton title="View" variant="secondary" onClick={this.onView}/>
                </Typography>
              </Grid>

            </Grid>
        </CardContent>
      </Card>
    )
  }

  onView = () => {
    const { charge } = this.props
    ControlTower.open(charge.receipt_url, "_blank")
  }

}

export default withStyles(styles)(AccountChargeCard)
