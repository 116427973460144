import * as React from 'react'
import Page from '../../components/page/Page'
import {Box, createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import TitleBar from "../../components/TitleBar";
import AccountCard from "../account/AccountCard";
import SearchBar from "material-ui-search-bar";
import TitleButton from "../../components/TitleButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import FormValidator from "../../components/form/FormValidator";
import {AccountType} from "../../API";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flex: "auto",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  card: {
    paddingTop: theme.spacing(1),
  },
  controlBar: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: 960,
    paddingRight: theme.spacing(1),
    flexWrap: "wrap"
  },
  search: {
    flexGrow: 1,
  },
  searchBar: {
    height: 40,
  },
  sortFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    width: 150,
  },
  accountTypeFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    width: 150,
  }
})

enum AccountTypeOptions {
  All = "All",
  Agency = "Agency",
  Individual = "Individual"
}

enum SortOptions {
  Name = "Name",
  Created = "Created"
}

interface IAccountsPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountsPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountsPageProps & WithTheme> {

  @observable allAccounts: Account[] = []
  @observable accounts: Account[] = []
  @observable isLoading = true
  @observable search?: string
  @observable accountTypeFilter = AccountTypeOptions.All
  @observable sortFilter = SortOptions.Name

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountsPage")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading,
      async () => {
        const accounts = await accountStore!.listAccounts()
        this.allAccounts = accounts.sort((a: Account, b: Account) => { return a.name.toLowerCase().localeCompare(b.name.toLowerCase())})
        this.filterAccounts()
        this.isLoading = false
        progress!.hide("AccountsPage")
      }
    )
  }

  render() {
    const { classes } = this.props

    const title = "Accounts"

    return (
      <Page title={title}>
        <MarginRow>
          <div className={ classes.root}>
            <TitleBar title={title}>
            </TitleBar>
            <Box borderRadius={10} bgcolor="grey.500" p={1}>
              <FormValidator name="filterForm">
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item>
                    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                      <Grid item>
                        <SearchBar 
                          value={this.search} 
                          className={classes.searchBar}
                          onChange={this.onSearchChange} 
                          onCancelSearch={this.onCancelSearch} 
                        />
                      </Grid>
                      <Grid item>
                        <TextFieldValidator
                          type="text"
                          className={classes.accountTypeFilter}
                          variant="outlined"
                          size="small"
                          name="accountTypeFilter"
                          label="Account Type"
                          onChange={this.onChangeAccountType}
                          autocompleteOptions={{
                            options: [...Object.values(AccountTypeOptions)],
                            value: this.accountTypeFilter,
                            disableClearable: true
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TextFieldValidator
                          type="text"
                          className={classes.sortFilter}
                          variant="outlined"
                          size="small"
                          name="sortFilter"
                          label="Sort By"
                          onChange={this.onChangeSort}
                          autocompleteOptions={{
                            options: [...Object.values(SortOptions)],
                            value: this.sortFilter,
                            disableClearable: true
                          }}
                        />
                      </Grid>
                    </Grid> 
                  </Grid>
                  <Grid item>
                    <TitleButton title="+ Add" variant="secondary" onClick={this.onAddAccount}/>
                  </Grid>
                </Grid>
              </FormValidator>
            </Box>

            {this.accounts &&
              <Grid container className={classes.content} direction="row" spacing={1}>
                {this.accounts.map((a: Account) => {
                  return (
                    <Grid item sm={6} xs={12} className={classes.card} key={a.id}>
                      <AccountCard account={a} onCardAction={this.onCardAction} onEdit={this.onEdit}/>
                    </Grid>
                  )
                })}
              </Grid>
            }
          </div>
        </MarginRow>
      </Page>
    )
  }

  onSearchChange = (value: string) => {
    this.search = value
    // localStorage.setItem(ClientsPageConstants.CLIENTS_SEARCH_NAME, this.search)
    this.filterAccounts()
  }

  onCancelSearch = () => {
    this.search = undefined
    // localStorage.removeItem(ClientsPageConstants.CLIENTS_SEARCH_NAME)
    this.filterAccounts()
  }

  onChangeAccountType = (event: any) => {
    this.accountTypeFilter = event.target.value
    this.filterAccounts()
  }

  onChangeSort = (event: any) => {
    this.sortFilter = event.target.value
    this.filterAccounts()
  }
  onAddAccount = () => {
    ControlTower.route(`${Routes.accountsEdit}`)
  }

  filterAccounts = () => {
    let accounts: Account[] = []

    if (this.search || this.accountTypeFilter !== AccountTypeOptions.All) {
      this.allAccounts.forEach((a: Account) => {
        if (this.search) {
          const search = this.search.toLowerCase()
            if (a.name.toLowerCase().indexOf(search!) >= 0) {
              if (this.accountTypeFilter === AccountTypeOptions.All ||
                (this.accountTypeFilter === AccountTypeOptions.Agency && a.accountType === AccountType.Agency) ||
                (this.accountTypeFilter === AccountTypeOptions.Individual && a.accountType === AccountType.Individual)) {
                accounts.push(a)
              }
            }
        } else if (this.accountTypeFilter === AccountTypeOptions.All ||
          (this.accountTypeFilter === AccountTypeOptions.Agency && a.accountType === AccountType.Agency) ||
          (this.accountTypeFilter === AccountTypeOptions.Individual && a.accountType === AccountType.Individual)) {
          accounts.push(a)
        }
      })
    } else {
      accounts = [...this.allAccounts]
    }

    if (this.sortFilter === SortOptions.Created) {
      accounts.sort((a: Account, b: Account) => b.createdAt.localeCompare(a.createdAt))
    } else {
      accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
    }

    this.accounts = accounts
  }

  onCardAction = async (account: Account) => {
    ControlTower.route(`${Routes.account}/${account.id}`)
  }

  onEdit = async (account: Account) => {
    ControlTower.route(`${Routes.accountsEdit}/${account.id}`)
  }
}

export default withTheme((withStyles(styles)(AccountsPage)))