import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import AccountStore from "../../stores/AccountStore";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import {observable, when} from "mobx";
import Class from "../../model/Class";
import UserStore from "../../stores/UserStore";
import Lesson from "../../model/Lesson";
import Course from "../../model/Course";
import CourseStore from "../../stores/CourseStore";
import LessonPlayer from "../lesson/LessonPlayer";
import LessonCard from "../class/LessonCard";
import NavigationBar from "../../components/NavigationBar";
import ControlTower, {Routes} from "../../components/ControlTower";
import Registration from "../../model/Registration";
import {ClassStatus, LessonStatus} from "../../API";
import DialogButton from "../../components/form/DialogButton";
import QuizCard from "../class/QuizCard";
import Notify from "../../components/notify/Notify";
import {Question} from "../../model/Question";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  quizContainer: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  quizButton: {
  }
})

// const PASSING_SCORE = 80.0

interface ICoursePreviewPageProps {
  courseId?: string
  lessonId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "courseStore", "progress", "notify")
@observer
class CoursePreviewPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICoursePreviewPageProps & WithTheme> {

  @observable classObj?: Class
  @observable course?: Course
  @observable lesson?: Lesson
  @observable registration?: Registration
  @observable isLoading = true
  @observable playing = true
  @observable disabled = false

  async componentDidMount () {
    const { userStore, accountStore, courseStore, courseId, lessonId, progress } = this.props
    console.log(`CoursePreviewPage componentDidMount`)
    this.isLoading = true
    progress!.show("CoursePreviewPage")

    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        const course = await courseStore!.getCourse(courseId!)
        if (course) {
          this.course = course
          this.classObj = this.mockClass(this.course)
          this.lesson = course?.getLesson(lessonId!)
          this.registration = this.mockRegistration(this.classObj, this.lesson)
          this.isLoading = false
          progress!.hide("CoursePreviewPage")
        }
    })
  }

  render() {
    const { classes, userStore } = this.props

    if (!this.registration) {
      return null
    }

    const isAdminOrAgent = userStore!.isAdminOrAgent

    const title = (this.classObj) ? this.classObj.course!.title : ""

    let showTakeQuiz = false
    let showQuiz = isAdminOrAgent
    let showNext = false

    return (
      <Page title={title}>
        <MarginRow>
          {!this.isLoading && this.course && this.registration &&
          <Grid container className={classes.root} direction="column">
            <NavigationBar title={title} onBack={this.onBack}/>
            <Grid item xs={12} className={classes.card}>
              {!this.isLoading &&
                <LessonPlayer course={this.course} lesson={this.lesson!}
                              registration={this.registration!}
                              playing={this.playing}
                              onPlay={this.onPlay}
                              onPause={this.onPause}
                              onProgress={this.onProgress}
                              onEnded={this.onEnded}
                              disabled={this.disabled}
                              hideContent={!isAdminOrAgent && showQuiz}
                />
              }
            </Grid>
            <Grid item xs={12} className={classes.card}>
              {!this.isLoading &&
                <LessonCard lesson={this.lesson!} onPlay={this.onTogglePlay} playing={this.playing} disabled={this.disabled}/>
              }
            </Grid>
            {showTakeQuiz &&
              <Grid item xs={12} className={classes.card}>
                <DialogButton variant="primary" onClick={this.onQuiz} fullWidth={true}>
                  Take Quiz
                </DialogButton>
              </Grid>
            }
            {showQuiz && this.lesson!.questions.map((q: Question, index: number) =>
              <Grid item xs={12} className={classes.card}>
                <QuizCard lesson={this.lesson!} questionIndex={index} registration={this.registration!} onAnswer={this.onAnswer} showAnswer={isAdminOrAgent}/>
              </Grid>
            )}
            {showNext &&
              <Grid item xs={12} className={classes.card} >
                <DialogButton variant="primary" onClick={this.onNext} fullWidth={true}>
                  NEXT
                </DialogButton>
              </Grid>
            }
          </Grid>
          }
        </MarginRow>
      </Page>
    )
  }

  mockClass = (course: Course) => {
    return new Class({
      courseId: course.id,
      course: course,
    })
  }

  mockRegistration = (classObj: Class, lesson?: Lesson) => {
    return new Registration({
      classId: classObj.id,
      class: classObj,
      classStatus: ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: lesson ? lesson.number : 1,
      lessonId: lesson ? lesson.id : undefined,
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      score: 0
    })
  }

  onBack = () => {
    const { courseId, accountStore } = this.props

    const currentRoute = ControlTower.currentRoute
    if (currentRoute.startsWith(Routes.account)) {
      ControlTower.route(`${Routes.account}/${accountStore!.account!.id}/courses/${courseId}`)
    } else {
      ControlTower.route(`${Routes.courses}/${this.props.courseId}`)
    }
  }

  onTogglePlay = () => {
    this.playing = !this.playing
  }

  onPlay = () => {
    this.playing = true
  }

  onPause = () => {
    this.playing = false
  }

  onEnded = (seconds: number) => {
    this.playing = false
  }

  onProgress = (seconds: number) => {
  }

  onQuiz = () => {
  }

  onAnswer = (answer: number) => {
  }

  onNext = async () => {
    // const { userStore } = this.props
    // Go to next lesson if admin
    // if (userStore!.isAdminOrAgent) {
    //   const nextLesson = this.course?.getNextLesson(this.registration!.lessonNumber)
    //
    //   if (nextLesson) {
    //     if (this.props.navigate) {
    //       this.props.navigate(`${Routes.course}/${this.course!.id}/lesson/${nextLesson.id}`)
    //     } else {
    //       ControlTower.route(`${Routes.course}/${this.course!.id}/lesson/${nextLesson.id}`)
    //     }
    //   }
    // } else {
      ControlTower.route(`${Routes.courses}/${this.course!.id}`)
    // }
  }
}

export default withTheme((withStyles(styles)(CoursePreviewPage)))