import React from 'react';
import './App.css';

// AWS
import {Amplify, API, Storage} from 'aws-amplify'
import aws_exports from './aws-exports'

// Config
import config from 'react-global-configuration';
import AppConfig from './AppConfig';

// Styles
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

// Routing
import { Router } from '@reach/router'
import ControlTower, {Routes} from "./components/ControlTower";
import NotFoundPage from "./pages/NotFoundPage";
import SignUpPage from "./pages/signup/SignUpPage";
import SignOutPage from "./pages/SignOutPage";
import AccountPage from "./pages/account/AccountPage";
import AccountEditPage from "./pages/account/AccountEditPage";
import ClassPage from "./pages/class/ClassPage";
import LessonPage from "./pages/lesson/LessonPage";
import UserEditPage from "./pages/account/UserEditPage";
import RegisterPage from "./pages/register/RegisterPage";
import AccountClassPage from "./pages/account/AccountClassPage";
import AccountsPage from "./pages/admin/AccountsPage";
import AccountUserPage from "./pages/account/AccountUserPage";
import MyClassesPage from "./pages/myClasses/MyClassesPage";
import SignInPage from "./pages/signIn/SignInPage";
import CoursesPage from "./pages/content/CoursesPage";
import CourseEditPage from "./pages/content/CourseEditPage";
import AccountClassEditPage from "./pages/account/AccountClassEditPage";
import CoursePage from "./pages/course/CoursePage";
import CoursePreviewPage from "./pages/course/CoursePreviewPage";
import ReportsPage from "./pages/reports/ReportsPage";
import grey from '@material-ui/core/colors/grey';
import { blueGrey } from '@material-ui/core/colors';
import TermsOfUsePage from "./pages/legal/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicyPage";
import PrivacyPolicyDialog from "./components/page/PrivacyPolicyDialog";
import TermsOfUseDialog from "./components/page/TermsOfUseDialog";
import CourseDetailPage from "./pages/content/CourseDetailPage";

config.set(AppConfig);

Amplify.configure(aws_exports);

// TODO: Remove cookieStorage for now, as it appears to accumulate a large size of cookie headers which cause site loading issues, see notes: https://trello.com/c/sjKuKIQi
// Enable cookie storage for cross-domain auth
// const authConfig = {
//   identityPoolId: aws_exports.aws_cognito_identity_pool_id,
//   region: aws_exports.aws_project_region,
//   userPoolId: aws_exports.aws_user_pools_id,
//   userPoolWebClientId: aws_exports.aws_user_pools_web_client_id,
//   cookieStorage: {
//     domain: AppConfig.domain,
//     path: "/",
//     expires: 1,
//     secure: AppConfig.domain !== "localhost"
//   }
// }
// Auth.configure(authConfig)

API.configure(aws_exports)
Storage.configure(aws_exports)

const rawTheme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      default: '#E7E9EB', // '#fff', // '#EDEEF2', // '#dedada', // '#fff', // '#E1E3E5', // '#E7E1E1', // '#dedada', // blueGrey[50], // off white
      paper: '#fff' // pure white
    },
    primary: {
      main: '#0d346a', // darkish blue
      100: '#d4e3f9',
      200: '#90b8f1',
      500: '#2e507e',
      600: '#203857',
      700: '#152438'
    },
    secondary: {
      main: '#0393ee', // brightish blue
      light: '#29a8fc' //'#5dbdfc' // 
    },
    action: {
      active: '#0393ee',
      selected: '#0393ee',
      hover: '#0393ee'
    },
    divider: grey[200], // '#D72322' // red
    text: {
      primary: '#333',
      secondary: '#777'
    },
    grey: {
      50: '#f5f7f8',
      100: '#F3F5F7',
      200: '#f5f7f9',
      300: '#e7e9eb',
      400: '#dbdee1',
      500: '#c0c5ca'
    },
    error: {
      main: 'hsl(359, 93%, 55%)'
    }
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'Roboto',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'initial',
      fontFamily: [
        'Open Sans',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontSize: "16px !important",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em"
    },
    h1: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h2: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h3: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h4: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h5: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h6: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontSize: 16
    },
    body1: {
      fontFamily: [
        'Open Sans',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontSize: 16
    },
    body2: {
      fontFamily: [
        'Open Sans',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontSize: 14
    }
  },
  shape: {
    borderRadius: 10
  }
})

const theme = {
  ...rawTheme, 
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      fontWeight: 800,
      fontSize: 44,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 32
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      fontWeight: 700,
      fontSize: 32,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 24
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      fontWeight: 600,
      fontSize: 22,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 18
      },
    },
    h4: {
      ...rawTheme.typography.h4,
      fontWeight: 300,
      fontSize: 20,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    h5: {
      ...rawTheme.typography.h5,
      fontWeight: 800,
      fontSize: 16,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 14
      },
      textTransform: 'uppercase'
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: 'rgba(25, 25, 25, 1.0)',
        "&$focused": { // Make less opaque than default of 0.7
          color: 'rgba(45, 45, 45, 1.0)',
        },
      }
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 4,
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: rawTheme.palette.secondary.main
        }
      }
    },
    MuiDialog: {
      paperFullWidth: {
        [rawTheme.breakpoints.down('xs')]: {
          margin: 0,
          width: 'calc(100% - 32px) !important',
        }
      }
    },
    MuiDialogTitle: {
      root: {
        [rawTheme.breakpoints.down('xs')]: {
          paddingLeft: rawTheme.spacing(2),
        },
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(3),
        },
        backgroundColor: rawTheme.palette.primary.main,
        color: rawTheme.palette.common.white
      }
    },
    MuiDialogContent: {
      root: {
        [rawTheme.breakpoints.down('xs')]: {
          paddingLeft: rawTheme.spacing(2),
          paddingRight: rawTheme.spacing(2),
        },
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(3),
          paddingRight: rawTheme.spacing(3),
        },
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "flex-end",
        alignItems: "center",
        [rawTheme.breakpoints.down('xs')]: {
          paddingLeft: rawTheme.spacing(2),
          paddingRight: rawTheme.spacing(2),
          paddingTop: rawTheme.spacing(1),
          paddingBottom: rawTheme.spacing(1),
        },
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(3),
          paddingRight: rawTheme.spacing(3),
          paddingTop: rawTheme.spacing(2),
          paddingBottom: rawTheme.spacing(2),
        },
        backgroundColor: blueGrey[50]
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
      }, 
      elevation2: {
        boxShadow: 'rgba(149, 157, 165, 0.5) 0px 0px 27px'
      }
    }
  }
}

interface IApp {
}

class App extends React.Component<IApp> {
  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <ControlTower path={Routes.signin}>
            <SignUpPage path={Routes.signup} />
            <AccountPage path={`${Routes.account}/:accountId`} />
            <AccountPage path={`${Routes.account}/:accountId/:tab`} />
            <AccountClassPage path={`${Routes.account}/:accountId/class/:classId`} />
            <AccountClassEditPage path={`${Routes.account}/:accountId/classEdit/:classId`} />
            <AccountClassEditPage path={`${Routes.account}/:accountId/classEdit`} />
            <AccountClassEditPage path={`${Routes.coursePurchase}/:courseId`} />
            <AccountEditPage path={`${Routes.accountEdit}/:accountId`}/>
            <AccountEditPage path={`${Routes.accountsEdit}/:accountId`}/>
            <AccountEditPage path={`${Routes.accountsEdit}`}/>
            <AccountsPage path={Routes.accounts} />
            <AccountUserPage path={`${Routes.account}/:accountId/person/:userId`}/>
            <LessonPage path={`${Routes.class}/:classId/lesson/:lessonId`}/>
            <ClassPage path={`${Routes.class}/:classId`}/>
            <CoursePage path={`${Routes.courses}/:courseId`}/>
            <CoursePage path={`${Routes.account}/:accountId/courses/:courseId`}/>
            <CourseDetailPage path={`${Routes.courseDetail}/:courseId`}/>
            <CoursePreviewPage path={`${Routes.courses}/:courseId/lesson/:lessonId`}/>
            <CoursePreviewPage path={`${Routes.account}/:accountId/courses/:courseId/lesson/:lessonId`}/>
            <CoursesPage path={Routes.courses} />
            <CourseEditPage path={`${Routes.courseEdit}/:courseId`} />
            <CourseEditPage path={Routes.courseEdit} />
            <MyClassesPage path={Routes.myClasses} />
            <PrivacyPolicyPage path={`${Routes.privacy}/:option`} />
            <PrivacyPolicyPage path={Routes.privacy} />
            <RegisterPage path={`${Routes.register}/:accountId/:userId/:role/:email`} />
            <RegisterPage path={`${Routes.register}/*`} />
            <ReportsPage path={`${Routes.reports}`}/>
            <SignInPage path={Routes.signin}/>
            <SignOutPage path={Routes.signout}/>
            <TermsOfUsePage path={Routes.terms} />
            <UserEditPage path={`${Routes.account}/:accountId/userEdit/:userId`} />
            <UserEditPage path={`${Routes.account}/:accountId/userEdit`} />
          </ControlTower>
          <NotFoundPage path={Routes.notFound}/>
        </Router>
        <PrivacyPolicyDialog/>
        <TermsOfUseDialog />
      </MuiThemeProvider>
    )

  }
}

export default App;
