import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import TermsOfUseUS from "./TermsOfUseUS";

const styles = (theme: Theme) => createStyles({

})

class TermsOfUsePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & WithTheme> {

  render() {
    return (
      <Page title="Terms of Use" hideHeader={true} hideFooter={true}>
        <MarginRow>
          <TermsOfUseUS/>
        </MarginRow>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(TermsOfUsePage)))

