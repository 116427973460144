import Class from "./Class";
import {ClassStatus, LessonStatus} from "../API";
import User from "./User";
import {getISODateFromDate, isoToLocalDate} from "../stores/StoreUtilities";
import {addYears, isBefore, startOfToday, subDays} from "date-fns";

export const PASSING_SCORE = 80.0

export class Registration {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  classId: string
  class?: Class
  userId: string
  user?: User
  classStatus: ClassStatus
  classProgress: number
  lessonNumber: number
  lessonId: string
  lessonStatus: LessonStatus
  videoProgress: number
  answers: number[]
  score: number
  startedAt: string
  endsAt: string
  completedAt: string
  lessonsAssigned: number
  nextAssignmentAt: string

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.classId = data.classId
    this.class = data.class ? new Class(data.class) : undefined
    this.userId = data.userId
    this.user = data.user ? new User(data.user) : undefined
    this.classStatus = data.classStatus
    this.classProgress = data.classProgress ? data.classProgress : 0
    this.lessonNumber = data.lessonNumber ? data.lessonNumber : 1
    this.lessonId = data.lessonId
    this.lessonStatus = data.lessonStatus
    this.videoProgress = data.videoProgress
    this.answers = data.answers ? data.answers : []
    this.score = data.score
    this.startedAt = data.startedAt
    this.endsAt = data.endsAt
    if (!data.endsAt && data.startedAt) {
      const startedAt = isoToLocalDate(data.startedAt)
      const endsAt = subDays(addYears(startedAt, 1), 1)
      this.endsAt = getISODateFromDate(endsAt)
    }
    this.completedAt = data.completedAt ? data.completedAt : data.updatedAt
    this.lessonsAssigned = data.lessonsAssigned
    this.nextAssignmentAt = data.nextAssignmentAt
  }

  update(data: any) {
    if (data.createdAt) {
      this.createdAt = data.createdAt
    }
    if (data.updatedAt) {
      this.updatedAt = data.updatedAt
    }
    if (data.accountId) {
      this.accountId = data.accountId
    }
    if (data.classId) {
      this.classId = data.classId
    }
    if (data.class) {
      this.class = new Class(data.class)
    }
    if (data.userId) {
      this.userId = data.userId
    }
    if (data.user) {
      this.user = new User(data.user)
    }
    if (data.classStatus) {
      this.classStatus = data.classStatus
    }
    if (data.classProgress) {
      this.classProgress = data.classProgress
    }
    if (data.lessonNumber) {
      this.lessonNumber = data.lessonNumber
    }
    if (data.lessonId) {
      this.lessonId = data.lessonId
    }
    if (data.lessonStatus) {
      this.lessonStatus = data.lessonStatus
    }
    if (data.videoProgress) {
      this.videoProgress = data.videoProgress
    }
    if (data.answers) {
      this.answers = data.answers
    }
    if (data.score) {
      this.score = data.score
    }
    if (data.startedAt) {
      this.startedAt = data.startedAt
    }
    if (data.endsAt) {
      this.endsAt = data.endsAt
    }
    if (data.completedAt) {
      this.completedAt = data.completedAt
    }
    if (data.lessonsAssigned) {
      this.lessonsAssigned = data.lessonsAssigned
    }
    if (data.nextAssignmentAt) {
      this.nextAssignmentAt = data.nextAssignmentAt
    }
  }

  get classCompleted(): boolean {
    return (this.classStatus === ClassStatus.Passed || this.classStatus === ClassStatus.Failed)
  }

  get passing(): boolean {
    return (this.score === 0 || this.score >= PASSING_SCORE)
  }

  get failing(): boolean {
    return (this.score !== 0 && this.score < PASSING_SCORE)
  }

  get lessonCompleted(): boolean {
    return (this.lessonStatus === LessonStatus.Passed || this.lessonStatus === LessonStatus.Failed)
  }

  get title(): string {
    return (this.class && this.class.course) ? this.class.course.title : ""
  }

  getAnswer(question: number) {
    return (this.answers && this.answers.length >= question) ? this.answers[question-1] : -1
  }

  getStatus(lesson: number): LessonStatus {
    if (this.classCompleted) {
      return LessonStatus.Ended
    } else if (this.lessonNumber === lesson) {
      return this.lessonStatus
    } else if (lesson < this.lessonNumber) {
      return LessonStatus.Passed
    } else {
      return LessonStatus.NotStarted
    }
  }

  setAnswer(lesson: number, answer: number) {
    if (!this.answers) {
      this.answers = []
    }
    this.answers[lesson-1] = answer
  }
  
  get isExpired(): boolean {
    if (!this.classCompleted && this.endsAt) {
      const endsAt = isoToLocalDate(this.endsAt)
      return (isBefore(endsAt, startOfToday()))
    }

    return false
  }
}

export default Registration