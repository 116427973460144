import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import {inject, observer} from "mobx-react";
import {Grid, Paper, Typography} from "@material-ui/core";
import DialogButton from "../../components/form/DialogButton";
import FormValidator from "../../components/form/FormValidator";
import SignInHero from "../../images/GovGig_Logo_Transparent_512.png";
import Registration from "../../model/Registration";
import {ClassStatus} from "../../API";
import {getErrorMessage} from "../../stores/StoreUtilities";
import {observable} from "mobx";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import ProgressButton from "../../components/form/ProgressButton";

const styles = (theme: Theme) => createStyles({
  dialogPaper: {
    maxWidth: 640,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'calc(50vh - 350px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(50vh - 325px)',
    },
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  dialogImage: {
    height: 'auto',
    width: '100%'
  },
  dialogHero: {
    width: "256px",
    height: "256px",
    paddingTop: 190,
    backgroundImage: `url(${SignInHero})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  titleBackground: {
    width: "100%",
  },
  dialogContent: {
    padding: "0 20px 10px 20px",
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    color: theme.palette.text.secondary,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  heroIcon: {
    color: theme.palette.secondary.main
  },
  appTitle: {
    paddingTop: 7,
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 800,
    textAlign: "center",
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 800,
    textAlign: "center",
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
  message: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
})

interface IClassCompletionDialogProps {
  onClose: any
  registration: Registration
  userStore?: UserStore
  accountStore?: AccountStore
}

@inject("userStore", "accountStore")
@observer
class ClassCompletionDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IClassCompletionDialogProps> {
  @observable isPrinting = false

  onSubmit = () => {
    this.onClose()
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  };

  render() {
    const { classes, registration } = this.props

    let title = ""
    let message = null

    if (registration.classStatus === ClassStatus.Passed) {
      title = "Congratulations!"
      message = <div className={classes.message}>
        <Typography variant="body2" className={classes.message}>
          You have successfully completed
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {registration.class!.course!.title}
        </Typography>
        <Typography variant="body2" className={classes.message}>
          with a score of {`${Math.round(registration.score)}%`}
        </Typography>
      </div>
    } else {
      title = "Good Effort!"
      message = <div className={classes.message}>
        <Typography variant="body2" className={classes.message}>
          You have completed
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {registration.class!.course!.title}
        </Typography>
        <Typography variant="body2" className={classes.message}>
          with a score of {`${Math.round(registration.score)}% which is below the 80% required to pass.`}
        </Typography>
        <Typography variant="body2" className={classes.message}>
          We encourage you to try again!
        </Typography>
      </div>
    }


    return (
      <Paper className={classes.dialogPaper}>
        <Grid container direction="row" className={classes.dialogHero}>
          <Grid item className={classes.titleBackground}>
          </Grid>
        </Grid>
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="loginForm" id="loginForm">
          <DialogContent className={classes.dialogContent}>
            <div className={classes.appTitle}>
              {title}
            </div>
            {message}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {registration.classStatus === ClassStatus.Passed &&
              <ProgressButton variant="contained" color="secondary"
                              type="submit" processing={this.isPrinting}
                              onClick={this.onPrintCertificate}>
                Print Certificate
              </ProgressButton>
            }
            {/*<DialogButton type="button" variant="primary" onClick={this.onPrintCertificate}>*/}
            {/*  PRINT CERTIFICATE*/}
            {/*</DialogButton>*/}
            <DialogButton variant="secondary" onClick={this.onClose}>
              Done
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Paper>
    )
  }

  onPrintCertificate = async () => {
    const { registration, accountStore } = this.props

    this.isPrinting = true
    if (!registration.user) {
      console.log("Loading registration user")
      registration.user = await accountStore!.getUser(registration.userId)
    }
    console.log(`Creating Certificate`)
    const result = await accountStore!.createCertificate(registration)
      .catch((err: Error) => {
        console.log(`Error creating certificate: ${getErrorMessage(err)}`)
      })

    this.isPrinting = false

    if (result) {
      const blobURL = URL.createObjectURL(result)
      window.open(blobURL)
      this.onClose()
    }

  }
}

export default withStyles(styles)(ClassCompletionDialog)
