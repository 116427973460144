import Lesson from "./Lesson";

export class Question {
  id: string
  createdAt: string
  updatedAt: string
  lessonId: string
  lesson?: Lesson
  number: number
  question: string
  answers: string[]
  correctAnswer: number

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.lessonId = data.lessonId
    this.lesson = data.lesson ? new Lesson(data.lesson) : undefined
    this.number = data.number
    this.question = data.question
    this.answers = data.answers
    this.correctAnswer = data.correctAnswer
  }
}