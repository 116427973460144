import {AgreementType} from "../API";

export class Agreement {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  accountId: string
  agreementTypes: [AgreementType]
  agreementKey?: string 

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.accountId = data.accountId
    this.agreementTypes = data.agreementTypes
    this.agreementKey = data.agreementKey
  }

  hasAgreementType(agreementType: AgreementType): boolean {
    return this.agreementTypes && this.agreementTypes.length > 0 ? this.agreementTypes.includes(agreementType) : false
  }
}

export default Agreement