import * as React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Grid, Link,
  Menu,
  MenuItem,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Registration from "../model/Registration";
import S3UrlCacheStore from "../stores/S3UrlCacheStore";
import {observable} from "mobx";
import {ClassStatus} from "../API";
import LinearProgress from '@material-ui/core/LinearProgress'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import Confirm from "./confirm/Confirm";
import Notify from "./notify/Notify";
import UserStore from "../stores/UserStore";
import {getErrorMessage, isoToLocalDate} from "../stores/StoreUtilities";
import {format} from "date-fns";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import config from 'react-global-configuration';
import AccountStore from "../stores/AccountStore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Storage from "@aws-amplify/storage";

const styles = (theme: Theme) => createStyles({
  card: {
    height: 94,
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.1,
    paddingTop: theme.spacing(0.5)
  },
  titleButtons: {
    textAlign: "right"
  },
  actionArea: {
    color: theme.palette.secondary.main
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    maxHeight: 24,
    overflow: "hidden",
  },
  status: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
  },
  cover: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  mediaArea: {
    display: "flex",
    justifyContent: "center",
  },
  media: {
    width: 78,
    height: 78
  },
  image: {
    width: "100%",
    height: "auto"
  },
  playIcon: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    bottom: 55,
    left: 25,
    width: 28,
    height: 28,
    borderRadius: 14, 
    zIndex: 11,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  progressBar: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    bottom: 15,
    padding: "0 2px",
    zIndex: 10
  },
  linearProgress: {
    color: theme.palette.primary.main,
    width: "100%",
    height: 4,
  },
  linearProgressDeterminateColor: {
    backgroundColor: theme.palette.primary.light
  },
  classProgressNotStarted: {
    backgroundColor: theme.palette.primary.light
  },
  classProgressPassing: {
    backgroundColor: theme.palette.success.main
  },
  classScorePassing: {
    color: theme.palette.success.main,
    fontWeight: 800
  },
  classProgressFailing: {
    backgroundColor: theme.palette.warning.dark,
  },
  classScoreFailing: {
    color: theme.palette.warning.light,
    fontWeight: 800
  },
  classProgressFailed: {
    backgroundColor: theme.palette.error.dark,
  },
  classScoreFailed: {
    color: theme.palette.error.light,
    fontWeight: 800
  },
  completedDate: {
    color: theme.palette.text.secondary,
    fountWeight: 600
  },
  iconButton: {
    padding: 4
  },
  menuIcon: {
    color: theme.palette.text.secondary,
    width: 20,
    height: 20,
    zOrder: 100
  },
  printingIndicator: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active,
    cursor: "pointer"
  },
})

interface IRegistrationCardProps {
  registration: Registration
  onCardAction?: any
  s3UrlCacheStore?: S3UrlCacheStore
  confirm?: Confirm
  notify?: Notify
  userStore?: UserStore
  accountStore?: AccountStore
}

@inject("registration", "s3UrlCacheStore", "confirm", "notify", "userStore", "accountStore")
@observer
class RegistrationCard extends React.Component<WithStyles<typeof styles> & IRegistrationCardProps> {

  @observable coverImageUrl?: string
  @observable menuAnchor = null
  @observable resetEnabled = false
  @observable isPrinting = false

  componentDidMount() {
    const { s3UrlCacheStore, registration } = this.props

    this.resetEnabled = config.get("branch") !== "main" && registration.id !== undefined

    if (registration.class && registration.class.course && registration.class.course.coverUrl) {
      const coverUrl = registration.class.course.coverUrl
      s3UrlCacheStore!.get(coverUrl)
        .then((url: string | undefined) => {
          this.coverImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading cover image")
        })
    }
  }

  render() {
    const { classes, registration } = this.props

    let title = "None"
    let description = "None"
    let scoreClassName = classes.classScorePassing
    if (registration.class && registration.class.course) {
      const course = registration.class.course
      title = course.title
      description = course.description
      if (registration.classStatus === ClassStatus.Failed) {
        scoreClassName = classes.classScoreFailed
      } else if (registration.failing) {
        scoreClassName = classes.classScoreFailing
      }
    }

    let handoutLink = null
    let status = ""
    if (registration.class && registration.class.course) {

      if (registration.classStatus === ClassStatus.NotStarted) {
        status = ""
      } else {
        if (registration.startedAt) {
          const startedAt = isoToLocalDate(registration.startedAt)
          status = `Started on ${format(startedAt, 'M/d/yyyy')}`
        } else {
          status = `Started`
        }
      }

      if (registration.classStatus === ClassStatus.InProgress) {
        status = `${Math.round(registration.classProgress)}% Completed`
        // if (registration.endsAt) {
        //   const endsAt = isoToLocalDate(registration.endsAt)
        //   const verb = registration.isExpired ? "Ended" : "Ends"
        //   status = `${status} (${verb} ${format(endsAt, 'M/d/yy')})`
        // }
      } else if (registration.classCompleted) {
        if (registration.completedAt) {
          const completedAt = isoToLocalDate(registration.completedAt)
          status = `${registration.classStatus} on ${format(completedAt, 'M/d/yyyy')}`
        } else if (registration.id) {
          status = 'Completed'
        } else {
          status = 'Preview'
        }
      }

      if (registration.class.course.handoutUrl) {
        handoutLink = <Link onClick={this.downloadHandout} className={classes.link}>Download Handout</Link>
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container justifyContent="space-between" wrap="nowrap" spacing={1}>
            <Grid item>
              <Grid container justifyContent="flex-start" wrap="nowrap" spacing={1}>
                <Grid item>
                  <Box m={1}>
                    <CardActionArea onClick={this.onCardAction}>
                      { this.coverImageUrl && <CardMedia className={classes.media}
                                image={this.coverImageUrl}
                                title={title}/> }
                      { this.renderProgress() }
                      <PlayArrowIcon className={classes.playIcon}/>
                    </CardActionArea>
                  </Box>
                </Grid>
                <Grid item>
                  <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
                    <Typography variant="h6" className={classes.title}>
                      {title}
                    </Typography>
                  </CardActionArea>
                  <Typography className={classes.description}>
                    {description}
                  </Typography>
                  <Typography className={classes.status}>
                    {status}
                  </Typography>
                  {handoutLink}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              { this.renderMenu() }
              <div>&nbsp;</div>
              <Box p={1} textAlign="right">
                { this.renderScore(scoreClassName) }
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  renderProgress = () => {
    const { classes, registration } = this.props

    let result = null

    if (registration.id) {

      let progressClassName = classes.classProgressNotStarted
      if (registration.classStatus === ClassStatus.InProgress) {
        progressClassName = registration.passing ? classes.classProgressPassing : classes.classProgressFailing
      } else if (registration.classStatus === ClassStatus.Failed) {
        progressClassName = classes.classProgressFailed
      } else if (registration.classStatus === ClassStatus.Passed) {
        progressClassName = classes.classProgressPassing
      }
      result =
        <div className={classes.progressBar}>
          <LinearProgress className={classes.linearProgress}
                          classes={{barColorPrimary: progressClassName, determinate: classes.linearProgressDeterminateColor}}
                          variant="determinate" value={registration.classProgress}/>
        </div>
    }

    return result
  }


  renderMenu = () => {
    const { classes, registration } = this.props

    if (!this.resetEnabled && !registration.classCompleted) {
      return null
    }

    const menuId = `class-menu`

    return (
      <Box p={1}>
        <IconButton
          color="inherit"
          className={classes.iconButton}
          aria-label="Menu"
          aria-owns={this.menuAnchor ? menuId : undefined}
          aria-haspopup="true"
          onClick={this.onOpenMenu}
        >
          <MoreVertIcon className={classes.menuIcon}/>
          {this.isPrinting && <CircularProgress size={24} className={classes.printingIndicator} />}
        </IconButton>
        <Menu id={menuId}
          anchorEl={this.menuAnchor}
          keepMounted
          open={Boolean(this.menuAnchor)}
          onClose={this.onCloseMenu}
        >
          {registration.classCompleted && registration.classStatus === ClassStatus.Passed &&
            <MenuItem onClick={this.onPrintCertificate}>Print Certificate</MenuItem>
          }
          {this.resetEnabled &&
            <MenuItem onClick={this.onResetClass}>Reset Class</MenuItem>
          }
        </Menu>
      </Box>
    )
  }

  renderScore(className: string) {
    const { registration } = this.props
    if (!registration || registration.classStatus === ClassStatus.NotStarted) {
      return (<div></div>)
    } else {
      const score = Math.round(registration.score)
      return (
        <div className={className}>{score}%</div>
      )
    }
  }

  onOpenMenu = (event: any) => {
    console.log("onOpenMenu")
    this.menuAnchor = event.currentTarget
  }
  
  onCloseMenu = () => {
    this.menuAnchor = null
  }

  onResetClass = () => {
    const { onCardAction, registration } = this.props
    if (onCardAction) {
      onCardAction("reset", registration)
    }
    this.onCloseMenu()
  }

  onPrintCertificate = async () => {
    const { registration, accountStore } = this.props
    this.onCloseMenu()
    this.isPrinting = true
    console.log(`Creating Certificate`)
    const result = await accountStore!.createCertificate(registration)
      .catch((err: Error) => {
        console.log(`Error creating certificate: ${getErrorMessage(err)}`)
      })

    this.isPrinting = false

    if (result) {
      const blobURL = URL.createObjectURL(result)
      window.open(blobURL)
    }
  }

  onCardAction = () => {
    const { onCardAction, registration } = this.props

    if (onCardAction) {
        onCardAction("open", registration)
    }
    this.onCloseMenu()
  }

  downloadHandout = async () => {
    const { registration, notify } = this.props

    if (registration.class!.course!.handoutUrl) {
      const result = await Storage.get(registration.class!.course!.handoutUrl, {download: true})
        .catch((err: Error) => {
          notify!.show("error", "Unable to download handout")
        })

      if (result && result.Body) {
        const filename = `${registration.class!.course!.title} Handout.pdf`
        this.downloadBlob(result.Body as Blob, filename);
      }
    }
  }

  downloadBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }
}

export default withStyles(styles)(RegistrationCard)
