import * as React from "react";
import {
  Box,
  Card, CardActionArea,
  CardContent, CardMedia,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Class from "../../model/Class";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {observable} from "mobx";
import TitleButton from "../../components/TitleButton";
import {format, isBefore, startOfToday} from "date-fns";
import {isoToLocalDate} from "../../stores/StoreUtilities";
import Visible from "../../components/Visible";
import UserStore from "../../stores/UserStore";

const styles = (theme: Theme) => createStyles({
  card: {
    minHeight: 90,
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  actionArea: {
    color: theme.palette.secondary.main
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.1,
    paddingTop: theme.spacing(0.5)
  },
  titleButtons: {
    textAlign: "right"
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.1,
    paddingTop: 4,
    paddingBottom: 0
  },
  titleDescriptionMax: {
    overflow: "hidden",
  },
  titleDescription: {
    overflow: "hidden",
  },
  seats: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.1,
    textAlign: "center",
    paddingTop: 4,
    paddingLeft: 5
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    maxHeight: 24,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  cover: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  mediaArea: {
    display: "flex",
    justifyContent: "center",
  },
  media: {
    width: 90,
    height: 90,
    margin: "8px 0 4px 8px",
  },
  image: {
    width: "100%",
    height: "auto"
  }
})

interface IClassCardProps {
  classObj: Class
  s3UrlCacheStore?: S3UrlCacheStore
  userStore?: UserStore
  onCardAction?: any
  onAddClass?: any
}

@inject("s3UrlCacheStore", "userStore")
@observer
class ClassCard extends React.Component<WithStyles<typeof styles> & IClassCardProps> {

  @observable coverImageUrl?: string

  componentDidMount() {
    const { s3UrlCacheStore, classObj } = this.props

    if (classObj.course && classObj.course.coverUrl) {
      const coverUrl = classObj.course.coverUrl
      s3UrlCacheStore!.get(coverUrl)
        .then((url: string | undefined) => {
          this.coverImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading cover image")
        })
    }
  }

  render() {
    const { classes, classObj, onCardAction, onAddClass, userStore } = this.props

    let title = ""
    let description = ""
    let term = ""
    let seats = ""
    let isEnded = false
    let isStudent = userStore!.isStudent

    if (classObj.course) {
      const course = classObj.course
      title = course.title
      description = course.description
      if (course.creditHours) {
        description += ` (${course.creditHours} hours)`
      }
      if (classObj.termBegin && classObj.termEnd) {
        const termBegin = isoToLocalDate(classObj.termBegin)
        const termEnd = isoToLocalDate(classObj.termEnd)
        term = `${format(termBegin, 'M/d/yy')} - ${format(termEnd, 'M/d/yy')}`
        isEnded = (isBefore(termEnd, startOfToday()))
      }

      if (classObj.seatsFilled !== undefined && classObj.seatsFilled !== null) {
        seats = classObj.seatsFilled.toString()
        if (classObj.seatsPurchased && !course.isFree) {
          seats += `/${classObj.seatsPurchased}`
        }
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container justifyContent="space-between" wrap="nowrap" spacing={1}>
            <Grid item>
              <Grid container justifyContent="flex-start" wrap="nowrap" spacing={1}>
                <Grid item>
                  <CardActionArea onClick={this.onCardAction}>
                    <CardMedia className={classes.media}
                              image={this.coverImageUrl}
                              title={title}/>
                  </CardActionArea>
                </Grid>
                <Grid item>
                  <div className={!classObj.id ? classes.titleDescription : classes.titleDescriptionMax}>
                    <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
                      <Typography variant="h6" className={classes.title}>
                        {title}
                      </Typography>
                    </CardActionArea>
                    <Typography className={classes.description}>
                      {description}
                    </Typography>
                  </div>
                  <Visible if={false /*term !== "" && !isStudent*/}>
                    <Typography className={classes.description}>
                      {term}
                    </Typography>
                  </Visible>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box p={1} textAlign="right">
                {!classObj.id && onAddClass &&
                  <Typography className={classes.titleButtons}>
                    <TitleButton title="+ Add" variant="secondary" onClick={this.onAddClass}/>
                  </Typography>
                }
                {classObj.id && onCardAction && !isEnded && !isStudent &&
                  <Typography className={classes.titleButtons}>
                    <TitleButton title="Edit" variant="secondary" onClick={this.onEditClass}/>
                  </Typography>
                }
                {classObj.id && !isStudent &&
                  <Typography className={classes.seats}>
                    {seats.length > 0 && <span>{seats}<br/>seats</span>}
                  </Typography>
                }
                {classObj.id &&
                  <Typography className={classes.seats}>
                    {classObj.userId ? "Self" : "Empl"}
                  </Typography>
                }
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  onAddClass = () => {
    const { classObj, onAddClass } = this.props

    if (onAddClass) {
      onAddClass(classObj.courseId)
    }
  }

  onEditClass = () => {
    const { onCardAction, classObj } = this.props

    if (onCardAction) {
      onCardAction("edit", classObj)
    }
  }

  onCardAction = () => {
    const { onCardAction, classObj } = this.props

    if (onCardAction) {
      onCardAction("open", classObj)
    }
  }

}

export default withStyles(styles)(ClassCard)
