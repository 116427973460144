import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles, Grid,
  Theme, Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import User from "../../model/User";
import Registration from "../../model/Registration";
import ControlTower, {Routes} from "../../components/ControlTower";
import {ActivityType, ClassStatus, CreateRegistrationInput, LessonStatus} from "../../API";
import NavigationBar from "../../components/NavigationBar";
import Tracking from "../../components/Tracking";
import ClassHistoryCard from "./ClassHistoryCard";
import Class from "../../model/Class";
import Confirm from "../../components/confirm/Confirm";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
  },
  titleBar: {
    display: "flex",
    flex: "none",
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.text.secondary,
  },
  container: {
    marginTop: theme.spacing(1)
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  subtitle: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  quantity: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600,
    textAlign: "right",
    paddingRight: theme.spacing(1)
  },

})

interface IAccountUserPageProps {
  accountId?: string
  userId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
}

@inject("accountStore", "userStore", "progress", "notify", "confirm")
@observer
class AccountUserPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountUserPageProps & WithTheme> {

  @observable isLoading = true
  @observable user?: User
  @observable registrations: Registration[] = []
  @observable inProgress: Registration[] = []
  @observable notStarted: Registration[] = []
  @observable available: Registration[] = []
  @observable completed: Registration[] = []


  componentDidMount () {
    const { accountId, userId, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("AccountUserPage")
    when(
      () => !accountStore!.isLoading && accountId !== undefined && userId !== undefined,
      async () => {
        await accountStore!.loadAccount(accountId!)
        this.user = await accountStore!.getUser(userId!)
        if (this.user) {
          this.loadRegistrations()
        }
        this.isLoading = false
        progress!.hide("AccountUserPage")
      }
    )
  }


  render() {
    const { classes } = this.props

    if (!this.user) {
      return null
    }

    const title = this.user.fullName

    return (
      <Page title={title}>
        <MarginRow>
          <div className={classes.root}>
            <NavigationBar title="People" onBack={this.onBack}/>
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>

            <Grid container direction="row" spacing={1} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>In Progress</Typography>
              </Grid>
              { this.inProgress.map((r: Registration) =>
                <Grid item sm={6} xs={12} key={r.id}>
                  <ClassHistoryCard registration={r}/>
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" spacing={1} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>Not Started</Typography>
              </Grid>
              { this.notStarted.map((r: Registration) =>
                <Grid item sm={6} xs={12} key={r.id}>
                  <ClassHistoryCard registration={r} onUnregister={this.onUnregister}/>
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" spacing={1} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>Available</Typography>
              </Grid>
              { this.available.map((r: Registration) =>
                <Grid item sm={6} xs={12} key={r.id}>
                  <ClassHistoryCard registration={r} onRegister={this.onRegister}/>
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" spacing={1} className={classes.container}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.subtitle}>Completed</Typography>
              </Grid>
              { this.completed.map((r: Registration) =>
                <Grid item sm={6} xs={12} key={r.id}>
                  <ClassHistoryCard registration={r} onUnregister={this.onUnregister} onReset={this.onReset}/>
                </Grid>
              )}
            </Grid>

          </div>
        </MarginRow>
      </Page>
    )
  }

  onBack = () => {
    const { accountId } = this.props
    ControlTower.route(`${Routes.account}/${accountId}/people`)
  }

  onRegister = async (reg: Registration) => {
    const { accountStore, userStore, notify } = this.props
    Tracking.event({action: 'Register'})

    const classObj = await accountStore!.getClass(reg.classId)
    if (!classObj) {
      console.log(`Class not found: ${reg.classId}`)
      return
    }
    const input: CreateRegistrationInput = {
      accountId: reg.accountId,
      classId: reg.classId,
      userId: reg.userId,
      classStatus: ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: 1,
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      score: 0,
      lessonsAssigned: classObj.getRegistrationLessonsAssigned()
    }

    const registration = await accountStore!.addClassRegistration(input)
      .catch(err => {
        notify!.show("error", "Unable to register user")
      })

    if (registration) {
      registration.user = reg.user
      userStore!.createActivity(ActivityType.ClassRegistration, registration.classId)
      // Add to registrations
      this.user!.registrations.push(registration)
      this.loadRegistrations()
    }
  }

  onUnregister = async (reg: Registration) => {
    const { accountStore, notify } = this.props
    Tracking.event({action: 'Unregister'})

    const registration = await accountStore!.deleteClassRegistration(reg.id)
      .catch(err => {
        notify!.show("error", "Unable to unregister user")
      })

    if (registration) {
      // Remove from list
      const registeredIndex = this.user!.registrations.findIndex((r: Registration) => { return r.id === registration.id })
      if (registeredIndex >= 0) {
        this.user!.registrations.splice(registeredIndex, 1)
        this.loadRegistrations()
      }
    }
  }

  onReset = (registration: Registration) => {
    const { userStore, confirm, accountStore } = this.props

    Tracking.event({action: 'ResetClass'})
    confirm!.show("Confirm Reset Class",
      `Please confirm you want to reset ${registration.class!.course!.title}`,
      ["Confirm", "Cancel"],
      async () => {
        const updatedRegistration = await userStore!.resetRegistration(registration.id)
        if (updatedRegistration) {
          // Update list
          this.user = await accountStore!.getUser(this.user!.id)
          if (this.user) {
            this.loadRegistrations()
          }
        }
        return true
      },
      () => {
        // Do nothing
      })
  }

  private loadRegistrations = () => {
    const { accountStore } = this.props

    if (this.user && this.user.registrations) {
      const inProgress: Registration[] = []
      const notStarted: Registration[] = []
      const completed: Registration[] = []
      const available: Registration[] = []

      this.user.registrations.forEach((r: Registration) => {
        if (r.classStatus === ClassStatus.NotStarted) {
          notStarted.push(r)
        } else if (r.classStatus === ClassStatus.InProgress) {
          inProgress.push(r)
        } else if (r.classCompleted) {
          completed.push(r)
        }
      })

      // Sort most completed first
      inProgress.sort((a: Registration, b: Registration) => b.classProgress - a.classProgress )
      this.inProgress = inProgress
      // Sort by createdAt ascending
      notStarted.sort((a: Registration, b: Registration) => a.createdAt.localeCompare(b.createdAt))
      this.notStarted = notStarted
      // Sort by updatedAt ascending
      completed.sort((a: Registration, b: Registration) => a.updatedAt.localeCompare(b.updatedAt))
      this.completed = completed

      // Add pseudo registrations for new classes
      const classes = accountStore!.account!.classes
      classes.forEach((c: Class) => {
        if (!c.userId) { // Ignore individually owned classes
          // let registration = this.user!.registrations.find((r: Registration) => r.classId === c.id)
          let registration = this.user!.registrations.find((r: Registration) => r.class!.courseId === c.courseId)
          if (!registration) {
            registration = new Registration({
              id: null,
              accountId: this.user!.accountId,
              classId: c.id,
              class: c,
              userId: this.user!.id,
              user: this.user,
              classStatus: ClassStatus.NotStarted,
              classProgress: 0,
              lessonNumber: 1,
              lessonStatus: LessonStatus.NotStarted,
              videoProgress: 0,
              score: 0
            })
            available.push(registration)
          }
        }
      })
      available.sort((a: Registration, b: Registration) => a.title.localeCompare(b.title))
      this.available = available
    }

  }

}

export default withTheme((withStyles(styles)(AccountUserPage)))