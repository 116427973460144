import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import {inject, observer} from "mobx-react";
import {Grid, Paper, Typography} from "@material-ui/core";
import DialogButton from "../../components/form/DialogButton";
import FormValidator from "../../components/form/FormValidator";
import SignInHero from "../../images/GovGig_Logo_Transparent_512.png";
import Registration from "../../model/Registration";
import {observable} from "mobx";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import ProgressButton from "../../components/form/ProgressButton";
import ValueProposition from "../../components/page/ValueProposition";
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FlightIcon from '@material-ui/icons/Flight';
import RoomIcon from '@material-ui/icons/Room';
import {UpdateUserInput, UserRole} from "../../API";
import Notify from "../../components/notify/Notify";
import config from "react-global-configuration";
import ControlTower from "../../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  dialogPaper: {
    maxWidth: 640,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'calc(50vh - 350px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(50vh - 410px)',
    },
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  dialogImage: {
    height: 'auto',
    width: '100%'
  },
  dialogHero: {
    width: "256px",
    height: "256px",
    paddingTop: 190,
    backgroundImage: `url(${SignInHero})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  titleBackground: {
    width: "100%",
  },
  dialogContent: {
    padding: "0 20px 10px 20px",
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    color: theme.palette.text.secondary,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  heroIcon: {
    color: theme.palette.secondary.main
  },
  appTitle: {
    paddingTop: 7,
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 800,
    textAlign: "center",
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 800,
    textAlign: "center",
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
  message: {
    color: theme.palette.text.primary,
    textAlign: "center",
    fontWeight: 600,
    marginTop: 8
  },
})

interface IProfileActionDialogProps {
  onClose: any
  registration: Registration
  userStore?: UserStore
  accountStore?: AccountStore
  notify?: Notify
}

@inject("userStore", "accountStore", "notify")
@observer
class ProfileActionDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IProfileActionDialogProps> {
  @observable isProcessing = false

  onSubmit = () => {
    this.onClose()
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  };

  render() {
    const { classes, registration, userStore } = this.props

    let title = ""
    let message
    let proposition
    let actionButton

    if (userStore?.isStudent) {
      title = "Do you have the GovGig Advantage?"
      message = <div className={classes.message}>
        <Typography variant="body2" className={classes.message}>
          As a Federal contracting professional, GovGig helps you display your skills, credentials, and relevant experience to be be seen by Contractors who need talent. The GovGig advantages make you stand out!
        </Typography>
      </div>
      proposition = <Grid container direction="column" justifyContent="space-between" spacing={3}>
          <ValueProposition
            avatarIcon={<PersonIcon />}
            text="Showcase your experience and credentials in a professional profile."
          />
          <ValueProposition
            avatarIcon={<AttachMoneyIcon />}
            text="Get contacted about well-paid positions, where you want to work."
          />
          <ValueProposition
            avatarIcon={<FlightIcon />}
            text="Learn about new positions to increase your income or work in a new region."
          />
          <ValueProposition
            avatarIcon={<RoomIcon />}
            text="You are more than a resume; GovGig helps you stand out from the crowd."
          />
        </Grid>
      actionButton = <ProgressButton variant="contained" color="secondary"
                       type="submit" processing={this.isProcessing}
                       onClick={this.onJoinGovGig}>Join GovGig</ProgressButton>
    } else if (userStore!.user!.role === UserRole.Applicant || userStore!.user!.role === UserRole.Candidate) {
      title = "Add Certificate to Profile?"
      message = <div className={classes.message}>
        <Typography variant="body2" className={classes.message}>
          Would you like to add this certificate to your GovGig Profile?
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {registration.class!.course!.title}
        </Typography>
      </div>
      actionButton = <ProgressButton variant="contained" color="secondary"
                                     type="submit" processing={this.isProcessing}
                                     onClick={this.onAddCertificate}>Add to Profile</ProgressButton>
    } else {
      // TODO: Handle doing nothing
    }

    return (
      <Paper className={classes.dialogPaper}>
        <Grid container direction="row" className={classes.dialogHero}>
          <Grid item className={classes.titleBackground}>
          </Grid>
        </Grid>
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="actionForm" id="actionForm">
          <DialogContent className={classes.dialogContent}>
            <div className={classes.appTitle}>
              {title}
            </div>
            {message}
            {proposition}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {actionButton}
            <DialogButton variant="secondary" onClick={this.onClose}>
              Done
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Paper>
    )
  }

  onJoinGovGig = async () => {
    const { registration, accountStore, userStore, notify } = this.props

    this.isProcessing = true
    if (!registration.user) {
      registration.user = await accountStore!.getUser(registration.userId)
    }

    // Convert to Applicant and take to Profile page
    if (userStore!.isStudent) {
      const updateUserInput: UpdateUserInput = {
        id: userStore!.user!.id,
        role: UserRole.Applicant
      }
      const user = await userStore!.updateUser(updateUserInput)
        .catch((err) => {
          notify!.show("error", "Unable to convert user to Applicant")
        })

      if (user) {
        // Send to GovGig Profile page
        const jobsUrl = config.get("jobsUrl")
        if (jobsUrl) {
          ControlTower.open(`${jobsUrl}/profile`, "_self")
        }

      }
    }
    
    this.isProcessing = false
  }

  onAddCertificate = async () => {
    const { registration, accountStore } = this.props

    this.isProcessing = true
    if (!registration.user) {
      registration.user = await accountStore!.getUser(registration.userId)
    }

    // TODO: Add certificate to profile

    this.isProcessing = false
  }
}

export default withStyles(styles)(ProfileActionDialog)
