import Lesson from "./Lesson";


export class Course {
  id: string
  createdAt: string
  updatedAt: string
  title: string
  description: string
  coverUrl: string
  details: string
  publishDate: string
  sunsetDate: string
  lessonCount: number
  creditHours: number
  isFree: boolean
  handoutUrl: string
  lessons: Lesson[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.title = data.title
    this.description = data.description
    this.coverUrl = data.coverUrl
    this.details = data.details
    this.publishDate = data.publishDate
    this.sunsetDate = data.sunsetDate
    this.lessonCount = data.lessonCount
    this.isFree = data.isFree
    this.creditHours = data.creditHours
    this.handoutUrl = data.handoutUrl
    this.lessons = []
    if (data.lessons && data.lessons.items) {
      this.loadLessons(data.lessons.items)
    }
  }

  loadLessons(data: any) {
    const json = JSON.parse(data)
    if (json.items) {
      this.lessons = json.items.map((item: any) => new Lesson(item))
      this.lessonCount = this.lessons.length
    }
  }

  getLesson(lessonId: string) {
    const lesson = this.lessons.find((l: Lesson) => { return l.id === lessonId})
    return lesson
  }

  getLessonNumber(num: number) {
    const lesson = this.lessons.find((l: Lesson) => { return l.number === num})
    return lesson
  }

  getNextLesson(num: number) {
    const index = this.lessons.findIndex((l: Lesson) => { return l.number === num})
    if (index >= 0 && index < this.lessons.length-1) {
      return this.lessons[index+1]
    } else {
      return null
    }
  }
}

export default Course