

class InvoiceItem {
  customer: string
  unit_amount: number
  quantity: number
  currency: string
  description: string
  discounts: any[]

  constructor (data: any) {
    this.customer = data.customer
    this.unit_amount = data.unit_amount
    this.quantity = data.quantity
    this.currency = data.currency
    this.description = data.description
    this.discounts = data.discounts
  }
}

export default InvoiceItem