/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAccountInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name: string,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  active?: boolean | null,
  dunsNumber?: string | null,
  cageCode?: string | null,
  naicsCode?: string | null,
  sicCode?: string | null,
  socioEconomicDesignation?: string | null,
  fein?: string | null,
  accountStatus?: AccountStatus | null,
  phone?: string | null,
  webUrl?: string | null,
  accountType?: AccountType | null,
  accountSize?: AccountSize | null,
  customerId?: string | null,
  industries?: Array< string | null > | null,
};

export enum AccountStatus {
  Inactive = "Inactive",
  Pending = "Pending",
  Active = "Active",
  Approved = "Approved",
  Suspended = "Suspended",
  Closed = "Closed",
}


export enum AccountType {
  Individual = "Individual",
  Agency = "Agency",
}


export enum AccountSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}


export type ModelAccountConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  dunsNumber?: ModelStringInput | null,
  cageCode?: ModelStringInput | null,
  naicsCode?: ModelStringInput | null,
  sicCode?: ModelStringInput | null,
  socioEconomicDesignation?: ModelStringInput | null,
  fein?: ModelStringInput | null,
  accountStatus?: ModelAccountStatusInput | null,
  phone?: ModelStringInput | null,
  webUrl?: ModelStringInput | null,
  accountType?: ModelAccountTypeInput | null,
  accountSize?: ModelAccountSizeInput | null,
  customerId?: ModelStringInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAccountStatusInput = {
  eq?: AccountStatus | null,
  ne?: AccountStatus | null,
};

export type ModelAccountTypeInput = {
  eq?: AccountType | null,
  ne?: AccountType | null,
};

export type ModelAccountSizeInput = {
  eq?: AccountSize | null,
  ne?: AccountSize | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name: string,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  active?: boolean | null,
  dunsNumber?: string | null,
  cageCode?: string | null,
  naicsCode?: string | null,
  sicCode?: string | null,
  socioEconomicDesignation?: string | null,
  fein?: string | null,
  accountStatus?: AccountStatus | null,
  phone?: string | null,
  webUrl?: string | null,
  users?: ModelUserConnection | null,
  contracts?: ModelContractConnection | null,
  accountType?: AccountType | null,
  accountSize?: AccountSize | null,
  customerId?: string | null,
  industries?: Array< string | null > | null,
  classes?: ModelClassConnection | null,
  activity?: ModelActivityConnection | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  userStatus?: UserStatus | null,
  accountId: string,
  account?: Account | null,
  firstName: string,
  lastName: string,
  title?: string | null,
  email: string,
  phone?: string | null,
  role?: UserRole | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  profiles?: ModelProfileConnection | null,
  agreements?: ModelAgreementConnection | null,
  industry?: string | null,
  lastActiveAt?: string | null,
  jobTitle?: string | null,
  invitedAt?: string | null,
  registrations?: ModelRegistrationConnection | null,
  customerId?: string | null,
};

export enum UserStatus {
  Inactive = "Inactive",
  Invited = "Invited",
  Registered = "Registered",
  Suspended = "Suspended",
}


export enum UserRole {
  Admin = "Admin",
  Agent = "Agent",
  Applicant = "Applicant",
  Candidate = "Candidate",
  Employer = "Employer",
  Student = "Student",
  Owner = "Owner",
}


export type ModelProfileConnection = {
  __typename: "ModelProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
};

export type Profile = {
  __typename: "Profile",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  active: boolean,
  userId: string,
  user?: User | null,
  nickname?: string | null,
  profileServices?: ModelProfileServiceConnection | null,
  profileLocations?: ModelProfileLocationConnection | null,
  availability?: string | null,
  availableDate?: string | null,
  desiredRate?: string | null,
  about?: string | null,
  resumeKey?: string | null,
  profileStatus: ProfileStatus,
  statusMessage?: string | null,
  notes?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  federalExperience?: number | null,
  totalExperience?: number | null,
  industries?: Array< string | null > | null,
  securityClearance?: string | null,
  securityStatus?: SecurityStatus | null,
  workSettings?: Array< WorkSetting | null > | null,
  experiences?: ModelExperienceConnection | null,
  education?: ModelEducationConnection | null,
  certifications?: ModelCertificationConnection | null,
  credentials?: string | null,
};

export type ModelProfileServiceConnection = {
  __typename: "ModelProfileServiceConnection",
  items:  Array<ProfileService | null >,
  nextToken?: string | null,
};

export type ProfileService = {
  __typename: "ProfileService",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  profile?: Profile | null,
  serviceId: string,
  title?: string | null,
  industries?: Array< string | null > | null,
};

export type ModelProfileLocationConnection = {
  __typename: "ModelProfileLocationConnection",
  items:  Array<ProfileLocation | null >,
  nextToken?: string | null,
};

export type ProfileLocation = {
  __typename: "ProfileLocation",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  profile?: Profile | null,
  locationId: string,
};

export enum ProfileStatus {
  Pending = "Pending",
  Submitted = "Submitted",
  Reviewing = "Reviewing",
  Verifying = "Verifying",
  Accepted = "Accepted",
  Declined = "Declined",
  Academy = "Academy",
  Future = "Future",
  Inactive = "Inactive",
}


export enum SecurityStatus {
  Active = "Active",
  Inactive = "Inactive",
}


export enum WorkSetting {
  InPerson = "InPerson",
  Remote = "Remote",
  Hybrid = "Hybrid",
}


export type ModelExperienceConnection = {
  __typename: "ModelExperienceConnection",
  items:  Array<Experience | null >,
  nextToken?: string | null,
};

export type Experience = {
  __typename: "Experience",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  profile?: Profile | null,
  jobTitle?: string | null,
  agencyType?: AgencyType | null,
  agencyName?: string | null,
  employerName?: string | null,
  employerLocation?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  projectNo?: string | null,
  projectName?: string | null,
  projectLocation?: string | null,
  projectDollarAmount?: number | null,
  jobDescription?: string | null,
};

export enum AgencyType {
  Federal = "Federal",
  State = "State",
  County = "County",
  Municipal = "Municipal",
  Commercial = "Commercial",
  Other = "Other",
  None = "None",
}


export type ModelEducationConnection = {
  __typename: "ModelEducationConnection",
  items:  Array<Education | null >,
  nextToken?: string | null,
};

export type Education = {
  __typename: "Education",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  profile?: Profile | null,
  school?: string | null,
  degree?: string | null,
  field?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  description?: string | null,
};

export type ModelCertificationConnection = {
  __typename: "ModelCertificationConnection",
  items:  Array<Certification | null >,
  nextToken?: string | null,
};

export type Certification = {
  __typename: "Certification",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  profile?: Profile | null,
  certificationType: string,
  issuedAt?: string | null,
  expiresAt?: string | null,
  certificateKey?: string | null,
};

export type ModelAgreementConnection = {
  __typename: "ModelAgreementConnection",
  items:  Array<Agreement | null >,
  nextToken?: string | null,
};

export type Agreement = {
  __typename: "Agreement",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  agreementTypes?: Array< AgreementType | null > | null,
  agreementKey?: string | null,
};

export enum AgreementType {
  BackgroundCheck = "BackgroundCheck",
  ElectronicCommunication = "ElectronicCommunication",
  FederalExperience = "FederalExperience",
  I9Eligible = "I9Eligible",
  PrivacyPolicy = "PrivacyPolicy",
  TermsOfUse = "TermsOfUse",
  MasterServicesAndNonSolicitation = "MasterServicesAndNonSolicitation",
  ConfidentialityAndNonDisclosure = "ConfidentialityAndNonDisclosure",
  ExclusiveRecruitment = "ExclusiveRecruitment",
  StaffingAgreement = "StaffingAgreement",
  ProfessionalServicesAgreement = "ProfessionalServicesAgreement",
}


export type ModelRegistrationConnection = {
  __typename: "ModelRegistrationConnection",
  items:  Array<Registration | null >,
  nextToken?: string | null,
};

export type Registration = {
  __typename: "Registration",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  classId: string,
  class?: Class | null,
  userId: string,
  user?: User | null,
  classStatus: ClassStatus,
  classProgress: number,
  lessonNumber: number,
  lessonId?: string | null,
  lessonStatus?: LessonStatus | null,
  videoProgress?: number | null,
  answers?: Array< number | null > | null,
  score: number,
  startedAt?: string | null,
  endsAt?: string | null,
  completedAt?: string | null,
  lessonsAssigned?: number | null,
  nextAssignmentAt?: string | null,
};

export type Class = {
  __typename: "Class",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  courseId: string,
  course?: Course | null,
  accountId?: string | null,
  account?: Account | null,
  ownerId?: string | null,
  userId?: string | null,
  termBegin?: string | null,
  termEnd?: string | null,
  seatsPurchased?: number | null,
  seatsFilled?: number | null,
  couponCode?: string | null,
  assignmentSchedule?: AssignmentSchedule | null,
  registrations?: ModelRegistrationConnection | null,
};

export type Course = {
  __typename: "Course",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  title: string,
  description?: string | null,
  coverUrl?: string | null,
  details?: string | null,
  publishDate?: string | null,
  sunsetDate?: string | null,
  lessonCount?: number | null,
  creditHours?: number | null,
  isFree?: boolean | null,
  lessons?: string | null,
  handoutUrl?: string | null,
  classes?: ModelClassConnection | null,
};

export type ModelClassConnection = {
  __typename: "ModelClassConnection",
  items:  Array<Class | null >,
  nextToken?: string | null,
};

export enum AssignmentSchedule {
  All = "All",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}


export enum ClassStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Passed = "Passed",
  Failed = "Failed",
}


export enum LessonStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Ended = "Ended",
  Quiz = "Quiz",
  Passed = "Passed",
  Failed = "Failed",
}


export type ModelContractConnection = {
  __typename: "ModelContractConnection",
  items:  Array<Contract | null >,
  nextToken?: string | null,
};

export type Contract = {
  __typename: "Contract",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  issuedBy?: string | null,
  name: string,
  number?: string | null,
  locationId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  perDiem?: number | null,
  value?: string | null,
  category?: string | null,
  jobPosts?: ModelJobPostConnection | null,
};

export type ModelJobPostConnection = {
  __typename: "ModelJobPostConnection",
  items:  Array<JobPost | null >,
  nextToken?: string | null,
};

export type JobPost = {
  __typename: "JobPost",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  account?: Account | null,
  contractId?: string | null,
  contract?: Contract | null,
  serviceRequestId?: string | null,
  serviceRequest?: ServiceRequest | null,
  locationId?: string | null,
  serviceId: string,
  industries?: Array< string | null > | null,
  title?: string | null,
  summary: string,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  openDate?: string | null,
  filledDate?: string | null,
  closedDate?: string | null,
  employmentType: EmploymentType,
  annualSalaryLow?: number | null,
  annualSalaryHigh?: number | null,
  jobCandidates?: ModelJobCandidateConnection | null,
  statusId?: string | null,
  status?: JobPostStatus | null,
  openings?: number | null,
  invoiceId?: string | null,
  jobPostType?: JobPostType | null,
  hiringDate?: string | null,
  unlocksAvailable?: number | null,
  featured?: boolean | null,
  questions?: string | null,
  workSettings?: Array< WorkSetting | null > | null,
  securityClearance?: string | null,
};

export type ServiceRequest = {
  __typename: "ServiceRequest",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  account?: Account | null,
  serviceRequestType: ServiceRequestType,
  name: string,
  status?: string | null,
  completedDate?: string | null,
  jobPosts?: ModelJobPostConnection | null,
};

export enum ServiceRequestType {
  RecruitingServices = "RecruitingServices",
  StaffingServices = "StaffingServices",
  ProfessionalServices = "ProfessionalServices",
  SelfService = "SelfService",
}


export enum EmploymentType {
  Contract = "Contract",
  Direct = "Direct",
  FullTime = "FullTime",
  PartTime = "PartTime",
  Temporary = "Temporary",
  Internship = "Internship",
}


export type ModelJobCandidateConnection = {
  __typename: "ModelJobCandidateConnection",
  items:  Array<JobCandidate | null >,
  nextToken?: string | null,
};

export type JobCandidate = {
  __typename: "JobCandidate",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  jobPostId: string,
  jobPost?: JobPost | null,
  accountId: string,
  account?: Account | null,
  profileId: string,
  profile?: Profile | null,
  status?: JobCandidateStatus | null,
  ranking?: number | null,
  notes?: string | null,
  message?: string | null,
  unlocked?: boolean | null,
  application?: string | null,
  origin?: RecordUpdateOrigin | null,
};

export enum JobCandidateStatus {
  Interested = "Interested",
  Invited = "Invited",
  Applied = "Applied",
  Accepted = "Accepted",
  Reviewing = "Reviewing",
  Rejected = "Rejected",
  Unlocked = "Unlocked",
  Contacted = "Contacted",
  Hired = "Hired",
  Declined = "Declined",
}


export enum RecordUpdateOrigin {
  User = "User",
  Lambda = "Lambda",
}


export enum JobPostStatus {
  Cart = "Cart",
  Draft = "Draft",
  Posted = "Posted",
  Pending = "Pending",
  Hired = "Hired",
  Closed = "Closed",
}


export enum JobPostType {
  RecruitingServices = "RecruitingServices",
  StaffingServices = "StaffingServices",
  SelfService = "SelfService",
}


export type ModelActivityConnection = {
  __typename: "ModelActivityConnection",
  items:  Array<Activity | null >,
  nextToken?: string | null,
};

export type Activity = {
  __typename: "Activity",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  account: Account,
  userId: string,
  activityDate: string,
  activityType: ActivityType,
  objectId: string,
  values?: string | null,
};

export enum ActivityType {
  AccountCreate = "AccountCreate",
  ClassComplete = "ClassComplete",
  ClassCreate = "ClassCreate",
  ClassEdit = "ClassEdit",
  ClassPayment = "ClassPayment",
  ClassRegistration = "ClassRegistration",
  CourseCreate = "CourseCreate",
  CourseEdit = "CourseEdit",
  LessonComplete = "LessonComplete",
  UserCreate = "UserCreate",
  UserRegister = "UserRegister",
  UserSignIn = "UserSignIn",
}


export type UpdateAccountInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  active?: boolean | null,
  dunsNumber?: string | null,
  cageCode?: string | null,
  naicsCode?: string | null,
  sicCode?: string | null,
  socioEconomicDesignation?: string | null,
  fein?: string | null,
  accountStatus?: AccountStatus | null,
  phone?: string | null,
  webUrl?: string | null,
  accountType?: AccountType | null,
  accountSize?: AccountSize | null,
  customerId?: string | null,
  industries?: Array< string | null > | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  userStatus?: UserStatus | null,
  accountId: string,
  firstName: string,
  lastName: string,
  title?: string | null,
  email: string,
  phone?: string | null,
  role?: UserRole | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  industry?: string | null,
  lastActiveAt?: string | null,
  jobTitle?: string | null,
  invitedAt?: string | null,
  customerId?: string | null,
};

export type ModelUserConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  userStatus?: ModelUserStatusInput | null,
  accountId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  industry?: ModelStringInput | null,
  lastActiveAt?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  invitedAt?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type UpdateUserInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  userStatus?: UserStatus | null,
  accountId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  title?: string | null,
  email?: string | null,
  phone?: string | null,
  role?: UserRole | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  industry?: string | null,
  lastActiveAt?: string | null,
  jobTitle?: string | null,
  invitedAt?: string | null,
  customerId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateServiceGroupInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  name: string,
  industries?: Array< string | null > | null,
  default?: boolean | null,
};

export type ModelServiceGroupConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  industries?: ModelStringInput | null,
  default?: ModelBooleanInput | null,
  and?: Array< ModelServiceGroupConditionInput | null > | null,
  or?: Array< ModelServiceGroupConditionInput | null > | null,
  not?: ModelServiceGroupConditionInput | null,
};

export type ServiceGroup = {
  __typename: "ServiceGroup",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  name: string,
  services?: ModelServiceConnection | null,
  industries?: Array< string | null > | null,
  default?: boolean | null,
};

export type ModelServiceConnection = {
  __typename: "ModelServiceConnection",
  items:  Array<Service | null >,
  nextToken?: string | null,
};

export type Service = {
  __typename: "Service",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  name: string,
  aliases?: Array< string | null > | null,
  active: boolean,
  serviceGroupId: string,
  serviceGroup?: ServiceGroup | null,
  industries?: Array< string | null > | null,
};

export type UpdateServiceGroupInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  name?: string | null,
  industries?: Array< string | null > | null,
  default?: boolean | null,
};

export type DeleteServiceGroupInput = {
  id: string,
};

export type CreateServiceInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  name: string,
  aliases?: Array< string | null > | null,
  active: boolean,
  serviceGroupId: string,
  industries?: Array< string | null > | null,
};

export type ModelServiceConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  aliases?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  serviceGroupId?: ModelIDInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelServiceConditionInput | null > | null,
  or?: Array< ModelServiceConditionInput | null > | null,
  not?: ModelServiceConditionInput | null,
};

export type UpdateServiceInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  name?: string | null,
  aliases?: Array< string | null > | null,
  active?: boolean | null,
  serviceGroupId?: string | null,
  industries?: Array< string | null > | null,
};

export type DeleteServiceInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationName: string,
  active?: boolean | null,
  name: string,
  aliases?: Array< string | null > | null,
  addressType?: AddressType | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country: string,
  latitude: number,
  longitude: number,
};

export enum AddressType {
  Street = "Street",
  Neighborhood = "Neighborhood",
  PostalCode = "PostalCode",
  City = "City",
  County = "County",
  State = "State",
  Country = "Country",
  World = "World",
}


export type ModelLocationConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationName?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  aliases?: ModelStringInput | null,
  addressType?: ModelAddressTypeInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
};

export type ModelAddressTypeInput = {
  eq?: AddressType | null,
  ne?: AddressType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationName: string,
  active?: boolean | null,
  name: string,
  aliases?: Array< string | null > | null,
  addressType?: AddressType | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country: string,
  latitude: number,
  longitude: number,
};

export type UpdateLocationInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationName?: string | null,
  active?: boolean | null,
  name?: string | null,
  aliases?: Array< string | null > | null,
  addressType?: AddressType | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  country?: string | null,
  latitude?: number | null,
  longitude?: number | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreateProfileInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  active: boolean,
  userId: string,
  nickname?: string | null,
  availability?: string | null,
  availableDate?: string | null,
  desiredRate?: string | null,
  about?: string | null,
  resumeKey?: string | null,
  profileStatus: ProfileStatus,
  statusMessage?: string | null,
  notes?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  federalExperience?: number | null,
  totalExperience?: number | null,
  industries?: Array< string | null > | null,
  securityClearance?: string | null,
  securityStatus?: SecurityStatus | null,
  workSettings?: Array< WorkSetting | null > | null,
  credentials?: string | null,
};

export type ModelProfileConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  userId?: ModelIDInput | null,
  nickname?: ModelStringInput | null,
  availability?: ModelStringInput | null,
  availableDate?: ModelStringInput | null,
  desiredRate?: ModelStringInput | null,
  about?: ModelStringInput | null,
  resumeKey?: ModelStringInput | null,
  profileStatus?: ModelProfileStatusInput | null,
  statusMessage?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  federalExperience?: ModelFloatInput | null,
  totalExperience?: ModelFloatInput | null,
  industries?: ModelStringInput | null,
  securityClearance?: ModelStringInput | null,
  securityStatus?: ModelSecurityStatusInput | null,
  workSettings?: ModelWorkSettingListInput | null,
  credentials?: ModelStringInput | null,
  and?: Array< ModelProfileConditionInput | null > | null,
  or?: Array< ModelProfileConditionInput | null > | null,
  not?: ModelProfileConditionInput | null,
};

export type ModelProfileStatusInput = {
  eq?: ProfileStatus | null,
  ne?: ProfileStatus | null,
};

export type ModelSecurityStatusInput = {
  eq?: SecurityStatus | null,
  ne?: SecurityStatus | null,
};

export type ModelWorkSettingListInput = {
  eq?: Array< WorkSetting | null > | null,
  ne?: Array< WorkSetting | null > | null,
  contains?: WorkSetting | null,
  notContains?: WorkSetting | null,
};

export type UpdateProfileInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  active?: boolean | null,
  userId?: string | null,
  nickname?: string | null,
  availability?: string | null,
  availableDate?: string | null,
  desiredRate?: string | null,
  about?: string | null,
  resumeKey?: string | null,
  profileStatus?: ProfileStatus | null,
  statusMessage?: string | null,
  notes?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  federalExperience?: number | null,
  totalExperience?: number | null,
  industries?: Array< string | null > | null,
  securityClearance?: string | null,
  securityStatus?: SecurityStatus | null,
  workSettings?: Array< WorkSetting | null > | null,
  credentials?: string | null,
};

export type DeleteProfileInput = {
  id: string,
};

export type CreateProfileLocationInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  locationId: string,
};

export type ModelProfileLocationConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  and?: Array< ModelProfileLocationConditionInput | null > | null,
  or?: Array< ModelProfileLocationConditionInput | null > | null,
  not?: ModelProfileLocationConditionInput | null,
};

export type UpdateProfileLocationInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  profileId?: string | null,
  locationId?: string | null,
};

export type DeleteProfileLocationInput = {
  id: string,
};

export type CreateProfileServiceInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  serviceId: string,
  title?: string | null,
  industries?: Array< string | null > | null,
};

export type ModelProfileServiceConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  serviceId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelProfileServiceConditionInput | null > | null,
  or?: Array< ModelProfileServiceConditionInput | null > | null,
  not?: ModelProfileServiceConditionInput | null,
};

export type UpdateProfileServiceInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  profileId?: string | null,
  serviceId?: string | null,
  title?: string | null,
  industries?: Array< string | null > | null,
};

export type DeleteProfileServiceInput = {
  id: string,
};

export type CreateExperienceInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  jobTitle?: string | null,
  agencyType?: AgencyType | null,
  agencyName?: string | null,
  employerName?: string | null,
  employerLocation?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  projectNo?: string | null,
  projectName?: string | null,
  projectLocation?: string | null,
  projectDollarAmount?: number | null,
  jobDescription?: string | null,
};

export type ModelExperienceConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  jobTitle?: ModelStringInput | null,
  agencyType?: ModelAgencyTypeInput | null,
  agencyName?: ModelStringInput | null,
  employerName?: ModelStringInput | null,
  employerLocation?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  projectNo?: ModelStringInput | null,
  projectName?: ModelStringInput | null,
  projectLocation?: ModelStringInput | null,
  projectDollarAmount?: ModelIntInput | null,
  jobDescription?: ModelStringInput | null,
  and?: Array< ModelExperienceConditionInput | null > | null,
  or?: Array< ModelExperienceConditionInput | null > | null,
  not?: ModelExperienceConditionInput | null,
};

export type ModelAgencyTypeInput = {
  eq?: AgencyType | null,
  ne?: AgencyType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateExperienceInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  profileId?: string | null,
  jobTitle?: string | null,
  agencyType?: AgencyType | null,
  agencyName?: string | null,
  employerName?: string | null,
  employerLocation?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  projectNo?: string | null,
  projectName?: string | null,
  projectLocation?: string | null,
  projectDollarAmount?: number | null,
  jobDescription?: string | null,
};

export type DeleteExperienceInput = {
  id: string,
};

export type CreateCertificationInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  certificationType: string,
  issuedAt?: string | null,
  expiresAt?: string | null,
  certificateKey?: string | null,
};

export type ModelCertificationConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  certificationType?: ModelStringInput | null,
  issuedAt?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  certificateKey?: ModelStringInput | null,
  and?: Array< ModelCertificationConditionInput | null > | null,
  or?: Array< ModelCertificationConditionInput | null > | null,
  not?: ModelCertificationConditionInput | null,
};

export type UpdateCertificationInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  profileId?: string | null,
  certificationType?: string | null,
  issuedAt?: string | null,
  expiresAt?: string | null,
  certificateKey?: string | null,
};

export type DeleteCertificationInput = {
  id: string,
};

export type CreateCertificationTypeInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  name: string,
  active: boolean,
  industries?: Array< string | null > | null,
};

export type ModelCertificationTypeConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelCertificationTypeConditionInput | null > | null,
  or?: Array< ModelCertificationTypeConditionInput | null > | null,
  not?: ModelCertificationTypeConditionInput | null,
};

export type CertificationType = {
  __typename: "CertificationType",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  name: string,
  active: boolean,
  industries?: Array< string | null > | null,
};

export type UpdateCertificationTypeInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  name?: string | null,
  active?: boolean | null,
  industries?: Array< string | null > | null,
};

export type DeleteCertificationTypeInput = {
  id: string,
};

export type CreateAgreementInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  accountId: string,
  agreementTypes?: Array< AgreementType | null > | null,
  agreementKey?: string | null,
};

export type ModelAgreementConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  agreementTypes?: ModelAgreementTypeListInput | null,
  agreementKey?: ModelStringInput | null,
  and?: Array< ModelAgreementConditionInput | null > | null,
  or?: Array< ModelAgreementConditionInput | null > | null,
  not?: ModelAgreementConditionInput | null,
};

export type ModelAgreementTypeListInput = {
  eq?: Array< AgreementType | null > | null,
  ne?: Array< AgreementType | null > | null,
  contains?: AgreementType | null,
  notContains?: AgreementType | null,
};

export type UpdateAgreementInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  accountId?: string | null,
  agreementTypes?: Array< AgreementType | null > | null,
  agreementKey?: string | null,
};

export type DeleteAgreementInput = {
  id: string,
};

export type CreateEducationInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  profileId: string,
  school?: string | null,
  degree?: string | null,
  field?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  description?: string | null,
};

export type ModelEducationConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  school?: ModelStringInput | null,
  degree?: ModelStringInput | null,
  field?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelEducationConditionInput | null > | null,
  or?: Array< ModelEducationConditionInput | null > | null,
  not?: ModelEducationConditionInput | null,
};

export type UpdateEducationInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  profileId?: string | null,
  school?: string | null,
  degree?: string | null,
  field?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  description?: string | null,
};

export type DeleteEducationInput = {
  id: string,
};

export type CreateServiceRequestInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  serviceRequestType: ServiceRequestType,
  name: string,
  status?: string | null,
  completedDate?: string | null,
};

export type ModelServiceRequestConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  serviceRequestType?: ModelServiceRequestTypeInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  completedDate?: ModelStringInput | null,
  and?: Array< ModelServiceRequestConditionInput | null > | null,
  or?: Array< ModelServiceRequestConditionInput | null > | null,
  not?: ModelServiceRequestConditionInput | null,
};

export type ModelServiceRequestTypeInput = {
  eq?: ServiceRequestType | null,
  ne?: ServiceRequestType | null,
};

export type UpdateServiceRequestInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  serviceRequestType?: ServiceRequestType | null,
  name?: string | null,
  status?: string | null,
  completedDate?: string | null,
};

export type DeleteServiceRequestInput = {
  id: string,
};

export type CreateContractInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  issuedBy?: string | null,
  name: string,
  number?: string | null,
  locationId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  perDiem?: number | null,
  value?: string | null,
  category?: string | null,
};

export type ModelContractConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  issuedBy?: ModelStringInput | null,
  name?: ModelStringInput | null,
  number?: ModelStringInput | null,
  locationId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  perDiem?: ModelFloatInput | null,
  value?: ModelStringInput | null,
  category?: ModelStringInput | null,
  and?: Array< ModelContractConditionInput | null > | null,
  or?: Array< ModelContractConditionInput | null > | null,
  not?: ModelContractConditionInput | null,
};

export type UpdateContractInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  issuedBy?: string | null,
  name?: string | null,
  number?: string | null,
  locationId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  perDiem?: number | null,
  value?: string | null,
  category?: string | null,
};

export type DeleteContractInput = {
  id: string,
};

export type CreateJobPostInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  contractId?: string | null,
  serviceRequestId?: string | null,
  locationId?: string | null,
  serviceId: string,
  industries?: Array< string | null > | null,
  title?: string | null,
  summary: string,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  openDate?: string | null,
  filledDate?: string | null,
  closedDate?: string | null,
  employmentType: EmploymentType,
  annualSalaryLow?: number | null,
  annualSalaryHigh?: number | null,
  statusId?: string | null,
  status?: JobPostStatus | null,
  openings?: number | null,
  invoiceId?: string | null,
  jobPostType?: JobPostType | null,
  hiringDate?: string | null,
  unlocksAvailable?: number | null,
  featured?: boolean | null,
  questions?: string | null,
  workSettings?: Array< WorkSetting | null > | null,
  securityClearance?: string | null,
};

export type ModelJobPostConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  contractId?: ModelIDInput | null,
  serviceRequestId?: ModelIDInput | null,
  locationId?: ModelStringInput | null,
  serviceId?: ModelIDInput | null,
  industries?: ModelStringInput | null,
  title?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  openDate?: ModelStringInput | null,
  filledDate?: ModelStringInput | null,
  closedDate?: ModelStringInput | null,
  employmentType?: ModelEmploymentTypeInput | null,
  annualSalaryLow?: ModelFloatInput | null,
  annualSalaryHigh?: ModelFloatInput | null,
  statusId?: ModelIDInput | null,
  status?: ModelJobPostStatusInput | null,
  openings?: ModelIntInput | null,
  invoiceId?: ModelStringInput | null,
  jobPostType?: ModelJobPostTypeInput | null,
  hiringDate?: ModelStringInput | null,
  unlocksAvailable?: ModelIntInput | null,
  featured?: ModelBooleanInput | null,
  questions?: ModelStringInput | null,
  workSettings?: ModelWorkSettingListInput | null,
  securityClearance?: ModelStringInput | null,
  and?: Array< ModelJobPostConditionInput | null > | null,
  or?: Array< ModelJobPostConditionInput | null > | null,
  not?: ModelJobPostConditionInput | null,
};

export type ModelEmploymentTypeInput = {
  eq?: EmploymentType | null,
  ne?: EmploymentType | null,
};

export type ModelJobPostStatusInput = {
  eq?: JobPostStatus | null,
  ne?: JobPostStatus | null,
};

export type ModelJobPostTypeInput = {
  eq?: JobPostType | null,
  ne?: JobPostType | null,
};

export type UpdateJobPostInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  contractId?: string | null,
  serviceRequestId?: string | null,
  locationId?: string | null,
  serviceId?: string | null,
  industries?: Array< string | null > | null,
  title?: string | null,
  summary?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  openDate?: string | null,
  filledDate?: string | null,
  closedDate?: string | null,
  employmentType?: EmploymentType | null,
  annualSalaryLow?: number | null,
  annualSalaryHigh?: number | null,
  statusId?: string | null,
  status?: JobPostStatus | null,
  openings?: number | null,
  invoiceId?: string | null,
  jobPostType?: JobPostType | null,
  hiringDate?: string | null,
  unlocksAvailable?: number | null,
  featured?: boolean | null,
  questions?: string | null,
  workSettings?: Array< WorkSetting | null > | null,
  securityClearance?: string | null,
};

export type DeleteJobPostInput = {
  id: string,
};

export type CreateJobInterestInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  jobPostId: string,
  ranking?: number | null,
  status?: JobInterestStatus | null,
  notes?: string | null,
  message?: string | null,
  unlocked?: boolean | null,
  application?: string | null,
  origin?: RecordUpdateOrigin | null,
};

export enum JobInterestStatus {
  Interested = "Interested",
  Invited = "Invited",
  Applied = "Applied",
  Accepted = "Accepted",
  Reviewing = "Reviewing",
  Rejected = "Rejected",
  Unlocked = "Unlocked",
  Contacted = "Contacted",
  Hired = "Hired",
  Declined = "Declined",
}


export type ModelJobInterestConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  jobPostId?: ModelIDInput | null,
  ranking?: ModelFloatInput | null,
  status?: ModelJobInterestStatusInput | null,
  notes?: ModelStringInput | null,
  message?: ModelStringInput | null,
  unlocked?: ModelBooleanInput | null,
  application?: ModelStringInput | null,
  origin?: ModelRecordUpdateOriginInput | null,
  and?: Array< ModelJobInterestConditionInput | null > | null,
  or?: Array< ModelJobInterestConditionInput | null > | null,
  not?: ModelJobInterestConditionInput | null,
};

export type ModelJobInterestStatusInput = {
  eq?: JobInterestStatus | null,
  ne?: JobInterestStatus | null,
};

export type ModelRecordUpdateOriginInput = {
  eq?: RecordUpdateOrigin | null,
  ne?: RecordUpdateOrigin | null,
};

export type JobInterest = {
  __typename: "JobInterest",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  jobPostId: string,
  ranking?: number | null,
  status?: JobInterestStatus | null,
  notes?: string | null,
  message?: string | null,
  unlocked?: boolean | null,
  application?: string | null,
  origin?: RecordUpdateOrigin | null,
};

export type UpdateJobInterestInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  jobPostId?: string | null,
  ranking?: number | null,
  status?: JobInterestStatus | null,
  notes?: string | null,
  message?: string | null,
  unlocked?: boolean | null,
  application?: string | null,
  origin?: RecordUpdateOrigin | null,
};

export type DeleteJobInterestInput = {
  id: string,
};

export type CreateJobCandidateInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  jobPostId: string,
  accountId: string,
  profileId: string,
  status?: JobCandidateStatus | null,
  ranking?: number | null,
  notes?: string | null,
  message?: string | null,
  unlocked?: boolean | null,
  application?: string | null,
  origin?: RecordUpdateOrigin | null,
};

export type ModelJobCandidateConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  jobPostId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  status?: ModelJobCandidateStatusInput | null,
  ranking?: ModelFloatInput | null,
  notes?: ModelStringInput | null,
  message?: ModelStringInput | null,
  unlocked?: ModelBooleanInput | null,
  application?: ModelStringInput | null,
  origin?: ModelRecordUpdateOriginInput | null,
  and?: Array< ModelJobCandidateConditionInput | null > | null,
  or?: Array< ModelJobCandidateConditionInput | null > | null,
  not?: ModelJobCandidateConditionInput | null,
};

export type ModelJobCandidateStatusInput = {
  eq?: JobCandidateStatus | null,
  ne?: JobCandidateStatus | null,
};

export type UpdateJobCandidateInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  jobPostId?: string | null,
  accountId?: string | null,
  profileId?: string | null,
  status?: JobCandidateStatus | null,
  ranking?: number | null,
  notes?: string | null,
  message?: string | null,
  unlocked?: boolean | null,
  application?: string | null,
  origin?: RecordUpdateOrigin | null,
};

export type DeleteJobCandidateInput = {
  id: string,
};

export type CreateUserActivityInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  subjectType: SubjectType,
  subjectId: string,
  activityType: string,
  actorId: string,
  actorName?: string | null,
  accountId: string,
  details?: string | null,
};

export enum SubjectType {
  Contract = "Contract",
  JobCandidate = "JobCandidate",
  JobInterest = "JobInterest",
  JobPost = "JobPost",
  Profile = "Profile",
  Invoice = "Invoice",
  User = "User",
  Account = "Account",
}


export type ModelUserActivityConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  subjectType?: ModelSubjectTypeInput | null,
  subjectId?: ModelIDInput | null,
  activityType?: ModelStringInput | null,
  actorId?: ModelIDInput | null,
  actorName?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelUserActivityConditionInput | null > | null,
  or?: Array< ModelUserActivityConditionInput | null > | null,
  not?: ModelUserActivityConditionInput | null,
};

export type ModelSubjectTypeInput = {
  eq?: SubjectType | null,
  ne?: SubjectType | null,
};

export type UserActivity = {
  __typename: "UserActivity",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  subjectType: SubjectType,
  subjectId: string,
  activityType: string,
  actorId: string,
  actorName?: string | null,
  accountId: string,
  details?: string | null,
};

export type UpdateUserActivityInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  subjectType?: SubjectType | null,
  subjectId?: string | null,
  activityType?: string | null,
  actorId?: string | null,
  actorName?: string | null,
  accountId?: string | null,
  details?: string | null,
};

export type DeleteUserActivityInput = {
  id: string,
};

export type CreateCourseInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  title: string,
  description?: string | null,
  coverUrl?: string | null,
  details?: string | null,
  publishDate?: string | null,
  sunsetDate?: string | null,
  lessonCount?: number | null,
  creditHours?: number | null,
  isFree?: boolean | null,
  lessons?: string | null,
  handoutUrl?: string | null,
};

export type ModelCourseConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverUrl?: ModelStringInput | null,
  details?: ModelStringInput | null,
  publishDate?: ModelStringInput | null,
  sunsetDate?: ModelStringInput | null,
  lessonCount?: ModelIntInput | null,
  creditHours?: ModelFloatInput | null,
  isFree?: ModelBooleanInput | null,
  lessons?: ModelStringInput | null,
  handoutUrl?: ModelStringInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
};

export type UpdateCourseInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  title?: string | null,
  description?: string | null,
  coverUrl?: string | null,
  details?: string | null,
  publishDate?: string | null,
  sunsetDate?: string | null,
  lessonCount?: number | null,
  creditHours?: number | null,
  isFree?: boolean | null,
  lessons?: string | null,
  handoutUrl?: string | null,
};

export type DeleteCourseInput = {
  id: string,
};

export type CreateLessonInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  courseId: string,
  number: number,
  title: string,
  description?: string | null,
  videoUrl?: string | null,
  videoDuration?: number | null,
};

export type ModelLessonConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  number?: ModelIntInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  videoUrl?: ModelStringInput | null,
  videoDuration?: ModelIntInput | null,
  and?: Array< ModelLessonConditionInput | null > | null,
  or?: Array< ModelLessonConditionInput | null > | null,
  not?: ModelLessonConditionInput | null,
};

export type Lesson = {
  __typename: "Lesson",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  courseId: string,
  number: number,
  title: string,
  description?: string | null,
  videoUrl?: string | null,
  videoDuration?: number | null,
  questions?: ModelQuestionConnection | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  lessonId: string,
  lesson?: Lesson | null,
  number?: number | null,
  question: string,
  answers?: Array< string | null > | null,
  correctAnswer?: number | null,
  lessonQuestionsId?: string | null,
};

export type UpdateLessonInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  courseId?: string | null,
  number?: number | null,
  title?: string | null,
  description?: string | null,
  videoUrl?: string | null,
  videoDuration?: number | null,
};

export type DeleteLessonInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  lessonId: string,
  number?: number | null,
  question: string,
  answers?: Array< string | null > | null,
  correctAnswer?: number | null,
  lessonQuestionsId?: string | null,
};

export type ModelQuestionConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  lessonId?: ModelIDInput | null,
  number?: ModelIntInput | null,
  question?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  correctAnswer?: ModelIntInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
  lessonQuestionsId?: ModelIDInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  lessonId?: string | null,
  number?: number | null,
  question?: string | null,
  answers?: Array< string | null > | null,
  correctAnswer?: number | null,
  lessonQuestionsId?: string | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateClassInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  courseId: string,
  accountId?: string | null,
  ownerId?: string | null,
  userId?: string | null,
  termBegin?: string | null,
  termEnd?: string | null,
  seatsPurchased?: number | null,
  seatsFilled?: number | null,
  couponCode?: string | null,
  assignmentSchedule?: AssignmentSchedule | null,
};

export type ModelClassConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  termBegin?: ModelStringInput | null,
  termEnd?: ModelStringInput | null,
  seatsPurchased?: ModelIntInput | null,
  seatsFilled?: ModelIntInput | null,
  couponCode?: ModelStringInput | null,
  assignmentSchedule?: ModelAssignmentScheduleInput | null,
  and?: Array< ModelClassConditionInput | null > | null,
  or?: Array< ModelClassConditionInput | null > | null,
  not?: ModelClassConditionInput | null,
};

export type ModelAssignmentScheduleInput = {
  eq?: AssignmentSchedule | null,
  ne?: AssignmentSchedule | null,
};

export type UpdateClassInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  courseId?: string | null,
  accountId?: string | null,
  ownerId?: string | null,
  userId?: string | null,
  termBegin?: string | null,
  termEnd?: string | null,
  seatsPurchased?: number | null,
  seatsFilled?: number | null,
  couponCode?: string | null,
  assignmentSchedule?: AssignmentSchedule | null,
};

export type DeleteClassInput = {
  id: string,
};

export type CreateRegistrationInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  classId: string,
  userId: string,
  classStatus: ClassStatus,
  classProgress: number,
  lessonNumber: number,
  lessonId?: string | null,
  lessonStatus?: LessonStatus | null,
  videoProgress?: number | null,
  answers?: Array< number | null > | null,
  score: number,
  startedAt?: string | null,
  endsAt?: string | null,
  completedAt?: string | null,
  lessonsAssigned?: number | null,
  nextAssignmentAt?: string | null,
};

export type ModelRegistrationConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  classId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  classStatus?: ModelClassStatusInput | null,
  classProgress?: ModelFloatInput | null,
  lessonNumber?: ModelIntInput | null,
  lessonId?: ModelIDInput | null,
  lessonStatus?: ModelLessonStatusInput | null,
  videoProgress?: ModelFloatInput | null,
  answers?: ModelIntInput | null,
  score?: ModelFloatInput | null,
  startedAt?: ModelStringInput | null,
  endsAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  lessonsAssigned?: ModelIntInput | null,
  nextAssignmentAt?: ModelStringInput | null,
  and?: Array< ModelRegistrationConditionInput | null > | null,
  or?: Array< ModelRegistrationConditionInput | null > | null,
  not?: ModelRegistrationConditionInput | null,
};

export type ModelClassStatusInput = {
  eq?: ClassStatus | null,
  ne?: ClassStatus | null,
};

export type ModelLessonStatusInput = {
  eq?: LessonStatus | null,
  ne?: LessonStatus | null,
};

export type UpdateRegistrationInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  classId?: string | null,
  userId?: string | null,
  classStatus?: ClassStatus | null,
  classProgress?: number | null,
  lessonNumber?: number | null,
  lessonId?: string | null,
  lessonStatus?: LessonStatus | null,
  videoProgress?: number | null,
  answers?: Array< number | null > | null,
  score?: number | null,
  startedAt?: string | null,
  endsAt?: string | null,
  completedAt?: string | null,
  lessonsAssigned?: number | null,
  nextAssignmentAt?: string | null,
};

export type DeleteRegistrationInput = {
  id: string,
};

export type CreateActivityInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  userId: string,
  activityDate: string,
  activityType: ActivityType,
  objectId: string,
  values?: string | null,
};

export type ModelActivityConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  activityDate?: ModelStringInput | null,
  activityType?: ModelActivityTypeInput | null,
  objectId?: ModelIDInput | null,
  values?: ModelStringInput | null,
  and?: Array< ModelActivityConditionInput | null > | null,
  or?: Array< ModelActivityConditionInput | null > | null,
  not?: ModelActivityConditionInput | null,
};

export type ModelActivityTypeInput = {
  eq?: ActivityType | null,
  ne?: ActivityType | null,
};

export type UpdateActivityInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  userId?: string | null,
  activityDate?: string | null,
  activityType?: ActivityType | null,
  objectId?: string | null,
  values?: string | null,
};

export type DeleteActivityInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  userStatus?: ModelUserStatusInput | null,
  accountId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  industry?: ModelStringInput | null,
  lastActiveAt?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  invitedAt?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAgreementFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  agreementTypes?: ModelAgreementTypeListInput | null,
  agreementKey?: ModelStringInput | null,
  and?: Array< ModelAgreementFilterInput | null > | null,
  or?: Array< ModelAgreementFilterInput | null > | null,
  not?: ModelAgreementFilterInput | null,
};

export type ModelJobInterestFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  jobPostId?: ModelIDInput | null,
  ranking?: ModelFloatInput | null,
  status?: ModelJobInterestStatusInput | null,
  notes?: ModelStringInput | null,
  message?: ModelStringInput | null,
  unlocked?: ModelBooleanInput | null,
  application?: ModelStringInput | null,
  origin?: ModelRecordUpdateOriginInput | null,
  and?: Array< ModelJobInterestFilterInput | null > | null,
  or?: Array< ModelJobInterestFilterInput | null > | null,
  not?: ModelJobInterestFilterInput | null,
};

export type ModelJobInterestConnection = {
  __typename: "ModelJobInterestConnection",
  items:  Array<JobInterest | null >,
  nextToken?: string | null,
};

export type ModelJobCandidateFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  jobPostId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  status?: ModelJobCandidateStatusInput | null,
  ranking?: ModelFloatInput | null,
  notes?: ModelStringInput | null,
  message?: ModelStringInput | null,
  unlocked?: ModelBooleanInput | null,
  application?: ModelStringInput | null,
  origin?: ModelRecordUpdateOriginInput | null,
  and?: Array< ModelJobCandidateFilterInput | null > | null,
  or?: Array< ModelJobCandidateFilterInput | null > | null,
  not?: ModelJobCandidateFilterInput | null,
};

export type ModelUserActivityFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  subjectType?: ModelSubjectTypeInput | null,
  subjectId?: ModelIDInput | null,
  activityType?: ModelStringInput | null,
  actorId?: ModelIDInput | null,
  actorName?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelUserActivityFilterInput | null > | null,
  or?: Array< ModelUserActivityFilterInput | null > | null,
  not?: ModelUserActivityFilterInput | null,
};

export type ModelUserActivityConnection = {
  __typename: "ModelUserActivityConnection",
  items:  Array<UserActivity | null >,
  nextToken?: string | null,
};

export type ModelLessonFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  number?: ModelIntInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  videoUrl?: ModelStringInput | null,
  videoDuration?: ModelIntInput | null,
  and?: Array< ModelLessonFilterInput | null > | null,
  or?: Array< ModelLessonFilterInput | null > | null,
  not?: ModelLessonFilterInput | null,
};

export type ModelLessonConnection = {
  __typename: "ModelLessonConnection",
  items:  Array<Lesson | null >,
  nextToken?: string | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  lessonId?: ModelIDInput | null,
  number?: ModelIntInput | null,
  question?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  correctAnswer?: ModelIntInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
  lessonQuestionsId?: ModelIDInput | null,
};

export type ModelClassFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  termBegin?: ModelStringInput | null,
  termEnd?: ModelStringInput | null,
  seatsPurchased?: ModelIntInput | null,
  seatsFilled?: ModelIntInput | null,
  couponCode?: ModelStringInput | null,
  assignmentSchedule?: ModelAssignmentScheduleInput | null,
  and?: Array< ModelClassFilterInput | null > | null,
  or?: Array< ModelClassFilterInput | null > | null,
  not?: ModelClassFilterInput | null,
};

export type ModelRegistrationFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  classId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  classStatus?: ModelClassStatusInput | null,
  classProgress?: ModelFloatInput | null,
  lessonNumber?: ModelIntInput | null,
  lessonId?: ModelIDInput | null,
  lessonStatus?: ModelLessonStatusInput | null,
  videoProgress?: ModelFloatInput | null,
  answers?: ModelIntInput | null,
  score?: ModelFloatInput | null,
  startedAt?: ModelStringInput | null,
  endsAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  lessonsAssigned?: ModelIntInput | null,
  nextAssignmentAt?: ModelStringInput | null,
  and?: Array< ModelRegistrationFilterInput | null > | null,
  or?: Array< ModelRegistrationFilterInput | null > | null,
  not?: ModelRegistrationFilterInput | null,
};

export type ModelActivityFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  activityDate?: ModelStringInput | null,
  activityType?: ModelActivityTypeInput | null,
  objectId?: ModelIDInput | null,
  values?: ModelStringInput | null,
  and?: Array< ModelActivityFilterInput | null > | null,
  or?: Array< ModelActivityFilterInput | null > | null,
  not?: ModelActivityFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  dunsNumber?: ModelStringInput | null,
  cageCode?: ModelStringInput | null,
  naicsCode?: ModelStringInput | null,
  sicCode?: ModelStringInput | null,
  socioEconomicDesignation?: ModelStringInput | null,
  fein?: ModelStringInput | null,
  accountStatus?: ModelAccountStatusInput | null,
  phone?: ModelStringInput | null,
  webUrl?: ModelStringInput | null,
  accountType?: ModelAccountTypeInput | null,
  accountSize?: ModelAccountSizeInput | null,
  customerId?: ModelStringInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelServiceGroupFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  industries?: ModelStringInput | null,
  default?: ModelBooleanInput | null,
  and?: Array< ModelServiceGroupFilterInput | null > | null,
  or?: Array< ModelServiceGroupFilterInput | null > | null,
  not?: ModelServiceGroupFilterInput | null,
};

export type ModelServiceGroupConnection = {
  __typename: "ModelServiceGroupConnection",
  items:  Array<ServiceGroup | null >,
  nextToken?: string | null,
};

export type ModelServiceFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  aliases?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  serviceGroupId?: ModelIDInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelServiceFilterInput | null > | null,
  or?: Array< ModelServiceFilterInput | null > | null,
  not?: ModelServiceFilterInput | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationName?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  aliases?: ModelStringInput | null,
  addressType?: ModelAddressTypeInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  country?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
};

export type ModelProfileFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  userId?: ModelIDInput | null,
  nickname?: ModelStringInput | null,
  availability?: ModelStringInput | null,
  availableDate?: ModelStringInput | null,
  desiredRate?: ModelStringInput | null,
  about?: ModelStringInput | null,
  resumeKey?: ModelStringInput | null,
  profileStatus?: ModelProfileStatusInput | null,
  statusMessage?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  federalExperience?: ModelFloatInput | null,
  totalExperience?: ModelFloatInput | null,
  industries?: ModelStringInput | null,
  securityClearance?: ModelStringInput | null,
  securityStatus?: ModelSecurityStatusInput | null,
  workSettings?: ModelWorkSettingListInput | null,
  credentials?: ModelStringInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
};

export type ModelProfileLocationFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  and?: Array< ModelProfileLocationFilterInput | null > | null,
  or?: Array< ModelProfileLocationFilterInput | null > | null,
  not?: ModelProfileLocationFilterInput | null,
};

export type ModelProfileServiceFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  serviceId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelProfileServiceFilterInput | null > | null,
  or?: Array< ModelProfileServiceFilterInput | null > | null,
  not?: ModelProfileServiceFilterInput | null,
};

export type ModelExperienceFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  jobTitle?: ModelStringInput | null,
  agencyType?: ModelAgencyTypeInput | null,
  agencyName?: ModelStringInput | null,
  employerName?: ModelStringInput | null,
  employerLocation?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  projectNo?: ModelStringInput | null,
  projectName?: ModelStringInput | null,
  projectLocation?: ModelStringInput | null,
  projectDollarAmount?: ModelIntInput | null,
  jobDescription?: ModelStringInput | null,
  and?: Array< ModelExperienceFilterInput | null > | null,
  or?: Array< ModelExperienceFilterInput | null > | null,
  not?: ModelExperienceFilterInput | null,
};

export type ModelCertificationFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  certificationType?: ModelStringInput | null,
  issuedAt?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  certificateKey?: ModelStringInput | null,
  and?: Array< ModelCertificationFilterInput | null > | null,
  or?: Array< ModelCertificationFilterInput | null > | null,
  not?: ModelCertificationFilterInput | null,
};

export type ModelCertificationTypeFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  industries?: ModelStringInput | null,
  and?: Array< ModelCertificationTypeFilterInput | null > | null,
  or?: Array< ModelCertificationTypeFilterInput | null > | null,
  not?: ModelCertificationTypeFilterInput | null,
};

export type ModelCertificationTypeConnection = {
  __typename: "ModelCertificationTypeConnection",
  items:  Array<CertificationType | null >,
  nextToken?: string | null,
};

export type ModelEducationFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  school?: ModelStringInput | null,
  degree?: ModelStringInput | null,
  field?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelEducationFilterInput | null > | null,
  or?: Array< ModelEducationFilterInput | null > | null,
  not?: ModelEducationFilterInput | null,
};

export type ModelServiceRequestFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  serviceRequestType?: ModelServiceRequestTypeInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  completedDate?: ModelStringInput | null,
  and?: Array< ModelServiceRequestFilterInput | null > | null,
  or?: Array< ModelServiceRequestFilterInput | null > | null,
  not?: ModelServiceRequestFilterInput | null,
};

export type ModelServiceRequestConnection = {
  __typename: "ModelServiceRequestConnection",
  items:  Array<ServiceRequest | null >,
  nextToken?: string | null,
};

export type ModelContractFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  issuedBy?: ModelStringInput | null,
  name?: ModelStringInput | null,
  number?: ModelStringInput | null,
  locationId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  perDiem?: ModelFloatInput | null,
  value?: ModelStringInput | null,
  category?: ModelStringInput | null,
  and?: Array< ModelContractFilterInput | null > | null,
  or?: Array< ModelContractFilterInput | null > | null,
  not?: ModelContractFilterInput | null,
};

export type ModelJobPostFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  contractId?: ModelIDInput | null,
  serviceRequestId?: ModelIDInput | null,
  locationId?: ModelStringInput | null,
  serviceId?: ModelIDInput | null,
  industries?: ModelStringInput | null,
  title?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  openDate?: ModelStringInput | null,
  filledDate?: ModelStringInput | null,
  closedDate?: ModelStringInput | null,
  employmentType?: ModelEmploymentTypeInput | null,
  annualSalaryLow?: ModelFloatInput | null,
  annualSalaryHigh?: ModelFloatInput | null,
  statusId?: ModelIDInput | null,
  status?: ModelJobPostStatusInput | null,
  openings?: ModelIntInput | null,
  invoiceId?: ModelStringInput | null,
  jobPostType?: ModelJobPostTypeInput | null,
  hiringDate?: ModelStringInput | null,
  unlocksAvailable?: ModelIntInput | null,
  featured?: ModelBooleanInput | null,
  questions?: ModelStringInput | null,
  workSettings?: ModelWorkSettingListInput | null,
  securityClearance?: ModelStringInput | null,
  and?: Array< ModelJobPostFilterInput | null > | null,
  or?: Array< ModelJobPostFilterInput | null > | null,
  not?: ModelJobPostFilterInput | null,
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  coverUrl?: ModelStringInput | null,
  details?: ModelStringInput | null,
  publishDate?: ModelStringInput | null,
  sunsetDate?: ModelStringInput | null,
  lessonCount?: ModelIntInput | null,
  creditHours?: ModelFloatInput | null,
  isFree?: ModelBooleanInput | null,
  lessons?: ModelStringInput | null,
  handoutUrl?: ModelStringInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items:  Array<Course | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionUserFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  userStatus?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  industry?: ModelSubscriptionStringInput | null,
  lastActiveAt?: ModelSubscriptionStringInput | null,
  jobTitle?: ModelSubscriptionStringInput | null,
  invitedAt?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAgreementFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  agreementTypes?: ModelSubscriptionStringInput | null,
  agreementKey?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAgreementFilterInput | null > | null,
  or?: Array< ModelSubscriptionAgreementFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionJobInterestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  jobPostId?: ModelSubscriptionIDInput | null,
  ranking?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  unlocked?: ModelSubscriptionBooleanInput | null,
  application?: ModelSubscriptionStringInput | null,
  origin?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobInterestFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobInterestFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionJobCandidateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  jobPostId?: ModelSubscriptionIDInput | null,
  profileId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  ranking?: ModelSubscriptionFloatInput | null,
  notes?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  unlocked?: ModelSubscriptionBooleanInput | null,
  application?: ModelSubscriptionStringInput | null,
  origin?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobCandidateFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobCandidateFilterInput | null > | null,
};

export type ModelSubscriptionUserActivityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  subjectType?: ModelSubscriptionStringInput | null,
  subjectId?: ModelSubscriptionIDInput | null,
  activityType?: ModelSubscriptionStringInput | null,
  actorId?: ModelSubscriptionIDInput | null,
  actorName?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserActivityFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserActivityFilterInput | null > | null,
};

export type ModelSubscriptionLessonFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  courseId?: ModelSubscriptionIDInput | null,
  number?: ModelSubscriptionIntInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  videoUrl?: ModelSubscriptionStringInput | null,
  videoDuration?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionLessonFilterInput | null > | null,
  or?: Array< ModelSubscriptionLessonFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  lessonId?: ModelSubscriptionIDInput | null,
  number?: ModelSubscriptionIntInput | null,
  question?: ModelSubscriptionStringInput | null,
  answers?: ModelSubscriptionStringInput | null,
  correctAnswer?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
};

export type ModelSubscriptionClassFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  courseId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  termBegin?: ModelSubscriptionStringInput | null,
  termEnd?: ModelSubscriptionStringInput | null,
  seatsPurchased?: ModelSubscriptionIntInput | null,
  seatsFilled?: ModelSubscriptionIntInput | null,
  couponCode?: ModelSubscriptionStringInput | null,
  assignmentSchedule?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClassFilterInput | null > | null,
  or?: Array< ModelSubscriptionClassFilterInput | null > | null,
};

export type ModelSubscriptionRegistrationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  classId?: ModelSubscriptionIDInput | null,
  classStatus?: ModelSubscriptionStringInput | null,
  classProgress?: ModelSubscriptionFloatInput | null,
  lessonNumber?: ModelSubscriptionIntInput | null,
  lessonId?: ModelSubscriptionIDInput | null,
  lessonStatus?: ModelSubscriptionStringInput | null,
  videoProgress?: ModelSubscriptionFloatInput | null,
  answers?: ModelSubscriptionIntInput | null,
  score?: ModelSubscriptionFloatInput | null,
  startedAt?: ModelSubscriptionStringInput | null,
  endsAt?: ModelSubscriptionStringInput | null,
  completedAt?: ModelSubscriptionStringInput | null,
  lessonsAssigned?: ModelSubscriptionIntInput | null,
  nextAssignmentAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRegistrationFilterInput | null > | null,
  or?: Array< ModelSubscriptionRegistrationFilterInput | null > | null,
};

export type ModelSubscriptionActivityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  activityDate?: ModelSubscriptionStringInput | null,
  activityType?: ModelSubscriptionStringInput | null,
  objectId?: ModelSubscriptionIDInput | null,
  values?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionActivityFilterInput | null > | null,
  or?: Array< ModelSubscriptionActivityFilterInput | null > | null,
};

export type ModelSubscriptionAccountFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  dunsNumber?: ModelSubscriptionStringInput | null,
  cageCode?: ModelSubscriptionStringInput | null,
  naicsCode?: ModelSubscriptionStringInput | null,
  sicCode?: ModelSubscriptionStringInput | null,
  socioEconomicDesignation?: ModelSubscriptionStringInput | null,
  fein?: ModelSubscriptionStringInput | null,
  accountStatus?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  webUrl?: ModelSubscriptionStringInput | null,
  accountType?: ModelSubscriptionStringInput | null,
  accountSize?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  industries?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
};

export type ModelSubscriptionServiceGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  industries?: ModelSubscriptionStringInput | null,
  default?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionServiceGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionServiceGroupFilterInput | null > | null,
};

export type ModelSubscriptionServiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  aliases?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  serviceGroupId?: ModelSubscriptionIDInput | null,
  industries?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionServiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionServiceFilterInput | null > | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  organizationName?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  aliases?: ModelSubscriptionStringInput | null,
  addressType?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  latitude?: ModelSubscriptionFloatInput | null,
  longitude?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
};

export type ModelSubscriptionProfileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  alias?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  availability?: ModelSubscriptionStringInput | null,
  availableDate?: ModelSubscriptionStringInput | null,
  desiredRate?: ModelSubscriptionStringInput | null,
  about?: ModelSubscriptionStringInput | null,
  resumeKey?: ModelSubscriptionStringInput | null,
  profileStatus?: ModelSubscriptionStringInput | null,
  statusMessage?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  federalExperience?: ModelSubscriptionFloatInput | null,
  totalExperience?: ModelSubscriptionFloatInput | null,
  industries?: ModelSubscriptionStringInput | null,
  securityClearance?: ModelSubscriptionStringInput | null,
  securityStatus?: ModelSubscriptionStringInput | null,
  workSettings?: ModelSubscriptionStringInput | null,
  credentials?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProfileFilterInput | null > | null,
  or?: Array< ModelSubscriptionProfileFilterInput | null > | null,
};

export type ModelSubscriptionProfileLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  profileId?: ModelSubscriptionIDInput | null,
  locationId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionProfileLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionProfileLocationFilterInput | null > | null,
};

export type ModelSubscriptionProfileServiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  profileId?: ModelSubscriptionIDInput | null,
  serviceId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  industries?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProfileServiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionProfileServiceFilterInput | null > | null,
};

export type ModelSubscriptionExperienceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  profileId?: ModelSubscriptionIDInput | null,
  jobTitle?: ModelSubscriptionStringInput | null,
  agencyType?: ModelSubscriptionStringInput | null,
  agencyName?: ModelSubscriptionStringInput | null,
  employerName?: ModelSubscriptionStringInput | null,
  employerLocation?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  projectNo?: ModelSubscriptionStringInput | null,
  projectName?: ModelSubscriptionStringInput | null,
  projectLocation?: ModelSubscriptionStringInput | null,
  projectDollarAmount?: ModelSubscriptionIntInput | null,
  jobDescription?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExperienceFilterInput | null > | null,
  or?: Array< ModelSubscriptionExperienceFilterInput | null > | null,
};

export type ModelSubscriptionCertificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  profileId?: ModelSubscriptionIDInput | null,
  certificationType?: ModelSubscriptionStringInput | null,
  issuedAt?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  certificateKey?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCertificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionCertificationFilterInput | null > | null,
};

export type ModelSubscriptionCertificationTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  industries?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCertificationTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionCertificationTypeFilterInput | null > | null,
};

export type ModelSubscriptionEducationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  profileId?: ModelSubscriptionIDInput | null,
  school?: ModelSubscriptionStringInput | null,
  degree?: ModelSubscriptionStringInput | null,
  field?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEducationFilterInput | null > | null,
  or?: Array< ModelSubscriptionEducationFilterInput | null > | null,
};

export type ModelSubscriptionServiceRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  serviceRequestType?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  completedDate?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionServiceRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionServiceRequestFilterInput | null > | null,
};

export type ModelSubscriptionContractFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  issuedBy?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  locationId?: ModelSubscriptionIDInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  perDiem?: ModelSubscriptionFloatInput | null,
  value?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContractFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractFilterInput | null > | null,
};

export type ModelSubscriptionJobPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  contractId?: ModelSubscriptionIDInput | null,
  serviceRequestId?: ModelSubscriptionIDInput | null,
  locationId?: ModelSubscriptionStringInput | null,
  serviceId?: ModelSubscriptionIDInput | null,
  industries?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  summary?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  openDate?: ModelSubscriptionStringInput | null,
  filledDate?: ModelSubscriptionStringInput | null,
  closedDate?: ModelSubscriptionStringInput | null,
  employmentType?: ModelSubscriptionStringInput | null,
  annualSalaryLow?: ModelSubscriptionFloatInput | null,
  annualSalaryHigh?: ModelSubscriptionFloatInput | null,
  statusId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  openings?: ModelSubscriptionIntInput | null,
  invoiceId?: ModelSubscriptionStringInput | null,
  jobPostType?: ModelSubscriptionStringInput | null,
  hiringDate?: ModelSubscriptionStringInput | null,
  unlocksAvailable?: ModelSubscriptionIntInput | null,
  featured?: ModelSubscriptionBooleanInput | null,
  questions?: ModelSubscriptionStringInput | null,
  workSettings?: ModelSubscriptionStringInput | null,
  securityClearance?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobPostFilterInput | null > | null,
};

export type ModelSubscriptionCourseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  coverUrl?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  publishDate?: ModelSubscriptionStringInput | null,
  sunsetDate?: ModelSubscriptionStringInput | null,
  lessonCount?: ModelSubscriptionIntInput | null,
  creditHours?: ModelSubscriptionFloatInput | null,
  isFree?: ModelSubscriptionBooleanInput | null,
  lessons?: ModelSubscriptionStringInput | null,
  handoutUrl?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCourseFilterInput | null > | null,
  or?: Array< ModelSubscriptionCourseFilterInput | null > | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type CreateServiceGroupMutationVariables = {
  input: CreateServiceGroupInput,
  condition?: ModelServiceGroupConditionInput | null,
};

export type CreateServiceGroupMutation = {
  createServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type UpdateServiceGroupMutationVariables = {
  input: UpdateServiceGroupInput,
  condition?: ModelServiceGroupConditionInput | null,
};

export type UpdateServiceGroupMutation = {
  updateServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type DeleteServiceGroupMutationVariables = {
  input: DeleteServiceGroupInput,
  condition?: ModelServiceGroupConditionInput | null,
};

export type DeleteServiceGroupMutation = {
  deleteServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type CreateServiceMutationVariables = {
  input: CreateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type CreateServiceMutation = {
  createService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type UpdateServiceMutation = {
  updateService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type DeleteServiceMutation = {
  deleteService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type CreateProfileMutation = {
  createProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type DeleteProfileMutation = {
  deleteProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type CreateProfileLocationMutationVariables = {
  input: CreateProfileLocationInput,
  condition?: ModelProfileLocationConditionInput | null,
};

export type CreateProfileLocationMutation = {
  createProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type UpdateProfileLocationMutationVariables = {
  input: UpdateProfileLocationInput,
  condition?: ModelProfileLocationConditionInput | null,
};

export type UpdateProfileLocationMutation = {
  updateProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type DeleteProfileLocationMutationVariables = {
  input: DeleteProfileLocationInput,
  condition?: ModelProfileLocationConditionInput | null,
};

export type DeleteProfileLocationMutation = {
  deleteProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type CreateProfileServiceMutationVariables = {
  input: CreateProfileServiceInput,
  condition?: ModelProfileServiceConditionInput | null,
};

export type CreateProfileServiceMutation = {
  createProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type UpdateProfileServiceMutationVariables = {
  input: UpdateProfileServiceInput,
  condition?: ModelProfileServiceConditionInput | null,
};

export type UpdateProfileServiceMutation = {
  updateProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type DeleteProfileServiceMutationVariables = {
  input: DeleteProfileServiceInput,
  condition?: ModelProfileServiceConditionInput | null,
};

export type DeleteProfileServiceMutation = {
  deleteProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type CreateExperienceMutationVariables = {
  input: CreateExperienceInput,
  condition?: ModelExperienceConditionInput | null,
};

export type CreateExperienceMutation = {
  createExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type UpdateExperienceMutationVariables = {
  input: UpdateExperienceInput,
  condition?: ModelExperienceConditionInput | null,
};

export type UpdateExperienceMutation = {
  updateExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type DeleteExperienceMutationVariables = {
  input: DeleteExperienceInput,
  condition?: ModelExperienceConditionInput | null,
};

export type DeleteExperienceMutation = {
  deleteExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type CreateCertificationMutationVariables = {
  input: CreateCertificationInput,
  condition?: ModelCertificationConditionInput | null,
};

export type CreateCertificationMutation = {
  createCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type UpdateCertificationMutationVariables = {
  input: UpdateCertificationInput,
  condition?: ModelCertificationConditionInput | null,
};

export type UpdateCertificationMutation = {
  updateCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type DeleteCertificationMutationVariables = {
  input: DeleteCertificationInput,
  condition?: ModelCertificationConditionInput | null,
};

export type DeleteCertificationMutation = {
  deleteCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type CreateCertificationTypeMutationVariables = {
  input: CreateCertificationTypeInput,
  condition?: ModelCertificationTypeConditionInput | null,
};

export type CreateCertificationTypeMutation = {
  createCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type UpdateCertificationTypeMutationVariables = {
  input: UpdateCertificationTypeInput,
  condition?: ModelCertificationTypeConditionInput | null,
};

export type UpdateCertificationTypeMutation = {
  updateCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type DeleteCertificationTypeMutationVariables = {
  input: DeleteCertificationTypeInput,
  condition?: ModelCertificationTypeConditionInput | null,
};

export type DeleteCertificationTypeMutation = {
  deleteCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type CreateAgreementMutationVariables = {
  input: CreateAgreementInput,
  condition?: ModelAgreementConditionInput | null,
};

export type CreateAgreementMutation = {
  createAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type UpdateAgreementMutationVariables = {
  input: UpdateAgreementInput,
  condition?: ModelAgreementConditionInput | null,
};

export type UpdateAgreementMutation = {
  updateAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type DeleteAgreementMutationVariables = {
  input: DeleteAgreementInput,
  condition?: ModelAgreementConditionInput | null,
};

export type DeleteAgreementMutation = {
  deleteAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type CreateEducationMutationVariables = {
  input: CreateEducationInput,
  condition?: ModelEducationConditionInput | null,
};

export type CreateEducationMutation = {
  createEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type UpdateEducationMutationVariables = {
  input: UpdateEducationInput,
  condition?: ModelEducationConditionInput | null,
};

export type UpdateEducationMutation = {
  updateEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type DeleteEducationMutationVariables = {
  input: DeleteEducationInput,
  condition?: ModelEducationConditionInput | null,
};

export type DeleteEducationMutation = {
  deleteEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type CreateServiceRequestMutationVariables = {
  input: CreateServiceRequestInput,
  condition?: ModelServiceRequestConditionInput | null,
};

export type CreateServiceRequestMutation = {
  createServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateServiceRequestMutationVariables = {
  input: UpdateServiceRequestInput,
  condition?: ModelServiceRequestConditionInput | null,
};

export type UpdateServiceRequestMutation = {
  updateServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteServiceRequestMutationVariables = {
  input: DeleteServiceRequestInput,
  condition?: ModelServiceRequestConditionInput | null,
};

export type DeleteServiceRequestMutation = {
  deleteServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateContractMutationVariables = {
  input: CreateContractInput,
  condition?: ModelContractConditionInput | null,
};

export type CreateContractMutation = {
  createContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateContractMutationVariables = {
  input: UpdateContractInput,
  condition?: ModelContractConditionInput | null,
};

export type UpdateContractMutation = {
  updateContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteContractMutationVariables = {
  input: DeleteContractInput,
  condition?: ModelContractConditionInput | null,
};

export type DeleteContractMutation = {
  deleteContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateJobPostMutationVariables = {
  input: CreateJobPostInput,
  condition?: ModelJobPostConditionInput | null,
};

export type CreateJobPostMutation = {
  createJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type UpdateJobPostMutationVariables = {
  input: UpdateJobPostInput,
  condition?: ModelJobPostConditionInput | null,
};

export type UpdateJobPostMutation = {
  updateJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type DeleteJobPostMutationVariables = {
  input: DeleteJobPostInput,
  condition?: ModelJobPostConditionInput | null,
};

export type DeleteJobPostMutation = {
  deleteJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type CreateJobInterestMutationVariables = {
  input: CreateJobInterestInput,
  condition?: ModelJobInterestConditionInput | null,
};

export type CreateJobInterestMutation = {
  createJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type UpdateJobInterestMutationVariables = {
  input: UpdateJobInterestInput,
  condition?: ModelJobInterestConditionInput | null,
};

export type UpdateJobInterestMutation = {
  updateJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type DeleteJobInterestMutationVariables = {
  input: DeleteJobInterestInput,
  condition?: ModelJobInterestConditionInput | null,
};

export type DeleteJobInterestMutation = {
  deleteJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type CreateJobCandidateMutationVariables = {
  input: CreateJobCandidateInput,
  condition?: ModelJobCandidateConditionInput | null,
};

export type CreateJobCandidateMutation = {
  createJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type UpdateJobCandidateMutationVariables = {
  input: UpdateJobCandidateInput,
  condition?: ModelJobCandidateConditionInput | null,
};

export type UpdateJobCandidateMutation = {
  updateJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type DeleteJobCandidateMutationVariables = {
  input: DeleteJobCandidateInput,
  condition?: ModelJobCandidateConditionInput | null,
};

export type DeleteJobCandidateMutation = {
  deleteJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type CreateUserActivityMutationVariables = {
  input: CreateUserActivityInput,
  condition?: ModelUserActivityConditionInput | null,
};

export type CreateUserActivityMutation = {
  createUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type UpdateUserActivityMutationVariables = {
  input: UpdateUserActivityInput,
  condition?: ModelUserActivityConditionInput | null,
};

export type UpdateUserActivityMutation = {
  updateUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type DeleteUserActivityMutationVariables = {
  input: DeleteUserActivityInput,
  condition?: ModelUserActivityConditionInput | null,
};

export type DeleteUserActivityMutation = {
  deleteUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateLessonMutationVariables = {
  input: CreateLessonInput,
  condition?: ModelLessonConditionInput | null,
};

export type CreateLessonMutation = {
  createLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateLessonMutationVariables = {
  input: UpdateLessonInput,
  condition?: ModelLessonConditionInput | null,
};

export type UpdateLessonMutation = {
  updateLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteLessonMutationVariables = {
  input: DeleteLessonInput,
  condition?: ModelLessonConditionInput | null,
};

export type DeleteLessonMutation = {
  deleteLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type CreateClassMutationVariables = {
  input: CreateClassInput,
  condition?: ModelClassConditionInput | null,
};

export type CreateClassMutation = {
  createClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateClassMutationVariables = {
  input: UpdateClassInput,
  condition?: ModelClassConditionInput | null,
};

export type UpdateClassMutation = {
  updateClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteClassMutationVariables = {
  input: DeleteClassInput,
  condition?: ModelClassConditionInput | null,
};

export type DeleteClassMutation = {
  deleteClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateRegistrationMutationVariables = {
  input: CreateRegistrationInput,
  condition?: ModelRegistrationConditionInput | null,
};

export type CreateRegistrationMutation = {
  createRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type UpdateRegistrationMutationVariables = {
  input: UpdateRegistrationInput,
  condition?: ModelRegistrationConditionInput | null,
};

export type UpdateRegistrationMutation = {
  updateRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type DeleteRegistrationMutationVariables = {
  input: DeleteRegistrationInput,
  condition?: ModelRegistrationConditionInput | null,
};

export type DeleteRegistrationMutation = {
  deleteRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type CreateActivityMutationVariables = {
  input: CreateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type CreateActivityMutation = {
  createActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type UpdateActivityMutationVariables = {
  input: UpdateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type UpdateActivityMutation = {
  updateActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type DeleteActivityMutationVariables = {
  input: DeleteActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type DeleteActivityMutation = {
  deleteActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgreementQueryVariables = {
  id: string,
};

export type GetAgreementQuery = {
  getAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type ListAgreementsQueryVariables = {
  id?: string | null,
  filter?: ModelAgreementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAgreementsQuery = {
  listAgreements?:  {
    __typename: "ModelAgreementConnection",
    items:  Array< {
      __typename: "Agreement",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      agreementTypes?: Array< AgreementType | null > | null,
      agreementKey?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobInterestQueryVariables = {
  id: string,
};

export type GetJobInterestQuery = {
  getJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type ListJobInterestsQueryVariables = {
  id?: string | null,
  filter?: ModelJobInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListJobInterestsQuery = {
  listJobInterests?:  {
    __typename: "ModelJobInterestConnection",
    items:  Array< {
      __typename: "JobInterest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      jobPostId: string,
      ranking?: number | null,
      status?: JobInterestStatus | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobCandidateQueryVariables = {
  id: string,
};

export type GetJobCandidateQuery = {
  getJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type ListJobCandidatesQueryVariables = {
  id?: string | null,
  filter?: ModelJobCandidateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListJobCandidatesQuery = {
  listJobCandidates?:  {
    __typename: "ModelJobCandidateConnection",
    items:  Array< {
      __typename: "JobCandidate",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      jobPostId: string,
      jobPost?:  {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      status?: JobCandidateStatus | null,
      ranking?: number | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserActivityQueryVariables = {
  id: string,
};

export type GetUserActivityQuery = {
  getUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type ListUserActivitiesQueryVariables = {
  id?: string | null,
  filter?: ModelUserActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserActivitiesQuery = {
  listUserActivities?:  {
    __typename: "ModelUserActivityConnection",
    items:  Array< {
      __typename: "UserActivity",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      subjectType: SubjectType,
      subjectId: string,
      activityType: string,
      actorId: string,
      actorName?: string | null,
      accountId: string,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLessonQueryVariables = {
  id: string,
};

export type GetLessonQuery = {
  getLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListLessonsQueryVariables = {
  id?: string | null,
  filter?: ModelLessonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLessonsQuery = {
  listLessons?:  {
    __typename: "ModelLessonConnection",
    items:  Array< {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  id?: string | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      lessonId: string,
      lesson?:  {
        __typename: "Lesson",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        number: number,
        title: string,
        description?: string | null,
        videoUrl?: string | null,
        videoDuration?: number | null,
      } | null,
      number?: number | null,
      question: string,
      answers?: Array< string | null > | null,
      correctAnswer?: number | null,
      lessonQuestionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClassQueryVariables = {
  id: string,
};

export type GetClassQuery = {
  getClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListClassesQueryVariables = {
  id?: string | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClassesQuery = {
  listClasses?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRegistrationQueryVariables = {
  id: string,
};

export type GetRegistrationQuery = {
  getRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type ListRegistrationsQueryVariables = {
  id?: string | null,
  filter?: ModelRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRegistrationsQuery = {
  listRegistrations?:  {
    __typename: "ModelRegistrationConnection",
    items:  Array< {
      __typename: "Registration",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      classStatus: ClassStatus,
      classProgress: number,
      lessonNumber: number,
      lessonId?: string | null,
      lessonStatus?: LessonStatus | null,
      videoProgress?: number | null,
      answers?: Array< number | null > | null,
      score: number,
      startedAt?: string | null,
      endsAt?: string | null,
      completedAt?: string | null,
      lessonsAssigned?: number | null,
      nextAssignmentAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActivityQueryVariables = {
  id: string,
};

export type GetActivityQuery = {
  getActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type ListActivitiesQueryVariables = {
  id?: string | null,
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListActivitiesQuery = {
  listActivities?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      },
      userId: string,
      activityDate: string,
      activityType: ActivityType,
      objectId: string,
      values?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByAccountQuery = {
  listUsersByAccount?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByRoleAndAccountQueryVariables = {
  role: UserRole,
  accountId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByRoleAndAccountQuery = {
  listUsersByRoleAndAccount?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgreementsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgreementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgreementsByUserQuery = {
  listAgreementsByUser?:  {
    __typename: "ModelAgreementConnection",
    items:  Array< {
      __typename: "Agreement",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      agreementTypes?: Array< AgreementType | null > | null,
      agreementKey?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgreementsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgreementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgreementsByAccountQuery = {
  listAgreementsByAccount?:  {
    __typename: "ModelAgreementConnection",
    items:  Array< {
      __typename: "Agreement",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      accountId: string,
      agreementTypes?: Array< AgreementType | null > | null,
      agreementKey?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobInterestsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobInterestsByUserQuery = {
  listJobInterestsByUser?:  {
    __typename: "ModelJobInterestConnection",
    items:  Array< {
      __typename: "JobInterest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      jobPostId: string,
      ranking?: number | null,
      status?: JobInterestStatus | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobInterestsByJobPostQueryVariables = {
  jobPostId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobInterestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobInterestsByJobPostQuery = {
  listJobInterestsByJobPost?:  {
    __typename: "ModelJobInterestConnection",
    items:  Array< {
      __typename: "JobInterest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      jobPostId: string,
      ranking?: number | null,
      status?: JobInterestStatus | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobCandidatesByJobPostQueryVariables = {
  jobPostId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobCandidateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobCandidatesByJobPostQuery = {
  listJobCandidatesByJobPost?:  {
    __typename: "ModelJobCandidateConnection",
    items:  Array< {
      __typename: "JobCandidate",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      jobPostId: string,
      jobPost?:  {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      status?: JobCandidateStatus | null,
      ranking?: number | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobCandidatesByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobCandidateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobCandidatesByAccountQuery = {
  listJobCandidatesByAccount?:  {
    __typename: "ModelJobCandidateConnection",
    items:  Array< {
      __typename: "JobCandidate",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      jobPostId: string,
      jobPost?:  {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      status?: JobCandidateStatus | null,
      ranking?: number | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobCandidatesByProfileQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobCandidateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobCandidatesByProfileQuery = {
  listJobCandidatesByProfile?:  {
    __typename: "ModelJobCandidateConnection",
    items:  Array< {
      __typename: "JobCandidate",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      jobPostId: string,
      jobPost?:  {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      status?: JobCandidateStatus | null,
      ranking?: number | null,
      notes?: string | null,
      message?: string | null,
      unlocked?: boolean | null,
      application?: string | null,
      origin?: RecordUpdateOrigin | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActivityByUserQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActivityByUserQuery = {
  listUserActivityByUser?:  {
    __typename: "ModelUserActivityConnection",
    items:  Array< {
      __typename: "UserActivity",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      subjectType: SubjectType,
      subjectId: string,
      activityType: string,
      actorId: string,
      actorName?: string | null,
      accountId: string,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActivityBySubjectQueryVariables = {
  subjectId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActivityBySubjectQuery = {
  listUserActivityBySubject?:  {
    __typename: "ModelUserActivityConnection",
    items:  Array< {
      __typename: "UserActivity",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      subjectType: SubjectType,
      subjectId: string,
      activityType: string,
      actorId: string,
      actorName?: string | null,
      accountId: string,
      details?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListClasesByCourseQueryVariables = {
  courseId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClasesByCourseQuery = {
  listClasesByCourse?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListClassesByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassesByAccountQuery = {
  listClassesByAccount?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRegistrationsByClassQueryVariables = {
  classId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationsByClassQuery = {
  listRegistrationsByClass?:  {
    __typename: "ModelRegistrationConnection",
    items:  Array< {
      __typename: "Registration",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      classStatus: ClassStatus,
      classProgress: number,
      lessonNumber: number,
      lessonId?: string | null,
      lessonStatus?: LessonStatus | null,
      videoProgress?: number | null,
      answers?: Array< number | null > | null,
      score: number,
      startedAt?: string | null,
      endsAt?: string | null,
      completedAt?: string | null,
      lessonsAssigned?: number | null,
      nextAssignmentAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRegistrationsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationsByUserQuery = {
  listRegistrationsByUser?:  {
    __typename: "ModelRegistrationConnection",
    items:  Array< {
      __typename: "Registration",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      classStatus: ClassStatus,
      classProgress: number,
      lessonNumber: number,
      lessonId?: string | null,
      lessonStatus?: LessonStatus | null,
      videoProgress?: number | null,
      answers?: Array< number | null > | null,
      score: number,
      startedAt?: string | null,
      endsAt?: string | null,
      completedAt?: string | null,
      lessonsAssigned?: number | null,
      nextAssignmentAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListActivityByDateQueryVariables = {
  activityDate: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActivityByDateQuery = {
  listActivityByDate?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      },
      userId: string,
      activityDate: string,
      activityType: ActivityType,
      objectId: string,
      values?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetServiceGroupQueryVariables = {
  id: string,
};

export type GetServiceGroupQuery = {
  getServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type ListServiceGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelServiceGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServiceGroupsQuery = {
  listServiceGroups?:  {
    __typename: "ModelServiceGroupConnection",
    items:  Array< {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetServiceQueryVariables = {
  id: string,
};

export type GetServiceQuery = {
  getService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type ListServicesQueryVariables = {
  id?: string | null,
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServicesQuery = {
  listServices?:  {
    __typename: "ModelServiceConnection",
    items:  Array< {
      __typename: "Service",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      aliases?: Array< string | null > | null,
      active: boolean,
      serviceGroupId: string,
      serviceGroup?:  {
        __typename: "ServiceGroup",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        industries?: Array< string | null > | null,
        default?: boolean | null,
      } | null,
      industries?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type ListLocationsQueryVariables = {
  id?: string | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      organizationName: string,
      active?: boolean | null,
      name: string,
      aliases?: Array< string | null > | null,
      addressType?: AddressType | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country: string,
      latitude: number,
      longitude: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type ListProfilesQueryVariables = {
  id?: string | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProfilesQuery = {
  listProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProfilesByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByAliasQuery = {
  listProfilesByAlias?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProfilesByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByUserQuery = {
  listProfilesByUser?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileLocationQueryVariables = {
  id: string,
};

export type GetProfileLocationQuery = {
  getProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type ListProfileLocationsQueryVariables = {
  id?: string | null,
  filter?: ModelProfileLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProfileLocationsQuery = {
  listProfileLocations?:  {
    __typename: "ModelProfileLocationConnection",
    items:  Array< {
      __typename: "ProfileLocation",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      locationId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLocationsByProfileQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsByProfileQuery = {
  listLocationsByProfile?:  {
    __typename: "ModelProfileLocationConnection",
    items:  Array< {
      __typename: "ProfileLocation",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      locationId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProfilesByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByLocationQuery = {
  listProfilesByLocation?:  {
    __typename: "ModelProfileLocationConnection",
    items:  Array< {
      __typename: "ProfileLocation",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      locationId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileServiceQueryVariables = {
  id: string,
};

export type GetProfileServiceQuery = {
  getProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type ListProfileServicesQueryVariables = {
  id?: string | null,
  filter?: ModelProfileServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProfileServicesQuery = {
  listProfileServices?:  {
    __typename: "ModelProfileServiceConnection",
    items:  Array< {
      __typename: "ProfileService",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      serviceId: string,
      title?: string | null,
      industries?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListServicesByProfileQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServicesByProfileQuery = {
  listServicesByProfile?:  {
    __typename: "ModelProfileServiceConnection",
    items:  Array< {
      __typename: "ProfileService",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      serviceId: string,
      title?: string | null,
      industries?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProfilesByServiceQueryVariables = {
  serviceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByServiceQuery = {
  listProfilesByService?:  {
    __typename: "ModelProfileServiceConnection",
    items:  Array< {
      __typename: "ProfileService",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      serviceId: string,
      title?: string | null,
      industries?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExperienceQueryVariables = {
  id: string,
};

export type GetExperienceQuery = {
  getExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type ListExperiencesQueryVariables = {
  id?: string | null,
  filter?: ModelExperienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExperiencesQuery = {
  listExperiences?:  {
    __typename: "ModelExperienceConnection",
    items:  Array< {
      __typename: "Experience",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      jobTitle?: string | null,
      agencyType?: AgencyType | null,
      agencyName?: string | null,
      employerName?: string | null,
      employerLocation?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      projectNo?: string | null,
      projectName?: string | null,
      projectLocation?: string | null,
      projectDollarAmount?: number | null,
      jobDescription?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListExperiencesByProfileQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExperienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExperiencesByProfileQuery = {
  listExperiencesByProfile?:  {
    __typename: "ModelExperienceConnection",
    items:  Array< {
      __typename: "Experience",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      jobTitle?: string | null,
      agencyType?: AgencyType | null,
      agencyName?: string | null,
      employerName?: string | null,
      employerLocation?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      projectNo?: string | null,
      projectName?: string | null,
      projectLocation?: string | null,
      projectDollarAmount?: number | null,
      jobDescription?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCertificationQueryVariables = {
  id: string,
};

export type GetCertificationQuery = {
  getCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type ListCertificationsQueryVariables = {
  id?: string | null,
  filter?: ModelCertificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCertificationsQuery = {
  listCertifications?:  {
    __typename: "ModelCertificationConnection",
    items:  Array< {
      __typename: "Certification",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      certificationType: string,
      issuedAt?: string | null,
      expiresAt?: string | null,
      certificateKey?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCertificationsByProfileQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCertificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCertificationsByProfileQuery = {
  listCertificationsByProfile?:  {
    __typename: "ModelCertificationConnection",
    items:  Array< {
      __typename: "Certification",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      certificationType: string,
      issuedAt?: string | null,
      expiresAt?: string | null,
      certificateKey?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCertificationTypeQueryVariables = {
  id: string,
};

export type GetCertificationTypeQuery = {
  getCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type ListCertificationTypesQueryVariables = {
  id?: string | null,
  filter?: ModelCertificationTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCertificationTypesQuery = {
  listCertificationTypes?:  {
    __typename: "ModelCertificationTypeConnection",
    items:  Array< {
      __typename: "CertificationType",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      active: boolean,
      industries?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEducationQueryVariables = {
  id: string,
};

export type GetEducationQuery = {
  getEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type ListEducationsQueryVariables = {
  id?: string | null,
  filter?: ModelEducationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEducationsQuery = {
  listEducations?:  {
    __typename: "ModelEducationConnection",
    items:  Array< {
      __typename: "Education",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      school?: string | null,
      degree?: string | null,
      field?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      description?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEducationByProfileQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEducationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEducationByProfileQuery = {
  listEducationByProfile?:  {
    __typename: "ModelEducationConnection",
    items:  Array< {
      __typename: "Education",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      userId: string,
      profileId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null,
      school?: string | null,
      degree?: string | null,
      field?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      description?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetServiceRequestQueryVariables = {
  id: string,
};

export type GetServiceRequestQuery = {
  getServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListServiceRequestsQueryVariables = {
  id?: string | null,
  filter?: ModelServiceRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListServiceRequestsQuery = {
  listServiceRequests?:  {
    __typename: "ModelServiceRequestConnection",
    items:  Array< {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListServiceRequestsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelServiceRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServiceRequestsByAccountQuery = {
  listServiceRequestsByAccount?:  {
    __typename: "ModelServiceRequestConnection",
    items:  Array< {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractQueryVariables = {
  id: string,
};

export type GetContractQuery = {
  getContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListContractsQueryVariables = {
  id?: string | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContractsQuery = {
  listContracts?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListContractsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractsByAccountQuery = {
  listContractsByAccount?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobPostQueryVariables = {
  id: string,
};

export type GetJobPostQuery = {
  getJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type ListJobPostsQueryVariables = {
  id?: string | null,
  filter?: ModelJobPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListJobPostsQuery = {
  listJobPosts?:  {
    __typename: "ModelJobPostConnection",
    items:  Array< {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobPostsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobPostsByAccountQuery = {
  listJobPostsByAccount?:  {
    __typename: "ModelJobPostConnection",
    items:  Array< {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobPostsByContractQueryVariables = {
  contractId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobPostsByContractQuery = {
  listJobPostsByContract?:  {
    __typename: "ModelJobPostConnection",
    items:  Array< {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobPostsByServiceRequestQueryVariables = {
  serviceRequestId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobPostsByServiceRequestQuery = {
  listJobPostsByServiceRequest?:  {
    __typename: "ModelJobPostConnection",
    items:  Array< {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobPostsByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobPostsByLocationQuery = {
  listJobPostsByLocation?:  {
    __typename: "ModelJobPostConnection",
    items:  Array< {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobPostsByServiceQueryVariables = {
  serviceId: string,
  locationId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelJobPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobPostsByServiceQuery = {
  listJobPostsByService?:  {
    __typename: "ModelJobPostConnection",
    items:  Array< {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCoursesQueryVariables = {
  id?: string | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userStatus?: UserStatus | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    title?: string | null,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        alias?: string | null,
        active: boolean,
        userId: string,
        nickname?: string | null,
        availability?: string | null,
        availableDate?: string | null,
        desiredRate?: string | null,
        about?: string | null,
        resumeKey?: string | null,
        profileStatus: ProfileStatus,
        statusMessage?: string | null,
        notes?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        federalExperience?: number | null,
        totalExperience?: number | null,
        industries?: Array< string | null > | null,
        securityClearance?: string | null,
        securityStatus?: SecurityStatus | null,
        workSettings?: Array< WorkSetting | null > | null,
        credentials?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    agreements?:  {
      __typename: "ModelAgreementConnection",
      items:  Array< {
        __typename: "Agreement",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        accountId: string,
        agreementTypes?: Array< AgreementType | null > | null,
        agreementKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industry?: string | null,
    lastActiveAt?: string | null,
    jobTitle?: string | null,
    invitedAt?: string | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    customerId?: string | null,
  } | null,
};

export type OnCreateAgreementSubscriptionVariables = {
  filter?: ModelSubscriptionAgreementFilterInput | null,
  userId?: string | null,
};

export type OnCreateAgreementSubscription = {
  onCreateAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type OnUpdateAgreementSubscriptionVariables = {
  filter?: ModelSubscriptionAgreementFilterInput | null,
  userId?: string | null,
};

export type OnUpdateAgreementSubscription = {
  onUpdateAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type OnDeleteAgreementSubscriptionVariables = {
  filter?: ModelSubscriptionAgreementFilterInput | null,
  userId?: string | null,
};

export type OnDeleteAgreementSubscription = {
  onDeleteAgreement?:  {
    __typename: "Agreement",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    accountId: string,
    agreementTypes?: Array< AgreementType | null > | null,
    agreementKey?: string | null,
  } | null,
};

export type OnCreateJobInterestSubscriptionVariables = {
  filter?: ModelSubscriptionJobInterestFilterInput | null,
  userId?: string | null,
};

export type OnCreateJobInterestSubscription = {
  onCreateJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type OnUpdateJobInterestSubscriptionVariables = {
  filter?: ModelSubscriptionJobInterestFilterInput | null,
  userId?: string | null,
};

export type OnUpdateJobInterestSubscription = {
  onUpdateJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type OnDeleteJobInterestSubscriptionVariables = {
  filter?: ModelSubscriptionJobInterestFilterInput | null,
  userId?: string | null,
};

export type OnDeleteJobInterestSubscription = {
  onDeleteJobInterest?:  {
    __typename: "JobInterest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    jobPostId: string,
    ranking?: number | null,
    status?: JobInterestStatus | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type OnCreateJobCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionJobCandidateFilterInput | null,
};

export type OnCreateJobCandidateSubscription = {
  onCreateJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type OnUpdateJobCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionJobCandidateFilterInput | null,
};

export type OnUpdateJobCandidateSubscription = {
  onUpdateJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type OnDeleteJobCandidateSubscriptionVariables = {
  filter?: ModelSubscriptionJobCandidateFilterInput | null,
};

export type OnDeleteJobCandidateSubscription = {
  onDeleteJobCandidate?:  {
    __typename: "JobCandidate",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    jobPostId: string,
    jobPost?:  {
      __typename: "JobPost",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      contractId?: string | null,
      contract?:  {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null,
      serviceRequestId?: string | null,
      serviceRequest?:  {
        __typename: "ServiceRequest",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        serviceRequestType: ServiceRequestType,
        name: string,
        status?: string | null,
        completedDate?: string | null,
      } | null,
      locationId?: string | null,
      serviceId: string,
      industries?: Array< string | null > | null,
      title?: string | null,
      summary: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      openDate?: string | null,
      filledDate?: string | null,
      closedDate?: string | null,
      employmentType: EmploymentType,
      annualSalaryLow?: number | null,
      annualSalaryHigh?: number | null,
      jobCandidates?:  {
        __typename: "ModelJobCandidateConnection",
        nextToken?: string | null,
      } | null,
      statusId?: string | null,
      status?: JobPostStatus | null,
      openings?: number | null,
      invoiceId?: string | null,
      jobPostType?: JobPostType | null,
      hiringDate?: string | null,
      unlocksAvailable?: number | null,
      featured?: boolean | null,
      questions?: string | null,
      workSettings?: Array< WorkSetting | null > | null,
      securityClearance?: string | null,
    } | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    status?: JobCandidateStatus | null,
    ranking?: number | null,
    notes?: string | null,
    message?: string | null,
    unlocked?: boolean | null,
    application?: string | null,
    origin?: RecordUpdateOrigin | null,
  } | null,
};

export type OnCreateUserActivitySubscriptionVariables = {
  filter?: ModelSubscriptionUserActivityFilterInput | null,
  userId?: string | null,
};

export type OnCreateUserActivitySubscription = {
  onCreateUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type OnUpdateUserActivitySubscriptionVariables = {
  filter?: ModelSubscriptionUserActivityFilterInput | null,
  userId?: string | null,
};

export type OnUpdateUserActivitySubscription = {
  onUpdateUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type OnDeleteUserActivitySubscriptionVariables = {
  filter?: ModelSubscriptionUserActivityFilterInput | null,
  userId?: string | null,
};

export type OnDeleteUserActivitySubscription = {
  onDeleteUserActivity?:  {
    __typename: "UserActivity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    subjectType: SubjectType,
    subjectId: string,
    activityType: string,
    actorId: string,
    actorName?: string | null,
    accountId: string,
    details?: string | null,
  } | null,
};

export type OnCreateLessonSubscriptionVariables = {
  filter?: ModelSubscriptionLessonFilterInput | null,
};

export type OnCreateLessonSubscription = {
  onCreateLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateLessonSubscriptionVariables = {
  filter?: ModelSubscriptionLessonFilterInput | null,
};

export type OnUpdateLessonSubscription = {
  onUpdateLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteLessonSubscriptionVariables = {
  filter?: ModelSubscriptionLessonFilterInput | null,
};

export type OnDeleteLessonSubscription = {
  onDeleteLesson?:  {
    __typename: "Lesson",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    number: number,
    title: string,
    description?: string | null,
    videoUrl?: string | null,
    videoDuration?: number | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        lessonId: string,
        number?: number | null,
        question: string,
        answers?: Array< string | null > | null,
        correctAnswer?: number | null,
        lessonQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    lessonId: string,
    lesson?:  {
      __typename: "Lesson",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      number: number,
      title: string,
      description?: string | null,
      videoUrl?: string | null,
      videoDuration?: number | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    number?: number | null,
    question: string,
    answers?: Array< string | null > | null,
    correctAnswer?: number | null,
    lessonQuestionsId?: string | null,
  } | null,
};

export type OnCreateClassSubscriptionVariables = {
  filter?: ModelSubscriptionClassFilterInput | null,
  ownerId?: string | null,
};

export type OnCreateClassSubscription = {
  onCreateClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateClassSubscriptionVariables = {
  filter?: ModelSubscriptionClassFilterInput | null,
  ownerId?: string | null,
};

export type OnUpdateClassSubscription = {
  onUpdateClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteClassSubscriptionVariables = {
  filter?: ModelSubscriptionClassFilterInput | null,
  ownerId?: string | null,
};

export type OnDeleteClassSubscription = {
  onDeleteClass?:  {
    __typename: "Class",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    courseId: string,
    course?:  {
      __typename: "Course",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      description?: string | null,
      coverUrl?: string | null,
      details?: string | null,
      publishDate?: string | null,
      sunsetDate?: string | null,
      lessonCount?: number | null,
      creditHours?: number | null,
      isFree?: boolean | null,
      lessons?: string | null,
      handoutUrl?: string | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    ownerId?: string | null,
    userId?: string | null,
    termBegin?: string | null,
    termEnd?: string | null,
    seatsPurchased?: number | null,
    seatsFilled?: number | null,
    couponCode?: string | null,
    assignmentSchedule?: AssignmentSchedule | null,
    registrations?:  {
      __typename: "ModelRegistrationConnection",
      items:  Array< {
        __typename: "Registration",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        classId: string,
        userId: string,
        classStatus: ClassStatus,
        classProgress: number,
        lessonNumber: number,
        lessonId?: string | null,
        lessonStatus?: LessonStatus | null,
        videoProgress?: number | null,
        answers?: Array< number | null > | null,
        score: number,
        startedAt?: string | null,
        endsAt?: string | null,
        completedAt?: string | null,
        lessonsAssigned?: number | null,
        nextAssignmentAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateRegistrationSubscriptionVariables = {
  filter?: ModelSubscriptionRegistrationFilterInput | null,
  userId?: string | null,
};

export type OnCreateRegistrationSubscription = {
  onCreateRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type OnUpdateRegistrationSubscriptionVariables = {
  filter?: ModelSubscriptionRegistrationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateRegistrationSubscription = {
  onUpdateRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type OnDeleteRegistrationSubscriptionVariables = {
  filter?: ModelSubscriptionRegistrationFilterInput | null,
  userId?: string | null,
};

export type OnDeleteRegistrationSubscription = {
  onDeleteRegistration?:  {
    __typename: "Registration",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      courseId: string,
      course?:  {
        __typename: "Course",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        title: string,
        description?: string | null,
        coverUrl?: string | null,
        details?: string | null,
        publishDate?: string | null,
        sunsetDate?: string | null,
        lessonCount?: number | null,
        creditHours?: number | null,
        isFree?: boolean | null,
        lessons?: string | null,
        handoutUrl?: string | null,
      } | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      ownerId?: string | null,
      userId?: string | null,
      termBegin?: string | null,
      termEnd?: string | null,
      seatsPurchased?: number | null,
      seatsFilled?: number | null,
      couponCode?: string | null,
      assignmentSchedule?: AssignmentSchedule | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    classStatus: ClassStatus,
    classProgress: number,
    lessonNumber: number,
    lessonId?: string | null,
    lessonStatus?: LessonStatus | null,
    videoProgress?: number | null,
    answers?: Array< number | null > | null,
    score: number,
    startedAt?: string | null,
    endsAt?: string | null,
    completedAt?: string | null,
    lessonsAssigned?: number | null,
    nextAssignmentAt?: string | null,
  } | null,
};

export type OnCreateActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
};

export type OnCreateActivitySubscription = {
  onCreateActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type OnUpdateActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
};

export type OnUpdateActivitySubscription = {
  onUpdateActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type OnDeleteActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
};

export type OnDeleteActivitySubscription = {
  onDeleteActivity?:  {
    __typename: "Activity",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    },
    userId: string,
    activityDate: string,
    activityType: ActivityType,
    objectId: string,
    values?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  ownerId?: string | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  ownerId?: string | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
  ownerId?: string | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country?: string | null,
    active?: boolean | null,
    dunsNumber?: string | null,
    cageCode?: string | null,
    naicsCode?: string | null,
    sicCode?: string | null,
    socioEconomicDesignation?: string | null,
    fein?: string | null,
    accountStatus?: AccountStatus | null,
    phone?: string | null,
    webUrl?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contracts?:  {
      __typename: "ModelContractConnection",
      items:  Array< {
        __typename: "Contract",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        issuedBy?: string | null,
        name: string,
        number?: string | null,
        locationId?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        perDiem?: number | null,
        value?: string | null,
        category?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    accountType?: AccountType | null,
    accountSize?: AccountSize | null,
    customerId?: string | null,
    industries?: Array< string | null > | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    activity?:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        userId: string,
        activityDate: string,
        activityType: ActivityType,
        objectId: string,
        values?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateServiceGroupSubscriptionVariables = {
  filter?: ModelSubscriptionServiceGroupFilterInput | null,
};

export type OnCreateServiceGroupSubscription = {
  onCreateServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type OnUpdateServiceGroupSubscriptionVariables = {
  filter?: ModelSubscriptionServiceGroupFilterInput | null,
};

export type OnUpdateServiceGroupSubscription = {
  onUpdateServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type OnDeleteServiceGroupSubscriptionVariables = {
  filter?: ModelSubscriptionServiceGroupFilterInput | null,
};

export type OnDeleteServiceGroupSubscription = {
  onDeleteServiceGroup?:  {
    __typename: "ServiceGroup",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    services?:  {
      __typename: "ModelServiceConnection",
      items:  Array< {
        __typename: "Service",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        name: string,
        aliases?: Array< string | null > | null,
        active: boolean,
        serviceGroupId: string,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    industries?: Array< string | null > | null,
    default?: boolean | null,
  } | null,
};

export type OnCreateServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnCreateServiceSubscription = {
  onCreateService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnUpdateServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnUpdateServiceSubscription = {
  onUpdateService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnDeleteServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnDeleteServiceSubscription = {
  onDeleteService?:  {
    __typename: "Service",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    aliases?: Array< string | null > | null,
    active: boolean,
    serviceGroupId: string,
    serviceGroup?:  {
      __typename: "ServiceGroup",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      name: string,
      services?:  {
        __typename: "ModelServiceConnection",
        nextToken?: string | null,
      } | null,
      industries?: Array< string | null > | null,
      default?: boolean | null,
    } | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    organizationName: string,
    active?: boolean | null,
    name: string,
    aliases?: Array< string | null > | null,
    addressType?: AddressType | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    country: string,
    latitude: number,
    longitude: number,
  } | null,
};

export type OnCreateProfileSubscriptionVariables = {
  filter?: ModelSubscriptionProfileFilterInput | null,
  userId?: string | null,
};

export type OnCreateProfileSubscription = {
  onCreateProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type OnUpdateProfileSubscriptionVariables = {
  filter?: ModelSubscriptionProfileFilterInput | null,
  userId?: string | null,
};

export type OnUpdateProfileSubscription = {
  onUpdateProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type OnDeleteProfileSubscriptionVariables = {
  filter?: ModelSubscriptionProfileFilterInput | null,
  userId?: string | null,
};

export type OnDeleteProfileSubscription = {
  onDeleteProfile?:  {
    __typename: "Profile",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    alias?: string | null,
    active: boolean,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      userStatus?: UserStatus | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      firstName: string,
      lastName: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
      } | null,
      agreements?:  {
        __typename: "ModelAgreementConnection",
        nextToken?: string | null,
      } | null,
      industry?: string | null,
      lastActiveAt?: string | null,
      jobTitle?: string | null,
      invitedAt?: string | null,
      registrations?:  {
        __typename: "ModelRegistrationConnection",
        nextToken?: string | null,
      } | null,
      customerId?: string | null,
    } | null,
    nickname?: string | null,
    profileServices?:  {
      __typename: "ModelProfileServiceConnection",
      items:  Array< {
        __typename: "ProfileService",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        serviceId: string,
        title?: string | null,
        industries?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    profileLocations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        locationId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    availability?: string | null,
    availableDate?: string | null,
    desiredRate?: string | null,
    about?: string | null,
    resumeKey?: string | null,
    profileStatus: ProfileStatus,
    statusMessage?: string | null,
    notes?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    federalExperience?: number | null,
    totalExperience?: number | null,
    industries?: Array< string | null > | null,
    securityClearance?: string | null,
    securityStatus?: SecurityStatus | null,
    workSettings?: Array< WorkSetting | null > | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        jobTitle?: string | null,
        agencyType?: AgencyType | null,
        agencyName?: string | null,
        employerName?: string | null,
        employerLocation?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        projectNo?: string | null,
        projectName?: string | null,
        projectLocation?: string | null,
        projectDollarAmount?: number | null,
        jobDescription?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    education?:  {
      __typename: "ModelEducationConnection",
      items:  Array< {
        __typename: "Education",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        school?: string | null,
        degree?: string | null,
        field?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        description?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    certifications?:  {
      __typename: "ModelCertificationConnection",
      items:  Array< {
        __typename: "Certification",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId: string,
        profileId: string,
        certificationType: string,
        issuedAt?: string | null,
        expiresAt?: string | null,
        certificateKey?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    credentials?: string | null,
  } | null,
};

export type OnCreateProfileLocationSubscriptionVariables = {
  filter?: ModelSubscriptionProfileLocationFilterInput | null,
  userId?: string | null,
};

export type OnCreateProfileLocationSubscription = {
  onCreateProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type OnUpdateProfileLocationSubscriptionVariables = {
  filter?: ModelSubscriptionProfileLocationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateProfileLocationSubscription = {
  onUpdateProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type OnDeleteProfileLocationSubscriptionVariables = {
  filter?: ModelSubscriptionProfileLocationFilterInput | null,
  userId?: string | null,
};

export type OnDeleteProfileLocationSubscription = {
  onDeleteProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    locationId: string,
  } | null,
};

export type OnCreateProfileServiceSubscriptionVariables = {
  filter?: ModelSubscriptionProfileServiceFilterInput | null,
  userId?: string | null,
};

export type OnCreateProfileServiceSubscription = {
  onCreateProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnUpdateProfileServiceSubscriptionVariables = {
  filter?: ModelSubscriptionProfileServiceFilterInput | null,
  userId?: string | null,
};

export type OnUpdateProfileServiceSubscription = {
  onUpdateProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnDeleteProfileServiceSubscriptionVariables = {
  filter?: ModelSubscriptionProfileServiceFilterInput | null,
  userId?: string | null,
};

export type OnDeleteProfileServiceSubscription = {
  onDeleteProfileService?:  {
    __typename: "ProfileService",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    serviceId: string,
    title?: string | null,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnCreateExperienceSubscriptionVariables = {
  filter?: ModelSubscriptionExperienceFilterInput | null,
  userId?: string | null,
};

export type OnCreateExperienceSubscription = {
  onCreateExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type OnUpdateExperienceSubscriptionVariables = {
  filter?: ModelSubscriptionExperienceFilterInput | null,
  userId?: string | null,
};

export type OnUpdateExperienceSubscription = {
  onUpdateExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type OnDeleteExperienceSubscriptionVariables = {
  filter?: ModelSubscriptionExperienceFilterInput | null,
  userId?: string | null,
};

export type OnDeleteExperienceSubscription = {
  onDeleteExperience?:  {
    __typename: "Experience",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    jobTitle?: string | null,
    agencyType?: AgencyType | null,
    agencyName?: string | null,
    employerName?: string | null,
    employerLocation?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    projectNo?: string | null,
    projectName?: string | null,
    projectLocation?: string | null,
    projectDollarAmount?: number | null,
    jobDescription?: string | null,
  } | null,
};

export type OnCreateCertificationSubscriptionVariables = {
  filter?: ModelSubscriptionCertificationFilterInput | null,
  userId?: string | null,
};

export type OnCreateCertificationSubscription = {
  onCreateCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type OnUpdateCertificationSubscriptionVariables = {
  filter?: ModelSubscriptionCertificationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateCertificationSubscription = {
  onUpdateCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type OnDeleteCertificationSubscriptionVariables = {
  filter?: ModelSubscriptionCertificationFilterInput | null,
  userId?: string | null,
};

export type OnDeleteCertificationSubscription = {
  onDeleteCertification?:  {
    __typename: "Certification",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    certificationType: string,
    issuedAt?: string | null,
    expiresAt?: string | null,
    certificateKey?: string | null,
  } | null,
};

export type OnCreateCertificationTypeSubscriptionVariables = {
  filter?: ModelSubscriptionCertificationTypeFilterInput | null,
};

export type OnCreateCertificationTypeSubscription = {
  onCreateCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnUpdateCertificationTypeSubscriptionVariables = {
  filter?: ModelSubscriptionCertificationTypeFilterInput | null,
};

export type OnUpdateCertificationTypeSubscription = {
  onUpdateCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnDeleteCertificationTypeSubscriptionVariables = {
  filter?: ModelSubscriptionCertificationTypeFilterInput | null,
};

export type OnDeleteCertificationTypeSubscription = {
  onDeleteCertificationType?:  {
    __typename: "CertificationType",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    name: string,
    active: boolean,
    industries?: Array< string | null > | null,
  } | null,
};

export type OnCreateEducationSubscriptionVariables = {
  filter?: ModelSubscriptionEducationFilterInput | null,
  userId?: string | null,
};

export type OnCreateEducationSubscription = {
  onCreateEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type OnUpdateEducationSubscriptionVariables = {
  filter?: ModelSubscriptionEducationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateEducationSubscription = {
  onUpdateEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type OnDeleteEducationSubscriptionVariables = {
  filter?: ModelSubscriptionEducationFilterInput | null,
  userId?: string | null,
};

export type OnDeleteEducationSubscription = {
  onDeleteEducation?:  {
    __typename: "Education",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    userId: string,
    profileId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      alias?: string | null,
      active: boolean,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        userStatus?: UserStatus | null,
        accountId: string,
        firstName: string,
        lastName: string,
        title?: string | null,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        industry?: string | null,
        lastActiveAt?: string | null,
        jobTitle?: string | null,
        invitedAt?: string | null,
        customerId?: string | null,
      } | null,
      nickname?: string | null,
      profileServices?:  {
        __typename: "ModelProfileServiceConnection",
        nextToken?: string | null,
      } | null,
      profileLocations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
      } | null,
      availability?: string | null,
      availableDate?: string | null,
      desiredRate?: string | null,
      about?: string | null,
      resumeKey?: string | null,
      profileStatus: ProfileStatus,
      statusMessage?: string | null,
      notes?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      federalExperience?: number | null,
      totalExperience?: number | null,
      industries?: Array< string | null > | null,
      securityClearance?: string | null,
      securityStatus?: SecurityStatus | null,
      workSettings?: Array< WorkSetting | null > | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        nextToken?: string | null,
      } | null,
      education?:  {
        __typename: "ModelEducationConnection",
        nextToken?: string | null,
      } | null,
      certifications?:  {
        __typename: "ModelCertificationConnection",
        nextToken?: string | null,
      } | null,
      credentials?: string | null,
    } | null,
    school?: string | null,
    degree?: string | null,
    field?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    description?: string | null,
  } | null,
};

export type OnCreateServiceRequestSubscriptionVariables = {
  filter?: ModelSubscriptionServiceRequestFilterInput | null,
};

export type OnCreateServiceRequestSubscription = {
  onCreateServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateServiceRequestSubscriptionVariables = {
  filter?: ModelSubscriptionServiceRequestFilterInput | null,
};

export type OnUpdateServiceRequestSubscription = {
  onUpdateServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteServiceRequestSubscriptionVariables = {
  filter?: ModelSubscriptionServiceRequestFilterInput | null,
};

export type OnDeleteServiceRequestSubscription = {
  onDeleteServiceRequest?:  {
    __typename: "ServiceRequest",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestType: ServiceRequestType,
    name: string,
    status?: string | null,
    completedDate?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
};

export type OnCreateContractSubscription = {
  onCreateContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
};

export type OnUpdateContractSubscription = {
  onUpdateContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
};

export type OnDeleteContractSubscription = {
  onDeleteContract?:  {
    __typename: "Contract",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    issuedBy?: string | null,
    name: string,
    number?: string | null,
    locationId?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    perDiem?: number | null,
    value?: string | null,
    category?: string | null,
    jobPosts?:  {
      __typename: "ModelJobPostConnection",
      items:  Array< {
        __typename: "JobPost",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        contractId?: string | null,
        serviceRequestId?: string | null,
        locationId?: string | null,
        serviceId: string,
        industries?: Array< string | null > | null,
        title?: string | null,
        summary: string,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        openDate?: string | null,
        filledDate?: string | null,
        closedDate?: string | null,
        employmentType: EmploymentType,
        annualSalaryLow?: number | null,
        annualSalaryHigh?: number | null,
        statusId?: string | null,
        status?: JobPostStatus | null,
        openings?: number | null,
        invoiceId?: string | null,
        jobPostType?: JobPostType | null,
        hiringDate?: string | null,
        unlocksAvailable?: number | null,
        featured?: boolean | null,
        questions?: string | null,
        workSettings?: Array< WorkSetting | null > | null,
        securityClearance?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateJobPostSubscriptionVariables = {
  filter?: ModelSubscriptionJobPostFilterInput | null,
};

export type OnCreateJobPostSubscription = {
  onCreateJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type OnUpdateJobPostSubscriptionVariables = {
  filter?: ModelSubscriptionJobPostFilterInput | null,
};

export type OnUpdateJobPostSubscription = {
  onUpdateJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type OnDeleteJobPostSubscriptionVariables = {
  filter?: ModelSubscriptionJobPostFilterInput | null,
};

export type OnDeleteJobPostSubscription = {
  onDeleteJobPost?:  {
    __typename: "JobPost",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      country?: string | null,
      active?: boolean | null,
      dunsNumber?: string | null,
      cageCode?: string | null,
      naicsCode?: string | null,
      sicCode?: string | null,
      socioEconomicDesignation?: string | null,
      fein?: string | null,
      accountStatus?: AccountStatus | null,
      phone?: string | null,
      webUrl?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      contracts?:  {
        __typename: "ModelContractConnection",
        nextToken?: string | null,
      } | null,
      accountType?: AccountType | null,
      accountSize?: AccountSize | null,
      customerId?: string | null,
      industries?: Array< string | null > | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      activity?:  {
        __typename: "ModelActivityConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    contractId?: string | null,
    contract?:  {
      __typename: "Contract",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      issuedBy?: string | null,
      name: string,
      number?: string | null,
      locationId?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      perDiem?: number | null,
      value?: string | null,
      category?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    serviceRequestId?: string | null,
    serviceRequest?:  {
      __typename: "ServiceRequest",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
        active?: boolean | null,
        dunsNumber?: string | null,
        cageCode?: string | null,
        naicsCode?: string | null,
        sicCode?: string | null,
        socioEconomicDesignation?: string | null,
        fein?: string | null,
        accountStatus?: AccountStatus | null,
        phone?: string | null,
        webUrl?: string | null,
        accountType?: AccountType | null,
        accountSize?: AccountSize | null,
        customerId?: string | null,
        industries?: Array< string | null > | null,
      } | null,
      serviceRequestType: ServiceRequestType,
      name: string,
      status?: string | null,
      completedDate?: string | null,
      jobPosts?:  {
        __typename: "ModelJobPostConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    locationId?: string | null,
    serviceId: string,
    industries?: Array< string | null > | null,
    title?: string | null,
    summary: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    openDate?: string | null,
    filledDate?: string | null,
    closedDate?: string | null,
    employmentType: EmploymentType,
    annualSalaryLow?: number | null,
    annualSalaryHigh?: number | null,
    jobCandidates?:  {
      __typename: "ModelJobCandidateConnection",
      items:  Array< {
        __typename: "JobCandidate",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        jobPostId: string,
        accountId: string,
        profileId: string,
        status?: JobCandidateStatus | null,
        ranking?: number | null,
        notes?: string | null,
        message?: string | null,
        unlocked?: boolean | null,
        application?: string | null,
        origin?: RecordUpdateOrigin | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    statusId?: string | null,
    status?: JobPostStatus | null,
    openings?: number | null,
    invoiceId?: string | null,
    jobPostType?: JobPostType | null,
    hiringDate?: string | null,
    unlocksAvailable?: number | null,
    featured?: boolean | null,
    questions?: string | null,
    workSettings?: Array< WorkSetting | null > | null,
    securityClearance?: string | null,
  } | null,
};

export type OnCreateCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    title: string,
    description?: string | null,
    coverUrl?: string | null,
    details?: string | null,
    publishDate?: string | null,
    sunsetDate?: string | null,
    lessonCount?: number | null,
    creditHours?: number | null,
    isFree?: boolean | null,
    lessons?: string | null,
    handoutUrl?: string | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        courseId: string,
        accountId?: string | null,
        ownerId?: string | null,
        userId?: string | null,
        termBegin?: string | null,
        termEnd?: string | null,
        seatsPurchased?: number | null,
        seatsFilled?: number | null,
        couponCode?: string | null,
        assignmentSchedule?: AssignmentSchedule | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};
