import {observable} from "mobx";

class Progress {

  @observable isVisible: boolean = false
  name: string = ''

  show = (name: string) => {
    this.isVisible = true
    this.name = name
  }

  hide = (name: string) => {
    this.isVisible = false
  }
}

export default Progress