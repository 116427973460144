import Course from "./Course";
import {Question} from "./Question";


export class Lesson {
  id: string
  createdAt: string
  updatedAt: string
  courseId: string
  course?: Course
  number: number
  title: string
  description: string
  videoUrl: string
  videoDuration: number
  questions: Question[]

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.courseId = data.courseId
    this.course = data.course ? new Course(data.course) : undefined
    this.number = data.number
    this.title = data.title
    this.description = data.description
    this.videoUrl = data.videoUrl
    this.videoDuration = data.videoDuration
    this.questions = []
    if (data.questions && data.questions.items) {
      this.loadQuestions(data.questions.items)
    }
  }

  loadQuestions(items: any[]) {
    this.questions = items.map((item: any) => new Question(item))
  }

}

export default Lesson