import * as React from 'react'
import Page from '../../components/page/Page'
import {
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DialogButton from "../../components/form/DialogButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import {
  ActivityType,
  ClassStatus,
  CreateRegistrationInput,
  CreateUserInput,
  LessonStatus,
  UpdateUserInput,
  UserRole,
  UserStatus
} from "../../API";
import Notify from "../../components/notify/Notify";
import User from "../../model/User";
import Registration from "../../model/Registration";
import Class from "../../model/Class";
import UserStore from "../../stores/UserStore";
import Tracking from "../../components/Tracking";
import {getISODateTime} from "../../stores/StoreUtilities";
import Visible from "../../components/Visible";
import PasswordChangeDialog from "../../components/auth/PasswordChangeDialog";
import EmailChangeDialog from "../../components/auth/EmailChangeDialog";
import Confirm from "../../components/confirm/Confirm";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.error.main,
    marginTop: 10
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: "0px 0px 10px 10px"
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  resendButton: {
    color: theme.palette.secondary.main,
    paddingLeft: 0,
  },
  radioGroup: {
    margin: 0,
    padding: 0
  }
})

interface IUserEditPageProps {
  accountId?: string
  userId?: string
  accountStore?: AccountStore
  userStore?: UserStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
  location?: any
}

@inject("accountStore", "userStore", "progress", "notify", "confirm")
@observer
class UserEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IUserEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable user?: User
  @observable values = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    phone: "",
    userRole: "Student",
    sendInvite: false,
    userStatus: "Inactive"
  }
  @observable registrations: Registration[] = []
  @observable message = ""
  @observable showPasswordChange = false
  @observable showEmailChange = false
  private classId?: any

  componentDidMount () {
    const { accountId, userId, userStore, accountStore, progress} = this.props
    this.isLoading = true
    when(
      () => !userStore!.isLoading && accountId !== undefined,
      async () => {
        // Get classId query param
        this.classId = new URLSearchParams(this.props.location.search).get("classId")
        console.log(`classId = ${this.classId}`)
        await accountStore!.loadAccount(accountId!)

        if (userId) {
          progress!.show("UserEditPage")
          this.user = await accountStore!.getUser(userId!)
          if (this.user) {
            this.values = {
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              jobTitle: this.user.jobTitle,
              email: this.user.email,
              phone: this.user.phone,
              userRole: UserRole[this.user.role] ?? UserRole.Student,
              userStatus: this.user.userStatus.toString(),
              sendInvite: false,
            }
            this.loadRegistrations()
          }
          this.isLoading = false
          progress!.hide("UserEditPage")
        } else {
          this.loadRegistrations()
          this.isLoading = false
          progress!.hide("UserEditPage")
        }
      }
    )

  }


  render() {
    const { classes, userStore, userId } = this.props

    const title = this.user ? "Edit Person" : "Add Person"
    const isAdmin = userStore!.isAdmin
    const isAdminOrAgent = userStore!.isAdminOrAgent
    const isEmployer = isAdminOrAgent || userStore!.isEmployer
    const roleDisabled = (userId !== undefined)

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                             name="UserEditForm" id="UserEditForm" className={classes.form}>
                <DialogContent className={classes.dialogContent}>
                  <TextFieldValidator
                    autoFocus
                    margin="dense"
                    name="firstName"
                    label="First Name"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.firstName}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.lastName}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="jobTitle"
                    label="Job Title"
                    type="text"
                    variant="standard"
                    validators={{}}
                    onChange={this.onChange}
                    value={this.values.jobTitle}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="email"
                    id="EmailInput"
                    label="Email"
                    variant="standard"
                    placeholder=""
                    type="text"
                    validators={{required:true, isEmail: true}}
                    value={this.values.email}
                    onChange={this.onChange}
                    fullWidth
                    autoCorrect="off"
                    autoCapitalize="off"
                    autoComplete="on"
                    disabled={this.user !== undefined}
                  />
                  { this.user && this.user.userStatus !== UserStatus.Registered && this.user.userStatus !== UserStatus.Suspended &&
                    <DialogButton variant="tertiary" onClick={this.onResendInvite} customClassName={classes.resendButton}>
                      SEND INVITATION
                    </DialogButton>
                  }
                  { this.user && this.user.id === userStore!.user!.id &&
                    <DialogButton variant="tertiary" onClick={this.onChangeEmailOpen} customClassName={classes.resendButton}>
                      CHANGE EMAIL &nbsp;&nbsp;&nbsp;&nbsp;
                    </DialogButton>
                  }
                  { this.user && this.user.id === userStore!.user!.id &&
                    <DialogButton variant="tertiary" onClick={this.onChangePasswordOpen} customClassName={classes.resendButton}>
                      CHANGE PASSWORD
                    </DialogButton>
                  }
                  <TextFieldValidator
                    margin="dense"
                    name="phone"
                    label="Phone Number"
                    type="text"
                    variant="standard"
                    validators={{isMobilePhone:null, required:true}}
                    onChange={this.onChange}
                    value={this.values.phone}
                    fullWidth
                  />
                  {/*<div>*/}
                  {/*  <FormControlLabel*/}
                  {/*    value=""*/}
                  {/*    label="Send Email Invitation"*/}
                  {/*    labelPlacement="end"*/}
                  {/*    className={classes.checkboxLabel}*/}
                  {/*    control={*/}
                  {/*      <Checkbox name="sendInvite" color="secondary" checked={this.values.sendInvite} onChange={this.onChange}/>*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <Visible if={isEmployer}>
                    <div className={classes.label}>Role</div>
                    <RadioGroup aria-label="userRole" name="userRole" value={this.values.userRole} onChange={this.onChange} row>
                      <Visible if={isAdmin}>
                        <FormControlLabel
                          value="Admin"
                          control={<Radio color="secondary"  disabled={roleDisabled}/>}
                          label="Admin"
                          labelPlacement="end"
                        />
                      </Visible>
                      <Visible if={isAdminOrAgent}>
                        <FormControlLabel
                          value="Agent"
                          control={<Radio color="secondary"  disabled={roleDisabled}/>}
                          label="Agent"
                          labelPlacement="end"
                        />
                      </Visible>
                      <Visible if={roleDisabled}>
                        <FormControlLabel
                          value="Applicant"
                          control={<Radio color="secondary" disabled={roleDisabled}/>}
                          label="Applicant"
                          labelPlacement="end"
                        />
                      </Visible>
                      <Visible if={roleDisabled}>
                        <FormControlLabel
                          value="Candidate"
                          control={<Radio color="secondary" disabled={roleDisabled}/>}
                          label="Candidate"
                          labelPlacement="end"
                        />
                      </Visible>
                      <FormControlLabel
                        value="Employer"
                        control={<Radio color="secondary"  disabled={roleDisabled}/>}
                        label="Employer"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Student"
                        control={<Radio color="secondary" disabled={roleDisabled}/>}
                        label="Student"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    <Visible if={isEmployer || isAdminOrAgent}>
                      <div className={classes.label}>Status</div>
                      <RadioGroup aria-label="userStatus" name="userStatus" className={classes.radioGroup}
                                  value={this.values.userStatus} onChange={this.onChange} row>
                        <FormControlLabel
                          value="Inactive"
                          control={<Radio color="secondary"/>}
                          label="Inactive"
                          labelPlacement="end"
                          disabled={userId !== undefined}
                        />
                        <FormControlLabel
                          value="Invited"
                          control={<Radio color="secondary"/>}
                          label="Invited"
                          labelPlacement="end"
                          disabled={userId !== undefined}
                        />
                        <FormControlLabel
                          value="Registered"
                          control={<Radio color="secondary"/>}
                          label="Registered"
                          labelPlacement="end"
                          disabled={this.values.userStatus === "Inactive" || this.values.userStatus === "Invited"}
                        />
                        <FormControlLabel
                          value="Suspended"
                          control={<Radio color="secondary"/>}
                          label="Suspended"
                          labelPlacement="end"
                          disabled={this.values.userStatus === "Inactive" || this.values.userStatus === "Invited"}
                        />
                      </RadioGroup>
                      { this.user && this.user.userStatus !== UserStatus.Registered && this.user.userStatus !== UserStatus.Suspended &&
                      <DialogButton variant="tertiary" onClick={this.onDelete} customClassName={classes.resendButton}>
                        DELETE PERSON
                      </DialogButton>
                      }
                    </Visible>
                    <Visible if={this.values.userRole !== "Admin" && userId === undefined}>
                      <div className={classes.label}>Classes</div>
                      <FormGroup row>
                        {this.registrations.map((r: Registration) => {
                          return <FormControlLabel
                            value={r.classId}
                            control={
                              <Checkbox color="secondary" name="registration" id={r.classId}
                                        checked={r.id !== ""}
                                        disabled={r.createdAt !== undefined}
                                        onChange={this.onChange}/>}
                            label={r.class!.course!.title}
                            labelPlacement="end"
                            key={r.classId}
                          />
                        })
                        }
                      </FormGroup>
                    </Visible>
                  </Visible>

                  <DialogContentText className={classes.message}>
                    {this.message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="secondary" onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                  <DialogButton variant="primary">
                    Save
                  </DialogButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>

          {this.showEmailChange &&
            <EmailChangeDialog oldEmail={this.values.email} onClose={this.onEmailChangeClose} onChange={this.onEmailChange}/>
          }
          {this.showPasswordChange &&
            <PasswordChangeDialog onClose={this.onPasswordChangeClose}/>
          }
        </MarginRow>
      </Page>
    )
  }

  loadRegistrations = async () => {
    const { accountStore } = this.props

    const classes = await accountStore!.listClasses()

    let registrations: Registration[] = []

    if (this.user) {
      registrations = [...this.user!.registrations]

      registrations.forEach((r: Registration) => {
        const classFound = classes.find((c: Class) => c.id === r.classId)
        if (classFound) {
          r.class = classFound
        }
      })
    }

    // Add pseudo-registrations for unregistered classes
    classes.forEach((c: Class) => {
      // Check for existing registration
      if (!registrations.find((r: Registration) => { return r.class!.courseId === c.courseId})) {

        const reg = new Registration({
          id: (this.classId && this.classId === c.id) ? "checked" : "",
          accountId: c.accountId,
          classId: c.id,
          class: c,
          userId: null,
          user: undefined,
          classStatus: ClassStatus.NotStarted,
          classProgress: 0,
          lessonNumber: 1,
          lessonStatus: LessonStatus.NotStarted,
          videoProgress: 0,
          score: 0
        })
        registrations.push(reg)
      }
    })

    // Sort by course title
    registrations.sort((a: Registration, b: Registration) => {
      return a.title.localeCompare(b.title)
    })

    this.registrations = registrations
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "sendInvite") {
      this.values![name] = event.target.checked
    } else if (name === "registration") {
      this.toggleRegistration(event.target.id)
    } else {
      this.values[name] = event.target.value
    }
  }

  toggleRegistration = (id: string) => {
    const found = this.registrations.find((r: Registration) => { return r.classId === id})
    if (found) {
      if (found.id === "checked") {
        found.id = ""
      } else if (found.id === "") {
        found.id = "checked"
      }
      this.registrations = [...this.registrations]
    }
  }

  onResendInvite = async () => {
    const { accountStore, notify } = this.props
    if (!this.user || this.user.userStatus === UserStatus.Registered) {
      return
    }

    // Set to Inactive, then Invited to cause resend
    const update: UpdateUserInput = {
      id: this.user!.id,
      userStatus: UserStatus.Inactive,
    }

    let user = await accountStore!.updateUser(update)
      .catch((err: Error) => {
        notify!.show("error", err.message)
      })

    if (user) {
      update.userStatus = UserStatus.Invited
      update.invitedAt = getISODateTime()
      user = await accountStore!.updateUser(update)
        .catch((err: Error) => {
          notify!.show("error", err.message)
        })

      if (user) {
        this.user = user
        this.values.userStatus = UserStatus.Invited.toString()
        notify!.show("success", "Email invitation sent!")
      }
    }
  }

  onChangeEmailOpen = () => {
    this.showEmailChange = true
  }

  onEmailChange = (newEmail: string) => {
    this.values.email = newEmail
    this.showEmailChange = false
    this.updateEmail()
  }

  onEmailChangeClose = () => {
    this.showEmailChange = false
  }

  onChangePasswordOpen = () => {
    this.showPasswordChange = true
  }

  onPasswordChangeClose = () => {
    this.showPasswordChange = false
  }

  onDelete = async () => {
    const { accountStore, confirm, notify } = this.props

    if (this.user) {
      confirm!.show("Confirm Delete",
        `Please confirm you want to delete ${this.user!.fullName}`,
        ["Confirm", "Cancel"],
        async () => {
          const user = await accountStore!.deleteUser(this.user!.id)
            .catch((err: Error) => {
              notify!.show("error", "Error deleting person")
            })
          if (user) {
            this.onCancel()
            return true
          } else {
            return false
          }
        },
        () => {
          // Do nothing
        })
    }
  }

  onCancel = () => {
    const { userStore, accountStore } = this.props
    const accountId = accountStore!.account!.id

    if (this.classId) {
      ControlTower.route(`${Routes.account}/${accountId}/class/${this.classId}`)
    } else if (userStore!.isEmployer || userStore!.isAdminOrAgent) {
      ControlTower.route(`${Routes.account}/${accountId}/people`)
    } else {
      ControlTower.route(`${Routes.account}/${accountId}/info`)
    }
  }

  onSubmit = async () => {
    const { userId, accountStore, userStore, notify } = this.props

    const values = this.values

    if (userId) {
      Tracking.event({action: 'UpdateUser'})
      const input: UpdateUserInput = {
        id: userId!,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        email: values.email.toLowerCase(),
        phone: values.phone,
        role: UserRole[values.userRole],
        userStatus: UserStatus[values.userStatus]
      }

      let user = undefined
      if (userId === userStore!.user!.id) {
        user = await userStore!.updateUser(input)
          .catch((err: Error) => {
            notify!.show("error", "Unable to update user")
          })
      } else {
        user = await accountStore!.updateUser(input)
          .catch((err: Error) => {
            notify!.show("error", "Unable to update user")
          })
      }

      if (user) {
        // Add registrations
        await this.registerUserForClasses(user)

        notify!.show("success", "User updated!")

        this.onCancel()
      }
    } else {
      // New user
      Tracking.event({action: 'CreateUser'})
      const input: CreateUserInput = {
        accountId: accountStore!.account!.id,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        email: values.email.toLowerCase(),
        phone: values.phone,
        userStatus: UserStatus[values.userStatus],
        role: UserRole[values.userRole]
      }

      const user = await accountStore!.createUser(input)
        .catch((err: Error) => {
          notify!.show("error", err.message)
        })

      if (user) {
        notify!.show("success", "User added!")
        userStore!.createActivity(ActivityType.UserCreate, user.id)
        // Add registrations
        await this.registerUserForClasses(user)

        this.onCancel()
      }
    }

  }

  registerUserForClasses = async (user: User) => {
    this.registrations.forEach(async (r: Registration) => {
      if (r.id === "checked") {
        await this.registerUserForClass(user, r.classId)
      }
    })
  }

  registerUserForClass = async (user: User, classId: string) => {
    const { accountStore, userStore, notify} = this.props

    const classObj = await accountStore!.getClass(classId)
    if (!classObj) {
      console.log(`Class not found: ${classId}`)
      return
    }

    // Register for class
    const input: CreateRegistrationInput = {
      accountId: user.accountId,
      classId: classId,
      userId: user.id,
      classStatus: ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: 1,
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      score: 0,
      lessonsAssigned: classObj.getRegistrationLessonsAssigned()
    }

    const registration = await accountStore!.addClassRegistration(input)
      .catch(err => {
        notify!.show("warning", err.message)
      })

    if (registration) {
      userStore!.createActivity(ActivityType.ClassRegistration, registration.classId)
    }
    return registration
  }

  private updateEmail = async () => {
    const { userId, userStore, notify } = this.props

    const input: UpdateUserInput = {
      id: userId!,
      email: this.values.email.toLowerCase(),
    }

    await userStore!.updateUser(input)
        .catch((err: Error) => {
          notify!.show("error", "Unable to update user email")
        })
  }
}

export default withTheme((withStyles(styles)(UserEditPage)))