import * as React from "react";
import {
  Card, CardActionArea,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {observer} from "mobx-react";
import Account from "../../model/Account"
import TitleButton from "../../components/TitleButton";
import {isoToLocalDate} from "../../stores/StoreUtilities";
import {format} from "date-fns";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    minHeight: 80,
    padding: theme.spacing(1)
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 10px",
    display: "flex",
    flexGrow: 1
  },
  actionArea: {
    color: theme.palette.secondary.main
  },
  titleBar: {
    marginBottom: 4
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  titleButtons: {
    textAlign: "right"
  },
  details: {
    maxHeight: 24,
    overflow: "hidden",
  },
  status: {
    textAlign: "right"
  },

})

interface IAccountCardProps {
  account: Account
  onCardAction?: any
  onEdit?: any
}

@observer
class AccountCard extends React.Component<WithStyles<typeof styles> & IAccountCardProps> {

  render() {
    const { classes, account, onCardAction } = this.props
    const status = account.accountStatus.toString()
    const createdAt = format(isoToLocalDate(account.createdAt), "M/d/yyy")

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
            <Grid container justifyContent={'space-between'} direction="column">
              <Grid container justifyContent={'space-between'} direction="row" className={classes.titleBar}>
                <Grid item xs={10}>
                  {onCardAction &&
                    <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
                      <Typography variant="h6" className={classes.title}>
                        {account.name}
                      </Typography>
                    </CardActionArea>
                  }
                  {!onCardAction &&
                    <Typography variant="h6" className={classes.title}>
                      {account.name}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.titleButtons}>
                    <TitleButton title="Edit" variant="secondary" onClick={this.onEdit}/>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent={'space-between'} direction="row">
                <Grid item xs={8}>
                  <Typography className={classes.details}>
                    {account.address}&nbsp;
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.status}>
                    {createdAt}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent={'space-between'} direction="row">
                <Grid item xs={8}>
                  <Typography className={classes.details}>
                    {account.city && `${account.city},`} {account.state} {account.postalCode}&nbsp;
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.status}>
                    {status}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
        </CardContent>
      </Card>
    )
  }

  onEdit = () => {
    if (this.props.onEdit) {
      this.props.onEdit(this.props.account)
    }
  }

  onCardAction = () => {
    if (this.props.onCardAction) {
      this.props.onCardAction(this.props.account)
    }
  }
}

export default withStyles(styles)(AccountCard)
