import {computed, observable} from "mobx";
import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import {Auth} from 'aws-amplify'
import LearningAPI from '../apis/LearningAPI'
import Logger from "../components/Logger";
import User from "../model/User";
import {format, fromUnixTime, isToday} from "date-fns";
import ControlTower, {Routes} from "../components/ControlTower";
import {getISODateToday, phoneToE164Format} from "./StoreUtilities";
import * as APITypes from "../API"
import {
  AccountStatus,
  ActivityType,
  ClassStatus,
  CreateActivityInput,
  CreateRegistrationInput,
  LessonStatus, UpdateUserInput,
  UserRole,
  UserStatus
} from "../API"
import Class from "../model/Class";
import Registration from "../model/Registration";
import Tracking from "../components/Tracking";
import Activity from "../model/Activity";
import Course from "../model/Course";
import Invoice from "../model/Invoice";
import Charge from "../model/Charge";
import BillingAPI from "../apis/BillingAPI";
import Account from "../model/Account";
import Agreement from "../model/Agreement";


export const UserStoreConstants = {

  USER_ALREADY_CONFIRMED: "User cannot be confirm. Current status is CONFIRMED",
  CONFIRMATION_SUCCESS: "SUCCESS",
  USER_NOT_FOUND: "User not found",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  EMAIL_EXISTS_MESSAGE: "PreSignUp failed with error Email exists.",
  USERNAME_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  USER_NOT_CONFIRMED: "User not confirmed",
  USER_ALREADY_CONFIRMED_MESSAGE: "User is already confirmed.",
  NOT_AUTHORIZED_EXCEPTION: "NotAuthorizedException",
  USER_NOT_FOUND_EXCEPTION: "UserNotFoundException",
  CODE_MISMATCH_EXEPTION: "CodeMismatchException",
  CONDITIONAL_REQUEST_FAILED: "The conditional request failed",
  EMAIL_VERIFICATION_PENDING: "Email verification pending",
  PHONE_VERIFICATION_PENDING: "Phone verification pending",
  COMPANY_EMAIL: ""
}

export interface ICognitoAttributes {
  email?: string,
  phone_number?: string,
  "custom:account"?: string,
  "custom:role"?: string
}

export const CognitoAttribute = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  ACCOUNT: "custom:account",
  ROLE: "custom:role"
}

export const CognitoAttributeValue = {
  FALSE: "false",
  TRUE: "true"
}

export enum PermissionType {
  Read = "Read",
  Create = "Create",
  Update = "Update",
  Delete = "Delete"
}

class UserStore {
  @observable groups: string[] = []
  @observable user?: User
  @observable isLoading: boolean = false
  @observable isAdmin: boolean = false

  learningAPI: LearningAPI
  billingAPI: BillingAPI

  @observable private _cognitoUser?: CognitoUser
  get cognitoUser() {
    Logger.debug(`UserStore get cognitoUser = ${this._cognitoUser?.getUsername()}`)
    return this._cognitoUser
  }

  set cognitoUser(cognitoUser: CognitoUser | undefined) {
    Logger.debug(`UserStore set cognitoUser = ${cognitoUser?.getUsername()}`)
    this._cognitoUser = cognitoUser
  }

  get isAgent() {
    return this.user ? this.user.isAgent : false
  }

  get isAdminOrAgent() {
    return this.user !== undefined ? this.user.isAdminOrAgent : false
  }


  get isEmployer() {
    return this.user ? this.user.isEmployer : false
  }

  get isStudent() {
    return this.user ? this.user.isStudent : false
  }

  private _attributes: CognitoUserAttribute[] = []

  constructor(options: any) {
    this.learningAPI = (options && options.learningAPI) ? options.learningAPI : null
    this.billingAPI = (options && options.billingAPI) ? options.billingAPI : null
  }

  /**
   * When switching between *.govgig.us properties, 
   * we need to sign out the user if they've logged in on another property,
   * otherwise too many cookies will build up and the site won't load,
   * resulting in a 431 error. 
   */
  async signOutCurrentAuthenticatedUser() {
    try {
      const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser()
      Logger.debug('Current authenticated user', user)
      await Auth.signOut()
    } catch (err) {
      // If we see err === "No current user," then we are not logged in, which is fine. 
      Logger.debug('UserStore.signIn: ', err)
    } finally {
      this.deleteAllCookies()
    }
  }

  signUp = async (username: string, password: string, email: string, phone?: string, accountId?: string, role?: UserRole) => {
    this.cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    await this.signOutCurrentAuthenticatedUser()

    return new Promise<CognitoUser | any>((resolve, reject) => {
      const attributes = {}
      attributes[CognitoAttribute.EMAIL] = email.toLowerCase()
      if (phone) {
        attributes[CognitoAttribute.PHONE_NUMBER] = phoneToE164Format(phone)
      }
      if (accountId) {
        attributes[CognitoAttribute.ACCOUNT] = accountId
      }
      if (role) {
        attributes[CognitoAttribute.ROLE] = role
      }

      Auth.signUp({
        username,
        password,
        attributes,
        validationData: []  // optional
      })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  confirmSignUp = async (username: string, code: string, options?: any): Promise<string | any> => {
    return await Auth.confirmSignUp(username, code, options)
  }

  resendSignUp = async (username: string): Promise<string> => {
    return await Auth.resendSignUp(username)
  }

  signIn = async (username: string, password: string) => {
    this.cognitoUser = undefined
    this.user = undefined
    this._attributes = []
    
    await this.signOutCurrentAuthenticatedUser()

    return new Promise<CognitoUser | any>((resolve, reject) => {
      Auth.signIn(username.toLowerCase(), password)
        .then(async (cognitoUser: any) => {
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            Logger.debug(`Auth.signIn(${username}) = ${cognitoUser.challengeName}`)
            resolve(cognitoUser)
          } else {
            Logger.debug(`Auth.signIn(${username}) success`)
            // Load and initialize User
            this.initSession(cognitoUser)
              .then(result => {
                this.createActivity(ActivityType.UserSignIn, result.id)
                resolve(result)
              })
              .catch(async (reason: any) => {
                // await this.signInAsGuest()
                reject(reason)
              })
          }
        }).catch(err => {
        if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION &&
          err.code !== UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
          Logger.error("Auth.SignIn error.", err)
        }
        reject(err)
      })
    })
  }

  signOut = async () => {
    return new Promise<any>((resolve, reject) => {
      if (this.cognitoUser) {
        Auth.signOut()
          .then(() => {
            Logger.debug("Auth.signOut success")
            this.cognitoUser = undefined
            this.user = undefined
            this._attributes = []
            if (this.checkInterval) {
              clearInterval(this.checkInterval)
              this.checkInterval = undefined
            }
            resolve(null)
          })
          .catch(err => {
            Logger.error("Auth.signOut error", err)
            reject(err)
          })
          .finally(() => {
            this.deleteAllCookies()
          })
      }
    })
  }

  currentSession = async() => {
    Auth.currentSession()
      .then(data => {
        return data
      })
      .catch(err => {
        console.log(`Auth.currentSession err: ${JSON.stringify(err)}`)
      });
  }

  currentAuthenticatedUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
      .catch(err => {
        this.cognitoUser = undefined
      })
    if (cognitoUser) {
      this.cognitoUser = cognitoUser
      return cognitoUser
    } else {
      this.cognitoUser = undefined
      return null
    }
  }

  getUserAttribute = async (cognitoUser: any, name: string) => {
    const attributes = await Auth.userAttributes(cognitoUser ? cognitoUser : this.cognitoUser)
    const attribute = attributes.find(a => a.getName() === name)
    if (attribute) {
      return attribute.getValue()
    } else {
      return null
    }
  }

  getUserAttributes = async (cognitoUser?: any) => {
    return await Auth.userAttributes(cognitoUser ? cognitoUser : this.cognitoUser)
  }

  updateUserAttributes = async (cognitoUser: any, attributes: any) => {
    return await Auth.updateUserAttributes(cognitoUser ? cognitoUser : this.cognitoUser, attributes)
  }

  initSession = async (cognitoUser: CognitoUser) => {

    console.log("UserStore.initSession")
    this.isLoading = true

    return new Promise<CognitoUser | any>( async (resolve, reject) => {
      this.cognitoUser = cognitoUser
      await this.getCurrentSessionPayload()
      const username = cognitoUser.getUsername()
      await this.checkAuthentication()

      this.loadUser(username)
        .then((user: any) => {
          Tracking.set({userId: user.email})
          this.isLoading = false
          resolve(user)
        })
        .catch((err: any) => {
          this.isLoading = false
          reject(err)
        })
    })
  }

  async getCurrentSessionPayload() {
    const session = await Auth.currentSession()
    const authTimeValue = session.getIdToken().payload['auth_time']
    const authTime = fromUnixTime(authTimeValue)
    console.log(`User authenticated at ${format(authTime!, "M/d/yyyy h:mm:ss aa")}`)
    const groups = session.getIdToken().payload['cognito:groups']
    this.isAdmin = (groups && groups.indexOf("Admin") >= 0)
  }

  @computed get isAuthenticated() {
    const isAuthenticated = this.cognitoUser !== undefined && this.user !== undefined
    Logger.debug(`UserStore get isAuthenticated = ${isAuthenticated}`)
    return isAuthenticated
  }

  checkInterval: any

  async checkAuthentication() {
    Logger.debug("checkAuthentication")
    try {
      const session = await Auth.currentSession()
      const authTimeValue = session.getIdToken().payload['auth_time']
      const authTime = fromUnixTime(authTimeValue)

      if (!isToday(authTime)) {  // Sign-out at midnight the day authenticated
        Logger.debug("Authentication expired")
        await this.signOut()
        ControlTower.route(Routes.home)
        window.location.reload() // necessary for reconfiguring app state after logout
      }
    } catch (err) {
      Logger.error('checkAuthentication error', err)
    }

    if (!this.checkInterval) {
      // Check authentication every hour
      this.checkInterval = setInterval(this.checkAuthentication, 60 * 60000)
    }
  }

  completeNewPassword = async (user: string, newPassword: string) => {
    return await new Promise<any>((resolve, reject) => {
      Auth.completeNewPassword(user, newPassword, null)
        .then(data => {
          Logger.debug("Auth.completeNewPassword success")
          resolve(data)
        })
        .catch(err => {
          Logger.debug("Auth.completeNewPassword error", err)
          reject(err)
        });
    })
  }

  loadUser = async (username: string): Promise<User | undefined> => {
    Logger.debug(`UserStore.loadUser(${username})`)
    const data = await this.learningAPI.getUser(username)
    console.log("Loaded user")

    if (data) {
      let user = new User(data)
      if (user) {
        if (user.userStatus === UserStatus.Suspended) {
          throw new Error("User is suspended. Please contact account owner or admin.")
        } else if (user.account?.accountStatus === AccountStatus.Suspended) {
          throw new Error("Account is suspended. Please contact account owner or admin.")
        } else {
          this.user = user
          console.log("Initializing Logger")
          Logger.configureUser(this.user.id)
          Logger.info("Signed in as " + this.user.id)
          console.log("loadUser completed")
        }
      }
    } else {
      throw new Error("User not found")
    }

    return this.user
  }

  getUser = async (username: string): Promise<User | undefined> => {
    const data = await this.learningAPI.getUser(username)
    if (data) {
      return new User(data)
    } else {
      return undefined
    }
  }

  async createUser(input:  APITypes.CreateUserInput) {
    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const user = await this.learningAPI!.createUser(input)
    if (user) {
    //   // Tracking.event({action: "Create Account"})
    //   const attributes: ICognitoAttributes = {}
    //   let updateAttributes = false
    //   if (user.accountId) {
    //     attributes[CognitoAttribute.ACCOUNT] = user.accountId
    //     updateAttributes = true
    //   }
    //   if (user.role) {
    //     attributes[CognitoAttribute.ROLE] = user.role
    //     updateAttributes = true
    //   }
    //   if (updateAttributes) {
    //     await this.updateUserAttributes(null, attributes)
    //   }

      this.user = new User(user)
      this.createActivity(ActivityType.UserCreate, this.user.id)
      return this.user
    } else {
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {

    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const user = await this.learningAPI!.updateUser(input)
    if (user) {
      if (user.id === this.user!.id) {
        const updatedUser = new User(user)
        // Preserve related data
        updatedUser.account = this.user!.account
        updatedUser.registrations = this.user!.registrations
        this.user = updatedUser
        // Verify custom account user attribute
        const accountValue = await this.getUserAttribute(null, CognitoAttribute.ACCOUNT)
        if (accountValue !== user.accountId) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ACCOUNT] = user.accountId
          console.log(`Updated custom:Account Cognito attribute: ${user.accountId}`)
          await this.updateUserAttributes(null, attributes)
        }
        const roleValue = await this.getUserAttribute(null, CognitoAttribute.ROLE)
        if (roleValue !== user.role) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ROLE] = user.role
          console.log(`Updated custom:Role Cognito attribute: ${user.role}`)
          await this.updateUserAttributes(null, attributes)
        }
        return this.user
      } else {
        return new User(user)
      }
    } else {
      return null
    }
  }

  async forgotPassword(userId: string) {
    await Auth.forgotPassword(userId)
    return "SUCCESS"
  }

  async forgotPasswordSubmit(userId: string, code: string, password: string) {
    await Auth.forgotPasswordSubmit(userId, code, password)
    return "SUCCESS"
  }

  async createAgreement(input: APITypes.CreateAgreementInput) {
    const data = await this.learningAPI!.createAgreement(input)
    if (data) {
      return new Agreement(data)
    } else {
      return undefined
    }
  }

  async addClassRegistration(classObj: Class) {
    const input: CreateRegistrationInput = {
      accountId: this.user!.accountId,
      classId: classObj.id,
      userId: this.user!.id,
      classStatus: APITypes.ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: 1,
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      score: 0,
      lessonsAssigned: classObj.getRegistrationLessonsAssigned()
    }

    const result = await this.learningAPI!.createRegistration(input)
      .catch(err => {
        Logger.error("Unable to create class registration")
      })

    if (result) {
      const registration = new Registration(result)
      this.user?.registrations.push(registration)
      this.createActivity(ActivityType.ClassRegistration, registration.id)
      return registration
    } else {
      return null
    }
  }

  listRegistrations = (): Registration[] => {
    return (this.user && this.user.registrations) ? [...this.user.registrations] : []
  }

  getRegistration = (registrationId: string) => {
    const registration = this.user!.registrations.find((r: Registration) => { return r.id === registrationId})
    return registration
  }

  updateRegistration = async (input: APITypes.UpdateRegistrationInput) => {
    let registration = undefined
    const result = await this.learningAPI.updateRegistration(input)
    if (result) {
      registration = new Registration(result)
      // update cached registration
      const index = this.user!.registrations.findIndex((r: Registration) => { return r.id === input.id})
      if (index >= 0) {
        const existing = this.user!.registrations[index]
        // Preserve class
        if (existing.class) {
          // console.log("updateRegistration: preserving class")
          registration.class = existing.class
        }
        this.user!.registrations[index] = registration
      }
    }
    return registration
  }

  resetRegistration = async (id: string) => {
    const input: APITypes.UpdateRegistrationInput = {
      id: id,
      classStatus: ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: 1,
      lessonId: "",
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      answers: [],
      score: 0
    }

    return this.updateRegistration(input)
  }

  getClassRegistration = (classId: string) => {
    const registration = this.user!.registrations.find((r: Registration) => { return r.classId === classId})
    if (registration && !registration.user) {
      registration.user = this.user
    }
    return registration
  }

  // Individual Billing Related Methods

  createInvoice = async (course: Course, couponId: string | undefined, quantity: number, amount: number, tokenId: string | undefined) => {
    const user = this.user!

    const invoice = new Invoice({
      customer: user.customerId,
      collection_method: "charge_automatically",
      auto_advance: true,
      items: [
        {
          customer: user.customerId,
          unit_amount: Math.round(amount * 100),
          quantity: quantity,
          currency: "usd",
          description: course.title,
        }
      ]
    })

    if (couponId) {
      invoice.items[0].discounts = [
        {coupon: couponId}
      ]
    }

    // Mock account
    const account = new Account({
      id: user.id,
      name: user.fullName,
      address: "",
      city: "",
      state: "",
      postalCode: ""
    })

    const result = await this.billingAPI.createInvoice(user, account, invoice, tokenId)

    if (!user.customerId && result && result.customer) {
      const input: UpdateUserInput = {
        id: user.id,
        customerId: result.customer
      }
      const update = await this.updateUser(input)
      if (update) {
        this.user!.customerId = update.customerId
      }
    }

    return (result)
  }

  getCustomer = async (id: string) => {
    const result = await this.billingAPI.getCustomer(id)
    return result
  }

  deleteSource = async (sourceId: string) => {
    if (this.user!.customerId) {
      return await this.billingAPI.deleteSource(this.user!.customerId, sourceId)
    } else {
      return null
    }
  }

  // Charges methods

  listCharges = async () => {
    let charges: Charge[] = []

    if (this.user!.customerId) {
      const result = await this.billingAPI.getCharges(this.user!.customerId)
      if (result && result.data) {
        charges = result.data.map((item: any) => {
          return new Charge(item)
        })
      }
    }

    return charges
  }

  // Activity methods

  createActivity = async (activityType: ActivityType, objectId: string) => {
    const input : CreateActivityInput = {
      accountId: this.user!.accountId,
      userId: this.user!.id,
      activityDate: getISODateToday(),
      activityType: activityType,
      objectId: objectId
    }

    const activity = await this.learningAPI!.createActivity(input)
    if (activity) {
      return new Activity(activity)
    } else {
      return null
    }
  }

  // Misc methods

  deleteAllCookies = () => {
    const cookies = document.cookie.split("; ")
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".")
      while (d.length > 0) {
        const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
        let p = window.location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
  }

}

export default UserStore