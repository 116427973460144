import * as React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {observer} from "mobx-react";
import Lesson from "../../model/Lesson";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import {secondsToDuration} from "../../stores/StoreUtilities";
import Registration from "../../model/Registration";
import {LessonStatus} from "../../API";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    padding: "2px 4px 4px 4px"
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 0px",
    display: "flex"
  },
  left: {
    flexShrink: 0,
    paddingRight: 5,
    alignSelf: "center",
  },
  right: {
    flexGrow: 1
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  duration: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    float: "right",
    paddingTop: 3,
    paddingRight: 4
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3
  },
  playIcon: {
    color: theme.palette.secondary.main,
    width: 40,
    height: 40,
  },
  playIconPassed: {
    color: theme.palette.success.main,
    width: 40,
    height: 40,
  },
  playIconFailed: {
    color: theme.palette.error.dark,
    width: 40,
    height: 40,
  },
  disabledIcon: {
    color: theme.palette.primary.light,
    width: 40,
    height: 40,
  },
  disabledIconPassed: {
    color: theme.palette.success.dark,
    width: 40,
    height: 40,
  },
  disabledIconFailed: {
    color: theme.palette.error.light,
    width: 40,
    height: 40,
  },
})

interface ILessonCardProps {
  lesson: Lesson
  registration?: Registration
  disabled?: boolean
  playing?: boolean
  onPlay?: any
  onCardAction?: any
}

// @inject('lesson')
@observer
class LessonCard extends React.Component<WithStyles<typeof styles> & ILessonCardProps> {

  componentDidMount() {
  }

  render() {
    const { classes, lesson, disabled } = this.props

    let number = ""
    let title = ""
    let description = ""
    let duration = ""
    if (lesson) {
      number = lesson.number.toString()
      title = lesson.title
      description = lesson.description
      duration = secondsToDuration(lesson.videoDuration)
    }

    return (
      <Card className={classes.card}>
        <CardActionArea onClick={this.onCardAction} disabled={disabled}>
          <CardContent className={classes.content}>
            <div className={classes.left}>
              { this.renderPlayIcon() }
            </div>
            <div className={classes.right}>
              <Typography className={classes.duration}>
                {duration}
              </Typography>
              <Typography variant="h6" className={classes.title}>
                {number}.&nbsp; {title}
              </Typography>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  renderPlayIcon = () => {
    const { classes, disabled, playing, registration, lesson } = this.props
    let className = classes.disabledIcon
    const status = (registration && lesson) ? registration!.getStatus(lesson.number) : LessonStatus.NotStarted

    if (status === LessonStatus.Passed) {
      className = (disabled) ? classes.disabledIconPassed : classes.playIconPassed
    } else if (status === LessonStatus.Failed) {
      className = (disabled) ? classes.disabledIconFailed : classes.playIconFailed
    } else {
      className = (disabled) ? classes.disabledIcon : classes.playIcon
    }

    if (disabled) {
      return <PlayCircleFilledWhiteIcon className={className}/>
    } else {
      if (playing) {
        return <PauseCircleFilledIcon className={className} onClick={this.onPlay}/>
      } else {
        return <PlayCircleFilledWhiteIcon className={className} onClick={this.onPlay}/>
      }
    }
  }

  onCardAction = () => {
    const { onCardAction, lesson } = this.props

    if (onCardAction) {
      onCardAction(lesson)
    }
  }

  onPlay = (event: any) => {
    const { onPlay, lesson } = this.props
    // event.stopPropagation()

    if (onPlay) {
      onPlay(lesson)
    }
  }
}

export default withStyles(styles)(LessonCard)
