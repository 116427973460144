import LearningAPI from "../apis/LearningAPI";
import Account from "../model/Account";
import Course from "../model/Course";
import * as APITypes from "../API";
import BillingAPI from "../apis/BillingAPI";

class CourseStore {
  learningAPI: LearningAPI
  billingAPI: BillingAPI
  courseCache: Course[]

  constructor(options: any) {
    this.learningAPI = (options && options.learningAPI) ? options.learningAPI : null
    this.billingAPI = (options && options.billingAPI) ? options.billingAPI : null
    this.courseCache = []
  }

  async listCourses(filter?: APITypes.ModelCourseFilterInput) {
    try {
      if (this.courseCache.length > 0) {
        return this.courseCache
      }

      const result = await this.learningAPI.listCourses(filter)

      if (result && result.items) {
        this.courseCache = result.items.map((item: any) => new Course(item))
      }

      return [...this.courseCache]
    } catch (e) {
      throw new Error('listCourses failed')
    }
  }

  async getCourse(courseId: string) {
    const course = this.courseCache.find((c: Course) => {
      return c.id === courseId
    })

    if (course) {
      if (course.lessons.length === 0) {
        console.log(`Loading ${course.title} lessons`)
        const courseData = await this.learningAPI.getCourse(course.id)
        if (courseData && courseData.lessons && courseData.lessons) {
          course.loadLessons(courseData.lessons)
        }
      }
    }

    return course
  }

  async getCoursePrice(course: Course, account?: Account) {
    const products = await this.billingAPI.getProducts()
      .catch((err: any) => {
        console.log("Error getting stripe products")
      })
    const prices = await this.billingAPI.getPrices()
      .catch((err: any) => {
        console.log("Error getting stripe prices")
      })

    if (products && prices) {
      // console.log(JSON.stringify(products))
      // console.log(JSON.stringify(prices))

      const title = course.title.toLowerCase()
      const product = products.data.find((p: any) => p.name.toLowerCase() === title)
      if (product) {
        let price
        if (account) {
          const size = account.accountSize.toString()
          price = prices.data.find((p: any) => p.product === product.id && p.nickname === size)
        }

        if (!price) {
          price = prices.data.find((p: any) => p.product === product.id)
        }
        return price
      }
    }

    return null
  }

  async getCoupon(id: string) {
    const result = await this.billingAPI.getCoupon(id)
    return result
  }

  async createCourse(input:  APITypes.CreateCourseInput) {
    const result = await this.learningAPI!.createCourse(input)
    if (result) {
      const course = new Course(result)
      this.updateCache(course)
      return course
    } else {
      return null
    }
  }

  async updateCourse(input:  APITypes.UpdateCourseInput) {
    const result = await this.learningAPI!.updateCourse(input)
    if (result) {
      const course = new Course(result)
      this.updateCache(course)
      return course
    } else {
      return null
    }
  }

  updateCache = (course: Course) => {
    const index = this.courseCache.findIndex((c: Course) => c.id === course.id)
    if (index >= 0) {
      this.courseCache[index] = course
    } else {
      this.courseCache.push(course)
    }
  }
}

export default CourseStore