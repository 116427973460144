/* tslint:disable */
import gql from "graphql-tag";

export const getAccount = /* GraphQL */ gql`
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      createdAt
      updatedAt
      ownerId  
      name
      address
      city
      state
      postalCode
      accountType
      accountStatus
      accountSize  
      customerId  
      classes(limit: 1000) {
          items {
              id
              createdAt
              updatedAt
              courseId
              accountId
              ownerId
              userId
              termBegin
              termEnd
              seatsPurchased
              seatsFilled
              couponCode
              assignmentSchedule
          }
          nextToken
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ gql`
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        ownerId  
        name
        address
        city
        state
        postalCode
        accountType
        accountStatus
        accountSize  
        customerId
      }
      nextToken
    }
  }
`;

export const getAccountUsers = /* GraphQL */ gql`
    query GetAccount($id: ID!) {
        getAccount(id: $id) {
            id
            users(limit: 1000) {
                items {
                    id
                    createdAt
                    updatedAt
                    invitedAt
                    accountId
                    firstName
                    lastName
                    email
                    phone
                    jobTitle
                    userStatus
                    active
                    role
                    customerId
                }
                nextToken
            }
        }
    }
`;

export const getClass = /* GraphQL */ gql`
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      createdAt
      updatedAt
      courseId
      course {
        id
        createdAt
        updatedAt
        title
        description
        coverUrl
        details
        publishDate
        sunsetDate
        lessonCount
        creditHours
        isFree
        lessons
        handoutUrl
        classes {
          nextToken
        }
      }
      accountId
      ownerId
      userId  
      termBegin
      termEnd
      seatsPurchased
      seatsFilled
      couponCode
      assignmentSchedule  
      registrations(limit: 1000) {
        items {
          id
          createdAt
          updatedAt
          accountId
          classId
          userId
          classStatus
          classProgress
          lessonNumber
          lessonId
          lessonStatus
          videoProgress
          answers
          score
          startedAt
          endsAt  
          completedAt
          lessonsAssigned
          nextAssignmentAt
        }
        nextToken
      }
    }
  }
`;

export const getUser = /* GraphQL */ gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      createdAt
      updatedAt
      invitedAt  
      accountId
      firstName
      lastName
      email
      phone
      jobTitle
      userStatus
      active  
      role
      customerId
      accountId
      account {
          id
          createdAt
          updatedAt
          ownerId
          name
          address
          city
          state
          postalCode
          accountType
          accountStatus
          accountSize
          customerId
          classes {
              items {
                  id
                  createdAt
                  updatedAt
                  courseId
                  accountId
                  ownerId
                  userId
                  termBegin
                  termEnd
                  seatsPurchased
                  seatsFilled
                  couponCode
                  assignmentSchedule
              }
              nextToken
          }
      }
      registrations(limit: 1000) {
        items {
          id
          createdAt
          updatedAt
          accountId
          classId
          userId
          classStatus
          classProgress
          lessonNumber
          lessonId  
          lessonStatus  
          videoProgress
          answers
          score
          startedAt
          endsAt
          completedAt
          lessonsAssigned
          nextAssignmentAt
        }
        nextToken
      }
    }
  }
`;

// Course

export const getCourse = /* GraphQL */ gql`
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      createdAt
      updatedAt
      title
      description
      coverUrl
      details
      publishDate
      sunsetDate
      lessonCount
      creditHours
      isFree
      lessons
      handoutUrl
    }
  }
`;
export const listCourses = /* GraphQL */ gql`
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
          id
          createdAt
          updatedAt
          title
          description
          coverUrl
          details
          publishDate
          sunsetDate
          lessonCount
          creditHours
          isFree
          handoutUrl
      }
      nextToken
    }
  }
`;

// Registration

export const getRegistration = /* GraphQL */ gql`
  query GetRegistration($id: ID!) {
    getRegistration(id: $id) {
      id
      createdAt
      updatedAt
      accountId
      classId
      class {
        id
        createdAt
        updatedAt
        courseId
        accountId
        ownerId
        userId  
        termBegin
        termEnd
        seatsPurchased
        seatsFilled
        couponCode
        assignmentSchedule  
      }
      userId
      classStatus
      classProgress
      lessonNumber
      lessonId
      lessonStatus  
      videoProgress
      answers
      score
      startedAt
      endsAt
      completedAt
      lessonsAssigned
      nextAssignmentAt
        
    }
  }
`;

// Activity
export const listAccountActivity = /* GraphQL */ gql`
  query GetAccount(
      $id: ID!
      $filter: ModelActivityFilterInput
      $limit: Int
      $sortDirection: ModelSortDirection
      $nextToken: String
  ) {
    getAccount(id: $id) {
      id
      activity(filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
        items {
          id
          createdAt
          updatedAt
          accountId
          userId
          activityDate
          activityType
          objectId
          values
        }
        nextToken
      }
    }
  }
`;

export const listActivitys = /* GraphQL */ gql`
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        accountId
        userId
        activityDate
        activityType
        objectId
        values
      }
      nextToken
    }
  }
`;
export const listActivityByDate = /* GraphQL */ gql`
  query ListActivityByDate(
    $activityDate: AWSDate
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityByDate(
      activityDate: $activityDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        accountId
        userId
        activityDate
        activityType
        objectId
        values
      }
      nextToken
    }
  }
`;