import {Account} from "./Account";
import Registration from "./Registration";
import {UserRole, UserStatus} from "../API";


class User {
  id: string
  createdAt: string
  updatedAt: string
  active: boolean
  invitedAt: string
  accountId: string
  account?: Account
  firstName: string
  lastName: string
  email: string
  phone: string
  jobTitle: string
  userStatus: UserStatus
  role: UserRole
  registrations: Registration[]
  customerId: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.active = data.active
    this.invitedAt = data.invitedAt
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.phone = data.phone
    this.jobTitle = data.jobTitle
    if (data.userStatus) {
      this.userStatus = data.userStatus
    } else if (data.active) {
      this.userStatus = UserStatus.Registered
    } else {
      this.userStatus = UserStatus.Inactive
    }
    this.role = data.role
    this.registrations = []
    if (data.registrations && data.registrations.items) {
      this.loadRegistrations(data.registrations.items)
    }
    this.customerId = data.customerId
  }

  loadRegistrations(items: any[]) {
    this.registrations = items.map((item: any) => {
      const r = new Registration(item)
      r.user = this
      return r
    })
  }

  get fullName(): string {
    return `${this.lastName}, ${this.firstName}`
  }

  get isStudent(): boolean {
    return !this.role || this.role === UserRole.Student || this.role === UserRole.Candidate || this.role === UserRole.Applicant
  }

  get isActive(): boolean {
    return this.userStatus === UserStatus.Registered
  }

  get isAdmin(): boolean {
    return this.role === UserRole.Admin || this.role === UserRole.Agent
  }

  get isAgent(): boolean {
    return this.role === UserRole.Agent
  }

  get isAdminOrAgent(): boolean {
    return this.role === UserRole.Admin || this.role === UserRole.Agent
  }
  get isEmployer(): boolean {
    return this.role === UserRole.Employer
  }
}

export default User