import * as React from 'react'
import {createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import Class from "../../model/Class";
import ClassCard from "../class/ClassCard";
import CourseStore from "../../stores/CourseStore";
import Course from "../../model/Course";
import Notify from "../../components/notify/Notify";
import ControlTower, {Routes} from "../../components/ControlTower";
import Tracking from "../../components/Tracking";
import {getISODateToday} from "../../stores/StoreUtilities";
import Visible from "../../components/Visible";
import Registration from "../../model/Registration";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(1),
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  subtitle: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
})

interface IAccountClassesProps {
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "courseStore", "progress", "notify")
@observer
class AccountClasses extends React.Component<WithStyles<typeof styles> & IAccountClassesProps & WithTheme> {

  @observable account?: Account
  @observable isLoading = true
  @observable classes: Class[] = []
  @observable current: Class[] = []
  @observable available: Class[] = []
  @observable past: Class[] = []

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountClasses")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading,
      () => {
        this.account = accountStore!.account
        this.loadClasses()
        this.isLoading = false
        progress!.hide("AccountClasses")
      }
    )
  }

  render() {
    const { classes, userStore } = this.props

    if (this.isLoading) {
      return null
    }

    const isAdminOrAgent = userStore!.isAdminOrAgent
    const isEmployer = userStore!.isEmployer

    return (
      <div className={classes.root}>
        <Grid container className={classes.content} direction="row" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.subtitle}>Purchased</Typography>
          </Grid>
          {this.current.map((c: Class) => {
            return (
              <Grid item sm={6} xs={12} className={classes.card} key={c.id}>
                <ClassCard classObj={c} onAddClass={this.onAddClass}
                           onCardAction={c.id ? this.onCardAction : null}/>
              </Grid>
            )
          })}
        </Grid>

        <Grid container className={classes.content} direction="row" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.subtitle}>Available</Typography>
          </Grid>
          {this.available.map((c: Class) => {
            return (
              <Grid item sm={6} xs={12} className={classes.card} key={c.id}>
                <ClassCard classObj={c} onAddClass={this.onAddClass}
                           onCardAction={this.onCardAction}/>
              </Grid>
            )
          })}
        </Grid>

        <Visible if={this.past.length > 0}>
          <Grid container className={classes.content} direction="row" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.subtitle}>Ended</Typography>
            </Grid>
            {this.past.map((c: Class) => {
              return (
                <Grid item sm={6} xs={12} className={classes.card} key={c.id}>
                  <ClassCard classObj={c} onAddClass={this.onAddClass}
                             onCardAction={(isEmployer || isAdminOrAgent) && c.id ? this.onCardAction : null}/>
                </Grid>
              )
            })}
          </Grid>
        </Visible>
      </div>
    )
  }

  private async loadClasses() {
    const { accountStore, courseStore, userStore } = this.props

    const isStudent = userStore!.isStudent

    let classes = await accountStore?.listClasses()
    if (!classes) {
      classes = []
    }

    // Divide into current and past classes
    let current: Class[] = []
    let past: Class[] = []
    let today = getISODateToday()
    classes.forEach((c: Class) => {
      // Ignore classes belonging to other students
      if ((!isStudent && !c.userId) || (isStudent && (c.userId === userStore!.user!.id || userStore!.getClassRegistration(c.id)))) {
        if (c.termEnd >= today) {
          current.push(c)
        } else {
          past.push(c)
        }
      }
    })

    // Sort by title
    current.sort((a: Class, b: Class) => a.course!.title.localeCompare(b.course!.title))

    // Sort by term
    past.sort((a: Class, b: Class) => a.termBegin.localeCompare(b.termBegin))

    // Add available courses
    let available: Class[] = []
    const courses = await courseStore!.listCourses()
    if (courses) {
      courses.forEach((course: Course) => {
        // CHeck for current class
        if (course.publishDate <= today && course.sunsetDate >= today) {
          const found = current.find((c: Class) => c.courseId === course.id && c.termEnd > today)
          if (!found) {
            // Make a pseudo class for purchasing
            const classObj = new Class({
              id: null,
              courseId: course.id,
              course: course
            })
            available!.push(classObj)
          }
        }
      })
    }

    // Sort by title
    available.sort((a: Class, b: Class) => a.course!.title.localeCompare(b.course!.title))

    this.current = current
    this.past = past
    this.available = available
  }

  private onAddClass = async (courseId: string) => {
    const { accountStore, courseStore } = this.props
    Tracking.event({action: 'AddClass'})

    const course = await courseStore!.getCourse(courseId)
    if (course) {
      // if (course.isFree) {
      //   const classObj = await accountStore!.addClass(course)
      //   if (classObj) {
      //     // Add registration
      //     await userStore!.addClassRegistration(classObj)
      //     // Reload classes
      //     await this.loadClasses()
      //     notify!.show("success", `${course.title} class added!`)
      //     ControlTower.route(`${Routes.account}/${classObj.accountId}/class/${classObj.id}`)
      //   }
      // } else {
        ControlTower.route(`${Routes.account}/${accountStore!.account!.id}/classEdit?courseId=${course.id}`)
      // }
    }
  }

  private onCardAction = (action: string, classObj: Class) => {
    const { userStore } = this.props

    const isStudent = userStore!.isStudent

    if (action === "open") {
      if (classObj.id) {
        if (isStudent) {
          const registration = userStore!.user!.registrations.find((r: Registration) => { return r.classId === classObj.id})
          if (registration) {
            ControlTower.route(`${Routes.class}/${registration.classId}`)
          }
        } else {
          ControlTower.route(`${Routes.account}/${this.account!.id}/class/${classObj.id}`)
        }
      } else if (classObj.courseId) {
        ControlTower.route(`${Routes.courseDetail}/${classObj.courseId}`)
      }
    } else if (action === "edit") {
      ControlTower.route(`${Routes.account}/${this.account!.id}/classEdit/${classObj.id}`)
    }
  }
}

export default withTheme((withStyles(styles)(AccountClasses)))