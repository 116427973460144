import * as React from 'react'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MarginRow from "./MarginRow";
import {alpha, Button, Hidden, isWidthDown, LinearProgress, Typography, WithWidth} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import logo from '../../images/header_logo_640x192.png'
import ControlTower, {Routes} from "../../components/ControlTower";
import SimpleMenu from "./SimpleMenu";
import config from "react-global-configuration";
import UserStore from "../../stores/UserStore";
import Progress from "../Progress";
import Tracking from "../Tracking";
import MenuItem from "@material-ui/core/MenuItem";
import PopperMenuItem from "./PopperMenuItem";
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {observable} from "mobx";
import withWidth from "@material-ui/core/withWidth";

const styles = (theme: Theme) => createStyles({
  appBar: {
    top: 0,
    backgroundColor: theme.palette.primary.contrastText,
    height: 76,
    maxHeight: 76,
  },
  toolBar: {
    minHeight: 76,
    height: 76
  },
  grow: {
    flexGrow: 1,
  },
  linkStyle: {
    marginBottom: '-5px',
    marginLeft: 5
  },
  appLogo: {
    height: 50, 
    width: 'auto'
  },
  button: {
    margin: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: 16,
    padding: theme.spacing(2)
  },
  activeButton: {
    margin: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    fontSize: 16,
    padding: theme.spacing(2),
    // border: `2px solid ${theme.palette.secondary.main}`,
    // borderRadius: 0,
  },
  input: {
    display: 'none',
  },
  title: {
    color: theme.palette.primary.contrastText,
    flexGrow: 1,
    paddingLeft: theme.spacing(1)
  },
  icon: {
    height: 20,
    width: 20,
  },
  progressWrapper: {
    flexGrow: 1
  },
  progress: {
    height: 3,
    backgroundColor: theme.palette.secondary.main
  },
  productTitle: {
    paddingLeft: theme.spacing(2),
    fontWeight: 500
  }
})

interface IHeaderProps {
  userStore?: UserStore
  progress?: Progress
}

@inject("userStore", "progress")
@observer
class Header extends React.Component<WithStyles<typeof styles> & WithWidth & IHeaderProps> {
  @observable anchorEl: Element | null = null

  render() {
    const {classes, userStore,  progress} = this.props

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <MarginRow>
          <Toolbar className={classes.toolBar} disableGutters={ true }>
            <a href={config.get('homeUrl')} className={classes.linkStyle}>
              <img src={logo} className={classes.appLogo} alt="logo" />
            </a>
            <Typography color="primary" className={classes.productTitle}>Academy</Typography>
            <Typography variant="h6" color="inherit" className={classes.grow} />
            <Hidden xsDown>
              {this.renderMenuItems()}
            </Hidden>
            <Hidden smUp>
              {userStore!.user &&
                <SimpleMenu />
              }
              {!userStore!.user &&
                <React.Fragment></React.Fragment>
              }
            </Hidden>
          </Toolbar>
        </MarginRow>
        <div className={classes.progressWrapper}>
          { <LinearProgress hidden={ !progress!.isVisible } className={ classes.progress } /> }
        </div>
      </AppBar>

    )
  }

  renderMenuItems = () => {
    const {
      classes,
      userStore
    } = this.props

    if (!userStore || !userStore.isAuthenticated) {
      return null
    }

    const currentPath = ControlTower.currentRoute
    const isXS = false // isWidthDown('sm', width)

    const menuItems = []
    // menuItems.push(
    //   <Button
    //     key="0"
    //     color="primary"
    //     aria-label="Home"
    //     className={classes.button}
    //     onClick={this.onClickHome}
    //   >
    //     Home
    //   </Button>
    // )

    if (userStore.isAdminOrAgent) {
      menuItems.push(
        <Button
          key="1"
          color="primary"
          aria-label="Accounts"
          className={currentPath === Routes.accounts ? classes.activeButton : classes.button}
          onClick={this.onClickAccounts}
        >
          Accounts
        </Button>
      )
      menuItems.push(
        <Button
          key="2"
          color="primary"
          aria-label="Reports"
          className={currentPath === Routes.reports ? classes.activeButton : classes.button}
          onClick={this.onClickReports}
        >
          Reports
        </Button>
      )
      menuItems.push(
        <Button
          key="3"
          color="primary"
          aria-label="Courses"
          className={currentPath.startsWith(Routes.courses) ? classes.activeButton : classes.button}
          onClick={this.onClickCourses}
        >
          Courses
        </Button>
      )
    } else {
      menuItems.push(
        <Button
          key="2"
          color="primary"
          aria-label="My Classes"
          className={currentPath === Routes.myClasses ? classes.activeButton : classes.button}
          onClick={this.onClickMyClasses}
        >
          My Classes
        </Button>
      )
    }

    menuItems.push(
      <Button key="5"
              color="primary"
              aria-label="Account"
              className={classes.button}
              onClick={this.onClickFindJobs}
      >
        Find Jobs
      </Button>)

    menuItems.push(
      <Button 
        key="6"
        color="primary"
        aria-label="Account"
        className={classes.button}
        onClick={() => {
          const jobsUrl = config.get("jobsUrl")
          if (jobsUrl) {
            ControlTower.open(`${jobsUrl}/create-job-posts`, "_self")
          }
        }}
      >
        Post Jobs
      </Button>)

    menuItems.push(
      <PopperMenuItem
        key='Account'
        button={(props) => this.getMenuItemButton(
          isXS ? 'Account' : <AccountCircleIcon />,
          "popper",
          undefined,
          props,
          true
        )}
        menuList={[
          <MenuItem
            key='Account'
            onClick={() => {
            this.onClickAccount(undefined)
          }}>
            Account
          </MenuItem>,
          <MenuItem
            key='Log Out'
            onClick={async () => {
              this.onClickSignOut(undefined)
            }}>
            Log Out
          </MenuItem>,
        ]}
        onClick={async (e) => {
          this.handleClose()
        }}
      />
    )

    return menuItems
  }

  getMenuItemButton = (label: string | React.ReactNode, key: string, route: string | undefined, otherProps: any = {}, renderArrow: boolean = false) => {
    const { classes, width } = this.props
    const currentPath = ControlTower.currentRoute
    const isActive = (
      (route === Routes.home && currentPath === route) // home route special case
      || (route !== Routes.home && route && String(currentPath).includes(route))
    )

    const Comp: any = isWidthDown('sm', width) ? MenuItem : Button

    return (
      <Comp
        key={key}
        button
        color="primary"
        aria-label={label}
        className={isActive ? classes.activeButton : classes.button}
        {...otherProps}
        onClick={async (e: React.MouseEvent) => {
          // Tracking.event({ category: 'Navigation', action: tracking })
          if (otherProps.onClick) {
            await otherProps.onClick(e)
          }
          if (route) {
            ControlTower.route(route)
          }
        }}>
        {label} {renderArrow && <ArrowDropDownIcon />}
      </Comp>
    )
  }

  get accountId(): string | undefined {
    const { userStore } = this.props
    if (userStore!.user && userStore!.user.account) {
      return userStore!.user!.account.id
    } else {
      return undefined
    }
  }

  handleClose = () => {
    this.anchorEl = null
  }

  onClickAccount = (event: any) => {
    ControlTower.route(`${Routes.account}/${this.accountId}`)
  }

  onClickAccounts = (event: any) => {
    ControlTower.route(Routes.accounts)
  }

  onClickReports = (event: any) => {
    ControlTower.route(Routes.reports)
  }

  onClickFindJobs = () => {
    const jobsUrl = config.get("jobsUrl")
    if (jobsUrl) {
      ControlTower.open(`${jobsUrl}/find-jobs`, "_self")
    }
  }

  onClickHome = (event: any) => {
    ControlTower.route(Routes.home)
  }

  onClickMyClasses = (event: any) => {
    ControlTower.route(Routes.myClasses)
  }

  onClickCourses = (event: any) => {
    ControlTower.route(Routes.courses)
  }

  onClickSignOut = async (event: any) => {
    Tracking.event({ category: 'Navigation', action: "UserLogOut" })
    const homeUrl = config.get("homeUrl")
    await this.props.userStore!.signOut()
    ControlTower.route(homeUrl)
  }
}

export default withStyles(styles)(withWidth()(Header))