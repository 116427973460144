import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import {Grid} from "@material-ui/core";
import SignInDialog from "../../components/auth/SignInDialog";
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import ControlTower, {Routes} from "../../components/ControlTower";
import config from 'react-global-configuration';
import Progress from "../../components/Progress";
import Logger from "../../components/Logger";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import MarginRow from "../../components/page/MarginRow";
import Page from "../../components/page/Page";
import PasswordAssistDialog from "../../components/auth/PasswordAssistDialog";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    width: "100%",
    flex: "auto",
    overflowY: "auto",
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  signInDialog: {
  },

})

interface IHomePageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  route?: string
}

@inject("userStore", "accountStore", "progress")
@observer
class HomePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IHomePageProps & WithTheme> {

  @observable isLoading = true
  @observable showPasswordAssist: boolean = false

  async componentDidMount () {
    const { userStore, accountStore, progress } = this.props

    when(
      () => !userStore!.isLoading && !userStore!.isAuthenticated,
      async () => {
        console.log("HomePage looking for current authenticated user")
        userStore!.isLoading = true
        this.isLoading = true
        const cognitoUser = await userStore!.currentAuthenticatedUser()
        if (cognitoUser) {
          progress!.show("HomePage")
          console.log("initializing current user")
          // Load and initialize User
          await userStore!.initSession(cognitoUser)
            .then(async (result: any) => {
              console.log("Reloaded user from cache")
              const account = userStore!.user!.account
              if (account) {
                await accountStore!.init(account)
              }
              this.isLoading = false
              progress!.hide("HomePage")
              // if (userStore!.isAdmin) {
              //   ControlTower.route(Routes.accounts)
              // }
            })
            .catch(async (reason: any) => {
              this.isLoading = false
              console.log("Unable to load user from cache")
              progress!.hide("HomePage")
            })
        } else {
          console.log("No existing authenticated user found")
          userStore!.isLoading = false
          this.isLoading = false
        }
        Logger.info("App started")
      }
    )

    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        console.log("HomePage completed loading user")
        this.isLoading = false
        progress!.hide("HomePage")
        if (this.props.route) {
          console.log(`HomePage routing to ${this.props.route}`)
          ControlTower.route(this.props.route)
        } else {
          console.log(`HomePage routing to ${Routes.myClasses}`)
          ControlTower.route(Routes.myClasses)
        }

      }
    )

  }

  render() {
    const { classes, userStore } = this.props

    const isAuthenticated = userStore!.isAuthenticated

    let title = "Sign In"
    if (isAuthenticated) {
      title = (this.showPasswordAssist) ? "Password Assist" : "My Classes"
    }

    return (
      <Page title={title}>
        <MarginRow>
            <Grid container className={classes.root} direction="column">
              {!this.isLoading && !isAuthenticated && !this.showPasswordAssist &&
                  <Grid item className={classes.signInDialog}>
                    <SignInDialog
                      onSignIn={this.onSignIn}
                      onSignUp={this.onSignUp}
                      onClose={this.onSignInClose}
                      onShowPasswordAssist={this.onShowPasswordAssist}
                    />
                  </Grid>
                }
                {this.showPasswordAssist &&
                  <Grid item className={classes.signInDialog}>
                    <PasswordAssistDialog
                      onSignIn={this.onSignIn}
                      onClose={() => {this.showPasswordAssist = false}}
                    />
                  </Grid>
                }
            </Grid>
        </MarginRow>
      </Page>
    )
  }

  onSignIn = async () => {
    const { userStore, accountStore, route } = this.props

    this.showPasswordAssist = false

    const account = userStore!.user!.account
    if (account) {
      await accountStore!.init(account)

      if (route) {
        ControlTower.route(route)
      } else {
        if (userStore!.isAdminOrAgent) {
          ControlTower.route(Routes.accounts)
        } else if (userStore!.isEmployer) {
          ControlTower.route(`${Routes.account}/${account!.id}`)
        } else {
          ControlTower.route(Routes.myClasses)
        }
      }
    }
  }

  onSignUp = async () => {
    const { route } = this.props

    if (route) {
      const state = {
        route: route
      }
      ControlTower.route(Routes.signup, { state, replace:false})
    } else {
      ControlTower.route(Routes.signup)
    }
  }

  onSignInClose = () => {
    window.location.href = config.get('homeUrl')
  }

  onShowPasswordAssist = () => {
    this.showPasswordAssist = true
  }

}

export default withTheme((withStyles(styles)(HomePage)))