import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText, FormControlLabel, FormGroup,
  Grid,
  Paper, Radio, RadioGroup,
  TextField,
  Theme, Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {Link, RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import DialogButton from "../../components/form/DialogButton";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import UserStore, {UserStoreConstants} from "../../stores/UserStore";
import User from "../../model/User";
import {
  AccountSize,
  AccountStatus,
  ActivityType, AgreementType,
  CreateAccountInput, CreateAgreementInput,
  CreateUserInput,
  UserRole,
  UserStatus
} from "../../API";
import Visible from "../../components/Visible";
import AccountStore, {AccountStoreConstants} from "../../stores/AccountStore";
import ProgressButton from "../../components/form/ProgressButton";
import Class from "../../model/Class";
import {createUUID} from "../../stores/StoreUtilities";
import PrivacyPolicy from "../../components/page/PrivacyPolicy";
import TermsOfUse from "../../components/page/TermsOfUse";
import CheckboxValidator from "../../components/form/CheckboxValidator";
import Tracking from "../../components/Tracking";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 480,
    height: 40,
    padding: theme.spacing(1),
  },
  message: {
    color: theme.palette.error.main,
    fontSize: 18,
    marginTop: 10
  },
  instructions: {
    color: theme.palette.text.primary,
    fontSize: 18,
    marginTop: 10
  },
  dialogPaper: {
    maxWidth: 480,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: "0px 0px 10px 10px"
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
    textAlign: "left"
  },
  formGroup: {
    marginBottom: 0,
    paddingBottom: 0
  },
  radioGroup: {
    marginBottom: 0,
    paddingBottom: 0
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  },
  termsOfUseText: {
    // color: '#252525',
    color: theme.palette.text.secondary,
    fontSize: '13px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 36,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 22,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 36,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 40,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active
  },

})

enum AccountType {
  Individual = "Individual",
  Company = "Company",
}

interface ISignUpPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  notify?: Notify
  privacyPolicy?: PrivacyPolicy
  termsOfUse?: TermsOfUse
  location?: any
  // promo?: string
}

@inject("userStore", "accountStore", "notify", "privacyPolicy", "termsOfUse")
@observer
class SignUpPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ISignUpPageProps & WithTheme> {

  @observable values = {
    accountType: "Individual",
    accountSize: "Small",
    accountName: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    password: "",
    phone: "",
    userId: "",
    accountId: "",
    role: UserRole.Employer,
    confirmationCode: "",
    termsCheckbox: false,
    route: undefined
  }
  @observable user?: User
  @observable isConfirming: boolean = false
  @observable isProcessing: boolean = false
  @observable message = ""

  componentDidMount () {
    this.values.userId = createUUID()
    this.values.accountId = createUUID()
    if (this.props.location && this.props.location.state && this.props.location.state.route) {
      this.values.route = this.props.location.state.route
      console.log(`SignupPage route: ${this.values.route}`)
    }
  }

  render() {
    const { classes } = this.props

    const title = "GovGig Academy Sign Up"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator 
                onSubmit={this.onSubmit} 
                autoComplete="off" 
                name="academySignUpForm" 
                id="academySignUpForm"
              >
                <DialogContent className={classes.dialogContent}>
                  <Visible if={!this.isConfirming}>
                    <DialogContentText>
                      <b>IMPORTANT:</b> If you are part of a company with an account, you should request an invitation and not create a new account.
                    </DialogContentText>
                    <FormGroup className={classes.formGroup}>
                      <label className={classes.fieldLabel}>Account Type</label>
                        <RadioGroup aria-label="accountType" name="accountType" value={this.values.accountType} onChange={this.onChange} row>
                          <FormControlLabel
                            value="Individual"
                            control={<Radio color="secondary" />}
                            label="Individual"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="Company"
                            control={<Radio color="secondary" />}
                            label="Company"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                    </FormGroup>
                    {this.values.accountType === AccountType.Company &&
                      <TextFieldValidator
                        margin="dense"
                        name="accountName"
                        label="Company Name"
                        type="text"
                        variant="standard"
                        validators={{required: this.values.accountType === "Company"}}
                        onChange={this.onChange}
                        value={this.values.accountName}
                        fullWidth
                        placeholder=""
                      />
                    }
                    <TextFieldValidator
                      margin="dense"
                      name="firstName"
                      label="First Name"
                      type="text"
                      variant="standard"
                      validators={{required:true}}
                      onChange={this.onChange}
                      value={this.values.firstName}
                      fullWidth
                      placeholder=""
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="lastName"
                      label="Last Name"
                      type="text"
                      variant="standard"
                      validators={{required:true}}
                      onChange={this.onChange}
                      value={this.values.lastName}
                      fullWidth
                      placeholder=""
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="jobTitle"
                      label="Job Title"
                      type="text"
                      variant="standard"
                      validators={{}}
                      onChange={this.onChange}
                      value={this.values.jobTitle}
                      fullWidth
                      placeholder=""
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="email"
                      id="EmailInput"
                      label="Email"
                      variant="standard"
                      placeholder=""
                      type="text"
                      validators={{required:true, isEmail: true}}
                      value={this.values.email}
                      onChange={this.onChange}
                      fullWidth
                      autoCorrect="off"
                      autoCapitalize="off"
                      autoComplete="on"
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="password"
                      label="Password"
                      type="password"
                      variant="standard"
                      validators={{required:true, isStrongPassword:3}}
                      value={this.values.password}
                      onChange={this.onChange}
                      fullWidth
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="phone"
                      label="Phone Number"
                      type="text"
                      variant="standard"
                      validators={{isMobilePhone:null, required:true}}
                      onChange={this.onChange}
                      value={this.values.phone}
                      fullWidth
                    />
                    <FormControlLabel style={{marginRight: 0}}
                                      control={
                                        <CheckboxValidator name="termsCheckbox" color="secondary" checked={this.values.termsCheckbox} value="checkedG" onChange={this.onChange}/>
                                      }
                                      label={
                                        <Typography className={ classes.termsOfUseText }>
                                          I agree with the <Link to="." onClick={this.onClickTermsOfUse} className={classes.link}>Terms of Use</Link> and <Link to="." onClick={this.onClickPrivacyPolicy} className={classes.link}>Privacy Policy</Link>.&nbsp;&nbsp;
                                          I give permission to communicate with me via email, text or phone.&nbsp;&nbsp;
                                        </Typography>
                                      }
                    />
                  </Visible>

                  <DialogContentText className={classes.message}>
                    {this.message}
                  </DialogContentText>

                  <Visible if={this.isConfirming}>
                    <Grid item className={classes.message}>
                      <Typography variant="body2" className={classes.instructions}>
                        Please check your email for a verification code.
                      </Typography>
                    </Grid>
                      <TextFieldValidator
                        margin="dense"
                        name="confirmationCode"
                        label="Verification Code"
                        type="text"
                        variant="standard"
                        value={this.values.confirmationCode}
                        validators={{required:true, matches:"^\\d{6}$"}}
                        onChange={this.onChange}
                        fullWidth
                      />
                    <DialogButton variant="tertiary" onClick={this.onResendCode}>
                      Resend verification code
                    </DialogButton>
                  </Visible>

                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="secondary" onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                  <ProgressButton variant="contained" color="secondary" fullWidth={false}
                                  type="button" onClick={this.onSubmit} processing={this.isProcessing}>
                    { this.isConfirming ? "Verify" : "Next" }
                  </ProgressButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "termsCheckbox") {
      this.values![name] = event.target.checked
    } else  {
      this.values[name] = event.target.value
    }
  }

  onCancel() {
    ControlTower.back()
  }

  onSubmit = async () => {
    const {userStore, accountStore, notify} = this.props
    const values = this.values

    if (!this.isConfirming) {
      // Sign-up user
      this.isProcessing = true

      if (!values.termsCheckbox) {
        notify!.show("error", "You must check the Terms box to indicate you agree with the Terms of Use and Privacy Policy.")
        return
      }

      if (values.accountType === AccountType.Individual) {
        values.accountId = AccountStoreConstants.PRIMARY_ACCOUNT_ID
      } else {
        values.accountId = createUUID()
      }

      const signupResult = await userStore!.signUp(values.userId, values.password, values.email.toLowerCase(), undefined,
        values.accountId, values.role)
        .catch((err: Error) => {
          let message
          if (err.name === UserStoreConstants.USERNAME_EXISTS_EXCEPTION || err.message === UserStoreConstants.EMAIL_EXISTS_MESSAGE) {
            message = "This email is already in use."
            Tracking.event({action: 'SignupError', label:"Email in use"})
          } else {
            message = `Unable to create user. ${err.message}`
            Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
          }
          notify!.show("error", message)
          this.isProcessing = false
        })
      if (signupResult) {
        this.isConfirming = true
      }
      this.isProcessing = false
    } else if (this.isConfirming) {
      this.isProcessing = true

      const confirmation = await userStore!.confirmSignUp(values.userId, values.confirmationCode)
        .catch((err: Error) => {
          notify!.show("error", "Unable to verify account")
          Tracking.event({action: 'SignupError', label: `Unable to verify account: ${err.message}`})
          this.isProcessing = false
        })

      if (confirmation === "SUCCESS") {
        // Sign in
        let user = await userStore!.signIn(values.userId, values.password)
          .catch((err: Error) => {
            if (err.message !== UserStoreConstants.USER_NOT_FOUND) {
              notify!.show("error", "Unable to log in to account")
              Tracking.event({action: 'SignupError', label: `Unable to log into account: ${err.message}`})
              this.isProcessing = false
            }
          })

        if (!user) {
          // Create user
          const createUserInput: CreateUserInput = {
            id: values.userId,
            userStatus: UserStatus.Registered,
            accountId: values.accountId,
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: values.jobTitle,
            email: values.email.toLowerCase(),
            phone: values.phone,
            role: values.accountType === AccountType.Company ? UserRole.Employer : UserRole.Student,
          }

          user = await userStore!.createUser(createUserInput)
            .catch((err: Error) => {
              notify!.show("error", "Unable to create user")
              Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
              this.isProcessing = false
            })

          if (user && values.accountType !== AccountType.Individual) {
            // Create account
            const createAccountInput: CreateAccountInput = {
              id: values.accountId,
              ownerId: user.id,
              name: values.accountName,
              accountStatus: AccountStatus.Active,
              // TODO: Remove accountSize
              accountSize: values.accountType === AccountType.Individual ? AccountSize.Small : AccountSize[values.accountSize]
            }

            const account = await accountStore!.createAccount(createAccountInput)
              .catch((err: Error) => {
                notify!.show("error", "Unable to create account")
                Tracking.event({action: 'SignupError', label: `Unable to create account: ${err.message}`})
                this.isProcessing = false
              })

            if (account) {
              if (user) {
                // Sign in again to get updated attributes and groups
                user = await userStore!.signIn(values.email.toLowerCase(), values.password)
                  .catch((err: Error) => {
                    notify!.show("error", "Unable to sign in new user")
                    Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                    this.isProcessing = false
                  })

                if (user) {
                  this.user = user
                  userStore!.createActivity(ActivityType.AccountCreate, account.id)
                  const classObj = await accountStore?.addFreeClass()
                    .catch(error => {
                      notify!.show("error", "Unable to add a class")
                      Tracking.event({action: 'SignupError', label: `Unable to add a class: ${error.message}`})
                    })
                  if (classObj) {
                    await this.addFreeRegistration()
                      .catch(error => {
                        notify!.show("error", "Unable to register for a class")
                        Tracking.event({
                          action: 'SignupError',
                          label: `Unable to register for a class: ${error.message}`
                        })
                      })
                  }
                }
              }
            }
          } else {
            user = await userStore!.signIn(values.email.toLowerCase(), values.password)
              .catch((err: Error) => {
                notify!.show("error", "Unable to sign in new user")
                Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                this.isProcessing = false
              })

            if (user && user.account) {
              await accountStore!.init(user.account)
            }
          }

          this.isProcessing = false
          if (user) {
            // Add agreement
            const agreementInput: CreateAgreementInput = {
              userId: user.id,
              accountId: user.accountId,
              agreementTypes: [
                AgreementType.PrivacyPolicy,
                AgreementType.TermsOfUse
              ]
            }

            const agreement = await userStore!.createAgreement(agreementInput)

            if (!agreement) {
              notify!.show("error", "Error saving agreement")
              return
            }

            notify!.show("success", "Welcome to GovGig Academy!")
            Tracking.event({action: 'SignupSuccess'})
            if (values.route) {
              ControlTower.route(values.route)
            } else if (user.role === UserRole.Employer) {
              ControlTower.route(`${Routes.account}/${user.accountId}/classes`)
            } else {
              ControlTower.route(Routes.myClasses)
            }
          }
        }
      }
    }
  }

  onResendCode = () => {
    const { userStore, notify} = this.props

    userStore!.resendSignUp(this.values.userId)
      .then((result: any) => {
        notify!.show("success", "Verification code resent")
        Tracking.event({action: 'SignupResendCode'})
      })
      .catch((err: Error) => {
        notify!.show("error", "Unable to resend verification code")
        Tracking.event({action: 'SignupError', label: `Unable to resend verification code: ${err.message}`})
      })
  }

  addFreeRegistration = async () => {
    const { accountStore, userStore, notify } = this.props
    const classes = await accountStore!.listClasses()
    const freeClass = classes.find((classObj: Class) => {
      return classObj.course?.isFree
    })
    if (freeClass) {
      await userStore!.addClassRegistration(freeClass)
        .catch(error => {
          notify!.show("error", "Unable to register for a class")
          Tracking.event({action: 'SignupError', label: `Unable to register for a class: ${error.message}`})
        })
    } else {

    }
  }

  onClickPrivacyPolicy = () => {
    const { privacyPolicy } = this.props
    if (privacyPolicy) {
      privacyPolicy.show()
    }
  }

  onClickTermsOfUse = () => {
    const { termsOfUse } = this.props
    if (termsOfUse) {
      termsOfUse.show()
    }
  }
}

export default withTheme((withStyles(styles)(SignUpPage)))
