import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import {Logger} from "../components/Logger";

class CertificateAPI {

  create = async (certificateName: string, formData: any) => {

    const init = {
      responseType: 'application/json',
      body: {
        certificateName: certificateName,
        formData: formData
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    try {
      const response = await API.post("CertificateAPI", "/certificate", init)
      Logger.debug(`CertificateAPI.create reply result = ${response.result}`)
      const arrayBuffer = this.base64ToArrayBuffer(response.body)
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
      return blob
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("CertificateAPI.create error", message, init.body)
      throw new Error(message)
    }
  }

  base64ToArrayBuffer(data: any) {
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };


}

export default CertificateAPI