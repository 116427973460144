import {timestampToISOString} from "../stores/StoreUtilities";

export class Charge {
  id: string
  amount: number
  created: string
  receipt_number: string
  receipt_url: string

  constructor (data: any) {
    this.id = data.id
    this.amount = data.amount / 100.0
    this.created = timestampToISOString(data.created)
    this.receipt_number = data.receipt_number
    this.receipt_url = data.receipt_url
  }
}

export default Charge