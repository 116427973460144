import * as React from 'react'
import Page from '../components/page/Page'
import { RouteComponentProps } from '@reach/router'
import MarginRow from '../components/page/MarginRow'
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  root: {
    justifyContent: "center"
  },
  logoImage: {
    height: '25%',
    width: '25%'
  },
  text: {
    paddingTop: 'calc(50vh - 100px)',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0
    }
  }
})

class NotFoundPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps>  {

  render() {

    const { classes } = this.props;

    return (
      <Page>
        <MarginRow>
          <Grid container>
            <Grid item lg={12}>
              <div className={classes.root}>
                {/*<img src={Logo} className={ classes.logoImage } />*/}
                <Typography variant={'subtitle1'} className={classes.text}>Sorry, this page doesn't exist or cannot be accessed.</Typography>
              </div>
            </Grid>
          </Grid>
        </MarginRow>
      </Page>
    )
  }
}

export default withStyles(styles)(NotFoundPage);