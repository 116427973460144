import * as React from 'react'
import Page from '../../components/page/Page'
import {Box, createStyles, Grid, Tab, Tabs, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import ControlTower, {Routes} from "../../components/ControlTower";
import AccountInfo from "./AccountInfo";
import AccountClasses from "./AccountClasses";
import AccountUsers from "./AccountUsers";
import TitleBar from "../../components/TitleBar";
import NavigationBar from "../../components/NavigationBar";
import AccountBilling from "./AccountBilling";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
  },
  tabs: {

  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    fontWeight: 800
  },
  hiddenTab: {
    display: "none"
  }
})

export enum AccountTabNames {
  info = "info",
  classes = "classes",
  students = "students",
  reports = "reports"
}

interface IAccountPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  accountId?: string
  tab?: string
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountPageProps & WithTheme> {

  tabNames: string[] = ["info", "classes", "billing", "people"]

  @observable account?: Account
  @observable user?: User
  @observable isLoading = true

  componentDidMount () {
    const { progress, accountId } = this.props

    this.isLoading = true
    progress!.show("AccountPage")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading && accountId !== undefined,
      async () => {
        this.account = await accountStore!.loadAccount(accountId!)
        this.user = userStore!.user
        this.isLoading = false
        progress!.hide("AccountPage")
      }
    )
  }

  handleChange = (event: any, value: any) => {
    // Navigate to tab to allow a direct url to each tab
    const tabName = this.tabNames[value]
     ControlTower.route(`${Routes.account}/${this.props.accountId}/${tabName}`)
  }

  render() {
    const { classes, userStore } = this.props

    if (!this.account) {
      return null
    }

    const title = this.isLoading ? "Account" : this.account!.name

    const tabName = (this.props.tab !== undefined) ? this.props.tab : this.tabNames[0]
    let tabValue = this.tabNames.findIndex(name => tabName === name)
    if (tabValue < 0) {
      tabValue = 0
    }

    const isAdminOrAgent = userStore!.isAdminOrAgent
    const isEmployer = userStore!.isEmployer

    return (
      <Page title={title}>
        <MarginRow>
          <div className={ classes.root}>
            <Grid container>
              { isAdminOrAgent &&
                <NavigationBar title="Accounts" onBack={this.onBack}/>
              }
              <TitleBar title={title}>
              </TitleBar>
              <Box width={'100%'} bgcolor="common.white" borderRadius="10px 10px 0px 0px">
                <Tabs
                  value={tabValue}
                  onChange={this.handleChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                  textColor="secondary"
                  className={ classes.tabs }
                >
                  <Tab className={classes.tab} label="Information"/>
                  <Tab className={classes.tab} label="Classes"/>
                  <Tab className={classes.tab} label="Billing" />
                  {(isEmployer || isAdminOrAgent) &&
                    <Tab className={classes.tab} label="People" />
                  }
                </Tabs>
              </Box>
            </Grid>
            <Grid container>
              {tabValue === 0 && <AccountInfo />}
              {tabValue === 1 && <AccountClasses />}
              {tabValue === 2 && <AccountBilling />}
              {tabValue === 3 && (isEmployer || isAdminOrAgent) && <AccountUsers />}
            </Grid>
          </div>
        </MarginRow>
      </Page>
    )
  }

  onBack = () => {
    ControlTower.route(Routes.accounts)
  }

  onAddClass = () => {
    // TODO
  }

}

export default withTheme((withStyles(styles)(AccountPage)))
