import * as APITypes from "../API"
import { GraphQLResult } from '@aws-amplify/api/lib/types/index'
import {API, Auth, graphqlOperation} from "aws-amplify"
import { print as gqlToString } from "graphql"
import ControlTower, {Routes} from "../components/ControlTower";
import Logger from "../components/Logger";
import * as CustomQueries from './CustomQueries'
import * as CustomMutations from './CustomMutations'
import {getErrorMessage} from "../stores/StoreUtilities";

class LearningAPI {

  // Account methods

  async getAccount(id: string) {
    const query = gqlToString(CustomQueries.getAccount)
    const variables = {"id": id}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.getAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount) {
      return (data as APITypes.GetAccountQuery).getAccount
    } else {
      return null
    }
  }

  async getAccountUsers(id: string) {
    const query = gqlToString(CustomQueries.getAccountUsers)
    const variables = {"id": id}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.getAccountUsers error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount) {
      return (data as APITypes.GetAccountQuery).getAccount
    } else {
      return null
    }
  }

  async listAccounts(filter?: APITypes.ModelAccountFilterInput) {
    const query = gqlToString(CustomQueries.listAccounts)
    const variables = {"filter": filter, "limit": 10000}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.listAccounts error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListAccountsQuery && (data as APITypes.ListAccountsQuery).listAccounts) {
      return (data as APITypes.ListAccountsQuery).listAccounts
    } else {
      return null
    }
  }

  async createAccount(input: APITypes.CreateAccountInput) {
    const query = gqlToString(CustomMutations.createAccount)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAccountMutation && (data as APITypes.CreateAccountMutation).createAccount) {
        const accountData = (data as APITypes.CreateAccountMutation).createAccount
        return accountData
      } else {
        throw new Error(`Call to create account does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("LearningAPI.createAccount error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAccount(input: APITypes.UpdateAccountInput) {
    const query = gqlToString(CustomMutations.updateAccount)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.updateAccount error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAccountMutation) {
      return (data as APITypes.UpdateAccountMutation).updateAccount
    } else {
      return null
    }
  }

  async deleteAccount(id: string) {
    const query = gqlToString(CustomMutations.deleteAccount)
    const input: APITypes.DeleteAccountInput = {
      id
    }
    const data = await this.makeQuery(query, {input})
      .catch(err => {
        Logger.error("LearningAPI.deleteAccount error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAccountMutation).deleteAccount
    }
    return null
  }

  // Agreement methods

  async createAgreement(input: APITypes.CreateAgreementInput) {
    const query = gqlToString(CustomMutations.createAgreement)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAgreementMutation && (data as APITypes.CreateAgreementMutation).createAgreement) {
        const AgreementData = (data as APITypes.CreateAgreementMutation).createAgreement
        return AgreementData
      } else {
        throw new Error(`Call to create Agreement does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createAgreement error", getErrorMessage(e), input)
      return null
    }
  }

  // Class methods

  async createClass(input: APITypes.CreateClassInput) {
    const query = gqlToString(CustomMutations.createClass)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateClassMutation && (data as APITypes.CreateClassMutation).createClass) {
        const result = (data as APITypes.CreateClassMutation).createClass
        return result
      } else {
        throw new Error(`Call to create class does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("LearningAPI.createClass error", getErrorMessage(e), input)
      return null
    }
  }

  async getClass(id: string) {
    const query = gqlToString(CustomQueries.getClass)
    const variables = {"id": id}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.getClass error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetClassQuery && (data as APITypes.GetClassQuery).getClass) {
      return (data as APITypes.GetClassQuery).getClass
    } else {
      return null
    }
  }

  async updateClass(input: APITypes.UpdateClassInput) {
    const query = gqlToString(CustomMutations.updateClass)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.updateClass error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateClassMutation) {
      return (data as APITypes.UpdateClassMutation).updateClass
    } else {
      return null
    }
  }

  // User methods

  async getUser (userId: string) {
    const query = gqlToString(CustomQueries.getUser)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.getUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async createUser (input: APITypes.CreateUserInput) {
    const query = gqlToString(CustomMutations.createUser)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAccountMutation && (data as APITypes.CreateUserMutation).createUser) {
        const userData = (data as APITypes.CreateUserMutation).createUser
        return userData
      } else {
        throw new Error(`Call to create user does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("LearningAPI.createUser error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {
    if (input.email) {
      input.email = input.email.toLowerCase()
    }
    const query = gqlToString(CustomMutations.updateUser)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.updateUser error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserMutation) {
      return (data as APITypes.UpdateUserMutation).updateUser
    } else {
      return null
    }
  }

  async deleteUser(id: string) {
    const query = gqlToString(CustomMutations.deleteUser)
    const input: APITypes.DeleteUserInput = {
      id
    }
    const data = await this.makeQuery(query, {input})
      .catch(err => {
        Logger.error("LearningAPI.deleteUser error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserMutation).deleteUser
    }
    return null
  }

  // Course methods

  async listCourses(filter?: APITypes.ModelCourseFilterInput) {
    const query = gqlToString(CustomQueries.listCourses)
    const variables = {"filter": filter, "limit": 1000}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.listCourses error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCoursesQuery && (data as APITypes.ListCoursesQuery).listCourses) {
      return (data as APITypes.ListCoursesQuery).listCourses
    } else {
      return null
    }
  }

  async getCourse(id: string) {
    const query = gqlToString(CustomQueries.getCourse)
    const variables = {"id": id}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.getCourse error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetCourseQuery && (data as APITypes.GetCourseQuery).getCourse) {
      return (data as APITypes.GetCourseQuery).getCourse
    } else {
      return null
    }
  }

  async createCourse(input: APITypes.CreateCourseInput) {
    const query = gqlToString(CustomMutations.createCourse)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateCourseMutation && (data as APITypes.CreateCourseMutation).createCourse) {
        const result = (data as APITypes.CreateCourseMutation).createCourse
        return result
      } else {
        throw new Error(`Call to create course does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("LearningAPI.createCourse error", getErrorMessage(e), input)
      return null
    }
  }

  async updateCourse(input: APITypes.UpdateCourseInput) {
    const query = gqlToString(CustomMutations.updateCourse)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.updateCourse error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateCourseMutation) {
      return (data as APITypes.UpdateCourseMutation).updateCourse
    } else {
      return null
    }
  }

  // Registration methods

  async createRegistration(input: APITypes.CreateRegistrationInput) {
    const query = gqlToString(CustomMutations.createRegistration)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateRegistrationMutation && (data as APITypes.CreateRegistrationMutation).createRegistration) {
        const result = (data as APITypes.CreateRegistrationMutation).createRegistration
        return result
      } else {
        throw new Error(`Call to create account does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("LearningAPI.createRegistration error", getErrorMessage(e), input)
      return null
    }
  }

  async updateRegistration(input: APITypes.UpdateRegistrationInput) {
    const query = gqlToString(CustomMutations.updateRegistration)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.updateRegistration error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateRegistrationMutation) {
      return (data as APITypes.UpdateRegistrationMutation).updateRegistration
    } else {
      return null
    }
  }

  async deleteRegistration(id: string) {
    const input: APITypes.DeleteRegistrationInput = {
      id: id
    }
    const query = gqlToString(CustomMutations.deleteRegistration)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.deleteRegistration error", err.message, input)
        throw err
      })
    if (data as APITypes.DeleteRegistrationMutation) {
      return (data as APITypes.DeleteRegistrationMutation).deleteRegistration
    } else {
      return null
    }
  }

  // Activity methods

  async listAccountActivity(accountId: string, filter?: APITypes.ModelActivityFilterInput) {
    const query = gqlToString(CustomQueries.listAccountActivity)
    const variables = {"id": accountId, "filter": filter, "limit": 10000}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.listAccountActivity error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount &&
        (data as APITypes.GetAccountQuery).getAccount!.activity) {
      return (data as APITypes.GetAccountQuery).getAccount!.activity
    } else {
      return null
    }
  }

  async listActivityByDate(date: string, filter?: APITypes.ModelActivityFilterInput) {
    const query = gqlToString(CustomQueries.listActivityByDate)
    const variables = {"activityDate": date, "filter": filter, "limit": 1000}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("LearningAPI.listActivityByDate error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListActivityByDateQuery && (data as APITypes.ListActivityByDateQuery).listActivityByDate) {
      return (data as APITypes.ListActivityByDateQuery).listActivityByDate!
    } else {
      return null
    }
  }

  async createActivity(input: APITypes.CreateActivityInput) {
    const query = gqlToString(CustomMutations.createActivity)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateActivityMutation && (data as APITypes.CreateActivityMutation).createActivity) {
        const result = (data as APITypes.CreateActivityMutation).createActivity
        return result
      } else {
        throw new Error(`Call to create activity does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("LearningAPI.createActivity error", getErrorMessage(e), input)
      return null
    }
  }

  // Helper methods

  async makeQuery(query: string, variables?: {}) {
    try {
      await this.checkAuthentication()
      const operation = graphqlOperation(query, variables)
      // console.log(JSON.stringify(operation))
      const result = await API.graphql(operation)
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err.message) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }

  async checkAuthentication() {
    try{
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser) {
        return
      }
    } catch (err) {
      // Logger.debug("checkAuthentication err: " + err.message)
    }

    ControlTower.route(Routes.signout)
  }

}

export default LearningAPI