import * as React from "react";
import {
  Box,
  Card, CardActionArea,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import User from "../../model/User";
import {phoneToNationalFormat} from "../../stores/StoreUtilities";
import TitleButton from "../../components/TitleButton";
import ControlTower, {Routes} from "../../components/ControlTower";


const styles = (theme: Theme) => createStyles({
  card: {
    maxWidth: '100%',
    minHeight: 125,
    padding: theme.spacing(1),
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 10px",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  titleButtons: {
    textAlign: "right",
  },
  owner: {
    fontSize: 14,
    fontWeight: 400,
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    maxHeight: 24,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  role: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "right"
  },
  actionArea: {
    color: theme.palette.secondary.main
  },

})

interface IUserCardProps {
  user: User
  onCardAction?: any
}

@inject('user')
@observer
class UserCard extends React.Component<WithStyles<typeof styles> & IUserCardProps> {

  render() {
    const { classes, user, onCardAction } = this.props
    const status = user.userStatus.toString()

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container justifyContent={'space-between'} direction="row">
            <Grid item xs={8}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  {onCardAction &&
                  <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
                    <Typography variant="h6" className={classes.title}>
                      {user.lastName}, {user.firstName}
                    </Typography>
                  </CardActionArea>
                  }
                  {!onCardAction &&
                    <Typography variant="h6" className={classes.title}>
                      {user.lastName}, {user.firstName}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.details}>
                    {user.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.details}>
                    {phoneToNationalFormat(user.phone)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.details}>
                    {user.jobTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="flex-end">
                <Grid item>
                  <Box pb={1}>
                    <Typography className={classes.titleButtons}>
                      <TitleButton title="Edit" variant="secondary" onClick={this.onEdit}/>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className={classes.role}>
                    {status}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.role}>
                    {user.role}
                  </Typography>
                </Grid>
              </Grid> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  onEdit = () => {
    const { user } = this.props
    ControlTower.route(`${Routes.account}/${user.accountId}/userEdit/${user.id}`)
  }

  onCardAction = () => {
    if (this.props.onCardAction) {
      this.props.onCardAction(this.props.user)
    }
  }

}

export default withStyles(styles)(UserCard)
