import Class from "./Class";
import User from "./User";
import {AccountType, AccountStatus, AccountSize} from "../API";


export class Account {
  id: string
  createdAt: string
  updatedAt: string
  ownerId: string
  name: string
  address: string
  city: string
  state: string
  postalCode: string
  accountType: AccountType
  accountStatus: AccountStatus
  customerId: string
  accountSize: AccountSize
  classes: Class[]
  users: User[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.ownerId = data.ownerId
    this.name = data.name
    this.address = data.address
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
    this.accountType = data.id === 'primary' ? AccountType.Individual : AccountType.Agency
    this.accountStatus = data.accountStatus ? (AccountStatus as any)[data.accountStatus] : AccountStatus.Active
    this.customerId = data.customerId
    this.accountSize = data.accountSize ? AccountSize[data.accountSize] : AccountSize.Small
    this.classes = []
    if (data.classes && data.classes.items) {
      this.loadClasses(data.classes.items)
    }
    this.users = []
    if (data.users && data.users.items) {
      this.loadUsers(data.users.items)
    }
  }

  loadClasses(items: any[]) {
    this.classes = items.map((item: any) => new Class(item))
  }

  loadUsers(items: any[]) {
    this.users = items.map((item: any) => new User(item))
  }
}

export default Account