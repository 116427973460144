import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import DialogButton from './form/DialogButton'


const styles = (theme: Theme) => createStyles({
  dialog: {
    margin: 0
  },
  dialogContent: {
    padding: "10px 10px"
  },
  paper: {
    margin: 10
  },
  dialogActions: {
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10
  }
})

interface IModalDialog {
  open?: boolean
  title?: string
  onClose?: any
  children?: any
  maxWidth?: any
}

class ModalDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IModalDialog> {

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  };

  render() {
    const {classes, title, children} = this.props
    const maxWidth = (this.props.maxWidth !== undefined) ? this.props.maxWidth : "md"

    return (
      <Dialog
        id="modalDialog"
        open={this.props.open ? true : false}
        onClose={this.onClose}
        scroll="paper"
        className={classes.dialog}
        classes={{paper: classes.paper}}
        maxWidth={maxWidth!}
        fullWidth
        aria-labelledby="modal-dialog-title">
        {title &&
        <DialogTitle id="modal-dialog-title">{title}</DialogTitle>
        }
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <DialogButton variant="primary" onClick={this.onClose}>
            Close
          </DialogButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ModalDialog)
