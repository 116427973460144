import * as React from "react";
import {createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  titleBar: {
    color: theme.palette.text.primary,
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    justifyContent: "left",
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontSize: 20,
    fontWeight: 600,
    alignItems: "center",
    paddingTop: 0,
    lineHeight: 1.2,
  },
  actions: {
    justifyContent: "right",
    textAlign: "right",
    paddingTop: 4
  },
})

interface ITitleBarProps {
  title: string,
  className?: string
  children?: any
}

class TitleBar extends React.Component<WithStyles<typeof styles> & ITitleBarProps & WithTheme> {

  render() {
    const { classes, className, title, children } = this.props

    return (
      <Grid item className={className ? className : classes.titleBar}>
        <Grid container direction="row">
          <Grid item className={classes.title} xs={9}>
            <Typography variant="h3">
              {title}
            </Typography>
          </Grid>
          <Grid item className={classes.actions} xs={3}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withTheme((withStyles(styles)(TitleBar)))
