import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import AccountStore from "../../stores/AccountStore";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import {observable, when} from "mobx";
import Class from "../../model/Class";
import UserStore from "../../stores/UserStore";
import LessonCard from "./LessonCard";
import Lesson from "../../model/Lesson";
import Course from "../../model/Course";
import CourseStore from "../../stores/CourseStore";
import ControlTower, {Routes} from "../../components/ControlTower";
import NavigationBar from "../../components/NavigationBar";
import Registration from "../../model/Registration";
import RegistrationCard from "../../components/RegistrationCard";
import Confirm from "../../components/confirm/Confirm";
import Tracking from "../../components/Tracking";
import TitleBar from "../../components/TitleBar";
import {isoToLocalDate} from "../../stores/StoreUtilities";
import {format} from "date-fns";

const styles = (theme: Theme) => createStyles({
  root: {
    // flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  registrationCard: {
    flex: "none",
    width: "100%",
    maxWidth: 960,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  content: {
    display: "flex",
    flex: "auto",
    flexGrow: 1,
    overflowY: "scroll",
    justifyContent: 'top',
    width: "100%",
    maxWidth: 960,
    maxHeight: "100%",
    marginTop: theme.spacing(0),
  },
  lessonCard: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  subtitle: {
    paddingLeft: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
})

interface IClassPageProps {
  classId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  confirm?: Confirm
}

@inject("userStore", "accountStore", "courseStore", "progress", "confirm")
@observer
class ClassPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IClassPageProps & WithTheme> {

  @observable isLoading = true
  @observable classObj?: Class
  @observable course?: Course
  @observable lessons: Lesson[] = []
  @observable registration?: Registration

  async componentDidMount () {
    const { userStore, accountStore, courseStore, classId, progress } = this.props
    this.isLoading = true
    progress!.show("ClassPage")

    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        this.classObj = accountStore!.getClass(classId!)
        if (this.classObj) {
          console.log("getClass succeeded")
          const course = await courseStore!.getCourse(this.classObj.courseId)
          if (course) {
            console.log("getCourse succeeded")
            this.course = course
            const registration = userStore!.getClassRegistration(classId!)
            if (registration) {
              console.log("getClassRegistration completed")
              accountStore!.joinClassToRegistration(registration)
              this.sortLessons(registration)
              this.registration = registration
              this.isLoading = false
            }
            progress!.hide("ClassPage")
          }
        } else {
          // TODO: Handle class not found
          this.isLoading = false
          progress!.hide("ClassPage")
        }
      }
    )
  }

  render() {
    const { classes } = this.props

    const title = (this.classObj) ? this.classObj.course!.title : ""

    if (!this.registration) {
      return null
    }

    return (
      <Page title={title}>
        {!this.isLoading && this.classObj && this.course && this.registration &&
        <MarginRow>
          <div className={classes.root}>
            <NavigationBar title="My Classes" onBack={this.onBack}/>
            <TitleBar title={title}></TitleBar>
            <div className={classes.registrationCard}>
              <RegistrationCard registration={this.registration} onCardAction={this.onRegistrationCardAction}/>
            </div>

            <Grid container className={classes.content} direction="row">
              {this.lessons.map((l: Lesson, index: number) => {
                return (
                  <Grid item xs={12} className={classes.lessonCard} key={l.id}>
                    {l.number === 1 && index > 0 &&
                      <Typography variant="body1" className={classes.subtitle}>Completed</Typography>
                    }
                    <LessonCard lesson={l} registration={this.registration!}
                                onCardAction={this.onLessonCardAction}
                                disabled={!this.registration!.classCompleted && l.number > this.registration!.lessonNumber}/>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </MarginRow>
        }
      </Page>
    )
  }

  onRegistrationCardAction = (action: string, registration: Registration) => {
    const { userStore, confirm } = this.props

    if (action === "reset") {
      Tracking.event({action: 'ResetClass'})
      confirm!.show("Confirm Reset Class",
        `Please confirm you want to reset ${this.registration!.class!.course!.title}`,
        ["Confirm", "Cancel"],
        async () => {
          const updatedRegistration = await userStore!.resetRegistration(this.registration!.id)
          if (updatedRegistration) {
            this.registration = updatedRegistration
          }
          return true
        },
        () => {
          // Do nothing
        })
    } else if (action === "open") {
      if (this.lessons.length > 0) {
        this.onLessonCardAction(this.lessons[0])
      }
    }
  }

  onLessonCardAction = (lesson: Lesson) => {
    const { classId, confirm } = this.props

    if (this.registration!.isExpired) {
      const endsAt = isoToLocalDate(this.registration!.endsAt)
      confirm!.show("Class Ended",
        `This class ended on ${format(endsAt, 'M/d/yyyy')}. Please contact your supervisor or admin.`,
        ["OK"],
        () => {
          return true
        },
        () => {
          // Do nothing
        })
    } else {
      ControlTower.route(`${Routes.class}/${classId}/lesson/${lesson.id}`)
    }
  }

  onBack = () => {
    ControlTower.route(Routes.myClasses)
  }

  sortLessons = (registration: Registration) => {
    // Sort by number with the next lesson at the beginning

    let assignedLessons = []
    if (registration.lessonsAssigned > 0) {
      // Restrict to only the assigned lessons
      assignedLessons = this.course!.lessons.slice(0, registration.lessonsAssigned)
    } else {
      assignedLessons = this.course!.lessons
    }

    if (registration && this.course) {
      if (registration!.lessonNumber <= 1) {
        this.lessons = assignedLessons
      } else {
        const lessonNumber = registration!.lessonNumber
        const index = assignedLessons.findIndex((l: Lesson) => { return l.number === lessonNumber})
        if (index >= 0) {
          const lessons = [...assignedLessons.slice(index), ...assignedLessons.slice(0, index)]
          this.lessons = lessons
        } else {
          this.lessons = assignedLessons
        }
      }
    } else {
      this.lessons = []
    }
  }
}

export default withTheme((withStyles(styles)(ClassPage)))