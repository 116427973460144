import {ActivityType} from "../API";
import Account from "./Account";
import User from "./User";

export class Activity {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  account?: Account
  userId: string
  user?: User
  activityDate: string
  activityType: ActivityType
  objectId: string
  values: {}

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.account = data.account
    this.userId = data.userId
    this.user = data.user
    this.activityDate = data.activityDate
    this.activityType = data.activityType
    this.objectId = data.objectId
    this.values = data.values
  }
}

export default Activity