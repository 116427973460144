import * as React from 'react'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import AccountChargeCard from "./AccountChargeCard";
import Charge from "../../model/Charge";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    justifyContent: 'top',
    alignItems: 'center',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(1),
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
})

interface IAccountBillingProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountBilling extends React.Component<WithStyles<typeof styles> & IAccountBillingProps & WithTheme> {

  @observable account?: Account
  @observable charges: Charge[] = []
  @observable isLoading = true

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountBilling")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      () => {
        this.account = accountStore!.account
        this.loadCharges()
      }
    )
  }

  render() {
    const { classes } = this.props

    if (this.isLoading) {
      return null
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.content} direction="row" spacing={1}>
          {this.charges.map((charge: Charge) => {
            return <Grid item xs={12} className={classes.card}>
                <AccountChargeCard charge={charge!}/>
              </Grid>
           })}
        </Grid>
      </div>
    )
  }

  private async loadCharges() {
    const { accountStore, userStore, progress } = this.props

    if (userStore!.isStudent) {
      this.charges = await userStore!.listCharges()
    } else {
      this.charges = await accountStore!.listCharges(this.account!.id)
    }
    this.isLoading = false
    progress!.hide("AccountBilling")
  }


}

export default withTheme((withStyles(styles)(AccountBilling)))