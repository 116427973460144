import * as React from "react";
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
})

interface ILinearGaugeSegmentProps {
  title: string
  percent: number
  className: string
}

class LinearGaugeSegment extends React.Component<WithStyles<typeof styles> & ILinearGaugeSegmentProps & WithTheme> {

  render() {
    const { title, percent } = this.props

    return (
      <div style={{width: `${percent}%`, height: "100%" }} {...this.props}>
        {title}
      </div>
    )
  }
}

export default withTheme((withStyles(styles)(LinearGaugeSegment)))
