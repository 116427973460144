import * as React from "react";
import {
  createStyles,
  DialogActions, FormControlLabel,
  Grid, Radio, RadioGroup,
  Theme, Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe, ReactStripeElements
} from 'react-stripe-elements';
import AccountStore from "../../stores/AccountStore";
import Alert from "../../components/alert/Alert";
import {inject} from "mobx-react";
import DialogButton from "../../components/form/DialogButton";
import ProgressButton from "../../components/form/ProgressButton";
import Visible from "../../components/Visible";

const styles = (theme: Theme) => createStyles({
  content: {
    width: '100%'
  },
  subtitle: {
    paddingBottom: 10,
  },
  textField: {
    color: theme.palette.text.primary,
    width: '100%',
    border: 'none',
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.text.secondary,
    backgroundColor: "transparent"
  },
  paymentField: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    // borderBottom: '1px solid rgba(0,0,0,0.54)',
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.text.secondary,
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    // fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fieldSet: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  fieldCheckbox: {
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  buttonSet: {
    justifyContent: "center",
    width: "100%",
    padding: "20px 0 0 0",
    marginTop: 5,
    height: 49,
    textAlign: "center"
  },
  progressButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogActions: {
    justifyContent: "center",
  },

})

const elementStyle = {
  base: {
    color: '#333'
  }
}

interface IPaymentForm {
  accountStore?: AccountStore
  amountDue?: number
  source?: any
  alert?: Alert
  stripe?: any
  confirmSubmit?: any
  onPayment?: any
  onCancel?: any
}

@inject("alert", "accountStore")
class PaymentForm extends React.Component<WithStyles<typeof styles> & IPaymentForm & ReactStripeElements.InjectedStripeProps> {

  state = {
    name: undefined,
    source: undefined,
    useSource: "new",
    isPaying: false
  }

  componentDidMount() {
    const { source, amountDue } = this.props
    if (!amountDue) {
      this.setState({useSource: "none"})
    } else if (source) {
      this.setState({source: source, useSource: "existing"})
    }
  }

  componentDidUpdate(prevProps: any) {
    const { source, amountDue } = this.props

    if (amountDue !== prevProps.amountDue) {
      if (!amountDue) {
        this.setState({useSource: "none"})
      } else if (source) {
        this.setState({source: source, useSource: "existing"})
      } else {
        this.setState({useSource: "new"})
      }
    }
  }
  handleSubmit = async (ev: any) => {
    ev.preventDefault();
    
    if (this.props.confirmSubmit) {
      if (!this.props.confirmSubmit()) {
        return
      }
    }

    if (this.state.useSource === "new") {
      this.setState({isPaying: true})
      // Within the context of `Elements`, this call to createToken knows which Element to
      // tokenize, since there's only one in this group.
      try {
        this.props.stripe.createToken({name: this.state.name}).then(async (response: any) => {
          // console.log('stripe.createToken response:', response);
          if (response.token) {
            if (this.props.onPayment) {
              await this.props.onPayment(response.token)
              this.setState({isPaying: false})
            }
          } else if (response.error) {
            this.setState({isPaying: false})
            this.props.alert!.show("Error", response.error.message)
          }
        });
      } catch (err) {
        this.setState({isPaying: false})
      }
    } else {
      // Pay with card on file or none due
      if (this.props.onPayment) {
        await this.props.onPayment(null)
        this.setState({isPaying: false})
      }
    }

  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.setState({
      [name]: event.target.value
    })
  };

  onCancel = (event: any) => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  render() {
    const { classes } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container className={classes.content} direction='row' spacing={2}>
          {this.state.source && this.state.useSource !== "none" &&
            <Grid item xs={12} className={classes.fieldSet}>
              <RadioGroup aria-label="useSource" name="useSource" value={this.state.useSource}
                          onChange={this.handleChange} row>
                <FormControlLabel
                  value="existing"
                  control={<Radio color="secondary"/>}
                  label={`${this.state.source ? this.state.source!['brand'] : ""} card on file ending in ${this.state.source ? this.state.source!['last4'] : ""}`}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="new"
                  control={<Radio color="secondary"/>}
                  label="New Card"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          }
          <Visible if={this.state.useSource === "new"}>
            <Grid item xs={12} className={classes.fieldSet}>
              <label className={classes.fieldLabel}>
                Name on Card
                <input type="text" name="name" className={classes.textField}
                       onChange={this.handleChange} />
              </label>
            </Grid>
            <Grid item xs={12} className={classes.fieldSet}>
              <label className={classes.fieldLabel}>
                Card Number
                <CardNumberElement className={classes.paymentField} style={elementStyle}/>
              </label>
            </Grid>
            <Grid item xs={6} className={classes.fieldSet}>
              <label className={classes.fieldLabel}>
                Expiration
                <CardExpiryElement className={classes.paymentField} style={elementStyle}/>
              </label>
            </Grid>
            <Grid item xs={6} className={classes.fieldSet}>
              <label className={classes.fieldLabel}>
                CVC
                <CardCVCElement className={classes.paymentField} style={elementStyle}/>
              </label>
            </Grid>
          </Visible>
          <Visible if={this.state.useSource === "none"}>
            <Grid item xs={12} className={classes.fieldSet}>
              <Typography variant="body1">
                No payment is due.
              </Typography>
            </Grid>
          </Visible>
          <Grid item xs={12}>
            <DialogActions className={classes.dialogActions}>
              <DialogButton variant="secondary" onClick={this.onCancel}>
                Cancel
              </DialogButton>
              <ProgressButton variant="contained" color="secondary"
                              type="submit" processing={this.state.isPaying}
                              onClick={this.handleSubmit}>
                { this.state.useSource === "none" ? "Save" : "Submit" }
              </ProgressButton>
            </DialogActions>
          </Grid>

        </Grid>
      </form>
    );
  }


}

export default withStyles(styles)(injectStripe(PaymentForm));
// export default withStyles(styles)(PaymentForm);