import * as React from "react";
import {
  Avatar,
  Card,
  CardContent,
  createStyles, IconButton,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {observer} from "mobx-react";
import Registration from "../../model/Registration";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import {ClassStatus} from "../../API";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    minHeight: 80,
    padding: 0
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 10px",
    display: "flex"
  },
  left: {
    flexShrink: 0,
    paddingRight: 10,
    alignSelf: "center",
  },
  middle: {
    flexGrow: 1
  },
  right: {
    flexShrink: 0,
    paddingLeft: 10,
    alignSelf: "right",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    maxHeight: 24,
    overflow: "hidden",
  },
  // Avatar Styles
  unRegisteredAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: 35,
    height: 35,
  },
  unregisteredIcon: {
    color: theme.palette.text.secondary,
    width: 25,
    height: 25,
  },
  notStartedAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: 35,
    height: 35,
  },
  notStartedIcon: {
    color: theme.palette.text.secondary,
    width: 25,
    height: 25,
  },
  inProgressAvatar: {
    width: 35,
    height: 35,
  },
  inProgressIcon: {
    color: theme.palette.text.primary,
    width: 25,
    height: 25,
  },
  failedAvatar: {
    backgroundColor: theme.palette.error.dark,
    width: 35,
    height: 35,
  },
  failedIcon: {
    color: theme.palette.error.contrastText,
    width: 25,
    height: 25,
  },
  passedAvatar: {
    backgroundColor: theme.palette.success.main,
    width: 35,
    height: 35,
  },
  passedIcon: {
    color: theme.palette.success.contrastText,
    width: 25,
    height: 25,
  },
  // Progress styles
  progressBar: {
    marginTop: 5
  },
  linearProgress: {
    color: theme.palette.primary.main,
    width: "100%",
    height: 3,
  },
  linearProgressDeterminateColor: {
    backgroundColor: theme.palette.primary.light
  },
  classProgressNotStarted: {
    backgroundColor: theme.palette.primary.light
  },
  classProgressPassing: {
    backgroundColor: theme.palette.success.main
  },
  classProgressFailing: {
    backgroundColor: theme.palette.warning.dark,
  },
  classProgressFailed: {
    backgroundColor: theme.palette.error.dark,
  },
  addButton: {
    backgroundColor: theme.palette.background.paper,
    width: 20,
    height: 20,
  },
  addIcon: {
    color: theme.palette.secondary.main,
    // color: theme.palette.success.main,
    width: 20,
    height: 20,
  },
  removeButton: {
    backgroundColor: theme.palette.background.paper,
    width: 20,
    height: 20,
  },
  removeIcon: {
    color: theme.palette.secondary.main,
    // color: theme.palette.error.main,
    width: 20,
    height: 20,
  },

})

interface IRosterCardProps {
  registration: Registration
  onRegister?: any
  onUnregister?: any
}

// @inject()
@observer
class RosterCard extends React.Component<WithStyles<typeof styles> & IRosterCardProps> {

  render() {
    const { classes, registration } = this.props

    const user = registration.user
    if (!user) {
      return null
    }

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.left}>
            { this.renderAvatar() }
            { this.renderProgress() }
          </div>
          <div className={classes.middle}>
            <Typography variant="h6" className={classes.title}>
              {user.lastName}, {user.firstName}
            </Typography>
            <Typography className={classes.details}>
              {user.role}
            </Typography>
          </div>
          <div className={classes.right}>
            { this.renderButton() }
          </div>
        </CardContent>
      </Card>
    )
  }

  renderAvatar = () => {
    const { classes, registration } = this.props

    let avatar = null

    if (!registration.id) {
      avatar = <Avatar className={classes.unRegisteredAvatar} >
                 <AssignmentIndIcon className={classes.unregisteredIcon}/>
               </Avatar>
    } else {
      if (registration.classStatus === ClassStatus.NotStarted) {
        avatar = <Avatar className={classes.notStartedAvatar}>
          <AssignmentIcon className={classes.notStartedIcon}/>
        </Avatar>
      } else if (registration.classStatus === ClassStatus.InProgress) {
        avatar = <Avatar className={classes.inProgressAvatar}>
          <AssignmentIcon className={classes.inProgressIcon} />
        </Avatar>
      } else if (registration.classStatus === ClassStatus.Failed) {
        avatar = <Avatar className={classes.failedAvatar}>
          <AssignmentTurnedInIcon className={classes.failedIcon} />
        </Avatar>
      } else if (registration.classStatus === ClassStatus.Passed) {
        avatar = <Avatar className={classes.passedAvatar}>
          <AssignmentTurnedInIcon className={classes.passedIcon} />
        </Avatar>
      }
    }

    return avatar
  }

  renderProgress = () => {
    const { classes, registration } = this.props

    let result = null

    if (registration.id) {

      let progressClassName = classes.classProgressNotStarted
      if (registration.classStatus === ClassStatus.InProgress) {
        progressClassName = registration.passing ? classes.classProgressPassing : classes.classProgressFailing
      } else if (registration.classStatus === ClassStatus.Failed) {
        progressClassName = classes.classProgressFailed
      } else if (registration.classStatus === ClassStatus.Passed) {
        progressClassName = classes.classProgressPassing
      }
      result =
        <div className={classes.progressBar}>
          <LinearProgress className={classes.linearProgress}
                          classes={{barColorPrimary: progressClassName, determinate: classes.linearProgressDeterminateColor}}
                          variant="determinate" value={registration.classProgress}/>
        </div>
    }

    return result
  }

  private renderButton() {
    const { classes, registration, onUnregister } = this.props

    let button = null

    if (!registration.id) {
      button =
        <IconButton className={classes.addButton} onClick={this.onRegister} aria-label="assign">
          <AddCircleIcon className={classes.addIcon}/>
        </IconButton>
    } else if (onUnregister) {
      button =
        <IconButton className={classes.removeButton} onClick={this.onUnregister} aria-label="unassign">
          <RemoveCircleIcon className={classes.removeIcon}/>
        </IconButton>
    }

    return button
  }


  onRegister = () => {
    const { registration, onRegister} = this.props

    if (onRegister) {
      onRegister(registration)
    }
  }

  onUnregister = () => {
    const { registration, onUnregister} = this.props

    if (onUnregister) {
      onUnregister(registration)
    }
  }

}

export default withStyles(styles)(RosterCard)
