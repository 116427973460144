import * as React from 'react'
import Page from '../../components/page/Page'
import {
  Checkbox,
  createStyles, DialogActions,
  DialogContent, FormControlLabel, FormGroup,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import CourseStore from "../../stores/CourseStore";
import {inject, observer} from "mobx-react";
import {observable, when} from "mobx";
import Course from "../../model/Course";
import Lesson from "../../model/Lesson";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../../components/Tracking";
import {
  ActivityType,
  CreateCourseInput,
  Lesson as APILesson,
  UpdateCourseInput
} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import Storage from '@aws-amplify/storage'
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import ProgressButton from "../../components/form/ProgressButton";
import {createUUID, getISODateFromDate, getISODateToday, isoToLocalDate} from "../../stores/StoreUtilities";
import CSVReader from "react-csv-reader";
import { saveAs } from 'file-saver'
import {format, parse} from "date-fns";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import { Editor } from "@tinymce/tinymce-react";
import config from 'react-global-configuration';

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: "0px 0px 10px 10px"
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  formGroup: {
    marginBottom: 0,
    paddingBottom: 0
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
  progress: {
    width: "100%",
  },
  progressText: {
    fontSize: 14,
    fontWeight: 800,
    padding: "0px 2px",
  },
  progressError: {
    fontSize: 14,
    fontWeight: 800,
    padding: "0px 2px",
    color: "red"
  },
  downloadLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    marginTop: 4,
  },
  downloadButton: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    paddingLeft: 2,
  },
  fileInput: {
    width: 340
  },
})

interface ICourseEditPageProps {
  courseId?: string
  userStore?: UserStore
  courseStore?: CourseStore
  s3UrlCacheStore?: S3UrlCacheStore
  progress?: Progress
  notify?: Notify
}

const correctAnswers = ["", "A", "B", "C", "D"]

enum CSV {
  Lesson,
  Title,
  VideoURL,
  VideoDuration,
  Description,
  Number,
  Question,
  AnswerA,
  AnswerB,
  AnswerC,
  AnswerD,
  CorrectAnswer,
  _Length
}

@inject("userStore", "courseStore", "progress", "notify", "s3UrlCacheStore")
@observer
class CourseEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICourseEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable course?: Course
  @observable values = {
    courseId: "",
    title: "",
    description: "",
    initialDetails: "",
    details: "",
    isFree: false,
    coverFile: undefined,
    coverUrl: "",
    creditHours: 0,
    publishDate:  format(isoToLocalDate(getISODateToday()), "M/d/yyyy"),
    sunsetDate: format(isoToLocalDate("2099-12-31"), "M/d/yyyy"),
    handoutFile: undefined,
    handoutUrl: ""
  }
  @observable lessons: any = undefined
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""

  componentDidMount () {
    const { courseId, userStore, courseStore, progress} = this.props
    this.isLoading = true
    progress!.show("CourseEditPage")
    when(
      () => !userStore!.isLoading,
      async () => {
        if (courseId) {
          const course = await courseStore!.getCourse(courseId!)
          if (course) {
            this.values = {
              courseId: course.id,
              title: course.title,
              description: course.description,
              initialDetails: course.details,
              details: course.details,
              coverUrl: course.coverUrl,
              coverFile: undefined,
              isFree: course.isFree,
              creditHours: course.creditHours,
              publishDate: course.publishDate ? format(isoToLocalDate(course.publishDate), "M/d/yyyy") : "",
              sunsetDate: course.publishDate ? format(isoToLocalDate(course.sunsetDate), "M/d/yyyy") : "",
              handoutUrl: course.handoutUrl,
              handoutFile: undefined
            }
            this.course = course
          }
        } else {
          this.values.courseId = createUUID()
        }
        this.isLoading = false
        progress!.hide("CourseEditPage")
      }
    )
  }

  render() {
    const { classes, courseId } = this.props

    const title = courseId ? "Edit Course" : "Add Course"
    const minDate = "1/1/2000"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                             name="CourseEditForm" id="CourseEditForm" className={classes.form}>
                <DialogContent className={classes.dialogContent}>
                  <TextFieldValidator
                    autoFocus
                    margin="dense"
                    name="title"
                    label="Title"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.title}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.description}
                    fullWidth
                  />
                  <FormGroup className={classes.formGroup}>
                    <label className={classes.fieldLabel}>Details</label>
                    {/*<TextareaAutosize*/}
                    {/*  name="details"*/}
                    {/*  value={this.values.details}*/}
                    {/*  onChange={this.onChange}*/}
                    {/*  rowsMin={5}*/}
                    {/*  className={classes.textarea}*/}
                    {/*/>*/}
                    {this.renderEditor()}
                  </FormGroup>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <FormGroup className={classes.formGroup} row>
                        <TextFieldValidator
                          margin="dense"
                          name="publishDate"
                          label="Publish Date"
                          type="text"
                          variant="standard"
                          validators={{required:true, minDate:minDate}}
                          onChange={this.onChange}
                          value={this.values.publishDate}
                          placeholder="m/d/yyyy"
                        />
                        <FormGroupSpacer />
                        <TextFieldValidator
                          margin="dense"
                          name="sunsetDate"
                          label="Sunset Date"
                          type="text"
                          variant="standard"
                          validators={{required:true, minDate:minDate}}
                          onChange={this.onChange}
                          value={this.values.sunsetDate}
                          placeholder="m/d/yyyy"
                        />
                      </FormGroup>
                      <TextFieldValidator
                        margin="dense"
                        name="creditHours"
                        label="Credit Hours"
                        type="text"
                        variant="standard"
                        validators={{required:true, minValue:0, maxValue:52}}
                        onChange={this.onChange}
                        value={this.values.creditHours}
                      />
                      <div className={classes.label}>Cover Image (JPEG)</div>
                      <FormGroup row>
                        <input
                          type='file'
                          accept='image/jpeg'
                          className={classes.fileInput}
                          onChange={(e) => this.onCoverChange(e.target!.files![0])}
                        />
                      </FormGroup>
                      <div className={classes.label}>Lessons (CSV)</div>
                      <div>
                        <CSVReader
                          cssInputClass={classes.fileInput}
                          label=""
                          onFileLoaded={this.onCSVLoaded}
                        />
                        {!courseId &&
                          <div className={classes.downloadLink}>
                            <a href={`${process.env.PUBLIC_URL}/CourseTemplate.csv`} download="CourseTemplate.csv" className={classes.downloadLink}>DOWNLOAD A TEMPLATE</a>
                          </div>
                        }
                        {courseId &&
                          <DialogButton variant="tertiary" onClick={this.downloadCSV} customClassName={classes.downloadButton}>
                            Download
                          </DialogButton>
                        }
                        <div className={classes.progress}>
                          <div className={this.isValid ? classes.progressText : classes.progressError}>
                            {this.progressText}
                          </div>
                        </div>
                      </div>
                      <FormControlLabel
                        value="isFree"
                        control={
                          <Checkbox color="secondary" name="isFree" id="isFree"
                                    checked={this.values.isFree}
                                    onChange={this.onChange}/>}
                        label="Free class"
                        labelPlacement="end"
                        className={classes.label}
                      />                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className={classes.label}>Handout (PDF)</div>
                      <FormGroup row>
                        <input
                          type='file'
                          accept='application/pdf'
                          className={classes.fileInput}
                          value={this.values.handoutFile ? undefined : ""}
                          onChange={(e) => this.onHandoutChange(e.target!.files![0])}
                        />
                        <div style={{width:"100%"}}>
                          {this.values.handoutUrl &&
                            <DialogButton variant="tertiary" onClick={this.downloadHandout}
                                          customClassName={classes.downloadButton}>
                              Download
                            </DialogButton>
                          }
                          {(this.values.handoutUrl || this.values.handoutFile) &&
                            <DialogButton variant="tertiary" onClick={this.deleteHandout} customClassName={classes.downloadButton}>
                              Delete
                            </DialogButton>
                          }
                        </div>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="secondary" onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                  <ProgressButton variant="contained" size="small" color="primary"
                                  type="submit" processing={this.isProcessing}
                                  onClick={this.onSubmit}>
                    Save
                  </ProgressButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  renderEditor = () => {
    const editorKey = config.get("tinymce.apiKey")

    return (
      <Editor
        id="description"
        apiKey={editorKey}
        init={{
          plugins: 'lists', // 'link',
          menubar: '',
          toolbar: 'undo redo | styleselect | bold italic forecolor | outdent indent bullist',
          skin: "oxide",
          height: 200,
          width: '100%',
          keep_styles: false,
          // See docs for valid_elements: https://www.tiny.cloud/docs/configure/content-filtering/#exampleusingvalid_elements
          valid_elements : 'a[href|target=_blank],strong/b,div[align],br,p,ul,li,h2,h3,h4,h5,h6,h1',
          style_formats: [
            { title: 'Headings', items: [
                // TODO: Determine if h1 is bad for SEO, disallow?
                { title: 'Heading 1', format: 'h1' },
                { title: 'Heading 2', format: 'h2' },
                { title: 'Heading 3', format: 'h3' },
                { title: 'Heading 4', format: 'h4' },
                { title: 'Heading 5', format: 'h5' },
                { title: 'Heading 6', format: 'h6' }
              ]},
            { title: 'Inline', items: [
                { title: 'Bold', format: 'bold' },
                { title: 'Italic', format: 'italic' },
                { title: 'Underline', format: 'underline' },
                { title: 'Strikethrough', format: 'strikethrough' },
                { title: 'Superscript', format: 'superscript' },
                { title: 'Subscript', format: 'subscript' },
                { title: 'Code', format: 'code' }
              ]},
            { title: 'Blocks', items: [
                { title: 'Paragraph', format: 'p' },
                { title: 'Blockquote', format: 'blockquote' },
                { title: 'Div', format: 'div' },
                { title: 'Pre', format: 'pre' }
              ]},
            { title: 'Align', items: [
                { title: 'Left', format: 'alignleft' },
                { title: 'Center', format: 'aligncenter' },
                { title: 'Right', format: 'alignright' },
                { title: 'Justify', format: 'alignjustify' }
              ]}
          ],
        }}
        initialValue={this.values.initialDetails}
        disabled={false}
        onEditorChange={this.onEditorChange}
      />
    )
  }

  onChange = (event: any) => {
    const name = event.target.name
    if (name === "isFree") {
      this.values![name] = event.target.checked
    } else {
      this.values[name] = event.target.value
    }
  }

  onEditorChange = (content: any, editor: any) => {
    // Logger.info(content, editor)
    this.values.details = content
  }

  onCoverChange = (file: any) => {
    console.log("onCoverChange")
    this.values.coverFile = file
  }

  onHandoutChange = (file: any) => {
    this.values.handoutFile = file
  }

  onSubmit = async () => {
    const { courseId, courseStore, userStore, notify } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values

    if (courseId) {
      Tracking.event({action: 'UpdateCourse'})
      this.isProcessing = true

      if (values.coverFile) {
        const result = await this.uploadCover()
        if (!result) {
          this.isProcessing = false
          return
        }
      }

      if (values.handoutFile) {
        const result = await this.uploadHandout()
        if (!result) {
          this.isProcessing = false
          return
        }
      } else {
        if (this.course!.handoutUrl && !values.handoutUrl) {
          // Delete handout
          await Storage.remove(this.course!.handoutUrl)
            .catch((err: Error) => {
              notify!.show("error", "Unable to delete handout")
            })
        }
      }

      const input: UpdateCourseInput = {
        id: courseId,
        title: values.title,
        description: values.description,
        details: values.details,
        coverUrl: values.coverUrl,
        isFree: values.isFree,
        creditHours: values.creditHours,
        handoutUrl: values.handoutUrl
      }
      if (values.publishDate !== "") {
        input.publishDate = getISODateFromDate(parse(values.publishDate, 'M/d/yyyy', new Date()))
      }
      if (values.sunsetDate !== "") {
        input.sunsetDate = getISODateFromDate(parse(values.sunsetDate, 'M/d/yyyy', new Date()))
      }
      if (this.lessons) {
        input.lessonCount = this.lessons.items.length
        input.lessons = JSON.stringify(this.lessons)
      }

      const update = await courseStore!.updateCourse(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to update course")
        })

      if (update) {
        this.isProcessing = false
        userStore!.createActivity(ActivityType.CourseEdit, courseId)
        notify!.show("success", "Course updated!")
        this.onCancel()
      }
    } else {
      Tracking.event({action: 'CreateCourse'})
      this.isProcessing = true

      if (values.courseId === "") {
        values.courseId = createUUID()
      }

      if (values.coverFile) {
        const result = await this.uploadCover()
        if (!result) {
          this.isProcessing = false
          return
        }
      }

      if (values.handoutFile) {
        const result = await this.uploadHandout()
        if (!result) {
          this.isProcessing = false
          return
        }
      }

      const input: CreateCourseInput = {
        id: values.courseId,
        title: values.title,
        description: values.description,
        details: values.details,
        coverUrl: values.coverUrl,
        isFree: values.isFree,
        lessonCount: 0,
        creditHours: values.creditHours,
        handoutUrl: values.handoutUrl
      }
      if (values.publishDate !== "") {
        input.publishDate = getISODateFromDate(parse(values.publishDate, 'M/d/yyyy', new Date()))
      }
      if (values.sunsetDate !== "") {
        input.sunsetDate = getISODateFromDate(parse(values.sunsetDate, 'M/d/yyyy', new Date()))
      }
      if (this.lessons) {
        input.lessonCount = this.lessons.items.length
        input.lessons = JSON.stringify(this.lessons)
      }

      const course = await courseStore!.createCourse(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to create course")
        })

      if (course) {
        this.isProcessing = false
        userStore!.createActivity(ActivityType.CourseCreate, course.id)
        notify!.show("success", "Course created!")
        this.onCancel()
      }
    }

  }

  onCancel = () => {
    ControlTower.route(`${Routes.courses}`)
  }

  onCSVLoaded = async (data: any) => {
    this.isValid = true
    this.progressValue = 0
    this.progressText = ""

    const courseId = this.values.courseId
    const lessons = []

    // Verify data
    if (!data || data.length <= 1 || data[0].length < 11 || data[1].length < 11) {
      this.progressText = "Invalid or empty CSV file"
      this.isValid = false
      return
    }

    const header = data[0]
    if (header[0] !== "Lesson" || header[1] !== "Title" || header[2] !== "Video URL" || header[3] !== "Video Duration" ||
      header[4] !== "Description" || header[5] !== "Number"|| header[6] !== "Question" || header[7] !== "Answer A" || header[8] !== "Answer B" ||
      header[9] !== "Answer C" || header[10] !== "Answer D" || header[11] !== "Correct Answer") {
      this.progressText = "Invalid CSV file header"
      this.isValid = false
      return
    }

    // Validate rows
    const lastRow: [] = data[data.length-1]
    const length = lastRow && lastRow.length > 1 ? data.length : data.length - 1
    let isValid = true
    let nextQuestionNumber = 1

    for (let n = 1; n < length && isValid; n++) {
      const row = data[n]

      if (row.length < 11) {
        this.progressText = `Missing columns on row ${n}`
        isValid = false
        continue
      }

      let lessonNumber = 0
      let title
      let videoUrl
      let videoDuration = 0
      let description
      let number = 0
      let question
      let answers
      let correctAnswer
      let lesson: APILesson

      if (row[CSV.Lesson] !== "") {
        // New lesson start

        lessonNumber = parseInt(row[CSV.Lesson])
        if (isNaN(lessonNumber)) {
          this.progressText = `Invalid Lesson number on row ${n}`
          isValid = false
          continue
        }

        title = row[CSV.Title]
        if (title.length === 0) {
          this.progressText = `Title is missing on row ${n}`
          isValid = false
          continue
        }

        videoUrl = row[CSV.VideoURL]
        if (videoUrl.length === 0) {
          this.progressText = `Video URL is missing on row ${n}`
          isValid = false
          continue
        }

        // Try parsing it as time (m:s)
        const time = parse(row[CSV.VideoDuration], "m:s", new Date())
        if (!isNaN(time.getTime())) {
          videoDuration = time.getMinutes() * 60 + time.getSeconds()
        } else {
          // Try parsing it as integer seconds
          videoDuration = parseInt(row[CSV.VideoDuration])
          if (isNaN(videoDuration)) {
            this.progressText = `Invalid Video Duration on row ${n}`
            isValid = false
            continue
          }
        }

        description = row[CSV.Description]
        if (description.length === 0) {
          this.progressText = `Description is missing on row ${n}`
          isValid = false
          continue
        }
      }

      if (row[CSV.Number] !== "") {
        number = parseInt(row[CSV.Number])
        if (isNaN(number) || number !== nextQuestionNumber) {
          this.progressText = `Number is invalid on row ${n}`
          isValid = false
          continue
        }
        nextQuestionNumber++

        question = row[CSV.Question]
        if (question.length === 0) {
          this.progressText = `Question is missing on row ${n}`
          isValid = false
          continue
        }

        answers = new Array<string>(2)

        answers[0] = row[CSV.AnswerA]
        if (answers[0].length === 0) {
          this.progressText = `Answer A is missing on row ${n}`
          isValid = false
          continue
        }

        answers[1] = row[CSV.AnswerB]
        if (answers[1].length === 0) {
          this.progressText = `Answer B is missing on row ${n}`
          isValid = false
          continue
        }

        // Answers C & D are optional
        if (row[CSV.AnswerC] !== "") {
          answers[2] = row[CSV.AnswerC]
        }
        if (row[CSV.AnswerD] !== "") {
          answers[3] = row[CSV.AnswerD]
        }

        // Answers are 1-based
        correctAnswer = correctAnswers.findIndex((answer: string) => row[CSV.CorrectAnswer] === answer )
        if (correctAnswer < 1 ) {
          this.progressText = `Correct Answer is invalid on row ${n}`
          isValid = false
          continue
        }
      }

      if (row[CSV.Lesson] !== "") {
        const lessonId = `${courseId}-${lessonNumber}`
        lesson = {
          __typename: "Lesson",
          id: lessonId,
          courseId: courseId,
          number: lessonNumber,
          title: title,
          videoUrl: videoUrl,
          videoDuration: videoDuration,
          description: description,
          questions: {
            __typename: "ModelQuestionConnection",
            items: []
          }
        }

        if (row[CSV.Number] !== "") {
          lesson.questions!.items!.push({
            __typename: "Question",
            id: `${courseId}-${lessonNumber}-${number}`,
            lessonId,
            number: number,
            question: question,
            answers: answers,
            correctAnswer: correctAnswer
          })
        }

        lessons.push(lesson)
      } else {
        // Add an additional question to the prior lesson
        if (lessons.length === 0) {
          this.progressText = `Missing lesson on row ${n}`
          isValid = false
          continue
        }

        lesson = lessons[lessons.length - 1]
        lesson.questions!.items!.push({
          __typename: "Question",
          id: `${courseId}-${lesson.number}-${number}`,
          lessonId: lesson.id,
          number: number,
          question: question,
          answers: answers,
          correctAnswer: correctAnswer
        })
      }
    }

    if (isValid) {
      // this.data = data
      this.progressText = `${lessons.length} lessons ready to import`
      this.lessons = {
        items: lessons
      }
      console.log("Lessons = " + JSON.stringify(lessons))
    } else {
      this.isValid = false
      this.lessons = undefined
    }

  }

  uploadCover = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const key = `content/${this.values.courseId}/cover.jpg`

    const result = await Storage.put(key, this.values.coverFile, {
      contentType: 'image/jpeg'
    })
      .catch((err: Error) => {
      notify!.show("error", "Unable to upload cover image")
    })

    if (result) {
      this.values.coverUrl = result["key"]
      s3UrlCacheStore!.remove(this.values.coverUrl)
    }
    return result
  }

  downloadCSV = () => {
    if (!this.course) {
      return
    }

    let text = "Lesson,Title,Video URL,Video Duration,Description,Number,Question,Answer A,Answer B,Answer C,Answer D,Correct Answer\n"

    this.course.lessons.forEach((value: Lesson) => {
      console.log('Do nothing')
    })

    // for (let l: Lesson in this.course.lessons) {
    this.course.lessons.forEach((value: Lesson) => {
      let row = Array(CSV._Length).fill("")
      row[CSV.Lesson] = value.number
      row[CSV.Title] = value.title
      row[CSV.VideoURL] = value.videoUrl
      const videoDuration = value.videoDuration ?? 0
      row[CSV.VideoDuration] = `${Math.floor(videoDuration / 60)}:${Math.round(videoDuration % 60)}`
      row[CSV.Description] = value.description
      if (value.questions && value.questions.length > 0) {
        const q = value.questions[0]
        row[CSV.Number] = q.number
        row[CSV.Question] = q.question
        row[CSV.AnswerA] = q.answers && q.answers.length >= 0 ? q.answers[0] : ""
        row[CSV.AnswerB] = q.answers && q.answers.length >= 1 ? q.answers[1] : ""
        row[CSV.AnswerC] = q.answers && q.answers.length >= 2 ? q.answers[2] : ""
        row[CSV.AnswerD] = q.answers && q.answers.length >= 3 ? q.answers[3] : ""
        row[CSV.CorrectAnswer] = (q.correctAnswer >= 1 && q.correctAnswer <= 4) ? correctAnswers[q.correctAnswer] : ""
      }
      text += `${row[CSV.Lesson]},"${row[CSV.Title]}","${row[CSV.VideoURL]}","${row[CSV.VideoDuration]}","${this.quote(row[CSV.Description])}",${row[CSV.Number]},"${this.quote(row[CSV.Question])}",${this.quote(row[CSV.AnswerA])},${this.quote(row[CSV.AnswerB])},${this.quote(row[CSV.AnswerC])},${this.quote(row[CSV.AnswerD])},${row[CSV.CorrectAnswer]}\n`

      // Output additional questions
      if (value.questions && value.questions.length > 1) {
        for (let i = 1; i < value.questions.length; i++) {
          row = Array(CSV._Length).fill("")
          const q = value.questions[i]
          row[CSV.Number] = q.number
          row[CSV.Question] = q.question
          row[CSV.AnswerA] = q.answers && q.answers.length >= 0 ? q.answers[0] : ""
          row[CSV.AnswerB] = q.answers && q.answers.length >= 1 ? q.answers[1] : ""
          row[CSV.AnswerC] = q.answers && q.answers.length >= 2 ? q.answers[2] : ""
          row[CSV.AnswerD] = q.answers && q.answers.length >= 3 ? q.answers[3] : ""
          row[CSV.CorrectAnswer] = q.correctAnswer
          row[CSV.CorrectAnswer] = (q.correctAnswer >= 1 && q.correctAnswer <= 4) ? correctAnswers[q.correctAnswer] : ""
          text += `${row[CSV.Lesson]},"${row[CSV.Title]}","${row[CSV.VideoURL]}","${row[CSV.VideoDuration]}","${this.quote(row[CSV.Description])}",${row[CSV.Number]},"${this.quote(row[CSV.Question])}",${this.quote(row[CSV.AnswerA])},${this.quote(row[CSV.AnswerB])},${this.quote(row[CSV.AnswerC])},${this.quote(row[CSV.AnswerD])},${row[CSV.CorrectAnswer]}\n`
        }
      }
    })

    const blob = new Blob([text], {type: "text/csv;charset=utf-8"});
    saveAs(blob, `${this.values.title}.csv`);
  }

  uploadHandout = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const key = `content/${this.values.courseId}/handout.pdf`

    const result = await Storage.put(key, this.values.handoutFile, {
      contentType: 'application/pdf'
    })
      .catch((err: Error) => {
        notify!.show("error", "Unable to upload handout")
      })

    if (result) {
      this.values.handoutUrl = result["key"]
      s3UrlCacheStore!.remove(this.values.handoutUrl)
    }
    return result
  }

  downloadHandout = async () => {
    const { notify } = this.props

    if (this.values.handoutUrl) {
      const result = await Storage.get(this.values.handoutUrl, {download: true})
        .catch((err: Error) => {
          notify!.show("error", "Unable to download handout")
        })

      if (result && result.Body) {
        const filename = `${this.course!.title} Handout.pdf`
        this.downloadBlob(result.Body as Blob, filename);
      }
    }
  }

  downloadBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  deleteHandout = async () => {
    if (this.values.handoutUrl) {
      this.values.handoutUrl = ""
      this.values.handoutFile = undefined
    } else if (this.values.handoutFile) {
      this.values.handoutFile = undefined
    }
  }

  quote = (text: string): string => {
    if (!text || typeof text !== "string") {
      return text
    } else {
      return text.replace(/"/g, '""')
    }
  }
}

export default withTheme((withStyles(styles)(CourseEditPage)))