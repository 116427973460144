import * as React from 'react'
import Page from '../../components/page/Page'
import {
  Button,
  Card, CardActions, CardContent, CardMedia,
  createStyles, Grid,
  Theme, Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import CourseStore from "../../stores/CourseStore";
import {inject, observer} from "mobx-react";
import {observable, when} from "mobx";
import Course from "../../model/Course";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import Notify from "../../components/notify/Notify";
import parseHTML from "html-react-parser";
import ControlTower, {Routes} from "../../components/ControlTower";
import AccountStore from "../../stores/AccountStore";
import {numberToMoneyFormat} from "../../stores/StoreUtilities";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    padding: theme.spacing(2)
  },
  cardActions: {
    padding: theme.spacing(2)
  }
})

interface ICourseDetailPageProps {
  courseId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  s3UrlCacheStore?: S3UrlCacheStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "courseStore", "progress", "notify", "s3UrlCacheStore")
@observer
class CourseDetailPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICourseDetailPageProps & WithTheme> {

  @observable isLoading = true
  @observable course?: Course
  @observable imageUrl?: string
  @observable price: number = 0

  componentDidMount() {
    const {courseId, userStore, courseStore, s3UrlCacheStore, progress} = this.props
    this.isLoading = true
    progress!.show("CourseDetailPage")
    when(
      () => !userStore!.isLoading,
      async () => {
        if (courseId) {
          this.course = await courseStore!.getCourse(courseId!)
          if (this.course) {
            if (this.course.coverUrl) {
              s3UrlCacheStore!.get(this.course.coverUrl)
                .then((url: string | undefined) => {
                  this.imageUrl = url
                })
                .catch((err: Error) => {
                  console.log("Error loading cover image")
                })
            }
            this.price = await this.getCoursePrice(this.course)
          }
        }
        this.isLoading = false
        progress!.hide("CourseDetailPage")
      }
    )
  }

  render() {
    const {classes, accountStore} = this.props

    if (!this.course) {
      return (null)
    }

    const formattedPrice = numberToMoneyFormat(this.price)

    const title = this.course.title
    const course = this.course

    return (
      <Page title={title}>
        <MarginRow >
          <Card className={classes.card}>
            <Grid container direction="row">
              <Grid item xs={12} sm={7}>
                <CardMedia
                  component="img"
                  image={this.imageUrl}
                  alt="Course Cover"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h3" component="div" style={{marginBottom: 8}}>
                    {course.title}
                  </Typography>
                  <Typography variant="h4" color="textPrimary" style={{marginBottom: 8}}>
                    {formattedPrice}
                  </Typography>
                  <Typography variant="body1" color="textPrimary" style={{marginBottom: 8}}>
                    {course.description}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      ControlTower.route(`${Routes.account}/${accountStore!.account!.id}/classEdit?courseId=${course.id}`)
                    }}
                  >
                    Purchase
                  </Button>
                </CardActions>
              </Grid>
              <Grid item xs={12}>
                {course.details &&
                <CardContent className={classes.cardContent}>
                  <Typography variant="h4">
                    Details
                  </Typography>
                  <Typography component="div">
                    { parseHTML(course.details) }
                  </Typography>
                </CardContent>
                }
              </Grid>
            </Grid>
          </Card>
        </MarginRow>
      </Page>
    )
  }

  getCoursePrice = async (course: Course) => {
    const { accountStore, courseStore } = this.props

    let price = 0

    if (!course.isFree) {
      const priceData = await courseStore!.getCoursePrice(course, accountStore!.account)
        .catch((err: any) => {
          console.log(`Error getting course price: ${err.message}`)
        })

      if (priceData) {
        price = priceData.unit_amount / 100.0
      }
    }
    console.log(`Course price: ${price}`)
    return price
  }
}


export default withTheme((withStyles(styles)(CourseDetailPage)))