import * as React from 'react'
import {
  Box,
  createStyles,
  Grid, 
  MenuItem,
  Select,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import CourseStore from "../../stores/CourseStore";
import Notify from "../../components/notify/Notify";
import Activity from "../../model/Activity";
import {getISODateFromDate, humanizeString, isoToLocalDateTime} from "../../stores/StoreUtilities";
import {addDays, format} from "date-fns";
import MaterialTable from "material-table";
import {useState} from "react";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  paper: {
    width: "100%",
    height: "100%"
  },
  toolBar: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    width: "100%"
  },
  title: {
  },
  range: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center"
  },
  selectField: {
  },

})

interface IActivityReportProps {
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  notify?: Notify
}

const ActivityTypeFilter = (props: any) => {
  const [selectedVal, setSelectedVal] = useState(0);

  let handleChange = (e: any) => {
    const val = e.target.value;
    console.log(`handleChange(${val})`)
    setSelectedVal(val);
    props.onFilterChanged(props.columnDef.tableData.id, val);
  }

  return (
    <th>
      <Select value={selectedVal} onChange={handleChange}>
        <MenuItem value={0}>All Types</MenuItem>
        <MenuItem value={'Account Create'}>Account Create</MenuItem>
        <MenuItem value={'Class Complete'}>Class Complete</MenuItem>
        <MenuItem value={'Class Create'}>Class Create</MenuItem>
        <MenuItem value={'Class Edit'}>Class Edit</MenuItem>
        <MenuItem value={'Class Payment'}>Class Payment</MenuItem>
        <MenuItem value={'Class Registration'}>Class Registration</MenuItem>
        <MenuItem value={'Course Create'}>Course Create</MenuItem>
        <MenuItem value={'Course Edit'}>Course Edit</MenuItem>
        <MenuItem value={'Lesson Complete'}>Lesson Complete</MenuItem>
        <MenuItem value={'User Create'}>User Create</MenuItem>
        <MenuItem value={'User Register'}>User Register</MenuItem>
        <MenuItem value={'User Sign In'}>User Sign In</MenuItem>
      </Select>
    </th>
  );
};


@inject("userStore", "accountStore", "courseStore", "progress", "notify")
@observer
class ActivityReport extends React.Component<WithStyles<typeof styles> & IActivityReportProps & WithTheme> {

  @observable account?: Account
  @observable activity: Activity[] = []
  @observable data: any[] = []
  @observable isLoading = true
  @observable values = {
    numDays: "-1"
  }

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("ActivityReport")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading,
      async () => {
        this.account = accountStore!.account
        await this.loadActivity()
      }
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Box width="100%" p={1} mt={1} mb={2} bgcolor="background.paper" borderRadius={10}>
          <MaterialTable
            style={{
              boxShadow: 'none',
              borderRadius: 0
            }}
            title="Activity Report"
            columns={[
              { title: 'When', field: 'createdAt' },
              { title: 'Activity', field: 'activityType', filterComponent: (props) => <ActivityTypeFilter {...props} />},
              { title: 'Account', field: 'accountName'},
              { title: 'User', field: 'userName'},
            ]}
            data={this.data}
            options={{
              showTitle: true,
              search: false,
              sorting: true,
              filtering: true,
              padding: "dense",
              pageSize: 100,
              pageSizeOptions: [10,25,50,100,250,500,1000],
              maxBodyHeight: "calc(100vh - 325px)"
            }}
            components={{
              Toolbar: props => (
                <Grid container direction="row" className={classes.toolBar}>
                  <Grid item xs={8} className={classes.title}>
                    <Typography variant="h3">
                      Activity Report
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.range}>
                    {/*<label>Range:&nbsp;&nbsp;</label>*/}
                    <Select
                      native
                      name="numDays"
                      value={this.values.numDays}
                      onChange={this.onChange}
                      inputProps={{
                        name: 'numDays',
                        id: 'numDays',
                      }}
                      className={classes.selectField}
                    >
                      <option value="-1">Today</option>
                      <option value="-7">Last 7 Days</option>
                      <option value="-30">Last 30 Days</option>
                    </Select>

                  </Grid>
                </Grid>
              ),
            }}
          />
        </Box>
      </div>
    )
  }

  onChange = (event: any) => {
    const name = event.target.name
    this.values[name] = event.target.value
    if (name === "numDays") {
      this.loadActivity()
    }
  }

  loadActivity = async () => {
    const { accountStore, progress } = this.props
    this.isLoading = true
    progress!.show("ActivityReport")
    this.activity = []
    const data: any[] = []
    const today = new Date()
    const numDays = parseInt(this.values.numDays)
    for (let day = 0; day > numDays; day--) {
      const date = addDays(today, day)
      const isoDate = getISODateFromDate(date)
      const activity = (await accountStore!.listActivityByDate(isoDate)).reverse()
      this.activity.push(...activity)
      activity.forEach((a: Activity) => {
        data.push({
          id: a.id,
          createdAt: format(isoToLocalDateTime(a.createdAt), 'MM/dd/yyyy HH:mm:ss'),
          activityType: humanizeString(a.activityType),
          accountName: a.account ? a.account.name : a.accountId,
          userName: a.user ? a.user.fullName : a.userId
        })
      })
    }
    progress!.hide("ActivityReport")
    this.isLoading = false
    this.data = data
  }

}

export default withTheme((withStyles(styles)(ActivityReport)))