import React from "react";
import { Avatar, Box, createStyles, Grid, Theme, Typography, withStyles, WithStyles, WithTheme, withTheme, withWidth, WithWidth } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import classnames from 'classnames'

const styles = (theme: Theme) => createStyles({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.25)'
  },
  darkBlue: {
    backgroundColor: blueGrey[700]
  },
  heading: {
    fontWeight: 'bold'
  }
})

interface IValuePropositionProps {
  avatarIcon: any
  customColorClass?: any
  heading?: string 
  text: string
}

class ValueProposition extends React.Component<WithStyles<typeof styles> & IValuePropositionProps & WithTheme & WithWidth> {
  
  render() {
    const { avatarIcon, classes, heading, text } = this.props 

    return (
      <Grid item>
        <Box width="100%">
          <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar className={ classnames(classes.avatar, this.colorClass()) }>
                {avatarIcon}
              </Avatar>
            </Grid>
            <Grid item>
              { heading && 
                <Typography variant='body1' className={classes.heading}>
                  {heading}
                </Typography>
              }
              <Box>
                <Typography variant='body1'>
                  {text}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    )    
  }

  colorClass(): any {
    const { classes, customColorClass } = this.props 
    if (customColorClass) {
      return customColorClass
    }
    return classes.darkBlue
  }
}

export default withTheme(withStyles(styles)(withWidth()(ValueProposition)))
