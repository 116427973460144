/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://15o0rqay26.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "AuthAPI",
            "endpoint": "https://sfukcy0667.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "BillingAPI",
            "endpoint": "https://9wx396ax7d.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "CertificateAPI",
            "endpoint": "https://ocavantaka.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "DocumentService",
            "endpoint": "https://nmkjgowen8.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bxjuzqse5zbotjp4g7xpigjvnm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hdfa2tqpkbhqnmhbu5pxbvh7yq",
    "aws_cognito_identity_pool_id": "us-west-2:f8a4e447-eead-4f8b-a54f-54d75455072b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_7bYPdGOFd",
    "aws_user_pools_web_client_id": "5cjgmnpo8ncb42jjlq8vh6u59v",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "govgig-storage101642-main",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
