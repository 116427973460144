import * as React from 'react'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 960,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    }
  }
})

class MarginRow extends React.Component<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.rootStyle}>
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles)(withWidth()(MarginRow))
