import * as React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
    primaryDialogButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      marginTop: 0,
      minWidth: 80,
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
      paddingTop: theme.spacing(0.625),
      paddingBottom: theme.spacing(0.625)
      // "&:hover": {
      //   color: theme.palette.primary.contrastText,
      //   backgroundColor: theme.palette.primary.light,
      // }
    },
    secondaryDialogButton: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      marginTop: 0,
      minWidth: 80,
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      "&:hover": {
        // color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[300]
      }
    },
    tertiaryDialogButton: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      marginTop: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
      "&:hover": {
        // color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[400]
      }
    }
})

interface IDialogButtonProps {
    onClick?: any
    type?: string, 
    variant?: any
    disabled?: boolean
    fullWidth?: boolean
    customClassName?: string
    id?: string
}
  
class DialogButton extends React.Component<WithStyles<typeof styles> & IDialogButtonProps> {
      render() {
        const { classes, onClick, disabled, fullWidth } = this.props

        const typeProp = this.props.type || "button"
        const variantProp = this.props.variant || "contained"
        const childrenProp = this.props.children || 'Button'

        let variant: any
        let className: any = this.props.customClassName ? this.props.customClassName : null
        let color: any
        let type: any

        switch (variantProp) {
            case "primary":
                variant = "contained"
                className = className ? className : classes.primaryDialogButton
                color = "primary"
                type = this.props.type || "submit"
                break
            case "secondary":
                variant = "outlined"
                className = className ? className : classes.secondaryDialogButton
                color = "primary"
                type = typeProp
                break;
            case "tertiary":
                variant = "text"
                className =  className ? className : classes.tertiaryDialogButton
                color = "primary"
                type = typeProp
                break;
            default:
                variant = variantProp
                className = className ? className : classes.primaryDialogButton
                color = "primary"
                type = typeProp
        }
    
        return (
            <Button type={type} 
                className={className}
                size="small"
                variant={variant} 
                color={color} 
                onClick={onClick}
                disabled={disabled}
                fullWidth={fullWidth}
                id={this.props.id}
            >
               {childrenProp}
            </Button>
        )
    }
}

export default withStyles(styles)(DialogButton)
