import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import AccountStore from "../../stores/AccountStore";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import {observable, when} from "mobx";
import Class from "../../model/Class";
import UserStore from "../../stores/UserStore";
import LessonCard from "../class/LessonCard";
import Lesson from "../../model/Lesson";
import Course from "../../model/Course";
import CourseStore from "../../stores/CourseStore";
import ControlTower, {Routes} from "../../components/ControlTower";
import NavigationBar from "../../components/NavigationBar";
import Registration from "../../model/Registration";
import RegistrationCard from "../../components/RegistrationCard";
import Confirm from "../../components/confirm/Confirm";
import TitleBar from "../../components/TitleBar";
import {ClassStatus, LessonStatus} from "../../API";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  registrationCard: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  content: {
    display: "flex",
    flexGrow: 1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2)
  },
  lessonCard: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  subtitle: {
    paddingLeft: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
})

interface ICoursePageProps {
  courseId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  courseStore?: CourseStore
  progress?: Progress
  confirm?: Confirm
}

@inject("userStore", "accountStore", "courseStore", "progress", "confirm")
@observer
class CoursePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICoursePageProps & WithTheme> {

  @observable isLoading = true
  @observable classObj?: Class
  @observable course?: Course
  @observable lessons: Lesson[] = []
  @observable registration?: Registration

  async componentDidMount () {
    const { userStore, accountStore, courseStore, courseId, progress } = this.props
    this.isLoading = true
    progress!.show("CoursePage")

    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        const course = await courseStore!.getCourse(courseId!)
        if (course) {
          this.course = course
          this.classObj = this.mockClass(course)
          const registration = this.mockRegistration(this.classObj)
          if (registration) {
            this.sortLessons(registration)
            this.registration = registration
            this.isLoading = false
          }
          progress!.hide("CoursePage")
        } else {
          // TODO: Handle class not found
          this.isLoading = false
          progress!.hide("CoursePage")
        }
      }
    )
  }

  render() {
    const { classes } = this.props

    const title = (this.classObj) ? `${this.classObj.course!.title} (Preview)` : ""
    const backTitle = ControlTower.currentRoute.startsWith(Routes.account) ? "Account Classes" : "Courses"

    if (!this.registration) {
      return null
    }

    return (
      <Page title={title}>
        {!this.isLoading && this.classObj && this.course && this.registration &&
        <MarginRow>
          <div className={classes.root}>
            <NavigationBar title={backTitle} onBack={this.onBack}/>
            <TitleBar title={title}></TitleBar>
            <div className={classes.registrationCard}>
              <RegistrationCard registration={this.registration} onCardAction={this.onRegistrationCardAction}/>
            </div>

            <Grid container className={classes.content} direction="row">
              {this.lessons.map((l: Lesson, index: number) => {
                return (
                  <Grid item xs={12} className={classes.lessonCard} key={l.id}>
                    {l.number === 1 && index > 0 &&
                      <Typography variant="body1" className={classes.subtitle}>Completed</Typography>
                    }
                    <LessonCard lesson={l} registration={this.registration!}
                                onCardAction={this.onLessonCardAction}
                                disabled={!this.registration!.classCompleted && l.number > this.registration!.lessonNumber}/>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </MarginRow>
        }
      </Page>
    )
  }

  mockClass = (course: Course) => {
    return new Class({
      courseId: course.id,
      course: course,
    })
  }

  mockRegistration = (classObj: Class) => {
    const { userStore } = this.props

    return new Registration({
      classId: classObj.id,
      class: classObj,
      classStatus: (userStore!.isAdminOrAgent) ? ClassStatus.Passed : ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: 1,
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      score: 0
    })
  }

  onRegistrationCardAction = (action: string, registration: Registration) => {
    if (action === "open") {
      if (this.lessons.length > 0) {
        this.onLessonCardAction(this.lessons[0])
      }
    }
  }

  onLessonCardAction = (lesson: Lesson) => {
    const { courseId, accountStore } = this.props

    const currentRoute = ControlTower.currentRoute
    if (currentRoute.startsWith(Routes.account)) {
      ControlTower.route(`${Routes.account}/${accountStore!.account!.id}/courses/${courseId}/lesson/${lesson.id}`)
    } else {
      ControlTower.route(`${Routes.courses}/${courseId}/lesson/${lesson.id}`)
    }

  }

  onBack = () => {
    const { accountStore } = this.props

    const currentRoute = ControlTower.currentRoute
    if (currentRoute.startsWith(Routes.account)) {
      ControlTower.route(`${Routes.account}/${accountStore!.account!.id}/classes`)
    } else {
      ControlTower.route(Routes.courses)
    }
  }

  sortLessons = (registration: Registration) => {
    // Sort by number with the next lesson at the beginning
    if (registration && this.course) {
      if (registration!.lessonNumber <= 1) {
        this.lessons = this.course!.lessons
      } else {
        const lessonNumber = registration!.lessonNumber
        const index = this.course!.lessons.findIndex((l: Lesson) => { return l.number === lessonNumber})
        if (index >= 0) {
          const lessons = [...this.course!.lessons.slice(index), ...this.course!.lessons.slice(0, index)]
          this.lessons = lessons
        } else {
          this.lessons = this.course!.lessons
        }
      }
    } else {
      this.lessons = []
    }
  }
}

export default withTheme((withStyles(styles)(CoursePage)))