import * as React from "react";
import {navigate, RouteComponentProps} from "@reach/router";
import UserStore from "../stores/UserStore";
import {inject} from "mobx-react";
import NotFoundPage from "../pages/NotFoundPage";
import SignInPage from "../pages/signIn/SignInPage";
import LoadingPage from "../pages/LoadingPage";
import Tracking from "./Tracking";
import config from "react-global-configuration";

export const Routes = {
  index: "/index.html",
  account: "/account",
  accountEdit: "/accountEdit",
  accounts: "/accounts",
  accountsEdit: "/accountsEdit",
  class: "/class",
  accountClassEdit: "/accountClassEdit",
  courseEdit: "/courseEdit",
  courses: "/courses",
  courseDetail: "/courseDetail",
  coursePurchase: "/coursePurchase",
  myClasses: "/myclasses",
  notFound: "/404",
  privacy: "/privacy",
  privacyCCPA: "/privacy/ccpa",
  register: "/register",
  reports: "/reports",
  signout: "/signout",
  signup: "/signup",
  terms: "/terms",
  signin: "/",
  home: "/", // Must be last
}

/**
 * undefined -> allows unauthenticated access
 * isAuthenticated -> requires authentication
 * isEmployer -> requires owner role
 * isAdmin -> requires admin role
 */
const RouteRequirements = {
  account: {isAuthenticated: true},
  accountEdit: {isAuthenticated: true, roles: ["Admin", "Agent", "Employer"]},
  accounts: {isAuthenticated: true, roles: ["Admin", "Agent"]},
  // TODO: See if accountsEdit is used
  accountsEdit: {isAuthenticated: true, roles: ["Admin", "Agent", "Employer"]},
  accountClassEdit: {isAuthenticated: true, roles: ["Admin", "Agent", "Employer"]},
  class: {isAuthenticated: true},
  courses: {isAuthenticated: true, roles: ["Admin", "Agent"]},
  courseEdit: {isAuthenticated: true, roles: ["Admin", "Agent"]},
  coursePurchase: {isAuthenticated: true},
  myClasses: {isAuthenticated: true},
  reports: {isAuthenticated: true, roles: ["Admin", "Agent"]}
}

interface IControlTowerProps {
  userStore?: UserStore
  children?: any
}

@inject("userStore")
class ControlTower extends React.Component<IControlTowerProps & RouteComponentProps> {

  private lastNavigatedRoute = "";

  static route = (to: string, options?: any) => {
    if (to.startsWith("http")) {
      window.location.href = to
    } else if (to === Routes.home) {
      let homeUrl = config.get('homeUrl')
      if (homeUrl) {
        window.location.href = homeUrl
      } else {
        navigate(Routes.signin, options)
      }
  } else {
      navigate(to, options)
    }
  }

  static back = () => {
    window.history.back()
  }

  static open = (to: string, target: string = "_blank") => {
    window.open(to, target)
  }

  static get currentRoute(): string {
    return window.location.pathname
  }

  render() {
    const route = "/" + this.props["*"]

    const {userStore} = this.props

   if (userStore && userStore!.isLoading) {
      console.log(`Routing ${route} to LoadingPage`)
      return <LoadingPage userStore={userStore} route={route}/>
    } else if (userStore && !userStore.isAuthenticated && this.routeRequiresLogin(route)) {
      console.log(`Routing ${route} to SignInPage for authentication`)
      return <SignInPage route={route}/>
   } else if (userStore && !this.hasRequiredRole(route)) {
     console.log(`Route ${route} requires role`)
     return <NotFoundPage />
    } else if (this.routeExists(route)) {
      console.log(`Routing to ${route}`)
      this.lastNavigatedRoute = route;
      Tracking.pageView(route)
      return (<React.Fragment>{this.props.children}</React.Fragment>)
    } else {
      console.log(`Route ${route} not found`)
      this.lastNavigatedRoute = Routes.notFound;
      return <NotFoundPage/>
    }
  }

  private routeExists= (route: string): boolean => {
    const routeKey = Object.keys(Routes).find(key => route.startsWith(Routes[key]))
    if (routeKey) {
      return true
    }

    return false
  }

  private routeRequiresLogin = (route: string): boolean => {
    // Check if the route has any requirements
    const routeKey = Object.keys(Routes).find(key => route.startsWith(Routes[key]))
    if (routeKey) {
      const routeRequirements = RouteRequirements[routeKey]
      if (routeRequirements) {
        // console.log(`${route} requires login`)
        return true
      }
    }

    return false
  }

  private hasRequiredRole = (route: string): boolean => {
    const { userStore } = this.props
    // Check if the route has any role requirements
    const routeMatch = Object.values(Routes).find(value => route.startsWith(value))
    if (routeMatch) {
      const routeRequirements = RouteRequirements[routeMatch]
      if (routeRequirements && routeRequirements.roles) {
        if (userStore!.user) {
          const role = userStore!.user.role
          if (!routeRequirements.roles.find((r: string) => r === role)) {
            return false
          }
        } else {
          // if no user loaded, then assume the requirement isn't met
          return false
        }
      }
    }
    return true
  }
}

export default ControlTower