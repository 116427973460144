import * as React from 'react'
import Page from '../components/page/Page'
import { RouteComponentProps } from '@reach/router'
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {when} from "mobx";
import ControlTower, {Routes} from "../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  progress: {
    marginTop: "calc((100vh - 140px)/2)"
  }
})

interface ILoadingPageProps {
  userStore?: any
  route?: string
}

class LoadingPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ILoadingPageProps> {

  componentDidMount() {
    console.log("LoadingPage mounting")
    when(
      // once...
      () => this.props.userStore !== undefined
        && !this.props.userStore!.isLoading
        && this.props.route !== undefined,
      // ... then
      () => {
        if (this.props.route) {
          console.log(`LoadingPage routing to ${this.props.route}`)
          ControlTower.route(this.props.route)
        } else {
          console.log(`LoadingPage routing to ${Routes.myClasses}`)
          ControlTower.route(Routes.myClasses)
        }
      }
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <Page hideFooter={true}>
        <div className={classes.content}>
          <CircularProgress className={classes.progress} />
        </div>
      </Page>
    )
  }
}

export default withStyles(styles)(LoadingPage)
