import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Logger from "./components/Logger";
import LearningAPI from "./apis/LearningAPI";
import UserStore from "./stores/UserStore";
import {Provider} from "mobx-react";
import Progress from "./components/Progress";
import Alert from "./components/alert/Alert";
import Notify from "./components/notify/Notify";
import Confirm from "./components/confirm/Confirm";
import AccountStore from "./stores/AccountStore";
import CourseStore from "./stores/CourseStore";
import S3UrlCacheStore from "./stores/S3UrlCacheStore";
import Tracking from "./components/Tracking";
import config from "react-global-configuration";
import PrivacyPolicy from "./components/page/PrivacyPolicy";
import TermsOfUse from "./components/page/TermsOfUse";
import BillingAPI from "./apis/BillingAPI";
import CertificateAPI from "./apis/CertificateAPI";

Logger.init()

const learningAPI = new LearningAPI()
const billingAPI = new BillingAPI()
const certificateAPI = new CertificateAPI()

const s3UrlCacheStore = new S3UrlCacheStore()

const courseStore = new CourseStore({
  learningAPI,
  billingAPI,
})

const userStore = new UserStore({
  learningAPI,
  billingAPI,
})

const accountStore = new AccountStore({
  learningAPI,
  courseStore,
  billingAPI,
  certificateAPI
})

const resources = {
  confirm: new Confirm(),
  notify: new Notify(),
  alert: new Alert(),
  progress: new Progress(),
  privacyPolicy: new PrivacyPolicy(),
  termsOfUse: new TermsOfUse(),
  userStore,
  accountStore,
  courseStore,
  s3UrlCacheStore
}

const trackingId = config.get("google.trackingId")
if (trackingId && trackingId !== "" && trackingId !== "NONE") {
  Tracking.initialize(trackingId, config.get("google.options"))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider {...resources}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
