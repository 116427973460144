import * as React from "react";
import {
  Button,
  Card, CardContent,
  createStyles, Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { observer} from "mobx-react";
import Lesson from "../../model/Lesson";
import Registration from "../../model/Registration";
import {observable} from "mobx";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    padding: "2px 4px 4px 4px",
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderWidth: 1,
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "0px 8px",
  },
  left: {
    flexShrink: 0,
    paddingRight: theme.spacing(1)
  },
  right: {
    flexGrow: 1
  },
  label: {
    color: theme.palette.secondary.main,
    fontWeight: 800,
  },
  question: {
    fontSize: 16,
    fontWeight: 800,
    paddingBottom: 4
  },
  answers: {
    paddingBottom: 0,
    marginBottom: 0
  },
  answerItem: {
    width: '100%',
    padding: "4px 0px"
  },
  answer: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[100],
    justifyContent: "left",
    textAlign: "left",
    lineHeight: 1.3,
    padding: "10px 10px",
    "&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    }
  },
  correctAnswer: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    justifyContent: "left",
    textAlign: "left",
    lineHeight: 1.3,
    "&:focus": {
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
    }
  },
  wrongAnswer: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    justifyContent: "left",
    textAlign: "left",
    lineHeight: 1.3,
    "&:focus": {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    }
  },
  cancelButton: {
    color: theme.palette.primary.main ,
    backgroundColor: theme.palette.background.paper
  }
})

interface IQuizCardProps {
  lesson: Lesson
  questionIndex: number
  registration: Registration
  showAnswer?: boolean
  onAnswer?: any
}

@observer
class QuizCard extends React.Component<WithStyles<typeof styles> & IQuizCardProps> {

  @observable registration?: Registration

  componentDidMount() {
    this.registration = this.props.registration
  }

  componentDidUpdate(prevProps: any) {
    console.log("QuizCard componentDidUpdate")
    this.registration = this.props.registration
  }

  render() {
    const { classes, lesson, questionIndex } = this.props

    const registration = this.registration!

    if (!lesson || !lesson.questions || !registration) {
      return null
    }

    const question = lesson.questions[questionIndex]

    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div className={classes.left}>
              <Typography className={classes.label}>
                Question {question.number}:
              </Typography>
            </div>
            <div className={classes.right}>
              <Typography className={classes.question}>
                {question.question}
              </Typography>
            </div>
            <Grid container direction="column" className={classes.answers}>
              {question.answers.map((answer: string, index) => {
                return (
                  <Grid item xs={12} className={classes.answerItem} key={index}>
                    <Button
                      name={`answer-${index}`}
                      variant="contained"
                      className={this.getAnswerClassName(registration, index)}
                      classes={{root: classes.answer}}
                      fullWidth={true}
                      onClick={(event) => this.onAnswer(event, index)}
                    >
                      {answer}
                    </Button>
                  </Grid>
                )
              })}
              {registration.lessonStatus === "Quiz" &&
                <Grid item xs={12} className={classes.answerItem} key={-1}>
                  <Button
                    name="cancel"
                    variant="outlined"
                    className={classes.cancelButton}
                    fullWidth={true}
                    onClick={(event) => this.onAnswer(event,-1)}
                  >
                    Cancel
                  </Button>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }

  getAnswerClassName = (registration: Registration, index: number) => {
    const { lesson, questionIndex, classes, showAnswer } = this.props
    let className = classes.answer

    if (showAnswer) {
      return index + 1 === lesson.questions[questionIndex].correctAnswer ? classes.correctAnswer : classes.answer
    } else {
      // Only show answer after question has been answered
      const questionNumber = lesson.questions[questionIndex].number
      if (lesson && registration && (registration.lessonCompleted || questionNumber === registration.answers.length)) {
        if (registration.answers.length >= questionNumber) {
          // Negative values indicate a wrong answer
          const answer = registration.getAnswer(questionNumber)
          // Only show the result of the answer they gave
          if (Math.abs(answer) === index+1) {
            className = (answer >= 0) ? classes.correctAnswer : classes.wrongAnswer
          }
        }
      }
    }

    return className
  }

  onAnswer = (event: any, index: number) => {
    if (this.props.onAnswer) {
      this.props.onAnswer(index + 1)
    }
  }

}

export default withStyles(styles)(QuizCard)
