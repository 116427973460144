import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  Grid,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import Class from "../../model/Class";
import User from "../../model/User";
import Registration from "../../model/Registration";
import RosterCard from "./RosterCard";
import SearchBar from "material-ui-search-bar";
import TitleButton from "../../components/TitleButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import {ActivityType, ClassStatus, CreateRegistrationInput, LessonStatus, UpdateClassInput} from "../../API";
import NavigationBar from "../../components/NavigationBar";
import Tracking from "../../components/Tracking";
import LinearGaugeSegment from "../../components/widgets/LinearGaugeSegment";
import ClassCard from "../class/ClassCard";
import Confirm from "../../components/confirm/Confirm";
import {isoToLocalDate} from "../../stores/StoreUtilities";
import {isBefore, startOfToday} from "date-fns";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start'
  },
  classCardContainer : {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
  titleBar: {
    display: "flex",
    flex: "none",
    justifyContent: "space-between",
    width: "100%",
    // maxWidth: 480,
    height: 40,
    padding: theme.spacing(1),
    paddingTop: 0,
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.error.main,
    marginTop: 10
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  content: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'top',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2)
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  controlBar: {
    display: "flex",
    flex: "none",
    flexDirection: "row",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    height: 30,
    width: "100%",
    maxWidth: 960,
    paddingRight: theme.spacing(1)
  },
  search: {
    flexGrow: 1,
  },
  searchBar: {
    height: 30,
  },
  actions: {
    flexGrow: 0,
    alignItems: "center",
    alignContent: "center",
  },
  subtitle: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  quantity: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600,
    textAlign: "right",
    paddingRight: theme.spacing(1)
  },
  gaugeBar: {
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  linearGauge: {
    backgroundColor: theme.palette.background.default,
    width: "calc(100% - 8px)",
    height: 20,
    padding: 0,
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  notStartedSegment: {
    display: "inline-block",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
    padding: "2px 4px",
    borderWidth: 0,
    overflow: "hidden"
  },
  inProgressSegment: {
    display: "inline-block",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
    padding: "2px 4px",
    borderWidth: 0,
    whiteSpace: "nowrap",
    textOverflow: "",
    overflow: "hidden"
  },
  passedSegment: {
    display: "inline-block",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
    padding: "2px 4px",
    borderWidth: 0,
    overflow: "hidden"
  },
  failedSegment: {
    display: "inline-block",
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
    padding: "2px 4px",
    borderWidth: 0,
    whiteSpace: "nowrap",
    textOverflow: "",
    overflow: "hidden"
  }
})

interface IAccountClassPageProps {
  accountId?: string
  classId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
}

@inject("accountStore", "userStore", "progress", "notify", "confirm")
@observer
class AccountClassPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountClassPageProps & WithTheme> {

  @observable isLoading = true
  @observable classObj?: Class
  @observable users: User[] = []
  @observable registered: Registration[] = []
  @observable registeredFilter: Registration[] = []
  @observable unregistered: Registration[] = []
  @observable unregisteredFilter: Registration[] = []
  @observable notStarted: Registration[] = []
  @observable inProgress: Registration[] = []
  @observable passed: Registration[] = []
  @observable failed: Registration[] = []
  @observable search?: string
  @observable isEnded: boolean = false


  componentDidMount () {
    const { accountId, classId, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("AccountClassPage")
    when(
      () => !accountStore!.isLoading && accountId !== undefined && classId !== undefined,
      async () => {
        await accountStore!.loadAccount(accountId!)
        this.classObj = await accountStore!.getClassAndRegistrations(classId!)
        this.registered = this.classObj!.registrations
        if (this.classObj) {
          if (this.classObj.termEnd) {
            const termEnd = isoToLocalDate(this.classObj.termEnd)
            this.isEnded = isBefore(termEnd, startOfToday())
          }
          this.users = await accountStore!.listUsers(this.classObj.accountId)
          this.joinUsers()
          this.filterUsers()
        }
        this.isLoading = false
        progress!.hide("AccountClassPage")
      }
    )
  }


  render() {
    const { classes } = this.props

    if (!this.classObj || !this.classObj.course) {
      return null
    }

    const title = this.classObj.course.title
    const notStartedPercent = (this.registered.length > 0) ? Math.round(this.notStarted.length / this.registered.length * 100.0) : 0
    const inProgressPercent = (this.registered.length > 0) ? Math.round(this.inProgress.length / this.registered.length * 100.0) : 0
    const passedPercent = (this.registered.length > 0) ? Math.round(this.passed.length / this.registered.length * 100.0) : 0
    const failedPercent = (this.registered.length > 0) ? Math.round(this.failed.length / this.registered.length * 100.0) : 0

    return (
      <Page title={title}>
        <MarginRow>
          <div className={classes.root}>
            <NavigationBar title="Account Classes" onBack={this.onBack}/>
            <div className={classes.classCardContainer}>
              <ClassCard classObj={this.classObj} onCardAction={this.onCardAction}/>
            </div>

            {/*<div className={classes.gaugeBar}>*/}
            {/*  <LinearGauge className={classes.linearGauge}>*/}
            {/*    {this.renderGaugeSegments()}*/}
            {/*  </LinearGauge>*/}
            {/*</div>*/}

            <div className={classes.controlBar}>
              <div className={classes.search}>
                <SearchBar value={this.search} className={classes.searchBar}
                           onChange={this.onSearchChange} onCancelSearch={this.onCancelSearch} />
              </div>
              <div className={classes.actions}>
                <TitleButton title="+ Add" onClick={this.onAddStudent}/>
              </div>
            </div>

            <Grid container direction="row">
              <Grid item xs={10}>
                <Typography variant="body1" className={classes.subtitle}>Not Started ({notStartedPercent}%)</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" className={classes.quantity}>{this.notStarted.length}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              {this.notStarted.map((r: Registration) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className={classes.card} key={r.id}>
                    <RosterCard registration={r!} onUnregister={this.onUnregister}/>
                  </Grid>
                )
              })}
            </Grid>

            <Grid container direction="row">
              <Grid item xs={10}>
                <Typography variant="body1" className={classes.subtitle}>In Progress ({inProgressPercent}%)</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" className={classes.quantity}>{this.inProgress.length}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              {this.inProgress.map((r: Registration) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className={classes.card} key={r.id}>
                    <RosterCard registration={r!} onUnregister={this.onUnregister}/>
                  </Grid>
                )
              })}
            </Grid>

            <Grid container direction="row">
              <Grid item xs={10}>
                <Typography variant="body1" className={classes.subtitle}>Passed ({passedPercent}%)</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" className={classes.quantity}>{this.passed.length}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              {this.passed.map((r: Registration) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className={classes.card} key={r.id}>
                    <RosterCard registration={r!}/>
                  </Grid>
                )
              })}
            </Grid>

            <Grid container direction="row">
              <Grid item xs={10}>
                <Typography variant="body1" className={classes.subtitle}>Failed ({failedPercent}%)</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" className={classes.quantity}>{this.failed.length}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              {this.failed.map((r: Registration) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className={classes.card} key={r.id}>
                    <RosterCard registration={r!}/>
                  </Grid>
                )
              })}
            </Grid>

            <Grid container direction="row">
              <Grid item xs={10}>
                <Typography variant="body1" className={classes.subtitle}>Unassigned</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" className={classes.quantity}>{this.unregisteredFilter.length}</Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.content} direction="row" spacing={1}>
              {this.unregisteredFilter.map((r: Registration) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className={classes.card} key={r.userId}>
                    <RosterCard registration={r!} onRegister={this.onRegister}/>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </MarginRow>
      </Page>
    )
  }

  private onCardAction = (action: string, classObj: Class) => {
    if (action === "edit") {
      ControlTower.route(`${Routes.account}/${this.props.accountId}/classEdit/${classObj.id}`)
    }
  }

  onSearchChange = (value: string) => {
    this.search = value
    // localStorage.setItem(ClientsPageConstants.CLIENTS_SEARCH_NAME, this.search)
    this.filterUsers()
  }

  onCancelSearch = () => {
    this.search = undefined
    // localStorage.removeItem(ClientsPageConstants.CLIENTS_SEARCH_NAME)
    this.filterUsers()
  }

  onBack = () => {
    const { accountId } = this.props
    ControlTower.route(`${Routes.account}/${accountId}/classes`)
  }

  onAddStudent = () => {
    const { accountId, classId, confirm, notify } = this.props

    if (this.isEnded) {
      notify!.show("warning", "The term for this class has ended and no students may be added. Please add a new class.")
      return
    }

    if (!this.classObj!.course!.isFree) {
      // Check if there are seats available
      if (this.classObj!.seatsPurchased && this.classObj!.seatsFilled >= this.classObj!.seatsPurchased) {
        confirm!.show("More Seats Needed",
          `There are no more seats available in this class. Press EDIT to add more seats.`,
          ["Edit", "Cancel"],
          () => {
            this.onCardAction("edit", this.classObj!)
            return true
          },
          () => {
            // Do nothing
          })
      } else {
        ControlTower.route(`${Routes.account}/${accountId}/userEdit?classId=${classId}`)
      }
    } else {
      ControlTower.route(`${Routes.account}/${accountId}/userEdit?classId=${classId}`)
    }

  }

  onRegister = async (reg: Registration) => {
    const { confirm, notify } = this.props
    Tracking.event({action: 'Register'})

    if (this.isEnded) {
      notify!.show("warning", "The term for this class has ended and no students may be registered. Please add a new class.")
      return
    }

    if (!this.classObj!.course!.isFree) {
      // Check if there are seats available
      if (this.classObj!.seatsPurchased && this.classObj!.seatsFilled >= this.classObj!.seatsPurchased) {
        confirm!.show("More Seats Needed",
          `There are no more seats available in this class. Press EDIT to add more seats.`,
          ["Edit", "Cancel"],
          () => {
            this.onCardAction("edit", reg.class!)
            return true
          },
          () => {
            // Do nothing
          })
      } else {
        this.registerUser(reg)
      }
    } else {
      this.registerUser(reg)
    }
  }

  registerUser = async (reg: Registration) => {
    const { accountStore, userStore, notify } = this.props
    Tracking.event({action: 'Register'})

    const input: CreateRegistrationInput = {
      accountId: reg.accountId,
      classId: reg.classId,
      userId: reg.userId,
      classStatus: ClassStatus.NotStarted,
      classProgress: 0,
      lessonNumber: 1,
      lessonStatus: LessonStatus.NotStarted,
      videoProgress: 0,
      score: 0,
      lessonsAssigned: this.classObj!.getRegistrationLessonsAssigned()
    }

    const registration = await accountStore!.addClassRegistration(input)
      .catch(err => {
        notify!.show("error", err.message)
      })

    if (registration) {
      registration.user = reg.user
      userStore!.createActivity(ActivityType.ClassRegistration, registration.classId)
      // Remove from unregistered list
      const unregisteredIndex = this.unregistered.findIndex((r: Registration) => {return r.userId === registration.userId})
      if (unregisteredIndex >= 0) {
        this.unregistered.splice(unregisteredIndex, 1)
      }

      // Add to registered list
      this.registered.push(registration)

      // Update seatsFilled
      const update: UpdateClassInput = {
        id: this.classObj!.id,
        accountId: this.classObj!.accountId,
        seatsFilled: this.classObj!.seatsFilled ? this.classObj!.seatsFilled + 1 : 1
      }
      const result = await accountStore?.updateClass(update)
      if (result) {
        result.registrations = this.classObj!.registrations
        result.course = this.classObj!.course
        this.classObj = undefined
        this.classObj = result
        console.log(`Updated classObj: seatsFilled = ${result.seatsFilled}`)
      }

      // Update view
      this.filterUsers()
    }
  }

  onUnregister = async (reg: Registration) => {
    const { confirm } = this.props

    confirm!.show("Confirm Unregister",
      `Please confirm you want unregister ${reg.user!.fullName}? Any progress will be lost.`,
      ["Confirm", "Cancel"],
      () => {
        this.unRegisterUser(reg)
        return true
      },
      () => {
        // Do nothing
      })
  }

  unRegisterUser = async (reg: Registration) => {
    const { accountStore, notify } = this.props
    Tracking.event({action: 'Unregister'})

    const registration = await accountStore!.deleteClassRegistration(reg.id)
      .catch(err => {
        notify!.show("error", "Unable to unregister user")
      })

    if (registration) {
      registration.user = reg.user

      // Remove from registered list
      const registeredIndex = this.registered.findIndex((r: Registration) => {return r.userId === registration.userId})
      if (registeredIndex >= 0) {
        this.registered.splice(registeredIndex, 1)
      }

      // Add to unregistered list
      registration.id = ""
      this.unregistered.push(registration)

      // Update seatsFilled
      const update: UpdateClassInput = {
        id: this.classObj!.id,
        accountId: this.classObj!.accountId,
        seatsFilled: this.classObj!.seatsFilled ? this.classObj!.seatsFilled - 1 : 0
      }
      const result = await accountStore?.updateClass(update)
      if (result) {
        result.registrations = this.classObj!.registrations
        result.course = this.classObj!.course
        this.classObj = undefined
        this.classObj = result
        console.log(`Updated classObj: seatsFilled = ${result.seatsFilled}`)
      }

      // Update view
      this.filterUsers()
    }
  }

  filterUsers = () => {
    if (this.search) {
      let registered: Registration[] = []
      let unregistered: Registration[] = []

      const search = this.search.toLowerCase()
      this.registered.forEach((r: Registration) => {
        const u = r.user!
        if (u.fullName.toLowerCase().indexOf(search!) >= 0 ||
          (u.jobTitle && u.jobTitle.toLowerCase().indexOf(search!) >= 0)) {
          registered.push(r)
        }
      })
      this.registeredFilter = registered

      this.unregistered.forEach((r: Registration) => {
        const u = r.user!
        if (u.fullName.toLowerCase().indexOf(search!) >= 0 ||
          (u.jobTitle && u.jobTitle.toLowerCase().indexOf(search!) >= 0)) {
          unregistered.push(r)
        }
        this.unregisteredFilter = unregistered
      })
    } else {
      this.registeredFilter = this.sortRegistrations([...this.registered])
      this.unregisteredFilter = this.sortRegistrations([...this.unregistered])
    }

    // Sort registered into groups
    const notStarted: Registration[] = []
    const inProgress: Registration[] = []
    const passed: Registration[] = []
    const failed: Registration[] = []

    this.registeredFilter.forEach((r: Registration) => {
      switch (r.classStatus) {
        case ClassStatus.NotStarted:
          notStarted.push(r)
          break
        case ClassStatus.InProgress:
          inProgress.push(r)
          break
        case ClassStatus.Passed:
          passed.push(r)
          break
        case ClassStatus.Failed:
          failed.push(r)
          break
      }
    })

    this.notStarted = notStarted
    this.inProgress = inProgress
    this.passed = passed
    this.failed = failed
  }

  private joinUsers = () => {
    // Add User and filter out registrations if not found
    this.registered = this.registered.filter((reg: Registration) => {
      const user = this.users.find((u: User) => { return u.id === reg.userId})
      if (user) {
        reg.user = user
        return true
      } else {
        return false
      }
    })

    // this.registered.forEach((reg: Registration) => {
    //   const user = this.users.find((u: User) => { return u.id === reg.userId})
    //   if (user) {
    //     reg.user = user
    //   }
    // })

    const unregistered: Registration[] = []
    // Now add mock Registrations for the non-registered users
    this.users.forEach((u: User) => {
      let registration = this.registered.find((r: Registration) => {return r.userId === u.id})
      if (!registration) {
        registration = new Registration({
          id: null,
          accountId: u.accountId,
          classId: this.classObj!.id,
          class: this.classObj,
          userId: u.id,
          user: u,
          classStatus: ClassStatus.NotStarted,
          classProgress: 0,
          lessonNumber: 1,
          lessonStatus: LessonStatus.NotStarted,
          videoProgress: 0,
          score: 0
        })
        unregistered.push(registration)
      }
    })

    this.unregistered = unregistered
  }

  sortRegistrations = (registrations: Registration[]) => {
    // Sort by lastName, firstNname
    registrations.sort((a: Registration, b: Registration) => {
      if (a.user && b.user) {
        return a.user.lastName.localeCompare(b.user.lastName)
      } else {
        return a.id.localeCompare(b.id)
      }
    })
    return registrations
  }

  private renderGaugeSegments() {
    const { classes } = this.props
    const segments = []
    const total = this.registered.length

    if (total > 0) {
      let notStarted = 0
      let inProgress = 0
      let passed = 0
      let failed = 0

      this.registered.forEach((r: Registration) => {
        if (!r.classStatus || r.classStatus === ClassStatus.NotStarted) {
          ++notStarted
        } else if (r.classStatus === ClassStatus.InProgress) {
          ++inProgress
        } else if (r.classStatus === ClassStatus.Passed) {
          ++passed
        } else if (r.classStatus === ClassStatus.Failed) {
          ++failed
        }
      })

      let percent = Math.round(notStarted/total*100.0)
      segments.push(
        <Tooltip title={`Not Started ${percent}%`} arrow>
          <LinearGaugeSegment title="Not Started" percent={percent} className={classes.notStartedSegment}/>
        </Tooltip>
      )
      percent = Math.round(inProgress/total*100.0)
      segments.push(
        <Tooltip title={`In Progress ${percent}%`} arrow>
          <LinearGaugeSegment title="In&nbsp;Progress" percent={percent} className={classes.inProgressSegment}/>
        </Tooltip>
      )
      percent = Math.round(passed/total*100.0)
      segments.push(
        <Tooltip title={`Passed ${percent}%`} arrow>
          <LinearGaugeSegment title="Passed" percent={percent} className={classes.passedSegment}/>
        </Tooltip>
      )
      percent = Math.round(failed/total*100.0)
      segments.push(
        <Tooltip title={`Failed ${percent}%`} arrow>
          <LinearGaugeSegment title="Failed" percent={percent} className={classes.failedSegment}/>
        </Tooltip>
      )
    }


    return segments
  }
}

export default withTheme((withStyles(styles)(AccountClassPage)))